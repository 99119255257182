import initialState from './initialState'
import { SignatureState, SignatureActionTypes } from './types'

export default function signatureReducer (state = initialState, action: any): SignatureState {
  switch (action.type) {
    case SignatureActionTypes.GET_DOCUMENTS_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    case SignatureActionTypes.GET_DOCUMENTS_FULFILLED:
      return {
        ...state,
        documents: action.payload,
        isLoading: false
      }
    case SignatureActionTypes.GET_DOCUMENTS_PENDING: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case SignatureActionTypes.SET_DOCUMENT_LINK:
      return {
        ...state,
        documentLink: action.payload
      }
    case SignatureActionTypes.DOWNLOAD_DOCUMENT_PENDING:
      return {
        ...state,
        isDownloadingFile: action.payload
      }
    case SignatureActionTypes.DOWNLOAD_DOCUMENT_FULFILLED:
      return {
        ...state,
        isDownloadingFile: initialState.isDownloadingFile
      }
    case SignatureActionTypes.DOWNLOAD_DOCUMENT_REJECTED:
      return {
        ...state,
        isDownloadingFile: initialState.isDownloadingFile
      }
    default:
      return state
  }
}
