const navigation = {
  path: '/content/your-account/',
  title: 'Tu cuenta',
  fromScreen: 'myContacts'
}

const title = {
  success: 'Nuevo contacto creado',
  error: 'No se pudo agendar el contacto seleccionado.'
}
const bodyDrawer = {
  success: 'El nuevo contacto se agendó con éxito',
  error: ''
}

export { bodyDrawer, navigation, title }
