import { ThunkDispatchType } from 'store'
import * as EnterpriseActionsTypes from 'store/enterprise/actions'
import { EnterpriseServices } from 'services'
import { AssociateCompany } from './types'

export const getEnterprise = (cuit: string) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(EnterpriseActionsTypes.getEnterprisePending())
      const response = await EnterpriseServices.getEnterprise(cuit)
      dispatch(EnterpriseActionsTypes.getEnterpriseFulfilled(response.data))
    } catch (error) {
      dispatch(EnterpriseActionsTypes.getEnterpriseRejected(error as Error))
      throw error
    }
  }

export const getSendEmailDocuments = () =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(EnterpriseActionsTypes.getSendEmailDocumentsPending())
      await EnterpriseServices.getSendEmailDocuments()
      dispatch(EnterpriseActionsTypes.getSendEmailDocumentsFulfilled())
    } catch (error) {
      dispatch(EnterpriseActionsTypes.getSendEmailDocumentsRejected(error as Error))
      throw error
    }
  }

export const associateCompany = (body: AssociateCompany) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(EnterpriseActionsTypes.associateCompanyPending())
      await EnterpriseServices.associateCompany(body)
      dispatch(EnterpriseActionsTypes.associateCompanyFulfilled())
    } catch (error) {
      dispatch(EnterpriseActionsTypes.associateCompanyRejected(error as Error))
      throw error
    }
  }

export const uploadDocuments = (files: File[]) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(EnterpriseActionsTypes.uploadDocumentsPending())
      const dni = localStorage.getItem('dni') as string
      const formData = new FormData()
      files.forEach((file: File) => {
        formData.append('files', file)
      })
      await EnterpriseServices.uploadDocuments(formData, dni)
      dispatch(EnterpriseActionsTypes.uploadDocumentsFulfilled())
    } catch (error) {
      dispatch(EnterpriseActionsTypes.uploadDocumentsRejected(error as Error))
      throw error
    }
  }
