import React from 'react'
import ConnectivityErrorSVG from 'assets/svg/ConnectivityErrorSVG'
import QiraPagosLogoWeb from '@qirapagos/web/src/assets/svg/QirapagosLogo'
import { BoxContent, BoxLogoContainer, BoxOutside, BoxTextContainer } from './styles'
import { violet } from '@qirapagos/lib/theme/colors'
import CustomText from 'components/atoms/CustomText'

const ErrorScreen: React.FC = () => {
  return (
    <BoxOutside>
      <BoxContent>
        <ConnectivityErrorSVG />
        <BoxLogoContainer>
          <QiraPagosLogoWeb width={220} height={30} color={violet} />
          <BoxTextContainer>
            <CustomText
              size="mmbig"
              weight="regular"
            >
              La URL ingresada no puede ser encontrada por éste servidor.
            </CustomText>
          </BoxTextContainer>
        </BoxLogoContainer>
      </BoxContent>
    </BoxOutside>
  )
}

export default ErrorScreen
