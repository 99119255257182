import { purple, white } from '@qirapagos/lib/theme/colors'
import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'

export const ViewContainer = styled.View`
  z-index: 100;
`

export const TextList = styled.Text`
  flex: 1;
  font-size: 1rem;
  margin-left: 15px;
`

export const TextEmptyResult = styled.Text`
  margin: 10px 0 10px 10px;
`

export const styles = StyleSheet.create({
  downArrow: {
    position: 'absolute',
    right: 25,
    top: 25
  },
  flatList: {
    borderWidth: 2,
    borderColor: purple,
    position: 'absolute',
    width: '100%',
    top: 62,
    zIndex: 100,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: white
  },
  customStyleContainer: {
    borderStyle: 'solid',
    borderColor: purple,
    borderTopLeftRadius: 9,
    borderTopRightRadius: 9,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  check: {
    width: 22,
    height: 22,
    borderRadius: 11,
    borderColor: purple,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20
  },
  checkmark: {
    position: 'absolute'
  },
  customInputContainerStyle: {
    borderColor: 'white'
  }
})
