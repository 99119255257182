import React from 'react'
import { List, EmptyMessage } from './styles'
import ActivityItem from '../../molecules/ActivityItem'
import { useAppSelector } from 'hooks/redux'
import { ActivityMovement } from 'store/activity/types'

const MovementsList: React.FC = () => {
  const { activity } = useAppSelector((state) => state.activity)

  if (!activity?.length) {
    return (
      <EmptyMessage>
        Aún no has realizado ningún movimiento
      </EmptyMessage>
    )
  }

  return (
    <List>
      {activity.map((item: ActivityMovement) => (
        <ActivityItem activity={item} />
      ))}
    </List>
  )
}
export default MovementsList
