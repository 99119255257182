/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Ellipse, Circle, Path } from 'react-native-svg'

interface Props {
  color: string
}

function WheatItem ({ color }: Props) {
  return (
    <Svg width={42} height={43} viewBox="0 0 42 43" fill="none">
      <Ellipse cx={21} cy={21.5} rx={17} ry={17} fill="#fff" />
      <Circle
        cx={21}
        cy={21.5}
        r={20}
        fill="#fff"
        stroke={color}
        strokeWidth={2}
      />
      <Path
        d="M29.858 12.779l-.023-.235-.234-.023a8.118 8.118 0 00-1.75.062c-1.297.183-2.361.658-3.078 1.374-.716.716-1.19 1.78-1.373 3.077-.07.492-.085.927-.083 1.241l-2.14 2.14c.246-.813.304-1.594.164-2.296-.474-2.384-3.104-3.816-3.216-3.876l-.208-.11-.149.182c-.02.024-.497.61-.92 1.489-.57 1.18-.766 2.328-.568 3.322.345 1.734 1.83 2.964 2.658 3.529l-3.085 3.084c.247-.814.304-1.594.165-2.297-.475-2.384-3.105-3.815-3.216-3.875l-.208-.111-.15.182c-.02.024-.497.61-.92 1.49-.57 1.18-.765 2.328-.568 3.321.345 1.735 1.83 2.964 2.658 3.53l-2.735 2.734.788.787 2.735-2.736c.168.245.395.548.677.864.872.977 1.858 1.598 2.851 1.795.266.053.532.077.793.077 2.084 0 3.931-1.494 4.018-1.565l.182-.15-.11-.207a8.112 8.112 0 00-1.024-1.42c-.873-.978-1.859-1.599-2.852-1.796-.797-.159-1.588-.054-2.292.16l3.08-3.081c.169.245.395.548.677.863.872.977 1.858 1.598 2.852 1.796.266.053.531.076.793.076 2.083 0 3.93-1.493 4.018-1.565l.182-.149-.111-.207a8.099 8.099 0 00-1.024-1.421c-.872-.977-1.858-1.598-2.852-1.796-.797-.158-1.588-.053-2.291.16l2.135-2.135h.047c.31 0 .727-.018 1.194-.083 1.297-.183 2.361-.658 3.078-1.374.716-.716 1.19-1.78 1.373-3.077a8.102 8.102 0 00.062-1.75z"
        fill={color}
      />
    </Svg>
  )
}

export default WheatItem
