import styled from 'styled-components'
import { ReactComponent as ExpiredIcon } from 'assets/svg/Expired.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
`

export const Expired = styled(ExpiredIcon)`
  width: 300px;
`

export const buttonStyles = {
  maxWidth: '350px'
}
