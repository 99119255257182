export const customListStyles = {
  title: {
    flex: 0.8,
    textAlign: 'right',
    marginRight: 10
  },
  flatList: {
    flexGrow: 0
  }
}
