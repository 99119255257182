import styled from 'styled-components'
import { white } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${white};
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;

  @media ${DEVICES.mobile} {
    height: 130%;
  }
`
