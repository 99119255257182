import * as React from 'react'
import Svg, { Rect, Path } from 'react-native-svg'

function PlusWithBackground () {
  return (
    <Svg
      width={50}
      height={50}
      viewBox="0 0 38 37"
      fill="none"
    >
      <Rect x={0.5} width={37} height={37} rx={6} fill="#F0EDFF" />
      <Path d="M19.5 12v14M26.5 19h-14" stroke="#7D66FF" strokeWidth={2} />
    </Svg>
  )
}

export default PlusWithBackground
