import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'
import { Text as ChakraText } from '@chakra-ui/react'
import { Colors, Fonts } from 'theme'

export const BoxContainer = styled.button`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 45%;
  height: 100%;
  border-radius: 6px;
  margin-right: 2%;
  gap: 25px;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.13);
  max-width: 500px;
  &:hover {
    background-color: #f0edff;
  }

  @media ${DEVICES.tablet} {
    width: 70%;
  }

  @media ${DEVICES.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 200px;
  }
`

export const Text = styled(ChakraText)`
  color: ${Colors.disabledGray};
  align-self: center;
  font-weight: 500;
  padding: 15px 5px;
  font-size: ${Fonts.SIZES.MMBIG}px;

  @media ${DEVICES.tablet} {
    font-size: ${Fonts.SIZES.BIG}px;
  }

  @media ${DEVICES.mobile} {
    font-size: ${Fonts.SIZES.SMALL}px;
  }
`
