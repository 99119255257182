import React from 'react'
import { Container, Row, Label, Value } from './styles'
import {
  QuotaComplete
} from '@qirapagos/web/src/store/contracts/types'

interface Props {
  quota: QuotaComplete
}

const QuotaDetailList: React.FC<Props> = ({ quota }) => {
  return (
    <Container>
      <Row>
        <Label>Nro del Contrato: </Label>
        <Value>{quota?.saleOrderId || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Código del cupo: </Label>
        <Value>{quota?.quotaCode || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Fecha de Entrega: </Label>
        <Value>{quota?.deliveryDate || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Intermediario: </Label>
        <Value>{quota?.Intermediary?.companyName || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Remitente: </Label>
        <Value>{quota?.Remitter?.companyName || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Corredor comprador: </Label>
        <Value>{quota?.BrokerBuyer?.companyName || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Corredor vendedor: </Label>
        <Value>{quota?.BrokerSeller?.companyName || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Entregador: </Label>
        <Value>{quota?.Deliverer?.deliverer || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Destinatario: </Label>
        <Value>{quota?.Receiver?.receiver || 'Sin datos' }</Value>
      </Row>
      <Row>
        <Label>Destino: </Label>
        <Value>{quota?.Location?.location || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Transportista: </Label>
        <Value>{quota?.Transport?.transport || 'Sin datos'}</Value>
      </Row>
      <Row>
        <Label>Observaciones: </Label>
        <Value>{quota?.observation || 'Sin datos'}</Value>
      </Row>
    </Container>
  )
}

export default QuotaDetailList
