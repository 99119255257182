import React from 'react'
import { Menu, MenuList } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import TripleDot from 'assets/svg/TripleDot'
import {
  Container,
  HeaderInformation,
  Title,
  Date,
  Information,
  Icon,
  Detail,
  MenuButton,
  MenuItem
} from './styles'
import { removeNotification, markAsViewNotification } from 'store/notifications/thunks'
import { setNotificationSingular } from 'store/notifications/actions'
import { Notification } from 'store/notifications/types'
import {
  setShowDrawerNotificationsDetails,
  setShowDrawerNotification
} from 'store/modals/actions'

const Notifications: React.FC<Notification> = ({
  id,
  title,
  body,
  createdAt,
  viewed
}) => {
  const dispatch = useDispatch()

  return (
    <Container viewed={viewed}>
      <Icon />
      <Information>
        <HeaderInformation>
          <Title>
            {title}
          </Title>
          <Date>
            {createdAt}
          </Date>
        </HeaderInformation>
        <Detail>
          {body}
        </Detail>
      </Information>
      <Menu>
        <MenuButton>
          <TripleDot />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => dispatch(removeNotification(id))}>
            Eliminar
          </MenuItem>
          <MenuItem onClick={() => {
            dispatch(setShowDrawerNotificationsDetails(true))
            dispatch(setShowDrawerNotification(false))
            dispatch(setNotificationSingular(id))
            dispatch(markAsViewNotification(id))
          }}
          >
            Ver detalle
          </MenuItem>
        </MenuList>
      </Menu>
    </Container>
  )
}

export default Notifications
