import { StyleSheet } from 'react-native'
import { purple, error, black, graySoft } from '@qirapagos/lib/theme/colors'
import { SIZES, POPPINS_REG } from '@qirapagos/lib/theme/fonts'

export const getFieldStyle = (withPadding: boolean) => {
  return withPadding
    ? {
        paddingTop: '5px',
        paddingBottom: '5px'
      }
    : {
        padding: 0,
        marginBottom: 6,
        height: 26,
        lineHeight: 22
      }
}

export default StyleSheet.create({
  container: {
    borderWidth: 2,
    borderColor: graySoft,
    borderRadius: 10,
    marginBottom: 20,
    maxHeight: 64
  },
  inputContainer: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: purple,
    paddingHorizontal: 16
  },
  smallpadding: {
  },
  blured: {
    borderColor: graySoft,
    backgroundColor: graySoft
  },
  label: {
    zIndex: -10,
    marginTop: 7,
    lineHeight: 19
  },
  input: {
    zIndex: 100,
    fontFamily: POPPINS_REG,
    fontSize: SIZES.XMEDIUM,
    fontWeight: '400',
    color: black,
    marginBottom: 2
  },
  error: {
    marginLeft: 5
  },
  placeholder: {
    zIndex: -10,
    position: 'absolute',
    top: 18,
    left: 16,
    color: '#616A8F'
  },
  errorContainer: {
    borderColor: error
  },
  eyeIcon: {
    position: 'absolute',
    right: 30,
    top: 20,
    zIndex: 101
  },
  errorContainerText: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 4
  }
})
