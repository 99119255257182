import React, { forwardRef } from 'react'
import CustomText from 'components/atoms/CustomText'
import { Controller } from 'react-hook-form'
import { KeyboardTypeOptions, TextInput } from 'react-native'
import styles from './styles'

interface Props {
  control: any
  clearErrors?: any
  name: string
  rules?: object
  errorTestID?: string
  defaultValue?: string
  placeholder: string
  placeholderTextColor: string
  testID?: string
  errorMessage?: object
  error?: any
  type?: KeyboardTypeOptions
}

const BorderlessField = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      placeholderTextColor,
      testID = '',
      type = 'default',
      control,
      clearErrors,
      errorTestID = '',
      errorMessage,
      name,
      rules = {},
      error = false,
      defaultValue = ''
    },
    ref
  ) => {
    return (
      <>
        <Controller
          control={control}
          render={({ onChange }) => (
            <TextInput
              style={styles.searchInput}
              placeholder={placeholder}
              placeholderTextColor={placeholderTextColor}
              onChange={clearErrors && (() => onChange(clearErrors()))}
              onChangeText={onChange}
              testID={testID}
              keyboardType={type || 'default'}
              ref={ref as any}
            />
          )}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
        />
        {error && errorMessage && (
          <CustomText
            size="medium"
            color="red"
            align="left"
            testID={errorTestID}
            customStyle={styles.error}
          >
            {
              error.message
            }
          </CustomText>
        )}
      </>
    )
  }
)

export default BorderlessField

BorderlessField.defaultProps = {
  clearErrors: '',
  rules: undefined,
  errorTestID: '',
  defaultValue: '',
  testID: '',
  error: '',
  type: undefined,
  errorMessage: undefined
}
