import styled from 'styled-components'
import { ReactComponent as LiquidationIcon } from 'assets/svg/LiquidationIcon.svg'
import { ReactComponent as ApplicationIcon } from 'assets/svg/ApplicationIcon.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
`

export const Header = styled.div`
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  margin-left: 1%;
  margin-right: 1%;
  overflow: scroll;
  height: 58vh;
`

export const Title = styled.p`
  align-self: flex-start;
  font-size: 25px;
  font-weight: 500;
  padding-left: 3.7vw;
  margin-bottom: 20px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Liquidation = styled(LiquidationIcon)`
  margin-right: 10px;
`
export const Application = styled(ApplicationIcon)`
  margin-right: 10px;
`
