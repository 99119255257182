import React from 'react'
import { CloseButton as CloseButtonSVG } from 'assets/svg/CloseButton'
import { CloseButton, IconContainer, ModalTitle } from './styles'

import { Modal as ModalNativeBase } from 'native-base'
import { purple, white } from '@qirapagos/lib/theme/colors'
import { Box } from '@chakra-ui/react'

interface Props {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  title: string
  icon?: any
  customStyle?: any
}

const Modal: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  title,
  icon,
  customStyle
}) => {
  return (
    <ModalNativeBase isOpen={isOpen} onClose={onClose}>
      <ModalNativeBase.Content maxWidth="430px">
        <ModalNativeBase.Header minHeight="50px" bg={purple} color={white}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            flexDirection="row"
            style={customStyle}
          >
            <IconContainer>{icon}</IconContainer>
            <ModalTitle>{title}</ModalTitle>
            <CloseButton onClick={onClose}>
              <CloseButtonSVG />
            </CloseButton>
          </Box>
        </ModalNativeBase.Header>
        <ModalNativeBase.Body textOverflow="ellipsis" py={10}>{children}</ModalNativeBase.Body>
      </ModalNativeBase.Content>
    </ModalNativeBase>
  )
}

export default Modal

Modal.defaultProps = {
  icon: null,
  customStyle: {}
}
