import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { white } from '@qirapagos/lib/theme/colors'

const GrainDesktopSkeleton = () => (
  <ContentLoader
    speed={3}
    interval={0}
    width="100%"
    height="100vh"
    viewBox="0 0 100% 100vh"
    backgroundColor="#e8e7f7"
    foregroundColor={white}
  >
    <Rect x="7%" y="9vh" rx="8" ry="6" width="20%" height="18.5%" />
    <Rect x="29%" y="9vh" rx="8" ry="6" width="20%" height="18.5%" />
    <Rect x="51%" y="9vh" rx="8" ry="6" width="20%" height="18.5%" />
    <Rect x="73%" y="9vh" rx="8" ry="6" width="20%" height="18.5%" />
    <Rect x="0.2%" y="30vh" rx="0" ry="0" width="100%" height="70%" />
  </ContentLoader>
)

export default GrainDesktopSkeleton
