import React from 'react'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { useFormik } from 'formik'
import { useTable } from 'hooks/useTable'
import { formatDateForFilter } from '@qirapagos/lib/utils/common'
import { FilterType } from 'constants/filters'
import {
  Drawer,
  Title,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  ContentContainer,
  Button,
  ButtonsContainer
} from './styles'
import { Drawers } from 'store/drawer/types'
import { DatePickerField } from 'components/shared/DatePickerField'
import wrapper from 'components/organisms/Drawers/wrapper'
import * as Yup from 'yup'

export interface FormValues {
  dateFrom: Date | undefined
  dateTo: Date | undefined
}

interface Props {
  showDrawer: boolean
  onClose: () => void
}

const DrawerFilterDate: React.FC<Props> = ({ showDrawer, onClose }) => {
  const {
    handleDateFilterType,
    handleDatesToFilter,
    selectedDateFilter,
    datesToFilter
  } = useTable()

  const isFilterSelected = selectedDateFilter !== FilterType.ALL_DATES

  const onSubmit = ({ dateFrom, dateTo }: FormValues) => {
    if (dateFrom && dateTo) {
      handleDateFilterType(
        `${formatDateForFilter(new Date(dateFrom))} - ${formatDateForFilter(
          new Date(dateTo)
        )}`
      )
      handleDatesToFilter(dateFrom, dateTo)
      onClose()
    }
  }

  const formik = useFormik({
    initialValues: {
      dateFrom: isFilterSelected ? datesToFilter.dateFrom : undefined,
      dateTo: isFilterSelected ? datesToFilter.dateTo : undefined
    },
    validationSchema: Yup.object().shape({
      dateTo: Yup.date().when('dateFrom', (dateFrom: Date, schema: any) => {
        return dateFrom
          ? schema.min(
            dateFrom,
            'La fecha hasta debe ser mayor a la fecha desde'
          )
          : schema
      })
    }),
    onSubmit
  })

  const resetFilter = () => {
    handleDateFilterType(FilterType.ALL_DATES)
    onClose()
  }

  return (
    <Drawer open={showDrawer} onClose={onClose} direction="right">
      <DrawerContainer>
        <HeaderContainer>
          <BackButton onPress={onClose}>
            <BackArrowIcon />
          </BackButton>
          <Title>Filtrar por fecha</Title>
        </HeaderContainer>
        <ContentContainer>
          <DatePickerField formik={formik} name="dateFrom" label="Desde" />
          <DatePickerField formik={formik} name="dateTo" label="Hasta" />
        </ContentContainer>
        <ButtonsContainer>
          <Button onPress={formik.handleSubmit}>Aplicar Filtro</Button>
          <Button onPress={resetFilter} secondary>
            Reiniciar Filtro
          </Button>
        </ButtonsContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(DrawerFilterDate, Drawers.FILTER_DATE)
