import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function Transfer () {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 24 25"
      fill="none"
    >
      <Path
        d="M18 14l3 3-3 3M3 17h18M6 11L3 8l3-3M21 8H3"
        stroke="#F5F6FA"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Transfer
