import React from 'react'
import OperationSuccess from '@qirapagos/lib/assets/svg/OperationSuccess'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  ButtonsContainer,
  Drawer,
  ContentContainer,
  DrawerContainer,
  TextBody,
  BackButton,
  MainButton,
  MainButtonText
} from './styles'
import BackArrow from 'assets/svg/BackArrow'
import { Drawers } from 'store/drawer/types'
import wrapper from 'components/organisms/Drawers/wrapper'
import { Colors } from '@qirapagos/lib/theme'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const SaleOrderSuccess: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { onNavigate } = useNavigation()

  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
    >
      <DrawerContainer>
        <BackButton onPress={onClose}>
          <BackArrow color={Colors.white} />
        </BackButton>
        <ContentContainer>
          <OperationSuccess />
          <Title>Orden enviada</Title>
          <TextBody>Te notificaremos por mail dentro de las próximas 24 hs el resultado de tu solicitud.</TextBody>
        </ContentContainer>
        <ButtonsContainer>
          <MainButton
            onPress={() => {
              onClose()
              onNavigate('grains')
            }}
          >
            <MainButtonText testID="drawer-btn">
              Ir a contratos
            </MainButtonText>
          </MainButton>
        </ButtonsContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(SaleOrderSuccess, Drawers.SALE_ORDER_SUCCESS)
