export interface AccountSummary {
    id: string
    period: string
    url: string
    createdAt: string
    updatedAt: string
    accountId: string
  }

export interface AccountState {
    summaries: AccountSummary[] | undefined
    isLoading: boolean
    error: boolean
  }

export enum AccountActionTypes {
    GET_ACCOUNT_SUMMARIES_PENDING = '@ACCOUNT:GET_ACCOUNT_SUMMARIES_PENDING',
    GET_ACCOUNT_SUMMARIES_FULFILLED = '@ACCOUNT:GET_ACCOUNT_SUMMARIES_FULFILLED',
    GET_ACCOUNT_SUMMARIES_REJECTED = '@ACCOUNT:GET_ACCOUNT_SUMMARIES_REJECTED',
  }
