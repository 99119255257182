import { SaleOrder } from 'store/contracts/types'

export enum Drawers {
  CONFIRM_NEW_SALE_ORDER = 'CONFIRM_NEW_SALE_ORDER',
  SALE_ORDER_SUCCESS = 'SALE_ORDER_SUCCESS',
  SALE_ORDER_ERROR = 'SALE_ORDER_ERROR',
  SALE_ORDER_DETAILS = 'SALE_ORDER_DETAILS',
  COUNTER_OFFER_SALE_ORDER = 'COUNTER_OFFER_SALE_ORDER',
  OUT_OF_TIME = 'OUT_OF_TIME',
  QUOTA_DETAILS = 'QUOTA_DETAILS',
  QUOTA_CREATE_SUCCESS = 'QUOTA_CREATE_SUCCESS',
  HOW_WORKS_QUOTA = 'HOW_WORKS_QUOTA',
  APPLICATIONS_DETAILS = 'APPLICATIONS_DETAILS',
  LIQUIDATIONS_DETAILS = 'LIQUIDATIONS_DETAILS',
  FILTER_DATE = 'FILTER_DATE',
  REPRESENTED_DETAIL = 'REPRESENTED_DETAIL',
  RECEIPT_DETAIL = 'RECEIPT_DETAIL',
  EMAIL_SENT_SUCCESS = 'EMAIL_SENT_SUCCESS',
  EMAIL_SENT_ERROR = 'EMAIL_SENT_ERROR',
  DOCUMENTS_SENT_SUCCESS = 'DOCUMENTS_SENT_SUCCESS',
  DOCUMENTS_SENT_ERROR = 'DOCUMENTS_SENT_ERROR'
}

export enum DrawerActionTypes {
  SHOW_DRAWER = '@DRAWER:SHOW_DRAWER',
  HIDE_DRAWER = '@DRAWER:HIDE_DRAWER'
}

export interface Action {
  type: string
  payload: any
}

interface Data {
  [Drawers.CONFIRM_NEW_SALE_ORDER]: {
    saleOrder: SaleOrder
  }
}

export interface DrawerState {
  actives: Drawers[]
  data: Partial<Data>
}
