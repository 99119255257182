/* eslint-disable react/require-default-props */
import React from 'react'
import { FormikProps } from 'formik'
import TextField from '@material-ui/core/TextField'
import { AutocompleteRenderInputParams, AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete'
import { Autocomplete as AutocompleteMUI } from './styles'

export interface Option {
  title: string,
  id: string | number,
}

interface Props {
  name: string;
  label: string;
  options: Option[];
  loading?: boolean;
  onInputChange?: (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
  formik: FormikProps<any>
}

export const AutocompleteField: React.FC<Props> = (props) => {
  const { options, label, name, loading, formik, onInputChange } = props

  return (
    <AutocompleteMUI
      options={options}
      onChange={(_, value: any) => formik.setFieldValue(name, value?.id)}
      getOptionLabel={(option: any) => option.title}
      value={options.find((option) => option.id === formik.values[name]) || null}
      style={{ width: 300 }}
      loading={loading}
      loadingText="Cargando"
      noOptionsText="Sin resultados"
      onInputChange={onInputChange}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={label}
            variant="filled"
            error={formik.touched?.[name] && Boolean(formik.errors?.[name])}
            helperText={formik.touched?.[name] && formik.errors?.[name]}
          />
        )
      }}
    />
  )
}
