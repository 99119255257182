import { gray, graySoft, purple, violetPressed } from 'theme/colors'
import { ReactComponent as EnvelopeIcon } from 'assets/svg/Envelope.svg'
import {
  MenuButton as MenuButtonChakra,
  MenuItem as MenuItemChakra
} from '@chakra-ui/react'
import styled from 'styled-components'

interface IContainer {
  viewed: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  width: 100%;
  padding: 2.5rem;
  border-bottom-color: ${graySoft};
  border-bottom-width: 2px;
  background-color: ${props => props.viewed ? 'transparent' : violetPressed};
  &:nth-child(1) {
    border-top-color: ${graySoft};
    border-top-width: 2px;
  }
`

export const Information = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  width: calc(100% - 6.2rem);
  height: 3rem;
`

export const HeaderInformation = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Date = styled.div`
  font-size: 1rem;
  color: ${gray};
`

export const Detail = styled.div`
  font-size: 0.9rem;
  font-weight: 200;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Icon = styled(EnvelopeIcon)`
  width: 3rem;
  height: 3rem;
`

export const MenuButton = styled(MenuButtonChakra)`
  width: 1.2rem;
`

export const MenuItem = styled(MenuItemChakra)`
  font-weight: 500;
  &:hover {
    color: ${purple};
  }
`
