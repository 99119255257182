import { ListItemType } from 'constants/common'

export interface MenuItem {
  id: number
  title: string
  icon: ListItemType
  isCollapsable?: boolean
  to: string
}

export interface RenderItemProps {
  item: MenuItem
  index: number
  setShowMenuDrawer?: (value: boolean) => void
}

export enum ScreenTitleSideBar {
  MY_ENTERPRISES = 'Mis empresas',
  YOUR_ACCOUNT = 'Tu cuenta',
  BLOCK_ACCOUNT = 'Bloquear cuenta'

}
