/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path, G, Defs, LinearGradient, Stop } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent () {
  return (
    <Svg
      width={174}
      height={159}
      viewBox="0 0 174 159"
      fill="none"
    >
      <Path
        d="M83.71 123.734c32.699 0 59.207-26.54 59.207-59.392 0-32.852-26.625-59.39-59.206-59.39-32.698 0-59.207 26.538-59.207 59.39 0 32.853 26.509 59.392 59.207 59.392z"
        fill="#EAEEF9"
      />
      <Path
        d="M139.063 25.41a4.79 4.79 0 004.788-4.793 4.79 4.79 0 00-4.788-4.794 4.79 4.79 0 00-4.788 4.794 4.79 4.79 0 004.788 4.793zM146.07 6.704a3.271 3.271 0 003.27-3.273 3.271 3.271 0 10-6.54 0 3.272 3.272 0 003.27 3.273zM27.657 25.293a3.272 3.272 0 003.27-3.273 3.272 3.272 0 00-3.27-3.274 3.272 3.272 0 00-3.27 3.274 3.272 3.272 0 003.27 3.273zM7.688 88.426a6.076 6.076 0 006.072-6.079 6.076 6.076 0 00-6.072-6.08 6.076 6.076 0 00-6.073 6.08 6.076 6.076 0 006.073 6.08z"
        fill="#E7EBF6"
      />
      <G>
        <Path
          d="M100.877 32.775l17.867 64.302c.584 2.221-.701 4.443-2.919 5.027l-62.944 16.719a4.075 4.075 0 01-5.021-2.923L25.905 32.892c-.584-2.221.701-4.442 2.92-5.027l45.543-12.042 26.509 16.952z"
          fill="url(#paint0_linear_4450_13541)"
        />
      </G>
      <Path
        d="M99.943 73.46L65.96 82.464l-3.736.935-11.328 3.04c-.467.117-.817.702-.584 1.286.117.585.7.935 1.168.819l11.327-3.04 3.737-.936 33.983-9.002c.467-.117.817-.701.584-1.286-.117-.584-.701-.935-1.168-.818zM97.724 65.511l-12.612 3.274-4.32 1.169-31.881 8.535c-.467.117-.818.701-.584 1.286.117.584.7.935 1.168.818l31.997-8.535 4.32-1.169 12.613-3.273c.467-.117.817-.702.584-1.286-.234-.468-.818-.936-1.285-.819zM95.856 57.444l-3.27.819-3.27.818-42.624 11.34c-.467.118-.818.702-.584 1.287.117.584.7.935 1.168.818L89.9 61.186l3.62-.936 2.92-.818c.467-.117.817-.701.584-1.286-.117-.468-.584-.818-1.168-.702z"
        fill="#D5DDEA"
      />
      <Path
        d="M76.12 98.83l-2.336.585c-.467.117-1.05-.233-1.167-.818-.117-.584.117-1.169.584-1.286l2.335-.585c.467-.116 1.051.234 1.168.819.117.701-.117 1.169-.584 1.286z"
        fill="#CED7E2"
      />
      <Path
        d="M68.997 100.701l-13.43 3.507c-.467.117-1.05-.233-1.168-.818-.116-.585.117-1.169.584-1.286l13.43-3.507c.467-.117 1.05.233 1.168.818.233.585 0 1.169-.584 1.286zM94.22 49.377l-10.626 2.806-2.57.702-36.317 9.587c-.468.117-.818.701-.584 1.286.117.584.7.935 1.168.818l36.2-9.47 2.57-.701 10.51-2.806c.584-.117.817-.702.7-1.403 0-.468-.583-.935-1.05-.819zM69.114 31.724l-30.13 7.95c-.7.233-1.4-.234-1.634-.936-.234-.701.233-1.402.934-1.636l30.129-7.95c.7-.234 1.401.233 1.635.935.233.701-.234 1.403-.934 1.637zM52.531 43.883l-11.56 3.04c-.702.233-1.402-.234-1.636-.936-.233-.701.234-1.403.934-1.637l11.445-3.04c.7-.233 1.401.235 1.635.936.233.702-.234 1.403-.818 1.637zM74.369 15.823l4.67 17.77c.702 2.456 3.388 3.976 5.84 3.274l15.998-4.092"
        fill="#D5DDEA"
      />
      <G>
        <Path
          d="M148.055 50.898l-10.743 65.938c-.351 2.221-2.569 3.741-4.788 3.274L68.53 108.886c-2.22-.351-3.737-2.572-3.387-4.793l14.83-84.528c.351-2.221 2.57-3.74 4.789-3.273l46.477 8.184 16.816 26.422z"
          fill="url(#paint1_linear_4450_13541)"
        />
      </G>
      <Path
        d="M130.188 87.49l-34.566-6.079-3.737-.701-11.56-1.988c-.468-.117-1.052.35-1.052.935-.117.585.234 1.17.7 1.287l11.562 1.987 3.737.701 34.566 6.08c.467.117 1.051-.35 1.051-.936.117-.584-.117-1.169-.701-1.285zM131.59 79.424l-12.846-2.221-4.321-.819-32.58-5.728c-.468-.117-1.052.35-1.052.935-.117.585.234 1.17.7 1.286l32.582 5.729 4.321.818 12.845 2.221c.467.117 1.051-.35 1.051-.935.117-.584-.233-1.169-.7-1.286z"
        fill="#CED7E2"
      />
      <Path
        d="M133.224 71.24l-3.386-.584-3.387-.585-43.441-7.599c-.467-.117-1.051.35-1.051.935-.117.585.233 1.17.7 1.287l43.442 7.599 3.737.701 2.919.468c.467.117 1.051-.35 1.051-.935.234-.585 0-1.17-.584-1.287z"
        fill="#D5DDEA"
      />
      <Path
        d="M97.958 100.585l-2.336-.351c-.467-.116-.817-.701-.7-1.286.116-.584.583-1.052 1.05-.935l2.336.35c.467.118.818.702.7 1.287-.116.701-.583.935-1.05.935z"
        fill="#CED7E2"
      />
      <Path
        d="M90.834 99.298L77.171 96.96c-.467-.117-.817-.701-.7-1.286.116-.585.583-1.052 1.05-.935l13.663 2.338c.468.117.818.701.701 1.286-.117.701-.584 1.052-1.05.935z"
        fill="#E3EAF2"
      />
      <Path
        d="M135.093 63.29l-10.86-1.87-2.686-.468-37.019-6.547c-.467-.117-1.05.35-1.05.935-.118.585.233 1.17.7 1.286l37.018 6.547 2.686.468 10.744 1.87c.584.117 1.051-.35 1.168-.935.117-.584-.234-1.169-.701-1.286zM119.795 36.751l-30.712-5.378c-.701-.117-1.168-.818-1.051-1.52.116-.701.817-1.169 1.518-1.052l30.712 5.378c.701.117 1.168.818 1.051 1.52-.117.584-.817 1.169-1.518 1.052zM99.593 40.726L87.797 38.62c-.7-.117-1.168-.818-1.051-1.52.117-.701.817-1.169 1.518-1.052l11.678 2.105c.701.116 1.168.818 1.051 1.52-.117.7-.818 1.169-1.401 1.052zM131.122 24.476l-3.153 18.12c-.467 2.573 1.402 5.028 3.971 5.496l16.115 2.806"
        fill="#D5DDEA"
      />
      <Path
        d="M104.03 110.639c14.382 0 26.042-11.673 26.042-26.072 0-14.398-11.66-26.07-26.042-26.07-14.382 0-26.041 11.672-26.041 26.07 0 14.4 11.659 26.072 26.041 26.072z"
        fill="#fff"
      />
      <Path
        d="M101.578 88.777c-.117-.468-.117-.936-.117-1.52 0-1.988.818-3.741 2.92-5.261l1.751-1.286c1.051-.819 1.518-1.754 1.518-2.923 0-1.637-1.167-3.274-3.62-3.274-2.569 0-3.737 2.105-3.737 4.092 0 .468 0 .819.117 1.17l-4.671-.117c-.117-.468-.234-1.053-.234-1.637 0-3.975 2.92-7.716 8.408-7.716 5.606 0 8.642 3.624 8.642 7.248 0 2.923-1.518 4.91-3.62 6.43l-1.518 1.053c-1.285.935-1.985 2.104-1.985 3.507v.234h-3.854zm1.985 2.338a2.893 2.893 0 012.92 2.923 2.893 2.893 0 01-2.92 2.923 2.892 2.892 0 01-2.919-2.923c.116-1.52 1.284-2.923 2.919-2.923z"
        fill="#989FB0"
      />
      <Path
        d="M143.011 125.046c-1.012 0-2.022-.404-2.831-1.315l-16.88-16.9-.608.405c-5.458 4.048-11.826 6.173-18.295 6.173-7.783 0-15.567-3.137-21.329-8.602-6.064-5.768-9.4-13.56-9.4-22.061 0-16.9 13.747-30.663 30.628-30.663 11.523 0 21.631 6.072 27.089 16.394 5.358 10.221 4.651 22.162-1.919 31.776l-.404.607 16.981 17.002c1.718 1.72 1.314 3.441 1.011 4.351-.809 1.62-2.426 2.833-4.043 2.833zm-38.715-64.564c-12.332 0-22.238 10.02-22.238 22.264 0 13.965 11.422 22.365 22.541 22.365 6.773 0 12.939-3.036 17.285-8.501 5.358-6.679 6.268-15.686 2.527-23.478-3.84-7.792-11.523-12.65-20.115-12.65z"
        fill="#989FB0"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_4450_13541"
          x1={72.2944}
          y1={13.4374}
          x2={72.2944}
          y2={120.075}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FDFEFF" />
          <Stop offset={0.9964} stopColor="#ECF0F5" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_4450_13541"
          x1={106.547}
          y1={13.8017}
          x2={106.547}
          y2={121.315}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FDFEFF" />
          <Stop offset={0.9964} stopColor="#ECF0F5" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

export default SvgComponent
