import { ThunkDispatchType } from 'store'
import * as RepresentationActions from 'store/representation/actions'
import { RepresentationService } from 'services'
import {
  setShowDrawerError,
  setShowDrawerMyAgent,
  setShowDrawerSuccess
} from 'store/modals/actions'

export const getRepresented =
  (id: number) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(RepresentationActions.getRepresentedPending())
      const response = await RepresentationService.getRepresented(id)
      dispatch(
        RepresentationActions.getRepresentedFulfilled(response.data.data)
      )
    } catch (error) {
      dispatch(RepresentationActions.getRepresentedRejected(error as Error))
    }
  }

export const getIsRepresentative =
  () => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(RepresentationActions.getIsRepresentativePending())
      const response = await RepresentationService.getIsRepresentative()
      dispatch(
        RepresentationActions.getIsRepresentativeFulfilled(response.data.data)
      )
    } catch (error) {
      dispatch(
        RepresentationActions.getIsRepresentativeRejected(error as Error)
      )
    }
  }

export const getRepresentative = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(RepresentationActions.getRepresentativePending())
    const response = await RepresentationService.getRepresentative()
    dispatch(
      RepresentationActions.getRepresentativeFulfilled(response.data.data)
    )
  } catch (error) {
    dispatch(RepresentationActions.getRepresentativeRejected(error as Error))
  }
}

export const getRepresentativeCompanyData =
  (taxId: string, setError: any) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(RepresentationActions.getRepresentativeCompanyDataPending())
      const response = await RepresentationService.getRepresentativeCompanyData(
        taxId
      )
      dispatch(
        RepresentationActions.getRepresentativeCompanyDataFulfilled(
          response.data
        )
      )
      dispatch(setShowDrawerMyAgent(true))
    } catch (error) {
      setError('cuit', { type: 'registered', message: 'CUIT/CUIL inválido' })
      dispatch(
        RepresentationActions.getRepresentativeCompanyDataRejected(
          error as Error
        )
      )
    }
  }

export const setRepresentative =
  (representativeId: number) => async (dispatch: ThunkDispatchType) => {
    const navigation = {
      path: '/content/representation',
      title: 'Mi representante'
    }
    try {
      dispatch(RepresentationActions.setRepresentativePending())
      const response = await RepresentationService.setRepresentative(
        representativeId
      )
      dispatch(
        RepresentationActions.setRepresentativeFulfilled(response.data.data)
      )
      dispatch(
        setShowDrawerSuccess(
          true,
          true,
          'Representante agregado',
          'En las próximas horas se verá reflejada en la cuenta de tu representante.',
          navigation
        )
      )
    } catch (error) {
      dispatch(
        setShowDrawerError(
          true,
          'No se pudo agregar el representante seleccionado.',
          '',
          navigation
        )
      )
      dispatch(RepresentationActions.setRepresentativeRejected(error as Error))
    }
  }

export const deleteRepresentative =
  () => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(RepresentationActions.deleteRepresentativePending())
      await RepresentationService.deleteRepresentative()
      dispatch(RepresentationActions.deleteRepresentativeFulfilled())
    } catch (error) {
      dispatch(
        RepresentationActions.deleteRepresentativeRejected(error as Error)
      )
    }
  }
