import React, { useEffect } from 'react'
import { useAppSelector } from 'hooks/redux'
import {
  ViewContainer,
  TextFullName,
  ViewInformation,
  TextInfo,
  TooltipContainer,
  OptionsContainer,
  OptionButton,
  Arrow,
  ViewTitleWithEdit,
  ViewLineSeparator,
  TextInfoLabel,
  ContainerPersonalInformation,
  ContainerAddressInformation,
  TextLabel,
  TextLabelEdit,
  ViewTitleWithEditContainer,
  ViewLineVerticalSeparator,
  ViewPhoneInformation,
  PencilButton
} from './styles'
import InfoSVG from 'assets/svg/infoSVG'
import Tooltip from 'components/atoms/Tooltip'
import PencilEditV2 from 'assets/svg/PencilEditV2'
import { getMyData } from 'store/auth/thunks'
import { useDispatch } from 'react-redux'
import MyDataDesktopSkeleton from 'assets/skeletons/MyDataDesktopSkeleton'

interface Action {
  title: string
  callback: () => void
}

interface Props {
  changePassword: Action,
  changeEmail: Action,
  changePhone: Action,
  unsubscribe: Action,
  regret: Action,
  changeAddress: Action
}

export enum StatusOnboarding {
  APPROVED = 4
}

const MyDataCard: React.FC<Props> = ({
  changePassword,
  unsubscribe,
  changeEmail,
  changePhone,
  regret,
  changeAddress
}) => {
  const dispatch = useDispatch()
  const businessName = localStorage.getItem('businessName')
  const cuit = localStorage.getItem('cuit')

  const { myData, isLoading } = useAppSelector(state => state.auth)

  useEffect(() => {
    dispatch(getMyData())
  }, [dispatch])

  const [showPencil, setShowPencil] = React.useState(false)

  const onboardingStatus = Number(localStorage.getItem('onboardingStatus'))

  if (isLoading || !myData) {
    return <MyDataDesktopSkeleton />
  }

  return (
    <>
      <ViewContainer>
        <TextFullName>{businessName}</TextFullName>
        <ViewTitleWithEditContainer>
          <TextInfoLabel>Datos personales</TextInfoLabel>
          {onboardingStatus === StatusOnboarding.APPROVED &&
            (
              <button type='button' onClick={() => setShowPencil(!showPencil)}>
                <TextLabelEdit>Editar</TextLabelEdit>
              </button>
            )}
        </ViewTitleWithEditContainer>
        <ContainerPersonalInformation>
          <ViewInformation>
            <ViewTitleWithEdit>
              <TextLabel>E-mail</TextLabel>
              <PencilButton type='button' onClick={changeEmail.callback} active={showPencil}>
                <PencilEditV2 />
              </PencilButton>
            </ViewTitleWithEdit>
            <TextInfo>{myData.email}</TextInfo>
          </ViewInformation>
          <ViewLineVerticalSeparator />
          <ViewInformation>
            <ViewTitleWithEdit>
              <ViewPhoneInformation>
                <TextLabel>Nro. teléfono</TextLabel>
                <PencilButton type='button' onClick={changePhone.callback} active={showPencil}>
                  <PencilEditV2 />
                </PencilButton>
              </ViewPhoneInformation>
            </ViewTitleWithEdit>
            {myData?.telephoneNumber
              ? (
                <TextInfo>{myData?.telephoneNumber?.trim()}</TextInfo>
                )
              : (
                <TooltipContainer>
                  <Tooltip
                    label="Para visualizar el número telefónico asociado a esta cuenta,
                 debes completar el proceso de onboarding a través de la app mobile"
                    hasArrow={false}
                    margin={1}
                  >
                    <InfoSVG />
                  </Tooltip>
                </TooltipContainer>
                )}
          </ViewInformation>
          <ViewLineVerticalSeparator />
          <ViewInformation>
            <TextLabel disabled={showPencil}>Documento</TextLabel>
            <TextInfo disabled={showPencil}>{cuit}</TextInfo>
          </ViewInformation>
        </ContainerPersonalInformation>
        <ViewLineSeparator />
        <ViewTitleWithEditContainer>
          <TextInfoLabel>Domicilio</TextInfoLabel>
          {onboardingStatus === StatusOnboarding.APPROVED &&
            (
              <button type='button' onClick={changeAddress.callback}>
                <TextLabelEdit>Editar</TextLabelEdit>
              </button>
            )}
        </ViewTitleWithEditContainer>
        <ContainerAddressInformation>
          <ViewInformation>
            <TextLabel>Calle</TextLabel>
            <TextInfo>{myData.address}</TextInfo>
          </ViewInformation>
          <ViewLineVerticalSeparator />
          <ViewInformation>
            <TextLabel>Altura</TextLabel>
            <TextInfo>{myData.number}</TextInfo>
          </ViewInformation>
          <ViewLineVerticalSeparator />
          <ViewInformation>
            <TextLabel>Localidad</TextLabel>
            <TextInfo>{myData.Location?.location}</TextInfo>
          </ViewInformation>
          <ViewLineVerticalSeparator />
          <ViewInformation>
            <TextLabel>Provincia</TextLabel>
            <TextInfo>{myData.Location?.Province?.province}</TextInfo>
          </ViewInformation>
          <ViewLineVerticalSeparator />
          <ViewInformation>
            <TextLabel>Código Postal</TextLabel>
            <TextInfo>{myData.postalCode}</TextInfo>
          </ViewInformation>
        </ContainerAddressInformation>
      </ViewContainer>
      <OptionsContainer>
        <OptionButton onClick={changePassword.callback}>
          {changePassword.title}
          <Arrow />
        </OptionButton>
        <OptionButton onClick={unsubscribe.callback}>
          {unsubscribe.title}
          <Arrow />
        </OptionButton>
        <OptionButton onClick={regret.callback}>
          {regret.title}
          <Arrow />
        </OptionButton>
      </OptionsContainer>
    </>
  )
}

export default MyDataCard
