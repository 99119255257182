import { useRef } from 'react'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'

const useSharePdf = (height?: number, width?: number) => {
  const printRef = useRef<HTMLDivElement>(null)

  const onShareHandler = async () => {
    const element = printRef.current
    if (!element) return
    const canvas = await html2canvas(element, { scale: 2 })
    const data = canvas.toDataURL('image/png')
    const pdf = new JsPDF('p', 'mm', [
      (height as number) || 350,
      (width as number) || 310
    ])
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)

    pdf.save('Comprobante.pdf')
  }

  return { printRef, onShareHandler }
}

export default useSharePdf
