import styled from 'styled-components'
import { gray, purple, white } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  margin-left: 5px;
  margin-top: 3.65vh;
  margin-left: 87px;
  margin-right: 87px;
`
export const ViewContainerInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5vh;
  gap: 40px 0px;
  margin-bottom: 2.5vh;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: center;
  }
`

export const TextFullName = styled.div`
  font-size: 3.5vw;
  font-weight: 500;
  text-align: center;
  color: ${gray};
  margin-top: 1.3vh;

  @media ${DEVICES.mobile} {
    font-size: 40px;
  }
`

export const ViewInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;

  @media ${DEVICES.mobile} {
    width: 100%;
    margin: 10px 0;
  }
`
export const TextInfo = styled.p`
  font-size: 1.1vw;
  color: ${gray};
  text-align: center;

  @media ${DEVICES.tablet} {
    font-size: 2vw;
  }

  @media ${DEVICES.mobile} {
    width: 100%;
    font-size: 20px;
    margin-bottom: 12px;
  }
`
export const TextInfoMargin = styled.div`
  display: flex;
  font-size: 1.1vw;
  color: ${gray};
  align-self: center;
  justify-content: center;

  @media ${DEVICES.tablet} {
    font-size: 2vw;
  }

  @media ${DEVICES.mobile} {
    width: 100%;
    font-size: 20px;
    margin-bottom: 12px;
  }
`
export const ObservationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid ${purple};
  margin-top: 27px;
  margin-bottom: 26px;
`

export const PencilContainerObs = styled.button`
  display: flex;
  margin-left: auto;
  position: relative;
  bottom: 29px;
`
export const PencilContainerInput = styled.button`
  display: flex;
  align-self: 'flex-end';
`
export const customButtonEdit = {
  padding: 3,
  width: 150
}

export const customButtonCancel = {
  marginRight: 30,
  width: 150
}
