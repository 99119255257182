/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, violet } from '@qirapagos/lib/theme/colors'

function HandshakeSVG ({
  active,
  strokeWidth,
  width = 50,
  height = 50
}: {
  active?: boolean
  strokeWidth?: number
  width?: number
  height?: number
}) {
  {
    return (
      <Svg width={width} height={height} viewBox="0 0 50 50" fill="none">
        <Path
          d="M47.015 23.786l-4.828 2.413-6.25-11.969 4.877-2.438a1.562 1.562 0 012.084.674l4.803 9.199a1.562 1.562 0 01-.686 2.12v0zM7.812 25.99l-4.827-2.413a1.563 1.563 0 01-.686-2.121l4.803-9.199a1.562 1.562 0 012.084-.674l4.877 2.438-6.25 11.97z"
          stroke={active ? violet : gray}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M42.188 26.2l-3.126 3.655-7.186 7.187a1.562 1.562 0 01-1.484.411l-11.32-2.83a1.56 1.56 0 01-.584-.285L7.813 25.99"
          stroke={active ? violet : gray}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M39.063 29.855l-8.594-6.25-2.5 1.875a6.25 6.25 0 01-7.5 0l-1.059-.794a1.561 1.561 0 01-.167-2.354l7.643-7.644a1.562 1.562 0 011.105-.458h7.947"
          stroke={active ? violet : gray}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M14.175 14.022L24.198 11.1a1.562 1.562 0 011.082.076l6.751 3.055M21.875 41.575l-5.887-1.472a1.562 1.562 0 01-.646-.337l-4.405-3.828"
          stroke={active ? violet : gray}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    )
  }
}

export default HandshakeSVG

HandshakeSVG.defaultProps = {
  strokeWidth: 2,
  width: 50,
  height: 50,
  active: false
}
