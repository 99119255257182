import React, { useEffect, useMemo } from 'react'
import { FormikProps } from 'formik'
import * as Yup from 'yup'
import { useAppSelector } from 'hooks/redux'
import {
  getGrainType,
  getHarvestDate,
  getCurrencies
} from '@qirapagos/web/src/store/contracts/thunks'
import { getMyData } from '@qirapagos/web/src/store/auth/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { SelectField } from 'components/shared/SelectField'
import { RadioField } from 'components/shared/RadioField'
import { TextField } from 'components/shared/TextField'
import { Container, Title, Inputs } from './styles'
import { getRepresented } from 'store/representation/thunks'
import { RootState } from 'store'
import { AppOperation } from 'store/contracts/types'
import useHasSisa from '@qirapagos/lib/hooks/useHasSisa'

export const formGrainInitialValues = {
  sellerTaxId: undefined,
  currencyId: undefined,
  grainId: undefined,
  harvestDateId: undefined,
  price: undefined,
  tons: undefined
}

export const formGrainValidator = Yup.object().shape({
  sellerTaxId: Yup.string().required('Es requerido'),
  currencyId: Yup.string().required('Es requerido'),
  grainId: Yup.string().required('Es requerido'),
  harvestDateId: Yup.string().required('Es requerido'),
  price: Yup.number()
    .typeError('Debe ser un número')
    .when('appOperation', {
      is: appOperation =>
        [AppOperation.SALE_FIXED, AppOperation.SWAP_FIXED].includes(appOperation),
      then: Yup.number().min(1, 'El precio debe ser mayor a 0'),
      otherwise: Yup.number()
    }),
  tons: Yup.number()
    .required('Es requerido')
    .test(
      'multiple-30',
      'Debe ser múltiplo de 30',
      (value: number | undefined) => {
        if (value === undefined) {
          return false
        }
        return value % 30 === 0
      }
    )
    .min(1, 'Debe ser mayor que 0')
})

export interface FormGrainValues {
  sellerTaxId: string | undefined
  currencyId: number | undefined
  grainId: number | undefined
  harvestDateId: number | undefined
  price: number | undefined
  tons: number | undefined
}

interface Props {
  titlesInfo: string
  formik: FormikProps<any>
  toFix?: boolean
}

const FormGrain: React.FC<Props> = props => {
  const { titlesInfo, formik, toFix } = props
  const { represented } = useSelector((state: RootState) => state.representation)
  const companyId = Number(localStorage.getItem('companyId'))
  const hasSisa = useHasSisa()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getHarvestDate())
    dispatch(getGrainType())
    dispatch(getMyData())
    dispatch(getCurrencies())
  }, [dispatch])

  useEffect(() => {
    dispatch(getRepresented(companyId))
  }, [dispatch, companyId])

  const {
    harvest,
    graintype: grainType,
    currencies,
    isLoading
  } = useAppSelector(state => state.contracts)

  const { myData } = useAppSelector(state => state.auth)

  const harvestList = useMemo(() => {
    return harvest?.map((harvest: any) => ({
      value: harvest.id,
      label: `${harvest.startDate.toString().substring(2, 4)}/${harvest.endDate
        .toString()
        .substring(2, 4)}`
    }))
  }, [harvest])

  const grainList = useMemo(() => {
    return grainType?.map((grainType: any) => ({
      value: grainType.id,
      label: grainType.grain
    }))
  }, [grainType])

  const currenciesList = useMemo(() => {
    return currencies?.map((currency: any) => ({
      value: currency.id,
      label: currency.currency
    }))
  }, [currencies])

  const options = []

  if (hasSisa) {
    options.push({ value: myData?.taxId as string, label: 'Mi cuenta' })
  }

  if (represented && represented.length > 0) {
    options.push(...represented.map((rep) => (
      { value: rep?.represented.taxId, label: rep?.represented.companyName }
    )))
  }

  if (isLoading) {
    return null
  }

  return (
    <Container>
      <Title>{titlesInfo}</Title>
      <Inputs>
        <SelectField
          formik={formik}
          name="sellerTaxId"
          label="A nombre de"
          options={options}
          onChange={(e) => {
            formik.setFieldValue('sellerTaxId', e.target.value)
          }}
        />
        <SelectField
          formik={formik}
          name="grainId"
          label="Tipo de grano"
          options={grainList}
        />
        <SelectField
          formik={formik}
          name="harvestDateId"
          label="Cosecha"
          options={harvestList}
        />
        <RadioField
          formik={formik}
          name="currencyId"
          disabled={toFix}
          options={currenciesList}
        />
        <TextField
          formik={formik}
          name="tons"
          label="TT a vender"
          type="number"
        />
        <TextField
          formik={formik}
          name="price"
          label="Precio"
          type="number"
          disabled={toFix}
        />
      </Inputs>
    </Container>
  )
}

FormGrain.defaultProps = {
  toFix: false
}

export default FormGrain
