import axios from 'axios'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_TEST_URL,
  headers: {
    client: process.env.REACT_APP_CLIENT_DEV,
    secret: process.env.REACT_APP_SECRET_DEV
  }
})

instance.interceptors.request.use(request => {
  const token = localStorage.getItem('token')
  if (token) {
    request.headers.token = token
  }
  return request
}, error => {
  return Promise.reject(error)
})

export interface ApiResponse<T> {
  data: T
  message: string
  error?: boolean
}

export * as AuthServices from './AuthServices'
export * as AccountServices from './AccountServices'
export * as TransferServices from './TransferServices'
export * as ServicesUtils from './utils'
export * as MultiEnterpriseServices from './MultiEnterpriseServices'
export * as MenuServices from './MenuServices'
export * as NotificationsServices from './NotificationsServices'
export * as SignatureServices from './SignatureServices'
export * as LocationsService from './Location'
export * as ActivityService from './ActivityService'
export * as RepresentationService from './Representation'
export * as EnterpriseServices from './Enterprise'
