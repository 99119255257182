import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

interface IAuthContext {
    isAuthenticated: boolean | null,
    login: () => void,
    logout: () => void,
}

interface IAuthProviderProps {
    children: React.ReactNode
}

const AuthContext = createContext<IAuthContext | null>(null)

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

  const setUserLoggedIn = async () => {
    const userLoggedIn = await localStorage.getItem('userLoggedIn')
    if (userLoggedIn === 'true') return setIsAuthenticated(true)
    setIsAuthenticated(false)
  }

  useEffect(() => {
    setUserLoggedIn()
  }, [])

  const login = () => {
    setIsAuthenticated(true)
  }

  const logout = () => {
    setIsAuthenticated(false)
  }

  const values = useMemo(
    () => ({
      isAuthenticated,
      login,
      logout
    }),
    []
  )

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}
