import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function DropArrow (props: SvgProps) {
  return (
    <Svg width={21} height={20} viewBox="0 0 21 20" fill="none" {...props}>
      <Path
        d="M17.031 7.5l-6.25 6.25-6.25-6.25"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default DropArrow
