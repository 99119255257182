import React from 'react'
import Drawer from 'react-modern-drawer'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import SleepyCalendar from 'assets/svg/SleepyCalendar'
import {
  Title,
  drawerStyle,
  Header,
  DrawerContainer,
  BackButton,
  TextDescription,
  BodyContainer,
  CalendarIconContainer
} from './styles'
import { Drawers } from 'store/drawer/types'
import wrapper from 'components/organisms/Drawers/wrapper'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const DrawerModal: React.FC<Props> = ({ showDrawer, onClose }) => {
  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
      style={drawerStyle}
    >
      <DrawerContainer>
        <Header>
          <BackButton onClick={onClose}>
            <BackArrowIcon />
          </BackButton>
          <Title>Nueva operación</Title>
        </Header>
        <BodyContainer>
          <CalendarIconContainer>
            <SleepyCalendar />
          </CalendarIconContainer>
          <TextDescription>
            Las operaciones pueden realizarse de Lunes a Viernes de 09:00 a
            18:00 horas. No están disponibles en feriados.
          </TextDescription>
        </BodyContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(DrawerModal, Drawers.OUT_OF_TIME)
