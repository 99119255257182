import { format } from 'date-fns'

export const formatDateForContracts = (date: any, formatDate: string) => {
  const formatedDate = format(date, formatDate)
  return formatedDate
}

export const formatDateShippingFromExpiration = (date: any, formatDate: string) => {
  const formatedDate = format(date, formatDate)
  return `${formatedDate} 00:00:00`
}

export const formatStringDateType = (date: string | Date, formatDate: string) => {
  return format(new Date(date), formatDate)
}

export function formatDate (date?: string | Date): string {
  if (!date) {
    return ''
  }
  if (date instanceof Date) {
    return date.toISOString().split('T')[0].split('-').reverse().join('/')
  }
  return date.split('T')[0].split('-').reverse().join('/')
}
