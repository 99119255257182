import CustomText from 'components/atoms/CustomText'
import styled from 'styled-components'

export const ContentContainer = styled.div`
  margin-top: 50px;
`

export const RecoverAccountText = styled(CustomText)`
  margin-bottom: 50px;
`

export const PasswordExpiredText = styled.p`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`
