import React from 'react'

import { Text } from '@chakra-ui/react'
import { Colors } from '@qirapagos/lib/theme'
import { formatDateWithMonth } from '@qirapagos/lib/utils/common'
import {
  BoxContainer,
  BoxIcon,
  BoxLeftInfo,
  BoxLeftText,
  BoxRightInfo,
  BoxRightText
} from './styles'
import { getInfo, TypeMovementsActivity } from './types'
import { ActivityMovement } from 'store/activity/types'

interface Props {
  activity: ActivityMovement
}

const ActivityItem: React.FC<Props> = ({ activity }) => {
  const isTransfer =
    activity.movementType === TypeMovementsActivity.MOVEMENT_TRANSFER_DEBIT ||
    activity.movementType === TypeMovementsActivity.MOVEMENT_TRANSFER_CREDIT

  const isQuota =
    activity.movementType === TypeMovementsActivity.QUOTA_AWARDED ||
    activity.movementType === TypeMovementsActivity.QUOTA_CANCELED ||
    activity.movementType === TypeMovementsActivity.QUOTA_REJECTED ||
    activity.movementType === TypeMovementsActivity.QUOTA_REQUIRED

  const amount = isQuota ? `${activity.amount}TT` : `$${getInfo(activity).amount}`

  const activityFormatted = getInfo(activity)

  return (
    <BoxContainer>
      <BoxLeftInfo>
        <BoxIcon>{activityFormatted.icon}</BoxIcon>
        <BoxLeftText>
          <Text fontWeight={600} fontSize={{ md: '14px', lg: '16px' }}>
            {activityFormatted.title}
          </Text>
          <Text fontWeight={400} fontSize="14px">
            {amount}
          </Text>
        </BoxLeftText>
      </BoxLeftInfo>
      <BoxRightInfo>
        <BoxRightText>
          <Text
            fontWeight={400}
            fontSize="14px"
            color={Colors.gray}
            lineHeight="12px"
            mb={2}
            alignSelf="center"
          >
            {formatDateWithMonth(activityFormatted.date?.toString() ?? '')}
          </Text>
          {isTransfer && (
            <Text
              fontWeight={400}
              fontSize={{ md: '12px', lg: '14px' }}
              lineHeight="12px"
              alignSelf="center"
            >
              {activityFormatted.owner}
            </Text>
          )}
        </BoxRightText>
      </BoxRightInfo>
    </BoxContainer>
  )
}

export default ActivityItem
