import styled from 'styled-components/native'
import { grayLight, gray } from '@qirapagos/lib/theme/colors'
import { StyleSheet } from 'react-native'

export const SidebarContainer = styled.View`
  width: 100%;
  height: 100%;
`

export const ViewLineSeparator = styled.View`
  margin: 1rem;
  width: 60%;
  border-width: 1;
  align-self: center;
  border-color: ${grayLight};
  background-color: ${grayLight};
`

export const ViewLogo = styled.View`
  align-self: center;
  margin-top: 40px;
  margin-bottom: 30px;
`
export const logoutButtonStyles = {
  width: '90%',
  color: gray
}

export const styles = StyleSheet.create({
  contentContainerStyle: {
    justifyContent: 'space-evenly',
    marginLeft: '10%',
    minHeight: '100%'
  },
  aboutUsStyle: {
    marginRight: 20
  }
})
