import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function PersonSVG () {
  return (
    <Svg width={22} height={22} viewBox="0 0 24 25" fill="none">
      <Path
        d="M11.879 16.473a5.473 5.473 0 100-10.946 5.473 5.473 0 000 10.946z"
        stroke="#FFFFFF"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <Path
        d="M3.583 21.26a9.58 9.58 0 0116.59 0"
        stroke="#FFFFFF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default PersonSVG
