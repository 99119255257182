import React from 'react'
import { Carousel } from 'components'
import { Container, Content } from './styles'

interface Props {
  children: React.ReactNode
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Carousel />
      <Content>{children}</Content>
    </Container>
  )
}

export default AuthLayout
