/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react'
import {
  ACCEPT_NUMBERS,
  LOWECASE_LETTER,
  SPECIAL_CHARACTER,
  UPPERCASE_LETTER
} from '@qirapagos/web/src/constants/validations'

const useCheckPassword = (password: any) => {
  const [checkPasswordLength, setCheckPasswordLength] = useState(false)
  const [checkCapitalLeter, setCheckCapitalLeter] = useState(false)
  const [checkLoweCase, setcheckLoweCase] = useState(false)
  const [checkNumber, setCheckNumber] = useState(false)
  const [checkSpecialCharacter, setCheckSpecialCharacter] = useState(false)

  useEffect(() => {
    password.length >= 10
      ? setCheckPasswordLength(true)
      : setCheckPasswordLength(false)
    password.match(LOWECASE_LETTER)
      ? setcheckLoweCase(true)
      : setcheckLoweCase(false)
    password.match(UPPERCASE_LETTER)
      ? setCheckCapitalLeter(true)
      : setCheckCapitalLeter(false)
    password.match(ACCEPT_NUMBERS)
      ? setCheckNumber(true)
      : setCheckNumber(false)
    password.match(SPECIAL_CHARACTER)
      ? setCheckSpecialCharacter(true)
      : setCheckSpecialCharacter(false)
  }, [password])

  return {
    checkPasswordLength,
    checkCapitalLeter,
    checkLoweCase,
    checkNumber,
    checkSpecialCharacter
  }
}

export default useCheckPassword
