/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import FormStepBox from 'components/molecules/FormStepBox'
import FieldSelect from 'components/molecules/FieldSelect'
import Field from '@qirapagos/lib/components/molecules/Field/index.web'
import { useDispatch } from 'react-redux'
import { styles } from './styles'
import { setContractIds } from 'store/contracts/actions'

interface Props {
  control: any
  setValue: any
  getValues: any
  titlesInfo: any
  dataHarvest: any
  dataGraintype: any
  dataAgent: any
  errors?: any
}

const StepGrainToSet: React.FC<Props> = ({
  control,
  setValue,
  getValues,
  titlesInfo,
  dataHarvest,
  dataGraintype,
  dataAgent,
  errors
}) => {
  const [selectedCreatedBy, setSelectedCreatedBy] = useState('')
  const [selectedGrain, setSelectedGrain] = useState('')
  const [selectedHarvestDate, setSelectedHarvestDate] = useState('')
  const dispatch = useDispatch()
  const hasErrors = !!(errors.tons || errors.amount)

  useEffect(() => {
    const values = !!(
      selectedCreatedBy &&
      selectedGrain &&
      selectedHarvestDate &&
      getValues('tons') &&
      !hasErrors
    )
    titlesInfo.setComplete(values)
  })

  useEffect(() => {
    dispatch(setContractIds(selectedGrain, 'grain'))
  }, [dispatch, selectedGrain])

  return (
    <FormStepBox
      title={titlesInfo.title}
      testID='grain-price-to-set-step'
      childrenCustomStyle={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginRight: '1%'
      }}
    >
      <FieldSelect
        customStyle={styles.fieldSelect}
        data={dataAgent}
        name="createdBy"
        label="A nombre de"
        editable={false}
        accessibilityLabelValue="A nombre de"
        control={control}
        setValue={setValue}
        setSelected={setSelectedCreatedBy}
        selected={selectedCreatedBy}
        setSelectedID={setSelectedCreatedBy}
        filterBy="companyName"
      />
      <FieldSelect
        customStyle={styles.fieldSelect}
        data={dataGraintype}
        name="grain"
        label="Tipo de grano"
        editable={false}
        accessibilityLabelValue="Tipo de grano"
        control={control}
        setValue={setValue}
        setSelected={setSelectedGrain}
        selected={selectedGrain}
        setSelectedID={setSelectedGrain}
        filterBy="grain"
      />
      <FieldSelect
        customStyle={styles.fieldSelect}
        data={dataHarvest}
        name="harvestDate"
        label="Cosecha"
        editable={false}
        accessibilityLabelValue="Cosecha"
        control={control}
        setValue={setValue}
        setSelected={setSelectedHarvestDate}
        selected={selectedHarvestDate}
      />
      <Field
        customStyleContainer={styles.fieldSelect}
        name="tons"
        control={control}
        label="TT a vender"
        accessibilityLabelValue="TT a vender"
        setValue={setValue}
        editable
        rules={{
          required: true
        }}
        error={errors.tons}
        errorMessage={errors.tons?.message}
      />
    </FormStepBox>
  )
}

export default StepGrainToSet

StepGrainToSet.defaultProps = {
  errors: {}
}
