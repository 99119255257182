import { purple } from '@qirapagos/lib/theme/colors'
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  isDotOpen?: boolean
}

const TripleDot: React.FC<Props> = ({ isDotOpen }) => {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M10 11.875a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM10 5.625a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM10 18.125a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
        fill={isDotOpen ? purple : '#B3B3B3'}
      />
    </Svg>
  )
}

export default TripleDot

TripleDot.defaultProps = {
  isDotOpen: false
}
