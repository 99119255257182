import React, { useState, useEffect } from 'react'

const Cursor = () => {
  const [showCursor, setShowCursor] = useState(true)

  useEffect(() => {
    const intervalId = setInterval(() => setShowCursor((prev) => !prev), 500)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return <span>{showCursor ? '|' : ''}</span>
}

export default Cursor
