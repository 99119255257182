import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInitials } from '@qirapagos/lib/utils/common'
import CustomText from 'components/atoms/CustomText'
import {
  EnterpriseAvatar,
  RenderIf,
  MenuItem as MenuItemCustom
} from 'components'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { Colors } from '@qirapagos/lib/theme'
import { ListItemType } from 'constants/common'
import { loginUserWithEnterprise } from 'store/auth/thunks'
import useNavigation from 'hooks/useNavigation'
import IEnterprise from './types'
import { getUserBalance } from 'store/transfers/thunks'
import { getActivity } from 'store/activity/thunks'

const EnterpriseSelector: React.FC = () => {
  const ref = useRef<any>()
  const { businessName, multiEnterprise } = useSelector(
    (state: any) => state.auth
  )

  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  const onClick = (item: IEnterprise) => {
    dispatch(loginUserWithEnterprise('', item, onNavigate))
    dispatch(getUserBalance())
    dispatch(getActivity())
  }

  const HAVE_MULTI_ENTERPRISE = multiEnterprise.length > 1
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton ref={ref}>
            <EnterpriseAvatar
              initials={getInitials(businessName)}
              visible={isOpen}
            />
          </MenuButton>
          <MenuList marginTop={-4}>
            <RenderIf isTrue={!HAVE_MULTI_ENTERPRISE}>
              <MenuItem
                _hover={{ cursor: 'auto' }}
                _first={{ bg: Colors.white }}
              >
                <CustomText>No tenés empresas asociadas</CustomText>
              </MenuItem>
            </RenderIf>
            <RenderIf isTrue={HAVE_MULTI_ENTERPRISE}>
              {multiEnterprise.map(
                (item: IEnterprise) =>
                  HAVE_MULTI_ENTERPRISE &&
                  item.businessName !== businessName && (
                    <MenuItem
                      style={{ alignSelf: 'center' }}
                      onClick={() => onClick(item)}
                    >
                      <MenuItemCustom
                        title={item.businessName}
                        icon={ListItemType.AVATAR}
                      />
                    </MenuItem>
                  )
              )}
            </RenderIf>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default EnterpriseSelector

EnterpriseSelector.defaultProps = {
  multiEnterpriseUsers: []
}
