import initialState from './initialState'
import { ContractState, ContractActionTypes } from './types'

// TODO: create a properly interface
interface Action<T = null> {
  type: string
  payload?: any
}

export default function signatureReducer (
  state = initialState,
  action: Action
): ContractState {
  switch (action.type) {
    case ContractActionTypes.GET_SALE_ORDERS_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoading: true
      }
    case ContractActionTypes.GET_SALE_ORDERS_FULFILLED:
      return {
        ...state,
        saleOrders: action.payload,
        isLoading: initialState.isLoading
      }
    case ContractActionTypes.GET_SALE_ORDERS_REJECTED: {
      return {
        ...state,
        isLoading: initialState.isLoading,
        error: action.payload
      }
    }
    case ContractActionTypes.SELECT_SALE_ORDERS: {
      return {
        ...state,
        selected: action.payload
      }
    }
    case ContractActionTypes.CREATE_SALE_ORDER_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoading: true
      }
    case ContractActionTypes.CREATE_SALE_ORDER_FULFILLED:
      return {
        ...state,
        saleOrders: [...state.saleOrders, action.payload],
        isLoading: initialState.isLoading
      }
    case ContractActionTypes.CREATE_SALE_ORDER_REJECTED: {
      return {
        ...state,
        isLoading: initialState.isLoading,
        error: action.payload
      }
    }
    case ContractActionTypes.REPLY_COUNTER_OFFER_PENDING: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ContractActionTypes.REPLY_COUNTER_OFFER_FULFILLED: {
      return {
        ...state,
        isLoading: initialState.isLoading,
        saleOrders: state.saleOrders.map(saleOrder => {
          if (saleOrder.id === action.payload.id) {
            return action.payload
          }
          return saleOrder
        })
      }
    }
    case ContractActionTypes.REPLY_COUNTER_OFFER_REJECTED: {
      return {
        ...state,
        isLoading: initialState.isLoading
      }
    }
    case ContractActionTypes.GET_SALE_ORDER_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoading: true
      }
    case ContractActionTypes.GET_SALE_ORDER_FULFILLED: {
      const tons = action.payload?.kg ? action.payload?.kg / 1000 : 0
      return {
        ...state,
        selected: { ...action.payload, tons: tons },
        isLoading: initialState.isLoading
      }
    }
    case ContractActionTypes.GET_SALE_ORDER_REJECTED: {
      return {
        ...state,
        isLoading: initialState.isLoading,
        error: action.payload
      }
    }
    case ContractActionTypes.CREATE_QUOTAS_PENDING:
      return {
        ...state,
        error: initialState.error,
        isQuotaLoading: true
      }
    case ContractActionTypes.CREATE_QUOTAS_FULFILLED: {
      let selected
      if (state.selected) {
        selected = {
          ...state.selected,
          Quota: [...action.payload, ...(state.selected?.Quota || [])]
        }
      }
      return {
        ...state,
        isQuotaLoading: initialState.isQuotaLoading,
        selected
      }
    }
    case ContractActionTypes.CREATE_QUOTAS_REJECTED: {
      return {
        ...state,
        isQuotaLoading: initialState.isQuotaLoading,
        error: action.payload
      }
    }
    case ContractActionTypes.GET_QUOTA_PENDING:
      return {
        ...state,
        error: initialState.error,
        isQuotaLoading: true
      }
    case ContractActionTypes.GET_QUOTA_FULFILLED: {
      return {
        ...state,
        quotaSelected: action.payload,
        isQuotaLoading: initialState.isQuotaLoading
      }
    }
    case ContractActionTypes.GET_QUOTA_REJECTED: {
      return {
        ...state,
        isQuotaLoading: initialState.isQuotaLoading,
        error: action.payload
      }
    }
    case ContractActionTypes.SELECT_APPLICATION: {
      return {
        ...state,
        selectedApplication: action.payload
      }
    }
    case ContractActionTypes.SELECT_LIQUIDATION: {
      return {
        ...state,
        selectedLiquidation: action.payload
      }
    }
    default:
      return state
  }
}
