import { DrawerActionTypes, Drawers } from './types'

export const showDrawer = (drawerToShow: Drawers, data?: object) => ({
  type: DrawerActionTypes.SHOW_DRAWER,
  payload: { drawer: drawerToShow, data }
})

export const hideDrawer = (drawerToHide: Drawers) => ({
  type: DrawerActionTypes.HIDE_DRAWER,
  payload: drawerToHide
})
