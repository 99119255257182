import React from 'react'
import Drawer from 'react-modern-drawer'
import SideBar from 'components/organisms/SideBar'
import { drawerStyles } from 'animations/AnimatedTrailView/styles'

interface Props {
  isOpen: boolean
  setShowMenuDrawer: (value: boolean) => void
}

const DrawerMenuSideBar: React.FC<Props> = ({ isOpen, setShowMenuDrawer }) => {
  const onClose = () => {
    setShowMenuDrawer(false)
  }

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="left"
      style={drawerStyles}
    >
      <SideBar setShowMenuDrawer={setShowMenuDrawer} />
    </Drawer>
  )
}

export default DrawerMenuSideBar
