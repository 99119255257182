import { FieldError } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Box } from '@chakra-ui/react'
import { NumericFormat as PureNumericFormat, InputAttributes } from 'react-number-format'
import { graySoft, purple, error as errorColor } from '@qirapagos/lib/theme/colors'

interface NumericFormatInput extends InputAttributes {
  focus: boolean;
  error?: FieldError;
}

export const NumericFormat = styled(PureNumericFormat)<NumericFormatInput>`
  position: relative;
  height: 65px !important;
  margin-bottom: 4px;
  padding-top: ${props => props.focus || props.value ? '15px' : '0px'};
  background-color: ${graySoft} !important;
  border-color: ${props => props.error ? errorColor : graySoft} !important;
  border-width: 2px !important;
  &:focus {
    box-shadow: none !important;
    border-color: ${props => props.error ? errorColor : purple} !important;
  }
  ::placeholder {
    ${props => props.focus && css`
      opacity: 0;
    `};
  }
`

export const Placeholder = styled(Box)`
  position: absolute;
  top: 5px !important;
  left: 15px !important;
  z-index: 99;
  color: ${props => props.error ? errorColor : purple}
`
