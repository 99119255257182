import React, { useEffect, useState } from 'react'
import Helmet from 'components/utilities/Helmet'
import { AuthLayout } from 'components'
import { PageBody, QiraPagos } from './styles'
import RenderIf from 'components/utilities/RenderIf'
import { EmailForm, EmailSent } from 'screens'
import useNavigation from 'hooks/useNavigation'

const Steps = {
  EMAIL_FORM: 'EMAIL_FORM',
  EMAIL_SENT: 'EMAIL_SENT',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED'
}

const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState(Steps.EMAIL_FORM)
  const [email, setEmail] = useState('')
  const { location } = useNavigation()
  const isPasswordExpired = location.state?.isPasswordExpired

  const onEmailValidate = () => {
    setStep(Steps.EMAIL_SENT)
  }

  return (
    <AuthLayout>
      <QiraPagos />
      <Helmet title="Restablecer contraseña" />
      <PageBody>
        <RenderIf isTrue={step === Steps.EMAIL_FORM}>
          <EmailForm
            setEmail={setEmail}
            next={onEmailValidate}
            isPasswordExpired={isPasswordExpired}
          />
        </RenderIf>
        <RenderIf isTrue={step === Steps.EMAIL_SENT}>
          <EmailSent email={email} />
        </RenderIf>
      </PageBody>
    </AuthLayout>
  )
}

export default ForgotPassword
