import {
  AuthActionTypes,
  IDocument,
  IDocumentRejected,
  IRegisterUser,
  ILastLogin,
  IMyData,
  IbodyAddress
} from './types'

export const setRegisterUser = (data?: IRegisterUser) => ({
  type: AuthActionTypes.SET_REGISTER_USER,
  payload: data
})

export const setKeyEndpoints = (key: string) => ({
  type: AuthActionTypes.SET_KEYENDPOINTS,
  payload: key
})

export const setVerifyPhoneInfo = (type: string, value: any) => ({
  type: AuthActionTypes.SET_VERIFY_PHONE_INFO,
  payload: {
    type,
    value
  }
})

export const setRecoverUser = (type: string, value: any) => ({
  type: AuthActionTypes.SET_RECOVER_USER,
  payload: {
    type,
    value
  }
})

export const setDocumentType = (type: string) => ({
  type: AuthActionTypes.SET_DOCUMENT_TYPE,
  payload: type
})

export const setLocalAuthSplash = (boolean: boolean) => ({
  type: AuthActionTypes.LOCAL_AUTH_SPLASH,
  payload: boolean
})

export const setIsBusiness = (isBusiness: boolean) => ({
  type: AuthActionTypes.SET_IS_BUSINESS,
  payload: isBusiness
})

export const setBusinessName = (businessName: string) => ({
  type: AuthActionTypes.SET_BUSINESS_NAME,
  payload: businessName
})

export const setAuthLoading = () => ({
  type: AuthActionTypes.SET_LOADING
})

export const setValidateRecoverPasswordLoading = (value: boolean) => ({
  type: AuthActionTypes.SET_RECOVER_PASSWORD_LOADING,
  payload: value
})

export const setAuthError = () => ({
  type: AuthActionTypes.SET_ERROR
})

export const dismissAuthLoading = () => ({
  type: AuthActionTypes.DISMISS_LOADING
})

export const cleanSelfie = () => ({
  type: AuthActionTypes.CLEAN_SELFIE
})

export const setVerifyInfo = (type: string, value: string) => ({
  type: AuthActionTypes.SET_VERIFY_INFO,
  payload: {
    type,
    value
  }
})

export const setFile = (file: IDocument) => ({
  type: AuthActionTypes.SET_FILE,
  payload: file
})

export const removeFile = (index: number) => ({
  type: AuthActionTypes.REMOVE_FILE,
  payload: index
})

export const resetFiles = () => ({
  type: AuthActionTypes.RESET_FILES
})

export const setUserSelfie = (type: string, value: string) => ({
  type: AuthActionTypes.SET_USER_SELFIE,
  payload: {
    type,
    value
  }
})

export const setUserDNIPhoto = (type: string, value: string) => ({
  type: AuthActionTypes.SET_USER_DNI_PHOTO,
  payload: {
    type,
    value
  }
})

export const dismissLoginError = () => ({
  type: AuthActionTypes.DISMISS_LOGIN_ERROR
})

export const removeNewPasswordError = () => ({
  type: AuthActionTypes.REMOVE_NEW_PASSWORD_ERROR
})

export const setUserStatus = (data: string) => ({
  type: AuthActionTypes.SET_USER_STATUS,
  payload: data
})

export const setDocumentRejected = (data: IDocumentRejected) => ({
  type: AuthActionTypes.SET_DOCUMENT_REJECTED,
  payload: data
})

export const clearDocumentRejected = () => ({
  type: AuthActionTypes.CLEAR_DOCUMENT_REJECTED
})

export const setMultiEnterprises = (data: []) => ({
  type: AuthActionTypes.SET_MULTI_ENTERPRISES,
  payload: data
})

export const setTwoFactorVerified = (value: boolean) => ({
  type: AuthActionTypes.SET_TWO_FACTOR_VERIFIED,
  payload: value
})

export const setAppState = (value: boolean) => ({
  type: AuthActionTypes.SET_APP_STATUS,
  payload: value
})

export const setNewPasswordError = (value: string) => ({
  type: AuthActionTypes.SET_NEW_PASSWORD_ERROR,
  payload: value
})

export const setRecoverPasswordError = (value: string) => ({
  type: AuthActionTypes.SET_RECOVER_PASSWORD_ERROR,
  payload: value
})

export const setLastLogin = (payload: ILastLogin) => ({
  type: AuthActionTypes.SET_LAST_LOGIN,
  payload: payload
})

export const getFullTermsAndConditionsPending = () => ({
  type: AuthActionTypes.GET_FULL_TERMS_AND_CONDITIONS_PENDING
})

export const getFullTermsAndConditionsFulfilled = () => ({
  type: AuthActionTypes.GET_FULL_TERMS_AND_CONDITIONS_FULFILLED
})

export const getFullTermsAndConditionsRejected = (error: Error) => ({
  type: AuthActionTypes.GET_FULL_TERMS_AND_CONDITIONS_REJECTED,
  payload: error
})

export const getShortTermsAndConditionsPending = () => ({
  type: AuthActionTypes.GET_SHORT_TERMS_AND_CONDITIONS_PENDING
})

export const getShortTermsAndConditionsFulfilled = () => ({
  type: AuthActionTypes.GET_SHORT_TERMS_AND_CONDITIONS_FULFILLED
})

export const getShortTermsAndConditionsRejected = (error: Error) => ({
  type: AuthActionTypes.GET_SHORT_TERMS_AND_CONDITIONS_REJECTED,
  payload: error
})

export const getPrivacyPoliciesPending = () => ({
  type: AuthActionTypes.GET_PRIVACY_POLICIES_PENDING
})

export const getPrivacyPoliciesFulfilled = () => ({
  type: AuthActionTypes.GET_PRIVACY_POLICIES_FULFILLED
})

export const getPrivacyPoliciesRejected = (error: Error) => ({
  type: AuthActionTypes.GET_PRIVACY_POLICIES_REJECTED,
  payload: error
})

export const setMyDataPending = () => ({
  type: AuthActionTypes.GET_MY_DATA_PENDING
})

export const setMyDataFulfilled = (data: IMyData) => ({
  type: AuthActionTypes.GET_MY_DATA_FULFILLED,
  payload: data
})

export const setMyDataRejected = () => ({
  type: AuthActionTypes.GET_MY_DATA_REJECTED
})

export const setPhonePending = () => ({
  type: AuthActionTypes.SET_PHONE_PENDING
})

export const setPhoneFulfilled = (phone: string) => ({
  type: AuthActionTypes.SET_PHONE_FULFILLED,
  payload: phone
})

export const setPhoneRejected = () => ({
  type: AuthActionTypes.SET_PHONE_REJECTED
})

export const setEmailPending = () => ({
  type: AuthActionTypes.SET_EMAIL_PENDING
})

export const setEmailFulfilled = (email: string) => ({
  type: AuthActionTypes.SET_EMAIL_FULFILLED,
  payload: email
})

export const setEmailRejected = () => ({
  type: AuthActionTypes.SET_EMAIL_REJECTED
})
export const changeAddressPending = () => ({
  type: AuthActionTypes.CHANGE_ADDRESS_PENDING
})

export const changeAddressFulfilled = (values: IbodyAddress) => ({
  type: AuthActionTypes.CHANGE_ADDRESS_FULFILLED,
  payload: values
})

export const changeAddressRejected = (error: Error) => ({
  type: AuthActionTypes.CHANGE_ADDRESS_REJECTED,
  payload: error
})
