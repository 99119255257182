import { useEffect, useState } from 'react'
import { getSimpleDataAsyncStorage } from '../utils/asyncStorage'

export enum StatusOnboardingLabel {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING'
}

export enum StatusOnboarding {
  APPROVED = '4'
}

export default function useHasOnboardingComplete () {
  const [hasOnboardingComplete, setHasOnboardingComplete] = useState<string>()
  useEffect(() => {
    const onboardingCheck = async () => {
      const onboardingStatus = await getSimpleDataAsyncStorage(
        'onboardingStatus'
      )
      setHasOnboardingComplete(
        onboardingStatus === StatusOnboarding.APPROVED
          ? StatusOnboardingLabel.APPROVED
          : StatusOnboardingLabel.PENDING
      )
    }
    onboardingCheck()
  }, [])

  return hasOnboardingComplete
}
