import { white, grayText } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'
import { purple } from 'theme/colors'

export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  max-width: 1000px;
  min-width: 780px;
  background-color: ${white} ;
  margin-left: 50px;
  margin-top: 3.65vh;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 2%;
`

export const Title = styled.h2`
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1.3em;
`

export const FieldRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  height: 100px;
`

export const FieldContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 400px;
`

export const LinkContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding-bottom: 25px;
`

export const LinkHeader = styled.p`
  font-size: 0.9em;
  color: ${grayText};
`

export const LinkTitle = styled.p`
  font-size: 0.8rem;
  color: ${purple};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
  margin: 0 10px;
`
