export interface ISale {
  amount?: number
  companyId?: string
  contraparte?: string
  currency?: number
  currencyProduct?: number
  currencyProductSymbol?: string
  customerId?: string
  destination?: string
  deliveryPeriod?: string
  dateFrom?: Date
  dateTo?: Date
  expiration?: string
  grain?: number
  grainName?: string
  harvestDate?: string
  location?: string
  operationType?: number
  origin?: string
  originId?: number
  price?: string
  product?: string
  shippingFrom?: string
  userId?: string
  weight?: string
  zoneId?: number
  zoneName?: string
  fromContra: boolean
  contractsID: {
    grain: string
    condition: string
    location: string
    zone: string
    exchanger: string
    currency: string
    currencyProduct: string
  }
  contractsDates: {
    priceFixingEnd: string | number
    priceFixingStart: string | number
    dateFrom: string | number
    dateTo: string | number
    shippingFrom: string | number
    expiration: string | number
  }
}

export interface IGrainPrice {
  price: number
  timestamp: string
  Currency: ICurrency
}

export interface IZone {
  zone: string
  description: string
  id: number
}

export interface IConditionType {
  condition: string
  id: number
}

export interface IFixing {
  fixedGrains: number
  grain: string
  grainId: number
  conditionName: string
  conditionId: number
  priceFixing: IPriceFixing[]
  weight: number
}

export interface IPriceFixing {
  date: string
  price: string
  status: string
  weight: number
}

export interface IOperation {
  operationId?: number
  companyId?: number
  userId?: any
  operationType?: number
  grain?: number | string
  weight?: number
  price?: any
  product?: any
  amount?: any
  customerId?: any
  shippingFrom: string
  expiration: string
  destination?: string
  currency?: string
  currencyProduct?: number
  currencyProductSymbol?: string
  fechainiciofijacion?: string
  fechafinfijacion?: string
  priceFixingStart?: any
  priceFixingEnd?: any
  condition?: string
  deliveryPeriod?: any
  conditionName?: string
  grainName?: string
  createdBy?: string
  zoneId?: number
  zoneName?: string
  fromContra?: boolean
  origin?: string
  location?: string
  harvestDate?: string
  contraparte?: string
  productAmount?: number
}

export interface ITrade {
  customerId?: number | string
  companyId?: string
  product?: string
  amount?: number | string
  currencyProduct?: number
  currencyProductSymbol?: string
  contraparte?: string
  fromContra?: boolean
  createdBy?: any
  grain?: number
  grainName?: string
  weight?: number
  deliveryPeriod?: string
  currencyid?: number
  currencySymbol?: string
  price?: number
  harvestDate?: string
  zoneId?: number
  zoneName?: string
  deliveryperiod?: number
  shippingFrom?: string
  expiration?: string
  dateFrom?: Date
  dateTo?: Date
  origin?: string
  location?: string
  destination?: string
  productAmount?: number
}

export interface IOperationDetail {
  contractStatus: string
  cupoStatus: string
  deliveryStatus: string
  settlementStatus: string
  grainsDelivered: number
  boletoPending: boolean
  cupos: ICupo[]
  deliveries: IDelivery[]
  grainsToDelivers: number
  settlements: ISettlement[]
  createCupo: boolean
}

export interface ICupo {
  deliveryDate: string
  id: number
  status: string
}

export interface IDelivery {
  cupoCode: string
  id: number
  porteNumber: string
  deliveryDate: string
  weight: number
  quality: string
}

export interface ISettlement {
  id: number
  type: string
  settlementDate: string
  amount: number
  currencySymbol: string
  weight: number
  price: number
  iva: string
  weigthVariation: number
  mermas: string
  activity: string
  grainType: string
  porteNumber: string
}

export interface IContract {
  operationId: number
  operationType: number
  status: string
  amount: number
  price: number
  weight: number
}
export interface ICurrency {
  currency: string
  symbol: string
  id: number
}

export interface IGrain {
  grain: string
  code: string
  id: number
  saggpya: number
  IDType: number
}

export interface IExchanger {
  taxid: string
  companyname: string
  address: string
  addressnumber: string
  phone: string
  email: string
  iibbnumber: string
  tdocsicore: string
  id: number
  Type: number
  IDAddressType: number
  IDLocalidad: number
  IDIVA: number
  IDGanancias: number
  IDIngresosBrutos: number
  IDRG1394: number
  IDVendedor: number
  Status: number
}

export interface ICompanyType {
  type: string
  id: number
}

export interface ICounterOffer {
  date: string
  grainType: string
  weight: number
  price: number
  destination: string
  businessStatus: string
  customerId: number
  product: string
  amount: any
  grain: any
  grainId: any
  currencyId: any
}

export interface IQuotaDetail {
  cupoCode: string
  holder: string
  intermediary: string
  sender: string
  brokerBuyer: string
  brokerSeller: string
  deliverer: string
  receiver: string
  destination: string
  grainType: string
  deliveryDate: string
  status: string
  observation: string
  harvestDate: string
  codeOnca: string
  location: string
  province: string
}

export interface ContractState {
  isLoading: boolean
  isLoadingDetail: boolean
  isLoadingOperationDetail: boolean
  isLoadingServerTime: boolean
  graintype?: IGrain[]
  sale?: ISale
  currencies?: ICurrency[]
  contracts?: IContract[]
  customerid?: any
  operationdetail?: IOperationDetail
  quotadetail?: IQuotaDetail
  counteroffer?: ICounterOffer
  companytypes?: ICompanyType[]
  exchangers?: IExchanger[]
  operationtypeid?: number
  trade?: ITrade
  salepricetoset: IOperation
  tradepricetoset: IOperation
  fixings?: IFixing
  conditiontype?: IConditionType[]
  zones?: IZone[]
  grainprice?: IGrainPrice[]
  harvest?: string[]
  origin?: string
  location?: string
  detailsContract: any
  contractsID: {
    grain: string
    condition: string
    location: string
    zone: string
    exchanger: string
    currency: string
    currencyProduct: string
  }
  contractsDates: {
    priceFixingEnd: string | number
    priceFixingStart: string | number
    dateFrom: string | number
    dateTo: string | number
    shippingFrom: string | number
    expiration: string | number
  }
  serverDate: string | number
}

export enum ContractActionTypes {
  SET_LOADING = '@CONTRACT:SET_LOADING',
  DISMISS_LOADING = '@CONTRACT:DISMISS_LOADING',
  SET_LOADING_DETAIL = '@CONTRACT:SET_LOADING_DETAIL',
  DISMISS_LOADING_DETAIL = '@CONTRACT:DISMISS_LOADING_DETAIL',
  SET_LOADING_OPERATION_DETAIL = '@CONTRACT:SET_LOADING_OPERATION_DETAIL',
  DISMISS_LOADING_OPERATION_DETAIL = '@CONTRACT:DISMISS_LOADING_OPERATION_DETAIL',
  SET_LOADING_SERVERTIME = '@CONTRACT:SET_SERVER_LOADING',
  DISMISS_LOADING_SERVERTIME = '@CONTRACT:DISMISS_SERVER_LOADING',
  GET_GRAIN_TYPE = '@CONTRACT:GET_GRAIN_TYPE',
  GET_CURRENCIES = '@CONTRACT:GET_CURRENCIES',
  GET_GRAIN_PRICE = '@CONTRACT:GET_GRAIN_PRICE',
  GET_CONTRACTS = '@CONTRACT:GET_CONTRACTS',
  CLEAR_CONTRACTS = '@CONTRACT:CLEAR_CONTRACTS',
  GET_VALIDATE_USER = '@CONTRACT:GET_VALIDATE_USER',
  GET_OPERATION_DETAIL = '@CONTRACT:GET_OPERATION_DETAIL',
  CLEAR_OPERATION_DETAIL = '@CONTRACT:CLEAR_OPERATION_DETAIL',
  SET_NEW_CUPO = '@CONTRACT:SET_NEW_CUPO',
  GET_QUOTA_DETAIL = '@CONTRACT:GET_QUOTA_DETAIL',
  GET_COUNTER_OFFER = '@CONTRACT:GET_COUNTER_OFFER',
  SET_OFFER_CONFIRM = '@CONTRACT:SET_OFFER_CONFIRM',
  SET_OFFER_REJECT = '@CONTRACT:SET_OFFER_REJECT',
  GET_COMPANY_TYPE = '@CONTRACT:GET_COMPANY_TYPE',
  LOGOUT = '@CONTRACT:LOGOUT',
  SET_TRADE = '@CONTRACT:SET_TRADE',
  CLEAR_TRADE_INFO = '@CONTRACT:CLEAR_TRADE_INFO',
  SET_SALE = '@CONTRACT:SET_SALE',
  CLEAR_SALE_INFO = '@CONTRACT:CLEAR_SALE_INFO',
  SET_OPERATION_TYPE_ID = '@CONTRACT:SET_OPERATION_TYPE_ID',
  GET_MY_DATA = '@CONTRACT:GET_MY_DATA',
  SET_SALEPRICETOSET = '@CONTRACT:SET_SALEPRICETOSET',
  CLEAR_SALEPRICETOSET_INFO = '@CONTRACT:CLEAR_SALEPRICETOSET_INFO',
  GET_CONDITION_TYPE = '@CONTRACT:GET_CONDITION_TYPE',
  SET_FIXINGS = '@CONTRACT:SET_FIXINGS',
  CLEAR_FIXINGS = '@CONTRACT:CLEAR_FIXINGS',
  SET_TRADE_PRICE_TO_SET = '@CONTRACT:SET_TRADE_PRICE_TO_SET',
  CLEAR_TRADE_PRICE_TO_SET = '@CONTRACT:CLEAR_TRADE_PRICE_TO_SET',
  CLEAR_TRADE_FIXED_PRICE = '@CONTRACT:CLEAR_TRADE_FIXED_PRICE',
  GET_EXCHANGERS = '@CONTRACT:GET_EXCHANGERS',
  GET_ZONES = '@CONTRACT:GET_ZONES',
  CLEAR_ZONES = '@CONTRACT:CLEAR_ZONES',
  SET_HARVEST_DATE = '@CONTRACT:SET_HARVEST_DATE',
  SET_ORIGIN = '@CONTRACT:SET_ORIGIN',
  GET_DETAILS_CONTRACT = '@CONTRAC:GET_DETAILS_CONTRACT',
  SET_ID = '@CONTRAC:SET_ID',
  SET_VALUE_DATE = '@CONTRAC:SET_VALUE_DATE',
  SET_SERVER_DATE = '@CONTRACT:SET_SERVER_DATE',
}
