import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, violet } from '@qirapagos/lib/theme/colors'

function SvgComponent ({ active }: {active: boolean}) {
  return (
    <Svg width={22} height={22} viewBox="0 0 23 23" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M8.448 22.078c-1.41 0-2.779-.157-4.073-.47C1.823 20.994.38 19.36.207 16.888.011 14.115.006 11.287 0 8.556v-.56a17.487 17.487 0 01.344-3.6C.83 2.026 2.432.636 5.104.259A25.46 25.46 0 018.65 0c1.308 0 2.594.115 3.821.343 2.435.45 3.92 1.875 4.408 4.238.118.565.19 1.038.23 1.482.03.368-.048.68-.23.896-.156.189-.388.298-.665.324-.036.002-.07.005-.106.005-.372 0-.85-.174-1.006-1.012l-.05-.267c-.085-.45-.171-.916-.277-1.366-.272-1.144-.979-1.88-1.99-2.074-1.401-.27-2.936-.433-4.568-.492-.115-.003-.23-.006-.344-.006-.836 0-1.764.104-2.837.315-1.784.352-2.58 1.215-2.835 3.072a30.85 30.85 0 00-.249 2.76 32.48 32.48 0 00-.014 1.88c.003.28.006.56.006.838 0 .08-.03.15-.084.2.025.49.048.98.067 1.473.05 1.23.1 2.507.26 3.738.285 2.237 1.012 2.996 3.237 3.384 1.143.2 2.256.3 3.307.3 1.303 0 2.566-.154 3.762-.457 1.39-.354 2.156-1.192 2.343-2.564.03-.233.087-.463.14-.683.039-.163.078-.326.106-.492.154-.868.64-1.048 1.02-1.048.07 0 .143.006.221.02.872.14.816.913.786 1.332-.24 3.35-1.806 5.09-5.08 5.633a21.93 21.93 0 01-3.578.309l-.002-.003z"
        fill={active ? violet : gray}
      />
      <Path
        // eslint-disable-next-line max-len
        d="M18.821 15.31a.944.944 0 01-.654-.278c-.464-.43-.45-1.004.045-1.571.321-.371.67-.725 1.006-1.068l.168-.172h-9.353c-.21 0-.422-.003-.635-.008-.38-.009-1.386-.028-1.386-1.051 0-1.001.939-1.001 1.389-1.001h2.379c2.418 0 4.833 0 7.25-.006.14 0 .283-.01.431-.022l-.02-.02c-.25-.256-.505-.511-.757-.767-.103-.107-.207-.214-.307-.32L18.2 8.84c-.45-.472-.481-1.034-.084-1.507.251-.298.52-.36.701-.36.269 0 .537.127.8.377.559.529 1.113 1.082 1.646 1.62l.548.547c.126.127.252.253.375.383.134.14.271.278.408.413.263.261.397.54.4.826 0 .281-.12.548-.367.798a318.96 318.96 0 01-2.965 2.968c-.274.273-.56.41-.845.41l.003-.005z"
        fill={active ? violet : gray}
      />
    </Svg>
  )
}

export default SvgComponent
