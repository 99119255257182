import styled from 'styled-components'
import DrawerComponent from 'react-modern-drawer'
import { gray } from 'theme/colors'

export const Container = styled.div`
  height: 100%;
  justify-content: space-evenly;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0;
  padding: 0 2.5rem;
`

export const HeaderTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0 1rem;
`

export const EmptyDiv = styled.div`
  height: 25px;
  width: 25px;
`

export const Body = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 2.5rem;
`

export const Title = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: ${gray};
  margin-bottom: 15px;
  margin-top: 15px;

`

export const Drawer = styled(DrawerComponent)`
  width: 50% !important;
  min-width: 300px !important;
  @media (max-width: 600px) {
    width: 100% !important;
  }
  @media (min-width: 1024px) {
    width: 30% !important;
  }
`
