import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function Handshake (props: SvgProps) {
  return (
    <Svg
      width={31}
      height={32}
      viewBox="0 0 31 32"
      fill="none"
      {...props}
    >
      <Path
        d="M29.008 15.223l-2.979 1.545-3.856-7.66 3.01-1.56a.932.932 0 01.727-.058c.24.08.44.256.558.489l2.963 5.887a1.028 1.028 0 01-.123 1.114.969.969 0 01-.3.243v0zM4.82 16.634l-2.978-1.545a.97.97 0 01-.3-.242 1.011 1.011 0 01-.23-.736c.01-.133.047-.261.106-.38l2.964-5.886a.974.974 0 01.557-.489c.24-.08.502-.06.728.057l3.01 1.56-3.857 7.661z"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M26.03 16.768l-1.929 2.34-4.434 4.6a.958.958 0 01-.424.256.93.93 0 01-.491.006l-6.985-1.81a.951.951 0 01-.36-.183L4.82 16.634"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M24.101 19.108l-5.302-4-1.542 1.2c-.668.519-1.48.8-2.314.8-.835 0-1.646-.281-2.314-.8l-.653-.508a.992.992 0 01-.268-.322 1.027 1.027 0 01.165-1.186l4.716-4.891a.934.934 0 01.682-.293h4.902"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.746 8.974l6.184-1.87a.932.932 0 01.667.048l4.166 1.956M13.497 26.608l-3.632-.942a.952.952 0 01-.399-.216L6.748 23"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Handshake
