import React from 'react'
import { FormikProps } from 'formik'
import * as Yup from 'yup'
import { SelectField } from 'components/shared/SelectField'
import { DatePickerField } from 'components/shared/DatePickerField'
import { FixationCondition, conditionsType } from 'store/contracts/types'
import {
  Container,
  Title,
  Inputs
} from './styles'

export enum DeliveryPeriod {
  IMMEDIATE = 'INMEDIATA',
  CONTRACTUAL = 'CONTRACTUAL',
  FUTURE = 'FUTURA',
}

const conditionsTypeOptions = [
  { value: FixationCondition.SLATE_PRICE, label: conditionsType[FixationCondition.SLATE_PRICE] },
  { value: FixationCondition.MARKET_PRICE, label: conditionsType[FixationCondition.MARKET_PRICE] }
]

export const formConditionInitialValues = {
  fixationCondition: undefined,
  conditionDateFrom: undefined,
  conditionDateTo: undefined
}

export const formConditionValidator = Yup.object().shape({
  fixationCondition: Yup.string()
    .required('Es requerido'),
  conditionDateFrom: Yup.date()
    .required('Es requerido'),
  conditionDateTo: Yup.date()
    .required('Es requerido')
})

export interface FormConditionValues {
  fixationCondition: FixationCondition | undefined;
  conditionDateFrom: Date | undefined;
  conditionDateTo: Date | undefined;
}

interface Props {
  titlesInfo: string;
  formik: FormikProps<any>;
}

const FormCondition: React.FC<Props> = (props) => {
  const { titlesInfo, formik } = props

  return (
    <Container>
      <Title>{titlesInfo}</Title>
      <Inputs>
        <SelectField
          formik={formik}
          name="fixationCondition"
          label="Condición"
          options={conditionsTypeOptions}
        />
        <DatePickerField
          formik={formik}
          name="conditionDateFrom"
          label="Desde"
        />
        <DatePickerField
          formik={formik}
          name="conditionDateTo"
          label="Hasta"
        />
      </Inputs>
    </Container>
  )
}

export default FormCondition
