export enum FilterType {
  ALL_MOVEMENTS = 'Todos los movimientos',
  INCOMING = 'Enviaste',
  OUTGOING = 'Recibiste',
  ALL_STATUS = 'Todos los estados',
  ACTIVE = 'Activos',
  FINISHED = 'Finalizados',
  ALL_DATES = 'Por fecha',
  ALL_DATES_DOCUMENTS = 'Filtrar por fecha',
  SIGNED = 'Firmados',
  PENDING_SIGNATURE = 'Pendiente de firma',
  ALL_DOCUMENTS = 'Todos los documentos'
}
