import styled from 'styled-components'

export const Container = styled.div`
  justify-content: space-between;
  align-content: center;
  align-items: center;
  align-self: center;
  margin: 3%;
`

export const Tons = styled.div`
  flex-direction: row;
  padding-top: 10px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 70px;
`

export const HowWorksContainer = styled.div`
  align-self: flex-start;
`
