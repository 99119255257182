import React from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch } from 'react-redux'
import { setShowDrawerGrains } from 'store/modals/actions'
import BackArrow from '@qirapagos/web/src/assets/svg/BackArrow'
import {
  Title,
  ButtonContainer,
  Header,
  DrawerContainer,
  BackButton,
  DrawerBody,
  ContentContainer,
  ContinueButton,
  ButtonText,
  getDrawerStyles
} from './styles'
import GrainInfo from 'components/molecules/GrainsInfo'
import { useAppSelector } from 'hooks/redux'

const DrawerGrains: React.FC = () => {
  const { drawerConfig, drawerInfo, drawerGrains } = useAppSelector(
    (state) => state.modal
  )
  const dispatch = useDispatch()
  const onClose = () => {
    dispatch(setShowDrawerGrains(false, drawerConfig, drawerInfo))
  }

  return (
    <Drawer
      open={drawerGrains.showDrawerGrains}
      onClose={onClose}
      direction="right"
      style={getDrawerStyles()}
    >
      <DrawerContainer>
        <ContentContainer>
          <Header>
            <BackButton onClick={onClose}>
              <BackArrow />
            </BackButton>
            <Title>{drawerGrains.drawerConfig.title}</Title>
          </Header>
          <DrawerBody>
            <GrainInfo />
          </DrawerBody>
        </ContentContainer>
        <ButtonContainer>
          <ContinueButton onPress={onClose}>
            <ButtonText>
              Entendido
            </ButtonText>
          </ContinueButton>
        </ButtonContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default DrawerGrains
