/* eslint-disable react/require-default-props */
import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useAppSelector } from 'hooks/redux'
import Button from 'components/atoms/Button'
import { Field as FieldWeb } from 'components/molecules/Field'
import { Form } from 'components'
import { emailRegex } from 'constants/validations'
import {
  ContentContainer,
  RecoverAccountText,
  PasswordExpiredText
} from './styles'
import { forgotPasswordAsk } from 'store/auth/thunks'
import { errorMessagesByCode } from 'constants/errors'

type Props = {
  setEmail: (email: string) => void
  next: () => void
  isPasswordExpired?: boolean
}

const PasswordRecover: React.FC<Props> = ({
  setEmail,
  next,
  isPasswordExpired = false
}) => {
  const { isLoading, newPasswordError } = useAppSelector(state => state.auth)

  const { control, clearErrors, errors, watch, setError, handleSubmit } =
    useForm({ defaultValues: { email: '' } })

  const dispatch = useDispatch()

  const emailValue = watch('email')
  const disabled = !emailValue

  const onSubmit = async () => {
    try {
      if (!emailValue.match(emailRegex)) {
        return setError('email', { message: 'Formato inválido' })
      }
      if (errors.email) {
        return setError('email', { message: 'Error inesperado' })
      }
      await dispatch(forgotPasswordAsk(emailValue))
      setEmail(emailValue)
    } catch (error) {
      return setError('email', { message: errorMessagesByCode(newPasswordError.code) })
    }
    next()
  }

  return (
    <>
      <ContentContainer>
        <RecoverAccountText
          size="big"
          weight="mediumfont"
          customStyle={{ marginBottom: '50px' }}
        >
          Restablecer contraseña
        </RecoverAccountText>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldWeb
            control={control}
            name="email"
            placeholder="Email"
            error={errors.email}
            showErrorMessage
            clearErrors={clearErrors}
            type="email"
          />
        </Form>
        {isPasswordExpired && (
          <PasswordExpiredText>
            La contraseña de tu cuenta ha vencido, ingresá tu email en el que te
            enviaremos los pasos a seguir para restablecerla
          </PasswordExpiredText>
        )}
      </ContentContainer>
      <Button onPress={onSubmit} disabled={disabled} loading={isLoading}>
        Siguiente
      </Button>
    </>
  )
}

export default PasswordRecover
