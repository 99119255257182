import React from 'react'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import 'moment/locale/es'
import { CustomBox } from './styles'
import { isValidDate, isValidDateIn2Years } from '@qirapagos/lib/utils/common'
import RenderIf from 'components/utilities/RenderIf'
import { AnimatedSpringView } from 'animations'
import { opacityAnimationConfig } from 'constants/common'
import OutsideAlerter from 'components/utilities/OutsideClickListener'

interface Props {
  open: boolean
  onClick: (value: any) => void
  shouldInvalidateDates: boolean
  setState?: (state: boolean) => void
}

const DatePicker: React.FC<Props> = ({
  open,
  onClick,
  setState = () => null,
  shouldInvalidateDates
}) => {
  return (
    <RenderIf isTrue={open}>
      <AnimatedSpringView customAnimation={opacityAnimationConfig}>
        <OutsideAlerter state={open} setState={setState}>
          <CustomBox>
            <Datetime
              timeFormat={false}
              input={false}
              locale="es"
              isValidDate={
                shouldInvalidateDates
                  ? (currentDate: any) => {
                      return (
                        isValidDate(currentDate) &&
                        isValidDateIn2Years(currentDate)
                      )
                    }
                  : () => true
              }
              onChange={value => onClick(value)}
            />
          </CustomBox>
        </OutsideAlerter>
      </AnimatedSpringView>
    </RenderIf>
  )
}

export default DatePicker

DatePicker.defaultProps = {
  setState: () => null
}
