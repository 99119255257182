import React, { useEffect, useMemo } from 'react'
import Quota from 'screens/Home/Grains/Quotas/QuotaDateBox/Quota'
import CustomText from 'components/atoms/CustomText'
import { FormikProps } from 'formik'
import { FormValues } from '../'
import { BoxContainer, BoxTextContainer, QuotaContainer } from './styles'

interface Props {
  formik: FormikProps<FormValues>
  setEmptyDateBox: React.Dispatch<React.SetStateAction<boolean>>
}
const QuotaDateBox: React.FC<Props> = ({ formik, setEmptyDateBox }) => {
  const totalAssigned = useMemo(() => {
    return formik.values.assignedQuotes.reduce(
      (acc, item) => acc + item.quantity,
      0
    )
  }, [formik.values.assignedQuotes])

  const handleChange = (index: number, value: number) => {
    const newValue = formik.values.assignedQuotes.map((field, position) => {
      if (position === index) {
        return {
          ...field,
          quantity: value
        }
      }
      return field
    })
    formik.setFieldValue('assignedQuotes', newValue)
  }

  useEffect(() => {
    setEmptyDateBox(!totalAssigned)
  }, [totalAssigned, setEmptyDateBox])

  return (
    <BoxContainer>
      <BoxTextContainer>
        <CustomText size="xmedium" color="gray">
          {'Pendientes de asignación: '}
          {formik.values.quotas - totalAssigned}
        </CustomText>
      </BoxTextContainer>
      <QuotaContainer>
        {formik.values.assignedQuotes.map(({ date, quantity }, index) => (
          <Quota
            key={date.toDateString()}
            index={index}
            date={date}
            quantity={quantity}
            handleChange={handleChange}
            disabled={totalAssigned === Number(formik.values.quotas)}
          />
        ))}
      </QuotaContainer>
    </BoxContainer>
  )
}

export default QuotaDateBox
