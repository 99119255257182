/* eslint-disable react/require-default-props */
import React from 'react'
import { FormikProps } from 'formik'
import { TextField as TextFieldMUI } from './styles'

interface Props {
  name: string
  label: string
  formik: FormikProps<any>
  type?: React.InputHTMLAttributes<unknown>['type'] | 'only-number'
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<Element>) => void
}

export const TextField = (props: Props) => {
  const { label, name, formik, type, disabled, onChange } = props

  return (
    <TextFieldMUI
      name={name}
      label={label}
      type={type}
      onChange={event => {
        if (type === 'only-number') {
          event.target.value = event.target.value
            .replace(/[^\d]/g, '')
            .replace(/^0+/, '')
        }
        if (onChange) {
          onChange(event)
        }
        formik.handleChange(event)
      }}
      value={formik.values[name]}
      variant="filled"
      error={formik.touched?.[name] && Boolean(formik.errors?.[name])}
      helperText={formik.touched?.[name] && formik.errors?.[name]}
      disabled={disabled}
    />
  )
}
