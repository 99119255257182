import { ContractActionTypes } from '@qirapagos/lib/store/contracts/types'
import {
  ContractActionTypes as ContractActionTypesV2,
  QuotaComplete,
  IApplication,
  ILiquidation,
  SaleOrderCreated
} from 'store/contracts/types'

export const setContractIds = (id: any, property: string) => ({
  type: ContractActionTypes.SET_ID,
  payload: {
    id,
    property
  }
})

export const setContractDates = (date: string | number, property: string) => ({
  type: ContractActionTypes.SET_VALUE_DATE,
  payload: { date, property }
})

export const getSaleOrdersPending = () => ({
  type: ContractActionTypesV2.GET_SALE_ORDERS_PENDING
})

export const getSaleOrdersFulfilled = (values: SaleOrderCreated) => ({
  type: ContractActionTypesV2.GET_SALE_ORDERS_FULFILLED,
  payload: values
})

export const getSaleOrdersRejected = (error: any) => ({
  type: ContractActionTypesV2.GET_SALE_ORDERS_REJECTED,
  payload: error
})

export const selectSaleOrder = (values: SaleOrderCreated) => ({
  type: ContractActionTypesV2.SELECT_SALE_ORDERS,
  payload: values
})

export const createSaleOrderPending = () => ({
  type: ContractActionTypesV2.CREATE_SALE_ORDER_PENDING
})

export const createSaleOrderFulfilled = (values: SaleOrderCreated) => ({
  type: ContractActionTypesV2.CREATE_SALE_ORDER_FULFILLED,
  payload: values
})

export const createSaleOrderRejected = (error: any) => ({
  type: ContractActionTypesV2.CREATE_SALE_ORDER_REJECTED,
  payload: error
})

export const replyCounterOfferPending = () => ({
  type: ContractActionTypesV2.REPLY_COUNTER_OFFER_PENDING
})

export const replyCounterOfferFulfilled = (response: any) => ({
  type: ContractActionTypesV2.REPLY_COUNTER_OFFER_FULFILLED,
  payload: response
})

export const replyCounterOfferRejected = (error: any) => ({
  type: ContractActionTypesV2.REPLY_COUNTER_OFFER_REJECTED,
  payload: error
})

export const getSaleOrderPending = () => ({
  type: ContractActionTypesV2.GET_SALE_ORDER_PENDING
})

export const getSaleOrderFulfilled = (values: SaleOrderCreated) => ({
  type: ContractActionTypesV2.GET_SALE_ORDER_FULFILLED,
  payload: values
})

export const getSaleOrderRejected = (error: any) => ({
  type: ContractActionTypesV2.GET_SALE_ORDER_REJECTED,
  payload: error
})

export const createQuotaPending = () => ({
  type: ContractActionTypesV2.CREATE_QUOTAS_PENDING
})

export const createQuotaFulfilled = (values: QuotaComplete) => ({
  type: ContractActionTypesV2.CREATE_QUOTAS_FULFILLED,
  payload: values
})

export const createQuotaRejected = (error: any) => ({
  type: ContractActionTypesV2.CREATE_QUOTAS_REJECTED,
  payload: error
})

export const getQuotaPending = () => ({
  type: ContractActionTypesV2.GET_QUOTA_PENDING
})

export const getQuotaFulfilled = (values: QuotaComplete) => ({
  type: ContractActionTypesV2.GET_QUOTA_FULFILLED,
  payload: values
})

export const getQuotaRejected = (error: any) => ({
  type: ContractActionTypesV2.GET_QUOTA_REJECTED,
  payload: error
})

export const selectApplication = (values: IApplication) => ({
  type: ContractActionTypesV2.SELECT_APPLICATION,
  payload: values
})

export const selectLiquidation = (values: ILiquidation) => ({
  type: ContractActionTypesV2.SELECT_LIQUIDATION,
  payload: values
})
