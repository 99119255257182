/* eslint-disable react/require-default-props */
import React from 'react'
import { FormikProps } from 'formik'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

import FormHelperText from '@material-ui/core/FormHelperText'
import { RadioGroup, FormControl } from './styles'

interface Option {
  label: string
  value: string | number
}

interface Props {
  name: string
  label?: string
  options?: Option[]
  formik: FormikProps<any>
  disabled?: boolean
}

export const RadioField = (props: Props) => {
  const { name, label, formik, options, disabled } = props

  return (
    <FormControl>
      <FormLabel error={formik.touched[name] && Boolean(formik.errors[name])}>
        {label}
      </FormLabel>
      <RadioGroup
        disabled={disabled ?? false}
        aria-label={name}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
      >
        {options?.map(option => (
          <FormControlLabel
            disabled={disabled}
            key={option.value}
            value={option.value}
            control={<Radio checked={formik.values[name] == option.value} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText error>{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  )
}
