export const purple = '#7D66FF'
export const purpleDisabled = '#CBC2FF'
export const purpleSec = '#F0EDFF'
export const purpleDark = '#6C57DD'
export const black = '#000000'
export const blackSec = '#1F1F1F'
export const blue = '#5AB0FF'
export const blueSec = '#CEE7FE'
export const blueMarine = '#7D66FF'
export const error = '#FF5A3D'
export const gray = '#7D8893'
export const graySec = '#B1B8BE'
export const grayLight = '#E2E5F0'
export const grayText = '#7D8893'
export const grayTextLight = '#7D8893'
export const grayBackLight = '#B1B8BE'
export const grayTextFilter = '#B3B3B3'
export const darkGray = '#4A4A4A'
export const grayLast = '#CCC'
export const graySoft = '#F5F6FA'
export const grayContent = '#F2F4F5'
export const grayContentLight = '#878787'
export const green = '#02A94C'
export const greenSec = '#EEF7E3'
export const magentaSec = '#F0EDFF'
export const newPurple = '#E8E7F7'
export const red = '#FF0000'
export const redSec = '#FAEAE7'
export const violet = '#7D66FF'
export const violetPressed = 'rgba(125, 102, 255, 0.2)'
export const white = '#ffffff'
export const yellow = '#FFBE40'
export const yellowSec = '#FDF5E4'
export const yellowThr = '#ffc83a'
export const disabledGray = '#98979D'
export const cream = '#FFF8E6'
