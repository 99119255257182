import styled from 'styled-components'
import { StyleSheet } from 'react-native'

export const Container = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
  height: 93%;
  margin-left: 3%;
  margin-top: 2%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
`

export const BoxQuota = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin-left: 5%;
  margin-top: 5%;
`

export const BoxCalendar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const BoxButton = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`
