import { Action } from '../../interfaces/reduxInterfaces'
import initialState from './initialState'
import { AuthActionTypes, AuthState } from './types'

export default function authReducer (state = initialState, action: Action): AuthState {
  switch (action.type) {
    case AuthActionTypes.SET_KEYENDPOINTS:
      return {
        ...state,
        keyEndPoint: action.payload
      }
    case AuthActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        newPasswordError: initialState.newPasswordError
      }
    case AuthActionTypes.SET_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false
      }
    case AuthActionTypes.DISMISS_LOADING:
      return {
        ...state,
        isLoading: false,
        isError: false
      }
    case AuthActionTypes.SET_REGISTER_USER:
      return {
        ...state,
        registerUser: {
          ...state.registerUser,
          ...action.payload
        }
      }
    case AuthActionTypes.SET_USER_STATUS:
      return {
        ...state,
        userStatus: action.payload
      }
    case AuthActionTypes.SET_VERIFY_INFO:
      return {
        ...state,
        verifyUser: {
          ...state.verifyUser,
          [action.payload.type]: action.payload.value
        }
      }
    case AuthActionTypes.SET_VERIFY_PHONE_INFO:
      return {
        ...state,
        verifyPhone: {
          ...state.verifyUser,
          [action.payload.type]: action.payload.value
        }
      }
    case AuthActionTypes.SET_USER_SELFIE:
      return {
        ...state,
        userSelfie: {
          ...state.userSelfie,
          [action.payload.type]: action.payload.value
        }
      }
    case AuthActionTypes.SET_USER_DNI_PHOTO:
      return {
        ...state,
        userDNI: {
          ...state.userDNI,
          [action.payload.type]: action.payload.value
        }
      }
    case AuthActionTypes.CLEAN_SELFIE:
      return {
        ...state,
        userSelfie: {
          feliz: '',
          serio: ''
        }
      }

    case AuthActionTypes.SET_FILE:
      return {
        ...state,
        documents: state.documents.concat(action.payload)
      }
    case AuthActionTypes.REMOVE_FILE:
      return {
        ...state,
        documents: state.documents.filter(
          (item, index) => index !== action.payload
        )
      }
    case AuthActionTypes.SET_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.payload
      }
    case AuthActionTypes.SET_BUSINESS_NAME:
      return {
        ...state,
        businessName: action.payload
      }
    case AuthActionTypes.SET_IS_BUSINESS:
      return {
        ...state,
        isBusiness: action.payload
      }
    case AuthActionTypes.RESET_FILES:
      return {
        ...state,
        documents: []
      }

    case AuthActionTypes.SET_RECOVER_USER:
      return {
        ...state,
        recoverUser: {
          ...state.recoverUser,
          [action.payload.type]: action.payload.value
        }
      }
    case AuthActionTypes.LOGOUT:
      return {
        ...initialState,
        keyEndPoint: state.keyEndPoint
      }
    case AuthActionTypes.LOCAL_AUTH_SPLASH:
      return {
        ...state,
        localAuthFromSplash: action.payload
      }
    case AuthActionTypes.SET_DOCUMENT_REJECTED:
      return {
        ...state,
        documentsRejected: action.payload
      }
    case AuthActionTypes.CLEAR_DOCUMENT_REJECTED:
      return {
        ...state,
        documentsRejected: []
      }
    case AuthActionTypes.SET_MULTI_ENTERPRISES:
      return {
        ...state,
        multiEnterprise: action.payload
      }
    case AuthActionTypes.SET_TWO_FACTOR_VERIFIED:
      return {
        ...state,
        twoFactorVerified: action.payload
      }
    case AuthActionTypes.SET_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload
      }
    case AuthActionTypes.SET_NEW_PASSWORD_ERROR:
      return {
        ...state,
        newPasswordError: action.payload
      }
    case AuthActionTypes.REMOVE_NEW_PASSWORD_ERROR:
      return {
        ...state,
        newPasswordError: initialState.newPasswordError
      }
    case AuthActionTypes.SET_RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        recoverPasswordError: action.payload
      }
    case AuthActionTypes.SET_RECOVER_PASSWORD_LOADING:
      return {
        ...state,
        isRecoverPasswordLoading: action.payload
      }
    case AuthActionTypes.SET_LAST_LOGIN:
      return {
        ...state,
        lastLogin: action.payload
      }
    case AuthActionTypes.GET_FULL_TERMS_AND_CONDITIONS_PENDING:
      return {
        ...state,
        isLoadingFullTermsAndConditions: true
      }
    case AuthActionTypes.GET_FULL_TERMS_AND_CONDITIONS_FULFILLED:
      return {
        ...state,
        isLoadingFullTermsAndConditions: false
      }
    case AuthActionTypes.GET_FULL_TERMS_AND_CONDITIONS_REJECTED:
      return {
        ...state,
        isLoadingFullTermsAndConditions: false,
        fullTermsAndConditionsError: action.payload
      }
    case AuthActionTypes.GET_SHORT_TERMS_AND_CONDITIONS_PENDING:
      return {
        ...state,
        isLoadingShortTermsAndConditions: true
      }
    case AuthActionTypes.GET_SHORT_TERMS_AND_CONDITIONS_FULFILLED:
      return {
        ...state,
        isLoadingShortTermsAndConditions: false
      }
    case AuthActionTypes.GET_SHORT_TERMS_AND_CONDITIONS_REJECTED:
      return {
        ...state,
        isLoadingShortTermsAndConditions: false,
        shortTermsAndConditionsError: action.payload
      }
    case AuthActionTypes.GET_PRIVACY_POLICIES_PENDING:
      return {
        ...state,
        isLoadingPrivacyPolicies: true
      }
    case AuthActionTypes.GET_PRIVACY_POLICIES_FULFILLED:
      return {
        ...state,
        isLoadingPrivacyPolicies: false
      }
    case AuthActionTypes.GET_PRIVACY_POLICIES_REJECTED:
      return {
        ...state,
        isLoadingPrivacyPolicies: false,
        privacyPoliciesError: action.payload
      }
    case AuthActionTypes.GET_MY_DATA_PENDING:
      return {
        ...state,
        isLoading: true
      }
    case AuthActionTypes.GET_MY_DATA_FULFILLED:
      return {
        ...state,
        isLoading: initialState.isLoading,
        myData: action.payload
      }
    case AuthActionTypes.GET_MY_DATA_REJECTED:
      return {
        ...state,
        isLoading: initialState.isLoading
      }
    case AuthActionTypes.SET_PHONE_PENDING:
      return {
        ...state,
        isLoading: true
      }
    case AuthActionTypes.SET_PHONE_FULFILLED:
      return {
        ...state,
        isLoading: initialState.isLoading,
        myData: {
          ...state.myData,
          telephoneNumber: action.payload
        }
      }
    case AuthActionTypes.SET_PHONE_REJECTED:
      return {
        ...state,
        isLoading: initialState.isLoading
      }
    case AuthActionTypes.SET_EMAIL_PENDING:
      return {
        ...state,
        isLoading: true
      }
    case AuthActionTypes.SET_EMAIL_FULFILLED:
      return {
        ...state,
        isLoading: initialState.isLoading,
        myData: {
          ...state.myData,
          email: action.payload
        }
      }
    case AuthActionTypes.SET_EMAIL_REJECTED:
      return {
        ...state,
        isLoading: initialState.isLoading
      }
    case AuthActionTypes.CHANGE_ADDRESS_PENDING: {
      return {
        ...state,
        isMyDataEditing: true
      }
    }
    case AuthActionTypes.CHANGE_ADDRESS_FULFILLED: {
      return {
        ...state,
        isMyDataEditing: initialState.isLoading,
        myData: {
          ...state.myData,
          ...action.payload
        }
      }
    }
    case AuthActionTypes.CHANGE_ADDRESS_REJECTED: {
      return {
        ...state,
        isMyDataEditing: initialState.isLoading
      }
    }
    default:
      return state
  }
}
