import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { white } from '@qirapagos/lib/theme/colors'

const MyEnterprisesSkeleton = () => (
  <ContentLoader
    speed={3}
    interval={0}
    width="100%"
    height="90vh"
    viewBox="0 0 100% 100%"
    backgroundColor="#e8e7f7"
    foregroundColor={white}
  >
    <Rect x="8%" y="5.5vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="8%" y="28vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="8%" y="51vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="8%" y="74vh" rx="8" ry="6" width="25%" height="15.5%" />
    <Rect x="34%" y="5.5vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="34%" y="28vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="34%" y="51vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="34%" y="74vh" rx="8" ry="6" width="25%" height="15.5%" />
    <Rect x="60%" y="5.5vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="60%" y="28vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="60%" y="51vh" rx="8" ry="6" width="25%" height="23.5%" />
    <Rect x="60%" y="74vh" rx="8" ry="6" width="25%" height="15.5%" />

  </ContentLoader>
)

export default MyEnterprisesSkeleton
