import styled from 'styled-components'

export const BoxContainer = styled.div`
  width: 80%;
  margin-left: 5px;
`

export const BoxTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 32px 5px;
`

export const QuotaContainer = styled.div`
  height: 450px;
  overflow-y: scroll;
`
