import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { onlyNumberSchemeValidation } from 'validations/operationsForms'
import Field from '@qirapagos/lib/components/molecules/Field/index.web'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from 'hooks/redux'
import {
  BoxFixations,
  BoxPriceFixing,
  BoxPriceWithTooltip,
  dropdownStyles,
  formStyles,
  ScreenContainer,
  TooltipContainer,
  radioStyles,
  requestNewPriceFixation,
  fieldCustomStyles
} from './styles'
import { FieldSelect, Form, Tooltip } from 'components'
import Radio from 'components/atoms/Radio'
import { options } from './constants'
import { useDispatch } from 'react-redux'
import { setContractIds } from 'store/contracts/actions'
import Button from 'components/atoms/Button'
import InfoSVG from 'assets/svg/infoSVG'
import { getPriceFixations, newPriceFix } from 'store/contracts/thunks'
import useNavigation from 'hooks/useNavigation'

const Fixings: React.FC = () => {
  const dispatch = useDispatch()
  const [selectedCreatedBy, setSelectedCreatedBy] = useState('')
  const [selectedCurrencyID, setSelectedCurrencyID] = useState('')
  const { location } = useNavigation()
  const { operationId } = (location.state as any) || 0
  const { isLoading: isLoadingContracts } = useAppSelector(
    state => state.contracts
  )

  useEffect(() => {
    dispatch(setContractIds(selectedCurrencyID, 'currency'))
    dispatch(setContractIds(selectedCreatedBy, 'createdBy'))
  }, [dispatch, selectedCreatedBy, selectedCurrencyID])
  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      quotas: 0
    },
    resolver: yupResolver(onlyNumberSchemeValidation),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const tons = watch('tons')
  const price = watch('price')

  const onSubmit = () => {
    const body = {
      operationId: operationId,
      weight: tons,
      price: price,
      currency: selectedCurrencyID
    }

    dispatch(newPriceFix(body, operationId))
    dispatch(getPriceFixations(operationId))
  }

  return (
    <ScreenContainer>
      <BoxFixations>
        <Form
          customStyle={formStyles}
          onSubmit={handleSubmit(onSubmit)}
        >
          <BoxPriceFixing>
            <FieldSelect
              customStyle={dropdownStyles}
              data={[{ companyName: 'Precio pizarra', id: 1 }]}
              name="condition"
              label="Condición de fijación"
              editable={false}
              accessibilityLabelValue="Condición de fijación"
              control={control}
              setValue={setValue}
              setSelected={setSelectedCreatedBy}
              selected={selectedCreatedBy}
              setSelectedID={setSelectedCreatedBy}
              filterBy="companyName"
            />
            <Field
              customStyleContainer={fieldCustomStyles}
              name="tons"
              control={control}
              setValue={setValue}
              label="Toneladas totales a fijar"
              rules={{
                required: true,
                regexp: [0 - 9]
              }}
              accessibilityLabelValue="Cantidad de cupos"
              error={errors.quotas}
              errorMessage={{ message: errors.quotas?.message }}
            />
            <BoxPriceWithTooltip>
              <TooltipContainer>
                <Tooltip
                  placement="right"
                  label="Ref. pizarra 03/03/2021 $18000 x TT"
                  hasArrow={false}
                  margin={10}
                >
                  <InfoSVG />
                </Tooltip>
              </TooltipContainer>
              <Field
                name="price"
                control={control}
                setValue={setValue}
                label="Precio por TT"
                rules={{
                  required: true,
                  regexp: [0 - 9]
                }}
                accessibilityLabelValue="Precio por TT"
                error={errors.quotas}
                errorMessage={{ message: errors.quotas?.message }}
              />
            </BoxPriceWithTooltip>
            <Radio
              name="radioFixings"
              register={register}
              control={control}
              options={options}
              colorScheme="purple"
              customStyle={radioStyles}
              setValueID={setSelectedCurrencyID}
            />
            <Button
              disabled={isLoadingContracts}
              onPress={onSubmit}
              customStyle={requestNewPriceFixation}
            >
              Confirmar
            </Button>
          </BoxPriceFixing>
        </Form>
      </BoxFixations>
    </ScreenContainer>
  )
}

export default Fixings

Fixings.defaultProps = {
  errors: {}
}
