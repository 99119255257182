import styled from 'styled-components'
import { StyleSheet } from 'react-native'
import { DEVICES } from 'theme/devicesSizes'

export const styles = StyleSheet.create({
  customTitle: {
    marginTop: '2%',
    marginLeft: '3%',
    marginRight: '3%',
    color: '#98979D'
  },
  customSubtitle: {
    marginTop: '2%',
    marginLeft: '3%',
    marginRight: '3%'
  },
  customText: {
    marginTop: '2%',
    marginBottom: '6.5%',
    marginLeft: '3%',
    marginRight: '3%',
    color: '#98979D'
  },
  buttonLine: {
    width: 'fit-content',
    height: 'fit-content'
  }
})

export const BoxContainer = styled.div`
  justify-content: space-around;
  padding: 2% 0px 1% 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  position: relative;
  background-color: #fff;
  width: 45%;
  min-width: 300px;
  height: 210px;
  border-radius: 6px;
  margin-right: 2%;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.13);

  @media ${DEVICES.tablet} {
    margin-bottom: 40px;
    width: 500px;
  }

  @media ${DEVICES.mobile} {
    width: 80%;
    min-width: auto;
  }
`

export const TooltipContainer = styled.div`
  position: absolute;
  right: 0;
  top: -25px;
`
