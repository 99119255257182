import styled from 'styled-components'

export const BoxStatus = styled.div<{ background: string }>`
  display: 'flex';
  flex-direction: 'column';
  border-radius: 6px;
  min-width: 100px;
  padding-bottom: 1px;
  padding-top: 1px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: 'center';
  align-items: 'center';
  align-self: 'center';
  background-color: ${props => props.background};
`
