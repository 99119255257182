import { useEffect, useState } from 'react'
import {
  ALIAS_CHARACTERS
} from '@qirapagos/web/src/constants/validations'

const useCheckAlias = (alias: string) => {
  const [checkAliasLength, setCheckAliasLength] = useState(false)
  const [checkCharacters, setCheckCharacters] = useState(false)

  useEffect(() => {
    setCheckAliasLength(alias.length >= 6 && alias.length <= 20)
    setCheckCharacters(Boolean(alias.match(ALIAS_CHARACTERS)))
  }, [alias])

  return {
    checkAliasLength,
    checkCharacters
  }
}

export default useCheckAlias
