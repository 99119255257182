import styled from 'styled-components'

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 60px;
  border-width: 1.5px 1.5px 0px 1.5px;
  border-radius: 6px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
  margin-bottom: 8px;
`
export const BoxQuotaQuantity = styled.div`
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  background-color: #f5f6fa;
  padding: 10px 10px;
  margin: 5px 5px;
`

export const BoxHandlerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
  height: 100%;
  margin-left: 10px;
`
