import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView } from 'react-native'
import { AccordionItemComponent, StepperContract, AccordionComponent } from 'components'
import { useForm } from 'react-hook-form'
import {
  subTitleOne,
  subTitleTwo,
  titleOne,
  titleTwo,
  titleThree,
  titleFour,
  subTitleFour,
  titleFive,
  subTitleFive,
  titleTwoAlternative
} from './constants'
import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepTwo'
import StepThree from './Steps/StepThree'
import StepFour from './Steps/StepFour'
import StepFive from './Steps/StepFive'
import SuccessTickWithBackground from 'assets/svg/SuccessTickWithBackground'
import { purple, white } from '@qirapagos/lib/theme/colors'
import useNavigation from 'hooks/useNavigation'
import { styles } from './styles'
import { useAppSelector } from 'hooks/redux'
import { IContact } from '@qirapagos/lib/store/transfers/types'
import { stringToNumber } from './utils'
import * as TransfersActions from '@qirapagos/lib/store/transfers/actions'
import { useDispatch } from 'react-redux'

const TransferSteps: React.FC = () => {
  const [isFirstComplete, setFirstIscomplete] = useState(false)
  const [isSecondComplete, setSecondIscomplete] = useState(false)
  const [isThirdComplete, setThirdIscomplete] = useState(false)
  const [isFourthComplete, setFourthIscomplete] = useState(false)
  const [isFifthComplete, setFifthIscomplete] = useState(false)
  const [data, setData] = useState<IContact>()
  const [amount, setAmount] = useState()
  const [currentStepBox, setCurrentStepBox] = useState(0)
  const [index, setIndex] = useState(0)
  const { location } = useNavigation()
  const { fromRepeatTransfer, dataAddressee, amountAddressee } = location.state as any
  const { contacts } = useAppSelector(state => state.transfers)
  const { balance } = useAppSelector((state) => state.transfers)
  const isBalanceEnough = amountAddressee < stringToNumber(balance)
  const dispatch: any = useDispatch()

  const { control, clearErrors, errors, watch, setError, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      cuit: '',
      transferInput: '',
      observation: ''
    }
  })

  const titlesInfo = useMemo(
    () => [
      fromRepeatTransfer
        ? {
            title: '1. Ingresá los datos',
            isComplete: true,
            setComplete: (value: boolean) => setFirstIscomplete(value),
            setDataSecondStep: (value: any) => setData(value),
            dataAddressee
          }
        : {
            title: '1. Ingresá los datos',
            isComplete: isFirstComplete,
            setComplete: (value: boolean) => setFirstIscomplete(value),
            setDataSecondStep: (value: any) => setData(value)
          },
      fromRepeatTransfer
        ? {
            title: '2. Confirmá los datos',
            isComplete: true,
            setComplete: (value: boolean) => setSecondIscomplete(value),
            dataSecondStep: data
          }
        : {
            title: '2. Confirmá los datos',
            isComplete: isSecondComplete,
            setComplete: (value: boolean) => setSecondIscomplete(value),
            dataSecondStep: data
          },
      fromRepeatTransfer
        ? {
            title: '3. Ingresá el monto',
            isComplete: true,
            setComplete: (value: boolean) => setThirdIscomplete(value),
            setAmountValue: (value: any) => setAmount(value),
            dataSecondStep: data
          }
        : {
            title: '3. Ingresá el monto',
            isComplete: isThirdComplete,
            setComplete: (value: boolean) => setThirdIscomplete(value),
            setAmountValue: (value: any) => setAmount(value),
            dataSecondStep: data
          },
      {
        title: '4. Confirmá y agendá',
        isComplete: isFourthComplete,
        setComplete: (value: boolean) => setFourthIscomplete(value),
        dataSecondStep: data,
        dataAmount: amount || amountAddressee
      },
      {
        title: '5. Ingresá el token',
        isComplete: isFifthComplete,
        setComplete: (value: boolean) => setFifthIscomplete(value)
      }
    ],
    [
      data,
      fromRepeatTransfer,
      isFifthComplete,
      isFirstComplete,
      isFourthComplete,
      isSecondComplete,
      isThirdComplete
    ]
  )

  useEffect(() => {
    if (fromRepeatTransfer) {
      if (isBalanceEnough && amountAddressee) {
        setIndex(3)
      } else {
        setIndex(2)
      }
    } else {
      setIndex(0)
    }
    return (
      dispatch(TransfersActions.clearAccountInfo())
    )
  }, [fromRepeatTransfer, isBalanceEnough, amountAddressee])

  useEffect(() => {
    if (isFifthComplete) return setCurrentStepBox(5)
    if (isFourthComplete) return setCurrentStepBox(4)
    if (isThirdComplete) return setCurrentStepBox(3)
    if (isSecondComplete) return setCurrentStepBox(2)
    if (isFirstComplete) return setCurrentStepBox(1)
  }, [
    isFifthComplete,
    isFirstComplete,
    isFourthComplete,
    isSecondComplete,
    isThirdComplete,
    titlesInfo
  ])

  const setTitleStepTwo = () => {
    const isAContact = contacts?.some((contact) => contact.cvu === data?.cvu)
    if (isAContact) {
      return titleTwoAlternative
    } else {
      return titleTwo
    }
  }
  setTitleStepTwo()

  return (
    <ScrollView stickyHeaderIndices={[0]} style={styles.scrollView}>
      <StepperContract
        titlesInfo={titlesInfo}
        currentStepBox={currentStepBox}
      />
      <AccordionComponent
        customStyle={styles.accordionContainer}
        allowToggle={false}
        index={index}
      >
        <AccordionItemComponent
          accordionTitle={titleOne}
          accordionSubTitle={subTitleOne}
          accordionBody={(
            <StepOne
              titlesInfo={titlesInfo[0]}
              setIndex={setIndex}
              control={control}
              errors={errors}
              setError={setError}
              getValues={getValues}
            />
          )}
          customStyle={{ marginBottom: 5 }}
          icon={
            titlesInfo[0].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
        />
        <AccordionItemComponent
          accordionTitle={setTitleStepTwo()}
          accordionSubTitle={subTitleTwo}
          accordionBody={(
            <StepTwo
              control={control}
              clearErrors={clearErrors}
              errors={errors}
              setIndex={setIndex}
              titlesInfo={titlesInfo[1]}
            />
          )}
          customStyle={{ marginBottom: 5 }}
          icon={
            titlesInfo[1].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
        />
        <AccordionItemComponent
          accordionTitle={titleThree}
          accordionBody={(
            <StepThree
              control={control}
              watch={watch}
              setIndex={setIndex}
              titlesInfo={titlesInfo[2]}
              index={index}
              setError={setError}
              errors={errors}
              setValue={setValue}
            />
          )}
          customStyle={{ marginBottom: 5 }}
          icon={
            titlesInfo[2].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
        />
        <AccordionItemComponent
          accordionTitle={titleFour}
          accordionSubTitle={subTitleFour}
          accordionBody={
            <StepFour setIndex={setIndex} titlesInfo={titlesInfo} />
          }
          customStyle={{ marginBottom: 5 }}
          icon={
            titlesInfo[3].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
        />
        <AccordionItemComponent
          accordionTitle={titleFive}
          accordionSubTitle={subTitleFive}
          accordionBody={<StepFive index={index} />}
          customStyle={{ marginBottom: 5 }}
          icon={
            titlesInfo[4].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
        />
      </AccordionComponent>
    </ScrollView>
  )
}

export default TransferSteps
