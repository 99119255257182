import React, { useMemo, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { Box } from '@chakra-ui/react'
import { useAppSelector } from 'hooks/redux'
import { createQuotas, getSaleOrder } from 'store/contracts/thunks'
import { StepperContract } from 'components'
import Button from '@qirapagos/web/src/components/atoms/Button'
import CustomText from 'components/atoms/CustomText'
import QuotaDateBox from 'screens/Home/Grains/Quotas/QuotaDateBox'
import { TextField } from 'components/shared/TextField'
import { BoxButton, BoxCalendar, BoxQuota, Container } from './styles'
import { QuotaDate } from 'store/contracts/types'
import { showDrawer as showDrawerAction } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'
import SetQuotaSkeleton from 'assets/skeletons/SetQuotaSkeleton'
import { StatusQuota } from '../ActivityGrains/QuoteRequest'

export interface FormValues {
  quotas: number
  assignedQuotes: {
    date: Date
    quantity: number
  }[]
}

const Quotas: React.FC = () => {
  const { saleOrderId } = useParams()

  const { isQuotaLoading, isLoading, selected } = useAppSelector(
    state => state.contractsV2
  )
  const [emptyDateBox, setEmptyDateBox] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSaleOrder(Number(saleOrderId)))
  }, [dispatch, saleOrderId])

  const tons = selected?.tons ? selected?.tons : 0

  const arrayPreviousQuotas = selected?.Quota?.filter(
    quota =>
      quota.status === StatusQuota.AWARDED ||
      quota.status === StatusQuota.REQUIRED
  )
  const previousQuotas = arrayPreviousQuotas?.length
    ? arrayPreviousQuotas?.length
    : 0

  const totalQuantityQuotas = Math.ceil(tons / 30)
  const totalQuantityQuotasAvailable = totalQuantityQuotas - previousQuotas

  const [currentStepBox, setCurrentStepBox] = useState(0)
  const [isFirstComplete, setFirstIsComplete] = useState(false)
  const [isSecondComplete, setSecondIsComplete] = useState(false)

  const titlesInfo = useMemo(
    () => [
      {
        title: '1. Cantidad de cupos',
        isComplete: isFirstComplete,
        setComplete: (value: boolean) => setFirstIsComplete(value)
      },
      {
        title: '2. Días de entrega',
        isComplete: isSecondComplete,
        setComplete: (value: boolean) => setSecondIsComplete(value)
      }
    ],
    [isFirstComplete, isSecondComplete]
  )

  const onSubmit = async (values: FormValues) => {
    const bodyFormatted: QuotaDate[] = []
    values.assignedQuotes.forEach(item => {
      if (item.quantity > 0) {
        for (let i = 0; i < item.quantity; i++) {
          bodyFormatted.push({
            deliveryDate: item.date.toISOString().split('T')[0]
          })
        }
      }
    })
    try {
      await dispatch(createQuotas(Number(saleOrderId), { body: bodyFormatted }))
      dispatch(showDrawerAction(Drawers.QUOTA_CREATE_SUCCESS))
    } catch (error) {
      dispatch(showDrawerAction(Drawers.SALE_ORDER_ERROR))
    }
  }

  const initialDate = selected?.deliveryFrom
    ? new Date(selected?.deliveryFrom + 'T00:00:00') // Avoid timeZone changes
    : new Date()

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      quotas: 0,
      assignedQuotes: [
        { date: addDays(initialDate, 1), quantity: 0 },
        { date: addDays(initialDate, 2), quantity: 0 },
        { date: addDays(initialDate, 3), quantity: 0 },
        { date: addDays(initialDate, 4), quantity: 0 },
        { date: addDays(initialDate, 5), quantity: 0 }
      ]
    },
    validationSchema: Yup.object().shape({
      quotas: Yup.string()
        .required('Es requerido')
        .test(
          'is-less-than-total',
          `La cantidad de cupos no puede ser mayor a ${totalQuantityQuotasAvailable}`,
          value => Number(value) <= totalQuantityQuotasAvailable
        )
    }),
    onSubmit
  })

  useEffect(() => {
    const { quotas, assignedQuotes } = formik.values
    setFirstIsComplete(Boolean(quotas))
    setCurrentStepBox(quotas ? 1 : 0)
    const someQuantity = assignedQuotes.some(item => item.quantity > 0)
    setSecondIsComplete(someQuantity)
  }, [formik.values])

  if (isLoading) {
    return <SetQuotaSkeleton />
  }

  return (
    <>
      <StepperContract
        titlesInfo={titlesInfo}
        currentStepBox={currentStepBox}
      />
      <Box w="100%" h="100%">
        <Container onSubmit={formik.handleSubmit}>
          <BoxQuota>
            <TextField
              name="quotas"
              formik={formik}
              label="Cantidad de cupos"
              type="only-number"
              onChange={() => {
                // Reset quotes assigned previously
                formik.setFieldValue(
                  'assignedQuotes',
                  formik.values.assignedQuotes.map(field => {
                    return {
                      ...field,
                      quantity: 0
                    }
                  })
                )
              }}
            />
            <CustomText
              customStyle={{ marginTop: 15, marginLeft: 15 }}
              size="xmedium"
              weight="regular"
              color="gray"
            >
              Cantidad de TT de referencia
            </CustomText>
            <CustomText
              size="xmedium"
              weight="semibold"
              color="purple"
              customStyle={{ marginLeft: 15 }}
            >
              {`${(formik.values.quotas || 0) * 30} TT`}
            </CustomText>
          </BoxQuota>
          <BoxCalendar>
            <QuotaDateBox formik={formik} setEmptyDateBox={setEmptyDateBox} />
            <BoxButton>
              <Button
                disabled={!formik.values.quotas || emptyDateBox}
                loading={isQuotaLoading}
              >
                Enviar solicitud de cupo
              </Button>
            </BoxButton>
          </BoxCalendar>
        </Container>
      </Box>
    </>
  )
}

export default Quotas
