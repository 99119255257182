import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'hooks/redux'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { AutocompleteField } from 'components/shared/AutocompleteField'
import { TextField } from 'components/shared/TextField'
import { getExchangers } from 'store/contracts/thunks'
import { Container, Title, Inputs } from './styles'

export const formExchangerInitialValues = {
  exchanger: '',
  exchangerTaxId: ''
}

export const formExchangersValidator = Yup.object().shape({
  exchanger: Yup.string()
    .required('Es requerido'),
  exchangerTaxId: Yup.string()
    .required('Es requerido')
})

export interface FormExchangersValues {
  exchanger: string | undefined
  exchangerTaxId: string | undefined
}

interface Props {
  titlesInfo: string;
  formik: FormikProps<any>;
}

const FormExchangers: React.FC<Props> = (props) => {
  const { titlesInfo, formik } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getExchangers())
  }, [dispatch])

  const { exchangers = [] } = useAppSelector((state) => state.contracts)

  const exchangersList = useMemo(() => {
    return exchangers
      .map((exchanger) => ({
        title: exchanger.companyname,
        id: exchanger.taxid
      }))
  }, [exchangers])

  useEffect(() => {
    formik.setFieldValue('exchangerTaxId', formik.values.exchanger || '')
  }, [formik.values.exchanger])

  return (
    <Container>
      <Title>{titlesInfo}</Title>
      <Inputs>
        <AutocompleteField
          formik={formik}
          name="exchanger"
          label="Canjeadores"
          options={exchangersList}
        />
        <TextField
          formik={formik}
          name="exchangerTaxId"
          label="CUIT"
          disabled
        />
      </Inputs>
    </Container>
  )
}

export default FormExchangers
