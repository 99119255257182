import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  @media ${DEVICES.tablet} {
    min-height: auto;
    justify-content: center;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media ${DEVICES.tablet} {
    width: 80%;
  }
`
