import { AuthState } from './types'

const initialState: AuthState = {
  registerUser: {
    cuit: '',
    name: '',
    password: '',
    email: '',
    dni: '',
    code: ''
  },
  verifyUser: {
    clave: '',
    codigo: ''
  },
  verifyPhone: {
    phone: {
      codearea: '',
      number: ''
    },
    codigo: ''
  },
  userSelfie: {
    feliz: '',
    serio: ''
  },
  userDNI: {
    frente: '',
    dorso: ''
  },
  keyEndPoint: '',
  isLoading: false,
  // Should start in true for an async issue with recovery password validation.
  // Go to RecoverPassword to know more about it.
  isRecoverPasswordLoading: true,
  isError: false,
  userStatus: '',
  documents: [],
  documentType: 'file',
  businessName: '',
  isBusiness: false,
  recoverUser: {
    email: '',
    password: '',
    code: '',
    validCode: ''
  },
  documentsRejected: [],
  localAuthFromSplash: false,
  multiEnterprise: [],
  twoFactorVerified: false,
  appStatus: true,
  newPasswordError: {
    message: '',
    code: 0,
    error: false
  },
  recoverPasswordError: '',
  lastLogin: {
    platform: '',
    ip: '',
    deviceId: '',
    clientId: 0,
    date: ''
  },
  isLoadingFullTermsAndConditions: false,
  isLoadingShortTermsAndConditions: false,
  isLoadingPrivacyPolicies: false,
  fullTermsAndConditionsError: undefined,
  shortTermsAndConditionsError: undefined,
  privacyPoliciesError: undefined,
  myData: undefined,
  isMyDataEditing: false
}

export default initialState
