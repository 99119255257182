import { graySoft } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'

export const BoxOutside = styled.div<any>`
  background-color: ${graySoft};
  height: 100vh;
  width: 100vw;
`

export const BoxContent = styled.div`
  padding-top: 200px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
`
export const BoxLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-top: 85px;
`
export const BoxTextContainer = styled.div`
  width: 320px;
  margin-top: 34px;
  flex-wrap: wrap;
`
