import styled from 'styled-components'
import { purple } from 'theme/colors'

export const RemoveButton = styled.button`
  text-decoration: underline;
  color: ${purple};
  font-weight: 400
`

export const TextUploadedFile = styled.p`
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 230px;
`
