import React, { useEffect, useState } from 'react'
import { CustomModal, Select, Table, withFade } from 'components'
import Helmet from 'components/utilities/Helmet'
import setShowModal from 'store/modals/actions'
import { SCREEN_TITLES } from 'utils/navigation'
import { useTable } from 'hooks/useTable'
import { FilterType } from 'constants/filters'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getDocuments } from 'store/signature/thunks'
import Button from 'components/atoms/Button'
import { configDocuments } from './types'
import {
  Container,
  OperationsContainer,
  ButtonContainer,
  TableContainer,
  Text,
  SelectTitle
} from './styles'

const Signature: React.FC = () => {
  const dispatch = useAppDispatch()

  const [selectedCompany, setSelectedCompany] = useState('')

  const { documentLink, isLoading } = useAppSelector(state => state.signature)
  const { showModal } = useAppSelector(state => state.modal)
  const { handleFilterDocuments, handleFilterType } = useTable()

  const companies = useAppSelector((state) => {
    const companiesName = new Set(state.signature.documents.map((document) => document.companyName))
    const allCompaniesOption = 'Todas'
    const filteredList = [allCompaniesOption, ...Array.from(companiesName)]
    return filteredList
  })

  const filteredDocuments = useAppSelector((state) => {
    const filteredByCompany = state.signature.documents.filter((document) => {
      return selectedCompany === document.companyName
    })
    return filteredByCompany.length > 0 ? filteredByCompany : state.signature.documents
  })

  useEffect(() => {
    dispatch(getDocuments())
  }, [dispatch])

  return (
    <Container>
      <Helmet title={SCREEN_TITLES.SIGNATURE} />
      <OperationsContainer>
        <SelectTitle>
          Empresas
        </SelectTitle>
        <Select
          placeholder="Seleccione una empresa"
          options={companies}
          selected={selectedCompany}
          onSelect={setSelectedCompany}
        />
      </OperationsContainer>
      <TableContainer>
        <Table
          data={handleFilterDocuments(filteredDocuments)}
          title="Todos los documentos"
          config={configDocuments}
          fromScreen="signature"
          filterOptions={[
            FilterType.ALL_DOCUMENTS,
            FilterType.SIGNED,
            FilterType.PENDING_SIGNATURE
          ]}
          isLoading={isLoading}
          onFilter={handleFilterType}
          emptyText="No hay resultados para tu búsqueda"
        />
      </TableContainer>
      <CustomModal
        isOpen={showModal === 'signature'}
        onClose={() => dispatch(setShowModal(''))}
        title="Saliendo de Qira"
      >
        <Text>
          Serás redireccionado a Signatura
        </Text>
        <Text>
          para firmar el documento
        </Text>
        <ButtonContainer>
          <Button
            customStyle={{ marginBottom: 10 }}
            onPress={() => {
              window.open(documentLink, '_blank')
              dispatch(setShowModal(''))
            }}
          >
            Continuar
          </Button>
          <Button
            secondary
            customStyle={{ marginBottom: 0 }}
            onPress={() => dispatch(setShowModal(''))}
          >
            Cancelar
          </Button>
        </ButtonContainer>
      </CustomModal>
    </Container>
  )
}

export default withFade(Signature)
