import { white } from '@qirapagos/lib/theme/colors'
import { ReactComponent as MaintenanceIcon } from 'assets/svg/Maintenance.svg'
import styled from 'styled-components'

export const Container = styled.div`
  z-index: 1000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  position: relative;
  width: 830px;
  height: 415px;
  text-align: center;
  @media (max-width: 850px) {
    width: auto;
  }
`

export const LogoContainer = styled.div`
  margin-bottom: 1.5rem;
`

export const Maintenance = styled(MaintenanceIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 850px) {
    position: relative;
    margin-top: 1.5rem;
  }
`

export const Text = styled.p`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
`
