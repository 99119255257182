import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, grayTextFilter, violet } from '@qirapagos/lib/theme/colors'

function CustomerSupportItem ({
  active,
  canBeHovered
}: {
  active: boolean
  canBeHovered?: boolean
}) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M14.01 2.279a8.13 8.13 0 015.739 5.739M13.17 5.415a4.878 4.878 0 013.444 3.444M7.22 10.882a8.555 8.555 0 003.961 3.943.805.805 0 00.794-.06l2.54-1.693a.812.812 0 01.77-.071l4.75 2.036a.809.809 0 01.487.841 4.87 4.87 0 01-4.83 4.253A13.794 13.794 0 011.897 6.336a4.87 4.87 0 014.253-4.83.809.809 0 01.841.487L9.03 6.748a.811.811 0 01-.067.764L7.275 10.09a.804.804 0 00-.055.792v0z"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default CustomerSupportItem

CustomerSupportItem.defaultProps = {
  canBeHovered: true
}
