import { grayContent } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components/native'

export const ViewContainer = styled.View`
  background-color: ${grayContent};
  padding-top: 40px;
  max-width: 1180px;
`

export const ViewMapContainer = styled.View`
  flex-direction: row;
  width: 85%;
  margin-left: 40px;
  justify-content: space-between;
  background-color: ${grayContent};
  top: -4;
  padding-bottom: 20px;
`
