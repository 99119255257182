import React, { useEffect, useCallback, useState } from 'react'
import { Field } from 'components/molecules/Field'
import Helmet from 'components/utilities/Helmet'
import { useForm } from 'react-hook-form'
import useCheckPassword from 'hooks/useCheckPassword'
import { useDispatch, useSelector } from 'react-redux'
import { updatePassword } from 'store/auth/thunks'
import { setShowDrawerSuccess } from 'store/modals/actions'
import { title } from './constants'
import { SCREEN_TITLES } from 'utils/navigation'
import { RenderIf, PasswordCheckers } from 'components'
import TwoFactorAuthentication from 'components/molecules/TwoFactorAuthentication'
import { Button } from './style'
import {
  ViewContainerFields,
  ViewNewPasswords,
  ViewInputContainer,
  ViewOldPassword,
  TextChangePassword,
  ViewContainerPassword
} from './styleWeb'
import { errorMessagesByCode, errorMessagesCode } from 'constants/errors'

const Steps = {
  TWO_FACTOR: 'TWO_FACTOR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD'
}

const ChangePasswordMyData = () => {
  const [step, setStep] = useState(Steps.TWO_FACTOR)
  const { showDrawerSuccess } = useSelector((state: any) => state.modal)
  const { isLoading, newPasswordError } = useSelector((state: any) => state.auth)

  const { control, watch, handleSubmit, setError, errors, clearErrors } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      passwordConfirm: ''
    }
  })

  const dispatch = useDispatch()
  const watchFields = watch(['oldPassword', 'newPassword', 'passwordConfirm'])

  const {
    checkPasswordLength,
    checkCapitalLeter,
    checkLoweCase,
    checkNumber,
    checkSpecialCharacter
  } = useCheckPassword(watchFields.newPassword)

  const isPasswordValid =
  checkPasswordLength && checkCapitalLeter && checkLoweCase && checkNumber && checkSpecialCharacter

  const isDisabled =
    !watchFields.oldPassword ||
    !watchFields.newPassword ||
    !watchFields.passwordConfirm ||
    !isPasswordValid ||
    watchFields.newPassword !== watchFields.passwordConfirm

  const onPressSubmit = useCallback(async ({ oldPassword, newPassword }) => {
    clearErrors()
    await dispatch(updatePassword(oldPassword, newPassword))
    dispatch(
      setShowDrawerSuccess(
        !showDrawerSuccess.show,
        true,
        title.success,
        '',
        {
          path: '/content/my-data'
        }
      )
    )
  }, [dispatch, showDrawerSuccess])

  const on2FAValidate = () => {
    setStep(Steps.CHANGE_PASSWORD)
  }

  useEffect(() => {
    if (newPasswordError.code === errorMessagesCode.NEW_PASSWORD_USED_BEFORE) {
      setError('newPassword', { message: errorMessagesByCode(newPasswordError.code) })
      setError('passwordConfirm', { message: errorMessagesByCode(newPasswordError.code) })
    } else if (newPasswordError.code === errorMessagesCode.OLD_PASSWORD_INCORRECT) {
      setError('oldPassword', { message: errorMessagesByCode(newPasswordError.code) })
    } else {
      clearErrors()
    }
  }, [newPasswordError, setError, clearErrors])

  return (
    <>
      <RenderIf isTrue={step === Steps.TWO_FACTOR}>
        <TwoFactorAuthentication callback={on2FAValidate} />
      </RenderIf>
      <RenderIf isTrue={step === Steps.CHANGE_PASSWORD}>
        <ViewContainerPassword>
          <Helmet title={SCREEN_TITLES.CHANGE_PASSWORD} />
          <TextChangePassword>Cambiar contraseña</TextChangePassword>
          <ViewContainerFields autoComplete='new-password'>
            <ViewOldPassword>
              <Field
                control={control}
                name="oldPassword"
                placeholder="Contraseña actual"
                type="password"
                showErrorMessage
                error={errors.oldPassword}
                clearErrors={clearErrors}
              />
            </ViewOldPassword>
            <ViewNewPasswords>
              <ViewInputContainer>
                <Field
                  control={control}
                  name="newPassword"
                  placeholder="Nueva contraseña"
                  type="password"
                  showErrorMessage
                  error={errors.newPassword}
                  clearErrors={clearErrors}
                />
              </ViewInputContainer>
              <ViewInputContainer>
                <Field
                  control={control}
                  name="passwordConfirm"
                  placeholder="Repetir nueva contraseña"
                  type="password"
                  showErrorMessage
                  error={errors.passwordConfirm}
                  clearErrors={clearErrors}
                />
              </ViewInputContainer>
              <PasswordCheckers
                checkPasswordLength={checkPasswordLength}
                checkCapitalLetter={checkCapitalLeter}
                checkLoweCase={checkLoweCase}
                checkNumber={checkNumber}
                checkSpecialCharacter={checkSpecialCharacter}
              />
              <Button
                onPress={handleSubmit(onPressSubmit)}
                disabled={isDisabled}
                loading={isLoading}
              >
                Guardar
              </Button>
            </ViewNewPasswords>
          </ViewContainerFields>
        </ViewContainerPassword>
      </RenderIf>
    </>
  )
}

export default ChangePasswordMyData
