import React from 'react'
import { FormikProps } from 'formik'
import CheckboxField from 'components/shared/CheckboxField'
import BoxWithAction from 'components/shared/BoxWithAction'
import { TextInfo } from './style'

interface Props {
  title: string;
  formik: FormikProps<any>;
}

const BoxWithCheckbox: React.FC<Props> = ({ title, formik }) => {
  return (
    <BoxWithAction>
      <TextInfo>
        {title}
      </TextInfo>
      <CheckboxField name="sendEmail" formik={formik} />
    </BoxWithAction>
  )
}

export default BoxWithCheckbox
