import React from 'react'
import { BoxContainer, Text } from './styles'

interface Props {
  title: string
  description: string
  onClick: () => void
}

const SignedDocumentsCard: React.FC<Props> = ({
  title,
  description,
  onClick
}) => {
  return (
    <BoxContainer type='button' onClick={onClick}>
      <Text>
        {title}
      </Text>
      <Text>
        {description}
      </Text>
    </BoxContainer>
  )
}

export default SignedDocumentsCard
