import styled from 'styled-components'
import { gray } from '@qirapagos/lib/theme/colors'
export const EmptyMessage = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-left: 25px;
  color: ${gray};
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`
