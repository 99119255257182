/* eslint-disable max-len */
import * as React from 'react'
import Svg, {
  G,
  Path,
  Defs,
  LinearGradient,
  Stop,
  ClipPath
} from 'react-native-svg'

function EmptyCabinetSVG () {
  return (
    <Svg width={140} height={144} viewBox="0 0 140 144" fill="none">
      <G clipPath="url(#clip0_3780_40137)">
        <Path
          d="M71.567 127.031c32.8 0 59.4-26.4 59.4-59s-26.6-59-59.4-59-59.4 26.4-59.4 59 26.6 59 59.4 59z"
          fill="#EAEEF9"
        />
        <Path
          d="M117.067 60.331v36.1c0 7.5-6.1 13.6-13.7 13.6h-63.4c-7.5 0-13.6-6-13.7-13.5v-36.2-.2c0-.2 0-.4.1-.6.1-.3.2-.5.3-.8l17-32.5c.6-1.3 1.9-2 3.3-2h49.3c1.4 0 2.6.7 3.3 2l17 32.5c.1.2.2.5.3.8.2.2.2.5.2.8z"
          fill="url(#paint0_linear_3780_40137)"
        />
        <G>
          <Path
            d="M84.667 60.631h-25.7c-1.1 0-2-.8-2-2v-35.1c0-1.1.8-2 2-2h25.6c1.1 0 2 .8 2 2v35.1c0 1.1-.8 2-1.9 2z"
            fill="#F5F8FF"
          />
        </G>
        <G>
          <Path
            d="M93.167 68.931h-42.7c-1.4 0-2.5-1.2-2.5-2.5v-34.1c0-1.4 1.2-2.5 2.5-2.5h42.7c1.4 0 2.5 1.2 2.5 2.5v34.1c-.1 1.4-1.1 2.5-2.5 2.5z"
            fill="#F5F8FF"
          />
        </G>
        <G>
          <Path
            d="M98.067 78.63h-52.4c-1.5 0-2.8-1.3-2.8-2.7v-33.6c0-1.5 1.3-2.7 2.8-2.7h52.4c1.5 0 2.799 1.3 2.799 2.7v33.5c-.1 1.6-1.3 2.8-2.8 2.8z"
            fill="#F5F8FF"
          />
        </G>
        <G>
          <Path
            d="M103.366 88.03h-63c-1.7 0-3-1.4-3-2.9v-33.1c0-1.7 1.4-2.9 3-2.9h63c1.7 0 3 1.4 3 2.9v33c-.1 1.7-1.3 3-3 3z"
            fill="#F5F8FF"
          />
        </G>
        <Path
          d="M84.466 62.231h-23.7c-.6 0-1.2-.5-1.2-1.2 0-.6.5-1.2 1.2-1.2h23.7c.6 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2zM77.567 69.231h-30c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7h29.9c.4 0 .7.3.7.7.2.2-.1.7-.6.7zM97.266 69.231h-15.1c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7h15c.4 0 .7.3.7.7.2.2-.1.7-.6.7z"
          fill="#E6ECF5"
        />
        <G>
          <Path
            d="M111.849 62.14v34.227c0 4.868-3.894 8.762-8.851 8.762H40.335c-4.868 0-8.85-3.894-8.85-8.762V61.964c0-.177 0-.354.088-.531h17.18c3.009 0 5.487 2.39 5.487 5.487 0 1.505.62 2.92 1.593 3.894 1.062 1.063 2.301 1.594 3.894 1.594h24.967c3.01 0 5.488-2.39 5.488-5.488 0-1.505.62-2.92 1.593-3.894 1.062-1.062 2.301-1.593 3.806-1.593h16.091c.177.177.177.442.177.708z"
            fill="#fff"
          />
        </G>
        <G>
          <Path
            d="M117.067 60.331v39.8c0 5.5-4.4 9.9-10 9.9h-70.8c-5.5 0-10-4.4-10-9.9v-40c0-.2 0-.4.1-.6h22.8c3.4 0 6.2 2.7 6.2 6.2 0 1.7.7 3.3 1.8 4.4 1.2 1.2 2.6 1.8 4.4 1.8h20.3c3.4 0 6.2-2.7 6.2-6.2 0-1.7.7-3.3 1.8-4.4 1.2-1.2 2.6-1.8 4.3-1.8h22.7c.2.2.2.5.2.8z"
            fill="url(#paint1_linear_3780_40137)"
          />
        </G>
        <Path
          d="M107.067 95.631h-20.6c-.7 0-1.3-.5-1.3-1.3 0-.7.5-1.3 1.3-1.3h20.4c.7 0 1.3.5 1.3 1.3.1.6-.5 1.3-1.1 1.3zM97.867 101.931h-11.4c-.7 0-1.3-.5-1.3-1.3 0-.7.5-1.3 1.3-1.3h11.3c.7 0 1.3.5 1.3 1.3.1.6-.5 1.3-1.2 1.3z"
          fill="#E6ECF5"
        />
        <Path
          d="M90.774 106.131c-.4 1.7-1 3.5-1.7 5-1.9 3.7-4.9 6.6-8.6 8.5-3.8 1.9-8.3 2.7-12.8 1.7-10.7-2.3-17.5-12.6-15.3-23.2 2.2-10.6 12.5-17.5 23.1-15.2 3.8.8 7.1 2.7 9.9 5.3 4.8 4.7 6.7 11.4 5.4 17.9z"
          fill="url(#paint2_linear_3780_40137)"
        />
        <Path
          d="M77.766 100.331h-4.5v-4.5c0-.9-.7-1.7-1.7-1.7-.9 0-1.7.7-1.7 1.7v4.5h-4.5c-.9 0-1.7.7-1.7 1.7s.7 1.7 1.7 1.7h4.5v4.5c0 .9.7 1.7 1.7 1.7.9 0 1.7-.7 1.7-1.7v-4.5h4.5c.9 0 1.7-.7 1.7-1.7s-.8-1.7-1.7-1.7z"
          fill="#fff"
        />
        <Path
          d="M129.449 39.83a4.1 4.1 0 100-8.2 4.1 4.1 0 000 8.2zM125.349 26.532a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zM11.949 35.73a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zM5.649 97.332a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4z"
          fill="#EAEEF9"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_3780_40137"
          x1={71.6738}
          y1={27.212}
          x2={71.6738}
          y2={75.3707}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#B0BACC" />
          <Stop offset={1} stopColor="#969EAE" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_3780_40137"
          x1={71.637}
          y1={58.3632}
          x2={71.637}
          y2={110.576}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FDFEFF" />
          <Stop offset={0.9964} stopColor="#ECF0F5" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_3780_40137"
          x1={71.5697}
          y1={83.8526}
          x2={71.5697}
          y2={105.898}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#B0BACC" />
          <Stop offset={1} stopColor="#969EAE" />
        </LinearGradient>
        <ClipPath id="clip0_3780_40137">
          <Path fill="#fff" d="M0 0H140V144H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default EmptyCabinetSVG
