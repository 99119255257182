/* eslint-disable react/require-default-props */
import React from 'react'
import { Container, PinInput, PinInputField } from './styles'

interface Props {
  value: string
  setValue:(val: string) => void
  error?: boolean
}

const TwoFactorAuthenticatorInput: React.FC<Props> = ({
  value,
  setValue,
  error
}) => {
  return (
    <Container>
      <PinInput
        value={value}
        onChange={setValue}
        placeholder="_"
        isInvalid={error}
      >
        <PinInputField />
        <PinInputField />
        <PinInputField />
        <PinInputField />
        <PinInputField />
        <PinInputField />
      </PinInput>
    </Container>
  )
}

export default TwoFactorAuthenticatorInput
