import { useNavigate, useLocation } from 'react-router-dom'

const useNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const onNavigate = (path: any, params?: any) => {
    navigate(path, { state: params })
  }

  return { onNavigate, location }
}

export default useNavigation
