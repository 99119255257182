import styled from 'styled-components'
import { Colors } from '@qirapagos/lib/theme'
import { MotionStyle } from 'framer-motion'
import { DEVICES } from 'theme/devicesSizes'
import ButtonComponent from 'components/atoms/Button'
import { Image } from '@chakra-ui/react'
import { CSSProperties } from 'react'

export const ScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: ${Colors.white};
`

export const slideStyle: MotionStyle = {
  position: 'relative',
  width: 'auto',
  marginBottom: 50
}

export const mainContentContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  white-space: pre-line;
  word-break: break-word;
  overflow-wrap: break-word;
  text-align: center;
  width: 500px;
  margin-right: 8%;
  @media ${DEVICES.tablet} {
    margin-inline: auto;
    max-width: 95%;
  }
`

export const TextTitleText = styled.p`
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 45px;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  width: 70%;
  @media ${DEVICES.mobile} {
    width: 100%;
  }
`

export const TextBodyText = styled.p`
  display: flex;
  font-size: 1.1rem;
  margin-bottom: 20px;
  justify-content: space-around;
`

export const StoresContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const ImageContainer = styled.div`
  width: 50%;

  @media ${DEVICES.tablet} {
    display: none;
  }
`

export const LoginButton = styled(ButtonComponent)`
  align-self: flex-start;
  margin-bottom: 70px;
`

export const MainImage = styled(Image)`
  height: 100vh;
  object-fit: fill;
`

export const Legend = styled.p`
  color: ${Colors.gray};
  text-align: justify;
  font-size: 10px;
`
