import styled from 'styled-components'
import { disabledGray, black } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'

export const styles = {
  customName: {
    color: black,
    marginLeft: 4
  },
  customText: {
    marginBottom: '6%',
    color: disabledGray
  }
}

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 28%;
  min-width: 260px;
  max-width: 400px;
  max-height: 300px;
  min-height: 200px;
  padding: 20px;
  border-radius: 6px;
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 0.5%;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.13);

  @media ${DEVICES.mobile} {
    width: 320px;
  }
`
export const BoxName = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-items: baseline;
  align-content: center;
`

export const TooltipContainer = styled.div`
  position: absolute;
  right: 0;
  top: -25px;
`
