import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import CustomText from 'components/atoms/CustomText'
import Button from 'components/atoms/Button'
import { PasswordCheckers } from 'components'
import { forgotPasswordRecover } from 'store/auth/thunks'
import { useDispatch, useSelector } from 'react-redux'
import useCheckPassword from 'hooks/useCheckPassword'
import { FieldsContainer, ForgotPasswordButtonContainer } from './styles'
import { Field } from 'components/molecules/Field'
import { errorMessagesByCode } from 'constants/errors'

type Props = {
  next: () => void
}

const ChangePassword: React.FC<Props> = ({ next }) => {
  const { control, clearErrors, setError, errors, watch } = useForm({
    defaultValues: { repeatPassword: '', password: '' },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  })
  const { token } = useParams()

  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: any) => state.auth)

  const password = watch('repeatPassword')
  const watchFields = watch(['password', 'repeatPassword'])
  const {
    checkPasswordLength,
    checkCapitalLeter,
    checkLoweCase,
    checkNumber,
    checkSpecialCharacter
  } = useCheckPassword(watchFields.password)

  const isPasswordValid =
    checkPasswordLength &&
    checkCapitalLeter &&
    checkLoweCase &&
    checkNumber &&
    checkSpecialCharacter

  const disabled =
    !isPasswordValid || watchFields.repeatPassword !== watchFields.password

  const onPress = async () => {
    clearErrors()
    try {
      await dispatch(forgotPasswordRecover(password, token as string))
      next()
    } catch (error) {
      setError('repeatPassword', {
        message: errorMessagesByCode(error as string)
      })
    }
  }

  return (
    <FieldsContainer>
      <CustomText
        size="big"
        weight="mediumfont"
        customStyle={{ marginBottom: 50 }}
      >
        Nueva Contraseña
      </CustomText>
      <Field
        control={control}
        name="password"
        placeholder="Contraseña"
        error={errors.password}
        clearErrors={clearErrors}
        type="password"
      />
      <Field
        control={control}
        name="repeatPassword"
        placeholder="Repetir contraseña"
        error={errors.repeatPassword}
        clearErrors={clearErrors}
        type="password"
      />
      <PasswordCheckers
        checkPasswordLength={checkPasswordLength}
        checkCapitalLetter={checkCapitalLeter}
        checkLoweCase={checkLoweCase}
        checkNumber={checkNumber}
        checkSpecialCharacter={checkSpecialCharacter}
      />
      <ForgotPasswordButtonContainer>
        <Button onPress={onPress} disabled={disabled} loading={isLoading}>
          Restablecer Contraseña
        </Button>
      </ForgotPasswordButtonContainer>
    </FieldsContainer>
  )
}

export default ChangePassword
