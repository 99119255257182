/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'
import QirapagosLogoWeb from '@qirapagos/web/src/assets/svg/QirapagosLogo'
import { violet } from '@qirapagos/lib/theme/colors'
import { LogoutModal, MenuItem, NavLink } from 'components'
import { MENU_SIDE_BAR, MENU_ITEMS } from './constants'
import useNavigation from 'hooks/useNavigation'
import CustomText from 'components/atoms/CustomText'
import AboutModal from '../AboutModal'
import useHasOnboardingComplete, { StatusOnboardingLabel } from '@qirapagos/lib/hooks/useHasOnboardingComplete'
import { useDispatch, useSelector } from 'react-redux'
import {
  RenderItemProps,
  MenuItem as MenuItemType,
  ScreenTitleSideBar
} from './types'
import { IMultiEnterprise } from 'store/menu/types'
import {
  ViewLogo,
  ViewLineSeparator,
  styles,
  SidebarContainer,
  logoutButtonStyles
} from './styles'
import SidebarSkeleton from 'assets/skeletons/SideBarSkeleton'
import { RootState } from 'store'
import {
  getIsRepresentative,
  getRepresented
} from 'store/representation/thunks'
import useHasSisa from '@qirapagos/lib/hooks/useHasSisa'

interface Props {
  setShowMenuDrawer?: any
}

const SideBar: React.FC<Props> = ({ setShowMenuDrawer }) => {
  const dispatch = useDispatch()
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [showEnterPrisesItem, setShowEnterPrisesItem] = useState(true)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [formattedMenuItems, setFormattedMenuItems] = useState<MenuItemType[]>(
    []
  )
  const hasSisa = useHasSisa()
  const companyId = localStorage.getItem('companyId')
  const hasOnboardingComplete = useHasOnboardingComplete()
  const { isLoading } = useSelector((state: RootState) => state.activity)
  const { isRepresentative, represented } = useSelector(
    (state: RootState) => state.representation
  )
  const { location, onNavigate } = useNavigation()
  const notSisaNotRepresented = !hasSisa && !represented?.length
  const representativeNotSisaNotRepresented = isRepresentative && notSisaNotRepresented
  const representedNotSisa = !isRepresentative && !hasSisa

  useEffect(() => {
    dispatch(getIsRepresentative())
    dispatch(getRepresented(Number(companyId)))
  }, [])

  const handleCloseSideBar = () => {
    setShowMenuDrawer(false)
  }

  const getAllEnterprisesFromLocalStorage = () => {
    const enterprises = localStorage.getItem('allEnterprises')
    const parsedEnterprises: IMultiEnterprise[] = JSON.parse(
      enterprises || '[]'
    )
    const businessName = localStorage.getItem('businessName')
    if (parsedEnterprises[0]?.businessName === businessName) {
      setFormattedMenuItems(MENU_SIDE_BAR)
      return setShowEnterPrisesItem(true)
    } else {
      setFormattedMenuItems(
        MENU_SIDE_BAR.filter(item => item.id !== MENU_ITEMS.myCompanies)
      )
      return setShowEnterPrisesItem(false)
    }
  }

  const getMenuSideBarWithDynamicTitle = (isRepresentative: boolean) => {
    return formattedMenuItems.map(item => {
      if (item.id === MENU_ITEMS.myRepresentatives) {
        return {
          ...item,
          title: isRepresentative ? 'Mis representados' : 'Mi representante'
        }
      }
      return item
    })
  }

  useEffect(() => {
    if (!isLoading) {
      getAllEnterprisesFromLocalStorage()
    }
  }, [isLoading])

  if (isLoading) {
    return <SidebarSkeleton />
  }

  const RenderItem: React.FC<RenderItemProps> = ({ item }) => {
    const { title, icon, to, id } = item
    const active = location.pathname.includes(to)

    if (
      id === MENU_ITEMS.grains &&
      (!showEnterPrisesItem ||
        representativeNotSisaNotRepresented ||
        representedNotSisa)
    ) {
      return (
        <MenuItem
          title={title}
          icon={icon}
          active={active}
          canBeHovered={false}
        />
      )
    }
    if (
      (
        hasOnboardingComplete === StatusOnboardingLabel.PENDING) &&
      (id === MENU_ITEMS.myAccount || id === MENU_ITEMS.myCompanies) &&
      showEnterPrisesItem
    ) {
      return (
        <MenuItem
          title={title}
          icon={icon}
          active={active}
          canBeHovered={false}
        />
      )
    }

    if (id === MENU_ITEMS.logout) {
      return (
        <button
          style={logoutButtonStyles}
          type="button"
          id="menuBtnLogout"
          onClick={() => setIsLogoutModalOpen(true)}
        >
          <MenuItem title={title} icon={icon} active={active} />
        </button>
      )
    }

    if
    (
      (notSisaNotRepresented) &&
      id === MENU_ITEMS.myRepresentatives
    ) {
      return (
        <MenuItem
          title={title}
          icon={icon}
          active={active}
          canBeHovered={false}
        />
      )
    }
    return (
      <>
        {!showEnterPrisesItem &&
        title === ScreenTitleSideBar.MY_ENTERPRISES
          ? null
          : (
            <NavLink to={to} title={title} onClick={handleCloseSideBar}>
              <MenuItem title={title} icon={icon} active={active} />
            </NavLink>
            )}
        {(title === ScreenTitleSideBar.YOUR_ACCOUNT ||
          title === ScreenTitleSideBar.BLOCK_ACCOUNT) && <ViewLineSeparator />}
      </>
    )
  }

  return (
    <SidebarContainer>
      <ViewLogo>
        <button type="button" onClick={() => onNavigate('wallet')}>
          <QirapagosLogoWeb color={violet} width={150} height={62.5} />
        </button>
      </ViewLogo>
      <FlatList
        data={getMenuSideBarWithDynamicTitle(isRepresentative)}
        testID="menu-side-bar"
        renderItem={({ item, index }) => RenderItem({ item, index })}
        contentContainerStyle={styles.contentContainerStyle}
        keyExtractor={(_, index) => index.toString()}
        ListFooterComponent={() => (
          <View>
            <ViewLineSeparator />
            <button
              type="button"
              onClick={() => {
                setIsAboutModalOpen(true)
              }}
            >
              <CustomText
                customStyle={{ marginRight: 20 }}
                weight="bold"
                color="purple"
                size="small"
              >
                Acerca de QIRA Pagos
              </CustomText>
            </button>
          </View>
        )}
      />
      <AboutModal isOpen={isAboutModalOpen} setIsOpen={setIsAboutModalOpen} />
      <LogoutModal
        isOpen={isLogoutModalOpen}
        setIsOpen={setIsLogoutModalOpen}
      />
    </SidebarContainer>
  )
}

export default SideBar

SideBar.defaultProps = {
  setShowMenuDrawer: () => null
}
