import { AxiosRequestConfig } from 'axios'
import { instance, ApiResponse } from '.'
import { insertKeyEndpoint } from './utils'
import { Destination, Location, State } from 'store/location/types'

export const getLocations = async (params: AxiosRequestConfig['params']) => {
  return instance.get<ApiResponse<Location[]>>(insertKeyEndpoint('location'), { params })
}

export const getLocationById = async (id: number) => {
  return instance.get<ApiResponse<Location>>(insertKeyEndpoint(`location/${id}`))
}

export const getStates = async () => {
  return instance.get<ApiResponse<State[]>>(insertKeyEndpoint('location/states'))
}

export const getDestinations = async (params: AxiosRequestConfig['params']) => {
  return instance.get<ApiResponse<Destination[]>>(insertKeyEndpoint('location/destinations'), { params })
}

export const getDestinationById = async (id: number) => {
  return instance.get<ApiResponse<Destination>>(insertKeyEndpoint(`location/destination/${id}`))
}

export const getDestinationStates = async () => {
  return instance.get<ApiResponse<State[]>>(insertKeyEndpoint('location/destination-states'))
}
