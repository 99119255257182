import { SignatureActionTypes, SignatureDocument } from './types'

export const getDocumentsPending = () => ({
  type: SignatureActionTypes.GET_DOCUMENTS_PENDING
})

export const getDocumentsFulfilled = (documents: SignatureDocument[]) => ({
  type: SignatureActionTypes.GET_DOCUMENTS_FULFILLED,
  payload: documents
})

export const getDocumentsRejected = (error: Error) => ({
  type: SignatureActionTypes.GET_DOCUMENTS_REJECTED,
  payload: error
})

export const setDocumentLink = (link: string) => ({
  type: SignatureActionTypes.SET_DOCUMENT_LINK,
  payload: link
})

export const getDownloadDocumentPending = (index: number) => ({
  type: SignatureActionTypes.DOWNLOAD_DOCUMENT_PENDING,
  payload: index
})

export const getDownloadDocumentFulfilled = () => ({
  type: SignatureActionTypes.DOWNLOAD_DOCUMENT_FULFILLED
})

export const getDownloadDocumentRejected = (error: Error) => ({
  type: SignatureActionTypes.DOWNLOAD_DOCUMENT_REJECTED,
  payload: error
})
