export const logoAnimationConfig = {
  from: { left: -180, opacity: 0 },
  to: { left: 0, opacity: 1 },
  config: {
    duration: 800,
    mass: 1,
    tension: 20,
    friction: 7
  }
}

export const bodyAnimationConfig = {
  from: { opacity: 0.01 },
  to: { opacity: 1 },
  config: {
    duration: 1000
  },
  delay: 500
}
