import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

export const breakpoints = createBreakpoints({
  sm: '0px',
  md: '600px',
  lg: '1024px',
  xl: '1800px'
})

export const theme = extendTheme({
  breakpoints,
  components: {
    Radio: {
      parts: ['label'],
      baseStyle: {
        label: {
          zIndex: -1
        }
      }
    }
  }
})
