import { styled } from '@material-ui/core/styles'
import FormControlMUI from '@material-ui/core/FormControl'
import TextFieldMUI from '@material-ui/core/TextField'
import MenuItemMUI from '@material-ui/core/MenuItem'
import { graySoft, purple, error } from 'theme/colors'

export const FormControl = styled(FormControlMUI)({
  margin: 10
})

export const TextField = styled(TextFieldMUI)({
  minWidth: 300,
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins',
    fontWeight: 400
  },
  '& .MuiFilledInput-root': {
    backgroundColor: graySoft,
    fontFamily: 'Poppins',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: 'white',
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      backgroundColor: graySoft
    },
    '&.Mui-focused': {
      backgroundColor: graySoft,
      borderColor: purple,
      borderWidth: 2
    },
    '&.MuiFilledInput-underline:before, &.MuiFilledInput-underline:after': {
      borderBottom: 'none !important'
    },
    '&.Mui-error': {
      borderColor: error,
      borderWidth: 1.5
    }
  }
})

export const MenuItem = styled(MenuItemMUI)({
  fontFamily: 'Poppins',
  '&:hover': {
    backgroundColor: graySoft
  },
  '&.Mui-selected': {
    backgroundColor: graySoft,
    color: purple,
    '&:hover': {
      backgroundColor: graySoft
    }
  }
})
