import React from 'react'
import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'
import Button from '@qirapagos/web/src/components/atoms/Button'
import { Colors, Fonts } from 'theme'
import { Text as ChakraText } from '@chakra-ui/react'

export const DrawerContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: scroll;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
`

export const ContentContainer = styled.div`
  display: flex;
  width: 575px;
  padding: 1%;
  flex-direction: column;
  align-self: center;

  @media ${DEVICES.mobile} {
    margin-top: 25px;
  }
`

export const DrawerTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
`

export const BackButton = styled.button`
  position: absolute;
  left: 20px;
`

export const ButtonContainer = styled.div`
  width: 50%;
  align-self: center;

  @media ${DEVICES.mobile} {
    margin-top: 25px;
  }
`

export const ConfirmButton = styled(Button)`
  height: 60px;
`

export const ButtonText = styled(ChakraText)`
  color: ${Colors.white};
  font-size: ${Fonts.SIZES.XMEDIUM};
  fontFamily: ${Fonts.POPPINS_MED};
  fontWeight: 500
`

export const getDrawerStyles = () : React.CSSProperties => {
  const isMobile = window.innerWidth < 600

  if (isMobile) {
    return {
      width: '100%'
    }
  }
  return {
    width: '600px'
  }
}
