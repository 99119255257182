import { LocationState } from './types'

const initialState: LocationState = {
  locations: [],
  destinations: [],
  states: [],
  destinationStates: [],
  isLoading: false,
  isLoadingDestinations: false,
  error: undefined
}

export default initialState
