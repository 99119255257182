import React, { useEffect } from 'react'
import {
  AccordionComponent,
  AccordionItemComponent,
  BoxNewAction,
  Table
} from 'components'
import PlusWithBackground from 'assets/svg/PlusWithBackground'
import { useDispatch } from 'react-redux'
import { getContacts } from 'store/transfers/thunks'
import { useAppSelector } from 'hooks/redux'
import YourAccountSkeleton from 'assets/skeletons/YourAccountSkeleton'
import { configApplicationsContacts } from './constants'
import useNavigation from 'hooks/useNavigation'
import { ViewAcordionContainer, ViewContainer } from './style'
import { useTable } from 'hooks/useTable'

const MyContacts = () => {
  const { isLoading, contacts } = useAppSelector(state => state.transfers)

  const dispatch = useDispatch()
  const {
    filterContacts,
    filterContactValue,
    onChangeFilterContact
  } = useTable()

  useEffect(() => {
    dispatch(getContacts())
  }, [dispatch])

  const { onNavigate } = useNavigation()
  const onPress = () => {
    onNavigate('add-contact', {
      title: 'Agendar nuevo contacto',
      hasBackArrow: true
    })
  }
  if (isLoading) return <YourAccountSkeleton />

  return (
    <ViewContainer>
      <BoxNewAction
        title="Agendar nuevo contacto"
        icon={<PlusWithBackground />}
        onPress={onPress}
      />
      <ViewAcordionContainer>
        <AccordionComponent allowToggle>
          <AccordionItemComponent
            accordionTitle="Mis Contactos"
            accordionBody={(
              <Table
                fromScreen="newTransferContacts"
                searchValue={filterContactValue}
                data={filterContacts(contacts) || []}
                onSearchText={onChangeFilterContact}
                title=""
                config={configApplicationsContacts}
                hasHowWorks={false}
                emptyText={'Aún no tenés ningun \ncontacto agendado'}
                isTextFilter
                showFilters={false}
                hasDateFilter={false}
              />
            )}
          />
        </AccordionComponent>
      </ViewAcordionContainer>
    </ViewContainer>
  )
}

export default MyContacts
