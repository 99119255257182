import { styled } from '@material-ui/core/styles'
import AutocompleteMUI from '@material-ui/lab/Autocomplete'
import { graySoft, purple, error } from 'theme/colors'

export const Autocomplete = styled(AutocompleteMUI)({
  margin: 10,
  minWidth: 300,
  maxWidth: 500,
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins',
    fontWeight: 400
  },
  '& .MuiFilledInput-root': {
    backgroundColor: graySoft,
    fontFamily: 'Poppins',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: 'white',
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: graySoft,
      borderColor: purple,
      borderWidth: 2
    },
    '&.MuiFilledInput-underline:before, &.MuiFilledInput-underline:after': {
      borderBottom: 'none !important'
    },
    '&.Mui-error': {
      borderColor: error,
      borderWidth: 1.5
    }
  }
})
