export enum SCREEN_TITLES {
  WELCOME = 'Qira Pagos',
  LOGIN = 'login',
  WALLET = 'Inicio',
  GRAINS = 'Granos',
  SALE_PRICE_FIXED = 'Nueva orden de venta',
  SALE_PRICE_TO_SET = 'Nueva orden de venta con precio a fijar',
  TRADE_PRICE_FIXED = 'Nueva orden de canje con precio hecho',
  TRADE_PRICE_TO_SET = 'Nueva orden de canje con precio a fijar',
  GRAINS_ACTIVITY = 'Mi orden',
  MY_ACCOUNT = 'Tu cuenta',
  MY_DATA = 'Mis datos',
  MY_ACCOUNT_DETAILS = 'Datos de mi cuenta',
  DOCUMENTATION = 'Documentación',
  EXCHANGERS = 'Canjeadores',
  CLIENT_SERVICE = 'Atención al cliente',
  REPRESENTED_ACCOUNTS = 'Mis representados',
  MY_ENTERPRISES = 'Mis empresas',
  MY_AGENT = 'Mi Representante',
  INVESTMENTS = 'Inversiones - Rendimientos',
  CHANGE_PASSWORD = 'Cambiar contraseña',
  CHANGE_EMAIL = 'Cambiar email',
  UNSUBSCRIPTION = 'Dar de baja',
  YOUR_ACTIVITY = 'Detalle de tu actividad',
  NEW_SALE_ORDER = 'Nueva orden de venta',
  NEW_SALE_ORDER_PTS = 'Nueva orden de venta con precio a fijar',
  NEW_TRADE_ORDER = 'Nueva orden de canje con precio hecho',
  NEW_TRADE_ORDER_PTS = 'Nueva orden de canje con precio a fijar',
  TRANSFER_LIMIT = 'Establecer límite máximo para futuras transferencias',
  SIGNATURE = 'Documentos',
  CHANGE_ALIAS = 'Editar alias',
  CHANGE_ADDRESS = 'Editar domicilio',
}
