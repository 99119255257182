import { LocationActionTypes, Location, State, Destination } from './types'

export const getLocationsPending = () => ({
  type: LocationActionTypes.GET_LOCATIONS_PENDING
})

export const getLocationsFulfilled = (locations: Location[]) => ({
  type: LocationActionTypes.GET_LOCATIONS_FULFILLED,
  payload: locations
})

export const getLocationsRejected = (error: Error) => ({
  type: LocationActionTypes.GET_LOCATIONS_REJECTED,
  payload: error
})

export const getLocationByIdPending = () => ({
  type: LocationActionTypes.GET_LOCATION_BY_ID_PENDING
})

export const getLocationByIdFulfilled = (location: Location) => ({
  type: LocationActionTypes.GET_LOCATION_BY_ID_FULFILLED,
  payload: location
})

export const getLocationByIdRejected = (error: Error) => ({
  type: LocationActionTypes.GET_LOCATION_BY_ID_REJECTED,
  payload: error
})

export const getStatesPending = () => ({
  type: LocationActionTypes.GET_STATES_PENDING
})

export const getStatesFulfilled = (states: State[]) => ({
  type: LocationActionTypes.GET_STATES_FULFILLED,
  payload: states
})

export const getStatesRejected = (error: Error) => ({
  type: LocationActionTypes.GET_STATES_REJECTED,
  payload: error
})

export const getDestinationsPending = () => ({
  type: LocationActionTypes.GET_DESTINATIONS_PENDING
})

export const getDestinationsFulfilled = (destinations: Destination[]) => ({
  type: LocationActionTypes.GET_DESTINATIONS_FULFILLED,
  payload: destinations
})

export const getDestinationsRejected = (error: Error) => ({
  type: LocationActionTypes.GET_DESTINATIONS_REJECTED,
  payload: error
})

export const getDestinationByIdPending = () => ({
  type: LocationActionTypes.GET_DESTINATION_BY_ID_PENDING
})

export const getDestinationByIdFulfilled = (destination: Destination) => ({
  type: LocationActionTypes.GET_DESTINATION_BY_ID_FULFILLED,
  payload: destination
})

export const getDestinationByIdRejected = (error: Error) => ({
  type: LocationActionTypes.GET_DESTINATION_BY_ID_REJECTED,
  payload: error
})

export const cleanLocations = () => ({
  type: LocationActionTypes.CLEAN_LOCATIONS
})

export const getDestinationStatesPending = () => ({
  type: LocationActionTypes.GET_DESTINATION_STATES_PENDING
})

export const getDestinationStatesFulfilled = (states: State[]) => ({
  type: LocationActionTypes.GET_DESTINATION_STATES_FULFILLED,
  payload: states
})

export const getDestinationStatesRejected = (error: Error) => ({
  type: LocationActionTypes.GET_DESTINATION_STATES_REJECTED,
  payload: error
})
