import React from 'react'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { Drawers } from 'store/drawer/types'
import { TransferInfoList } from 'components'
import useSharePdf from 'hooks/useSharePdf'
import {
  Drawer,
  LogoContainer,
  ButtonContainer,
  DownloadButton,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  DataContainer,
  SpinnerContainer,
  ToastRenderItem,
  ReceiptDetailText
} from './styles'
import wrapper from 'components/organisms/Drawers/wrapper'
import QirapagosLogoWeb from '@qirapagos/web/src/assets/svg/QirapagosLogo'
import { violet } from '@qirapagos/lib/theme/colors'
import { Spinner, useToast } from '@chakra-ui/react'
import { useAppSelector } from 'hooks/redux'
import SuccessTickWithBackground from 'assets/svg/SuccessTickWithBackground'
import useNavigation from 'hooks/useNavigation'
import { useLocation } from 'react-router-dom'

interface Props {
  showDrawer: boolean
  onClose: () => void
}

const ReceiptDetail: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { isLoading } = useAppSelector(state => state.transfers)
  const { onNavigate } = useNavigation()
  const location = useLocation()
  const toast = useToast({
    containerStyle: {
      width: '100%'
    }
  })

  const { printRef, onShareHandler } = useSharePdf(220, 110)

  const handleClose = () => {
    if (location.pathname.includes('/content/your-account/new-transfer')) {
      onClose()
      onNavigate('/content/your-account/new-transfer', {
        title: 'Nueva transferencia',
        hasBackArrow: true
      })
    } else {
      onClose()
    }
  }

  return (
    <Drawer
      open={showDrawer}
      onClose={handleClose}
      direction="right"
    >
      {isLoading
        ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          )
        : (
          <DrawerContainer>
            <BackButton onClick={handleClose}>
              <BackArrowIcon />
            </BackButton>
            <div ref={printRef}>
              <HeaderContainer>
                <LogoContainer>
                  <QirapagosLogoWeb color={violet} width={150} height={35} />
                  <ReceiptDetailText>Detalle de comprobante</ReceiptDetailText>
                </LogoContainer>
              </HeaderContainer>
              <DataContainer>
                <TransferInfoList />
              </DataContainer>
            </div>
            <ButtonContainer>
              <DownloadButton
                onPress={() => {
                  toast({
                    position: 'bottom',
                    render: () => (
                      <ToastRenderItem>
                        El comprobante se ha descargardo correctamente
                        <SuccessTickWithBackground />
                      </ToastRenderItem>
                    )
                  })
                  onShareHandler()
                }}
              >
                Descargar PDF
              </DownloadButton>
            </ButtonContainer>
          </DrawerContainer>
          )}
    </Drawer>
  )
}

export default wrapper(ReceiptDetail, Drawers.RECEIPT_DETAIL)
