import React from 'react'
import DocumentsSent from '@qirapagos/lib/assets/svg/DocumentsSent'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  ButtonsContainer,
  Drawer,
  ContentContainer,
  DrawerContainer,
  TextBody,
  MainButton,
  MainButtonText
} from './styles'
import { Drawers } from 'store/drawer/types'
import wrapper from 'components/organisms/Drawers/wrapper'
import { Header } from '../DrawerSuccess/style'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const DocumentsSentSuccess: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { onNavigate } = useNavigation()

  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
    >
      <DrawerContainer>
        <Header />
        <ContentContainer>
          <DocumentsSent />
          <Title>¡Listo!</Title>
          <TextBody>
            Te notificaremos via email acerca del estado
            de tu solicitud dentro de las próximas 48hs.
          </TextBody>
        </ContentContainer>
        <ButtonsContainer>
          <MainButton
            onPress={() => {
              onClose()
              onNavigate('/content/wallet')
            }}
          >
            <MainButtonText testID="drawer-btn">
              Ir a Inicio
            </MainButtonText>
          </MainButton>
        </ButtonsContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(DocumentsSentSuccess, Drawers.DOCUMENTS_SENT_SUCCESS)
