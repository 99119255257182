import { StatusType } from 'components/atoms/Status/constants'
import { SaleOrderStatus } from 'store/contracts/types'

export const getQuotaAvailableToRequest = async () => {
  const cuposAvailableToRequest = await localStorage.getItem(
    'cuposAvailableToRequest'
  )
  return cuposAvailableToRequest
}
export const getSaleOrderStatus = (status?: SaleOrderStatus) => {
  switch (status) {
    case SaleOrderStatus.PENDING:
    case SaleOrderStatus.PENDING_PLUS:
    case SaleOrderStatus.IN_PROCESS:
    case SaleOrderStatus.ACCEPTED:
    case SaleOrderStatus.EXPORTABLE:
      return StatusType.IN_PROGRESS
    case SaleOrderStatus.COUNTER_OFFER:
      return StatusType.COUNTER_OFFER
    case SaleOrderStatus.MIGRATED:
      return StatusType.COMPLETE
    case SaleOrderStatus.REJECTED:
      return StatusType.REJECTED
    case SaleOrderStatus.REMOVED:
      return StatusType.REMOVED
    default:
      return StatusType.PENDING
  }
}
