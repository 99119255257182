import { Box } from '@chakra-ui/react'
import styled from 'styled-components'

export const ScreenContainer = styled(Box)`
  width: 100%;
  height: 100%;
`

export const BoxFixations = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
  margin-left: 3%;
  margin-top: 2%;
  background-color: white;
`

export const BoxPriceFixing = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5%;
  margin: 3%;
  position: relative;
`

export const TooltipContainer = styled.div`
  position: absolute;
  top: -20px;
  right: 0;
`

export const BoxPriceWithTooltip = styled.div`
  width: 33%;
`

export const formStyles = {
  width: '85%'
}

export const fieldCustomStyles = {
  width: '33%'
}

export const dropdownStyles = {
  width: '33%',
  marginBottom: 50
}

export const radioStyles = {
  box: {
    display: 'flex',
    width: '28%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4%',
    justifyContent: 'space-evenly',
    zIndex: 1
  },
  radioChakra: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly'
  }
}

export const requestNewPriceFixation = {
  width: '30%',
  height: '55px',
  borderRadius: 6,
  marginTop: '20px'
}
