import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint } from './utils'

const NOTIFICATIONS = 'notifications/pushNotifications/'

export const getNotificationsByUser = (idClient: string) => {
  const token = localStorage.getItem('token')
  return api.get(
    insertKeyEndpoint(`${NOTIFICATIONS}${idClient}`),
    {},
    { headers: { token } }
  ).then((response) => {
    if (response?.status !== 200) {
      throw response
    }
    return response
  })
}

export const updateUserNotification = (
  idClient: string,
  idNotification: number
) => {
  const token = localStorage.getItem('token')
  return api.post(
    insertKeyEndpoint(`${NOTIFICATIONS}${idClient}`),
    { id: idNotification },
    { headers: { token } }
  ).then((response) => {
    if (response?.status !== 200) {
      throw response
    }
    return response
  })
}

export const removeNotification = (
  idClient: string,
  idNotification: number
) => {
  const token = localStorage.getItem('token')
  return api.delete(insertKeyEndpoint(`${NOTIFICATIONS}${idClient}/${idNotification}`), { }, {
    headers: { token }
  }).then((response) => {
    if (response?.status !== 200) {
      throw response
    }
    return response
  })
}

export const removeNotifications = (
  idClient: string
) => {
  const token = localStorage.getItem('token')
  return api.delete(
    insertKeyEndpoint(`${NOTIFICATIONS}${idClient}`),
    {},
    { headers: { token } }
  ).then((response) => {
    if (response?.status !== 200) {
      throw response
    }
    return response
  })
}
