import React, { useEffect, useCallback, useState } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'
import * as AuthActions from '@qirapagos/lib/store/auth/actions'
import { DrawerContainer, DrawerMenuSideBar, SideBar, TopBar } from 'components'
import useNavigation from 'hooks/useNavigation'
import { ViewSideBarContainer, ViewContentContainer, styles } from './styles'

interface Props {
  children: React.ReactNode
}

const HomeLayout: React.FC<Props> = ({ children }) => {
  const [showMenuDrawer, setShowMenuDrawer] = useState(false)
  const { location } = useNavigation()
  const dispatch = useDispatch()
  const { title, hasBackArrow } = (location.state as any) || 'Inicio'

  const handleBusinessName = useCallback(async () => {
    const name = await localStorage.getItem('businessName')
    const multiEnterprise = await localStorage.getItem('allEnterprises')
    if (!name) return
    if (!multiEnterprise) return
    dispatch(AuthActions.setBusinessName(name))
    dispatch(AuthActions.setMultiEnterprises(JSON.parse(multiEnterprise)))
  }, [dispatch])

  useEffect(() => {
    handleBusinessName()
  }, [handleBusinessName])

  const onClickMenuHamburger = () => {
    setShowMenuDrawer(true)
  }

  return (
    <View style={styles.container}>
      <Helmet title={SCREEN_TITLES.WELCOME} />
      <DrawerContainer />
      <DrawerMenuSideBar
        isOpen={showMenuDrawer}
        setShowMenuDrawer={setShowMenuDrawer}
      />
      <ViewSideBarContainer>
        <SideBar />
      </ViewSideBarContainer>
      <ViewContentContainer>
        <TopBar
          title={title}
          hasBackArrow={hasBackArrow}
          onClickMenuHamburger={onClickMenuHamburger}
        />
        {children}
      </ViewContentContainer>
    </View>
  )
}

export default HomeLayout
