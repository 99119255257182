import React from 'react'
import { CustomList } from 'components'
import { customListStyles } from './styles'
import { useAppSelector } from 'hooks/redux'
import { formatDate } from '@qirapagos/lib/utils/common'

const GrainInfo: React.FC = () => {
  const { drawerGrains } = useAppSelector(state => state.modal)

  const DELIVERY_GRAINS_REQUESTED = [
    { Estado: drawerGrains.drawerInfo?.status },
    { Fecha: formatDate(drawerGrains?.drawerInfo?.deliveryDate) }
  ]

  const DELIVERY_GRAINS_GIVEN = [
    { Estado: drawerGrains.drawerInfo?.status },
    { Fecha: formatDate(drawerGrains?.drawerInfo?.deliveryDate) },
    { 'Código de cupo': drawerGrains.drawerInfo?.codeCupo },
    { Observaciones: drawerGrains.drawerInfo?.observation || 'Sin Datos' }
  ]

  return (
    <CustomList
      data={drawerGrains.drawerInfo?.status === 'SOLICITADO' ? DELIVERY_GRAINS_REQUESTED : DELIVERY_GRAINS_GIVEN}
      positioning="horizontal"
      customStyle={customListStyles}
    />
  )
}

export default GrainInfo
