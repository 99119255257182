import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  width?: number
  height?: number
  color?: string
}

function WhatsApp ({ width, height, color }: Props) {
  return (
    <Svg width={width} height={height} viewBox="0 0 21 21" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.536 2.997A10.154 10.154 0 0010.306 0C4.673 0 .088 4.585.086 10.22c-.001 1.8.47 3.56 1.364 5.109L0 20.625l5.418-1.421a10.21 10.21 0 004.884 1.244h.004c5.633 0 10.218-4.585 10.22-10.22a10.158 10.158 0 00-2.99-7.23zm-7.23 15.725h-.003c-1.525 0-3.02-.41-4.324-1.184l-.31-.184-3.215.843.858-3.135-.202-.321a8.474 8.474 0 01-1.299-4.52c.002-4.684 3.813-8.495 8.498-8.495 2.27 0 4.402.886 6.006 2.491a8.444 8.444 0 012.486 6.01c-.002 4.684-3.813 8.495-8.495 8.495zm4.66-6.362c-.256-.128-1.511-.746-1.745-.831-.234-.085-.405-.128-.575.128-.17.255-.66.83-.809 1-.149.171-.297.193-.553.065-.255-.128-1.078-.398-2.053-1.268-.76-.677-1.272-1.513-1.421-1.769-.149-.255-.001-.38.112-.52.276-.344.553-.704.638-.874.086-.17.043-.32-.021-.448s-.575-1.384-.787-1.896c-.208-.498-.418-.43-.575-.438-.149-.008-.319-.01-.49-.01a.938.938 0 00-.68.32c-.234.256-.894.874-.894 2.13 0 1.258.915 2.472 1.043 2.643.127.17 1.8 2.75 4.362 3.855.61.264 1.085.42 1.456.538.612.195 1.168.167 1.608.102.491-.074 1.511-.618 1.724-1.215.213-.596.213-1.107.149-1.214-.064-.107-.234-.17-.49-.298z"
        fill={color}
      />
    </Svg>
  )
}

WhatsApp.defaultProps = {
  width: 42.5,
  height: 42.5,
  color: '#0ACC94'
}

export default WhatsApp
