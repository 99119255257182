export interface State {
  id: number;
  province: string;
  IDCountry: number;
}

export interface Location {
  id: number;
  provinceId: State['id'];
  location: string;
  code: string;
  cp: string;
  address: string;
  addressNumber: string;
  oncaPort: string;
  onca: string;
  geoLatitude: string;
  geoLongitude: string;
  Province: State,
}

export interface Destination {
  id: number;
  provinceId: State['id'];
  destination: string;
  code: string;
  cp: string;
  address: string;
  addressNumber: string;
  oncaPort: string;
  onca: string;
  geoLatitude: string;
  geoLongitude: string;
  Province: State,
}

export interface LocationState {
  locations: Location[];
  destinations: Destination[];
  destinationStates: State[],
  states: State[],
  isLoading: boolean,
  isLoadingDestinations: boolean,
  error: string | undefined,
}

export enum LocationActionTypes {
  GET_LOCATIONS_PENDING = '@LOCATION:GET_LOCATIONS_PENDING',
  GET_LOCATIONS_FULFILLED = '@LOCATION:GET_LOCATIONS_FULFILLED',
  GET_LOCATIONS_REJECTED = '@LOCATION:GET_LOCATIONS_REJECTED',
  GET_LOCATION_BY_ID_PENDING = '@LOCATION:GET_LOCATION_BY_ID_PENDING',
  GET_LOCATION_BY_ID_FULFILLED = '@LOCATION:GET_LOCATION_BY_ID_FULFILLED',
  GET_LOCATION_BY_ID_REJECTED = '@LOCATION:GET_LOCATION_BY_ID_REJECTED',
  GET_STATES_PENDING = '@LOCATION:GET_STATES_PENDING',
  GET_STATES_FULFILLED = '@LOCATION:GET_STATES_FULFILLED',
  GET_STATES_REJECTED = '@LOCATION:GET_STATES_REJECTED',
  GET_DESTINATIONS_PENDING = '@LOCATION:GET_DESTINATIONS_PENDING',
  GET_DESTINATIONS_FULFILLED = '@LOCATION:GET_DESTINATIONS_FULFILLED',
  GET_DESTINATIONS_REJECTED = '@LOCATION:GET_DESTINATIONS_REJECTED',
  CLEAN_LOCATIONS = '@LOCATION:CLEAN_LOCATIONS',
  GET_DESTINATION_BY_ID_PENDING = '@LOCATION:GET_DESTINATION_BY_ID_PENDING',
  GET_DESTINATION_BY_ID_FULFILLED = '@LOCATION:GET_DESTINATION_BY_ID_FULFILLED',
  GET_DESTINATION_BY_ID_REJECTED = '@LOCATION:GET_DESTINATION_BY_ID_REJECTED',
  GET_DESTINATION_STATES_PENDING = '@LOCATION:GET_DESTINATION_STATES_PENDING',
  GET_DESTINATION_STATES_FULFILLED = '@LOCATION:GET_DESTINATION_STATES_FULFILLED',
  GET_DESTINATION_STATES_REJECTED = '@LOCATION:GET_DESTINATION_STATES_REJECTED',
}
