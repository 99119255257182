import styled from 'styled-components/native'
import { AvatarType } from './types'

export const ViewAvatar = styled.View<AvatarType>`
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: #7d66ff;
  padding: ${props => props.padding || '15px'};
  min-width: ${props => props.minWidth || '62.5px'};
  height: ${props => props.height || '62.5px'};
  width: ${props => props.width || 'min-content'};
  z-index: 10;
  left: ${props => props.left || '50'};
`
