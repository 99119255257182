import styled from 'styled-components/native'

export const ViewContainer = styled.View`
  top: -15;
  border-radius: 6px;
  width: fit-content;
  background-color: white;
`

export const drawerStyles = {
  width: '300px'
}
