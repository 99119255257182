import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 50px;
  margin-left: 10%;
  background-color: #f5f5f5;
  gap: 30px;

  @media ${DEVICES.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
  }

  @media ${DEVICES.mobile} {
    justify-content: flex-start;
    height: 100vh;
    padding-bottom: 50px;
  }
`
