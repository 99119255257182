export const configApplications = [
  { id: 'date', header: 'Fecha' },
  { id: 'owner', header: 'Destinatario' },
  { id: 'amount', header: 'Monto' },
  { id: 'actions', header: '' }
]

export const configApplicationsContacts = [
  { id: 'name', header: 'Nombre y apellido' },
  { id: 'cvu', header: 'CBU / CVU' },
  { id: 'account', header: 'CUIL' },
  { id: 'entity', header: 'Banco' },
  { id: 'actions', header: '' }
]
