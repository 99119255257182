import Button from 'components/atoms/Button'
import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const BoxDirectAccess = styled.div`
  @media ${DEVICES.tablet} {
    align-self: center;
  }
`

export const Container = styled.div`
  padding: 1.5vh 1.5vh 1.5vh 0.8vh;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`

export const RenderView = styled.div`
  margin: 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 25px;
  width: 45%;
`

export const ModalDescription = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalButton = styled(Button)`
  margin-bottom: 10px;
`
