/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import {
  View,
  ImageSourcePropType,
  StyleProp,
  ViewStyle,
  ImageStyle,
  Image
} from 'react-native'
import styles from './styles'
import AvatarEmpty from '@qirapagos/lib/assets/svg/AvatarSquare'

export interface Props {
  avatarPicture?: string
  withCheck?: boolean
  initials?: string
  size?: number | null
  icon?: ImageSourcePropType
  customStyle?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ImageStyle>
}

function AvatarSquare ({ initials, size, customStyle, avatarPicture }: Props) {
  const customSize = size
    ? { height: size, width: size, borderRadius: size / 2 }
    : null

  return (
    <View>
      <View
        style={[
          styles.iconContainer,
          customSize || styles.icon,
          customStyle,
          !avatarPicture && styles.background
        ]}
      >
        {avatarPicture
          ? (
            <Image
              source={{ uri: avatarPicture }}
              resizeMode="cover"
              style={styles.image}
            />
            )
          : (
            <AvatarEmpty />
            )}
      </View>
    </View>
  )
}

export default AvatarSquare

AvatarSquare.defaultProps = {
  avatarPicture: '',
  withCheck: false,
  initials: '',
  size: null,
  icon: null,
  customStyle: {},
  iconStyle: {}
}
