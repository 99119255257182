import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  color?: string
}

const SvgComponent: React.FC<Props> = ({ color }) => {
  return (
    <Svg width={12.444} height={7.222} viewBox="0 0 12.444 7.222">
      <Path
        data-name="Trazado 3087"
        d="M1.414 1.414l4.808 4.808 4.808-4.808"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </Svg>
  )
}

export default SvgComponent

SvgComponent.defaultProps = {
  color: '#7D66FF'
}
