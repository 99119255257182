import React, { useEffect } from 'react'
import { AvatarSquare, Table } from 'components'
import { configTransfers } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { getRedeemers } from 'store/menu/thunks'
import RedeeremsDesktopSkeleton from 'assets/skeletons/RedeeremsDesktopSkeleton'
import {
  BoxContainer,
  ViewContainerPopularRendeerems,
  TextTitle,
  TextRol,
  TextRedeemers,
  ViewTitleContainer
} from './styleWeb'
import { useTable } from 'hooks/useTable'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'
import { TextStyled, ViewMargin } from './style'

const Redeemers = () => {
  const { redeemers, isLoading } = useSelector((state: any) => state.menu)
  const { filterRedeemers, filterRedeemerValue, onChangeFilter } = useTable()

  const dispatch = useDispatch()
  const PAGE = 0

  useEffect(() => {
    dispatch(getRedeemers(PAGE))
  }, [dispatch])

  if (isLoading) return <RedeeremsDesktopSkeleton />

  return (
    <>
      <Helmet title={SCREEN_TITLES.EXCHANGERS} />
      <TextRedeemers>Canjeadores destacados</TextRedeemers>
      <ViewContainerPopularRendeerems>
        {redeemers.slice(0, 3).map((redeemer: any) => (
          <BoxContainer>
            <ViewMargin>
              <ViewTitleContainer>
                <AvatarSquare />
                <TextTitle>{redeemer.companyname}</TextTitle>
              </ViewTitleContainer>
              <TextRol>{redeemer.description}</TextRol>
              <TextStyled>
                Contacto:
                {redeemer.phone.substring(4)}
              </TextStyled>
              <TextStyled>
                Ubicación:
                {redeemer.address}
              </TextStyled>
            </ViewMargin>
          </BoxContainer>
        ))}
      </ViewContainerPopularRendeerems>
      <Table
        onSearchText={onChangeFilter}
        hasDateFilter={false}
        showFilters={false}
        searchValue={filterRedeemerValue}
        data={filterRedeemers(redeemers) || []}
        config={configTransfers}
        title="Buscar canjeador"
        isTextFilter
        emptyText="No hay resultados para tu busqueda"
      />
    </>
  )
}

export default Redeemers
