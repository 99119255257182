import styled from 'styled-components'
import { gray, grayLast, white, black, purple } from 'theme/colors'
import { SIZES } from 'theme/fonts'
import { MenuItem as MenuItemChakra } from '@chakra-ui/react'
import { ReactComponent as DownArrow } from 'assets/svg/DownArrow.svg'

export const Select = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 350px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1.5px ${grayLast} solid;
  color: ${gray};
  font-weight: 500;
  background-color: ${white}
`

export const DownArrowIcon = styled(DownArrow)`
`
export const Text = styled.p`
  color: ${black};
  font-family: Poppins;
  font-weight: 500;
  font-size: ${SIZES.XMEDIUM}px;
  text-align: center;
`

export const MenuItem = styled(MenuItemChakra)`
  &:hover > p {
    color: ${purple}
  }
`
