import React from 'react'
import ContentLoader from 'react-content-loader'
import { white, newPurple } from '@qirapagos/lib/theme/colors'
import { useScreenSizes } from 'hooks/useScreenSizes'

const mobile = (
  <>
    <rect x="4%" y="2vh" rx="8" ry="6" width="90%" height="12vh" />
    <rect x="12%" y="18.5vh" rx="8" ry="6" width="74%" height="22vh" />
    <rect x="12%" y="47vh" rx="8" ry="6" width="74%" height="25vh" />
    <rect x="12%" y="78vh" rx="8" ry="6" width="74%" height="3vh" />
  </>
)

const desktop = (
  <>
    <rect x="5%" y="3vh" rx="8" ry="6" width="83%" height="15vh" />
    <rect x="43%" y="23vh" rx="8" ry="6" width="45%" height="51vh" />
    <rect x="5%" y="23vh" rx="8" ry="6" width="33%" height="23vh" />
    <rect x="5%" y="51vh" rx="8" ry="6" width="33%" height="23vh" />
  </>
)

const WalletDesktopSkeleton = () => {
  const { isTabletDisplayOrLower } = useScreenSizes()

  return (
    <ContentLoader
      speed={3}
      interval={0}
      width="100%"
      height="100vh"
      viewBox="0 0 100% 100vh"
      backgroundColor={newPurple}
      foregroundColor={white}
    >
      {
          isTabletDisplayOrLower
            ? mobile
            : desktop
        }
    </ContentLoader>
  )
}

export default WalletDesktopSkeleton
