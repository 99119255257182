import { ThunkDispatchType } from 'store'
import * as NotificationsActionsTypes from 'store/notifications/actions'
import { NotificationsServices } from 'services'

export const getNotifications = () => async (dispatch: ThunkDispatchType) => {
  try {
    const idClient = localStorage.getItem('clientId')
    const response: any = await NotificationsServices.getNotificationsByUser(idClient as string)
    dispatch(NotificationsActionsTypes.setNotifications(response.data))
  } catch (error) {
    dispatch(NotificationsActionsTypes.setError(error as Error))
  }
}

export const markAsViewNotification = (idNotification: number) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(NotificationsActionsTypes.markAsViewNotification(idNotification))
    const idClient = localStorage.getItem('clientId')
    await NotificationsServices.updateUserNotification(idClient as string, idNotification as number)
  } catch (error) {
    dispatch(NotificationsActionsTypes.setError(error as Error))
  }
}

export const removeNotification =
(id: number) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(NotificationsActionsTypes.removeNotification(id))
    const idClient = localStorage.getItem('clientId')
    await NotificationsServices.removeNotification(idClient as string, id as number)
  } catch (error) {
    dispatch(NotificationsActionsTypes.setError(error as Error))
  }
}

export const removeNotifications = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(NotificationsActionsTypes.removeNotifications())
    const idClient = localStorage.getItem('clientId')
    await NotificationsServices.removeNotifications(idClient as string)
  } catch (error) {
    dispatch(NotificationsActionsTypes.setError(error as Error))
  }
}
