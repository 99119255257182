import React from 'react'
import { View } from 'react-native'

interface Props {
  children: React.ReactNode
  onSubmit: () => void
  customStyle?: any
}

const Form: React.FC<Props> = ({ children, onSubmit, customStyle }) => {
  return (
    <form onSubmit={onSubmit} style={customStyle}>
      <View>
        {children}
      </View>
    </form>
  )
}

export default Form

Form.defaultProps = {
  customStyle: {}
}
