import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  quotasLimitReach?: boolean
}

function MinusSVG ({ quotasLimitReach }: Props) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <Path
        d="M2.5 8h11"
        stroke={quotasLimitReach ? 'grey' : '#7D66FF'}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </Svg>
  )
}

export default MinusSVG

MinusSVG.defaultProps = {
  quotasLimitReach: false
}
