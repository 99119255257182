import React from 'react'
import { FlatList, ListRenderItemInfo } from 'react-native'
import ActionCard from 'components/molecules/ActionCard'
import useNavigation from 'hooks/useNavigation'
import { WHATSAPP_LINK } from '@qirapagos/lib/constants/common'
import { ContainerView, RenderView } from './styles'

interface IDataActionCards {
  icon: 'accountData' | 'newTransfer' | 'myContacts' | 'helpCenter'
  title: string
  onPress: () => void
}

const ActionCardList = () => {
  const { onNavigate } = useNavigation()

  const dataActionCards: IDataActionCards[] = [
    {
      icon: 'accountData',
      title: 'Datos de mi cuenta',
      onPress: () =>
        onNavigate('/content/your-account/details', {
          title: 'Datos de mi cuenta',
          hasBackArrow: true
        })
    },
    {
      icon: 'newTransfer',
      title: 'Nueva transferencia',
      onPress: () =>
        onNavigate('/content/your-account/new-transfer', {
          title: 'Nueva transferencia',
          hasBackArrow: true
        })
    },
    {
      icon: 'myContacts',
      title: 'Mis contactos',
      onPress: () =>
        onNavigate('/content/your-account/my-contacts', {
          title: 'Mis Contactos',
          hasBackArrow: true
        })
    },
    {
      icon: 'helpCenter',
      title: 'Centro de ayuda',
      onPress: () => window.open(WHATSAPP_LINK, '_blank')
    }
  ]

  const renderItem = ({ item }: ListRenderItemInfo<IDataActionCards>) => {
    return (
      <RenderView>
        <ActionCard
          icon={item.icon}
          title={item.title}
          onPress={item.onPress}
          height="100px"
          width="120px"
        />
      </RenderView>
    )
  }
  return (
    <ContainerView>
      <FlatList
        data={dataActionCards}
        renderItem={renderItem}
        contentContainerStyle={{
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      />
    </ContainerView>
  )
}

export default ActionCardList
