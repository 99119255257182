import styled from 'styled-components'

export const CustomBox = styled.div`
  position: absolute;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  .rdtPicker {
    background-color: #f5f6fa;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    text-transform: capitalize;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 25px;
    font-weight: 200;
    vertical-align: top;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #000;
    border-radius: 999px;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtDay {
    width: 32px;
    height: 45px;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 0px solid transparent;
    border-bottom: 0px;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
`
