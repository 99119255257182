import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function PencilEdit () {
  return (
    <Svg
      width={28}
      height={28}
      viewBox="0 0 21 20"
      fill="none"
    >
      <Path
        // eslint-disable-next-line max-len
        d="M8 16.876H4.25a.625.625 0 01-.625-.625V12.76a.625.625 0 01.183-.442l9.375-9.375a.625.625 0 01.884 0l3.491 3.49a.625.625 0 010 .885L8 16.876zM11.125 5L15.5 9.375"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default PencilEdit
