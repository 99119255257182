import { StyleSheet } from 'react-native'
import { grayContent } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'
import styled from 'styled-components'

export const ViewSideBarContainer = styled.div`
  display: flex;
  background-color: white;
  width: 17%;
  min-width: 290px;
  height: 100vh;

  @media ${DEVICES.tablet} {
    display: none;
  }
`

export const ViewContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top-left-radius: 86px;
  background-color: ${grayContent};
  max-height: 100vh;
  z-index: -1;

  @media ${DEVICES.tablet} {
    border-top-left-radius: 0px;
  }
`

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    overflowY: 'hidden'
  }
})
