/* eslint-disable react/require-default-props */
import React from 'react'
import CustomText from 'components/atoms/CustomText'
import Status from 'components/atoms/Status'
import { StatusType } from 'components/atoms/Status/constants'
import { ViewStageBox, styles, ViewStatus } from './styles'
import { FontSize } from 'types/fontStyles'

interface Props {
  title?: string
  text?: string
  statusType: StatusType
  statusFontSize?: FontSize
  isSelected?: boolean
}

const StageBox: React.FC<Props> = ({
  title,
  text,
  statusType,
  statusFontSize,
  isSelected
}) => {
  return (
    <ViewStageBox
      isPending={statusType === 'PENDING'}
      isDone={statusType === 'SHE_FINISHED'}
      isInProgress={statusType === 'IN_PROGRESS'}
      isSelected={isSelected}
    >
      <CustomText
        customStyle={
          statusType === 'PENDING' ? styles.titleDisabled : styles.title
        }
      >
        {title}
      </CustomText>
      <CustomText
        customStyle={
          statusType === 'PENDING' ? styles.textDisabled : styles.text
        }
      >
        {text}
      </CustomText>
      <ViewStatus>
        <Status statusType={statusType} fontSize={statusFontSize} />
      </ViewStatus>
    </ViewStageBox>
  )
}

export default StageBox
