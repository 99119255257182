/* eslint-disable react/jsx-props-no-spreading */
import { gray, violet } from '@qirapagos/lib/theme/colors'
import React from 'react'
import { NavLink as NavLinkReactRouter } from 'react-router-dom'

interface Props {
  to: string
  title: string
  children: React.ReactNode
  onClick: any
}

const NavLink = ({ to, title, onClick, children, ...props }: Props) => {
  return (
    <NavLinkReactRouter
      {...props}
      to={to}
      onClick={onClick}
      state={{ title }}
      style={({ isActive }) =>
        isActive
          ? {
              color: violet,
              backgroundColor: '#F1F3F4',
              width: '90%',
              borderRadius: '5px'
            }
          : { color: gray, backgroundColor: 'white', width: '90%' }}
    >
      {children}
    </NavLinkReactRouter>
  )
}

export default NavLink
