import styled from 'styled-components'
import { Fonts } from '@qirapagos/web/src/theme'
import {
  MenuButton as MenuButtonCK,
  MenuList as MenuListCK
} from '@chakra-ui/react'
import { ReactComponent as CurrencyCircleDollar } from 'assets/svg/CurrencyCircleDollar.svg'
import { ReactComponent as Handshake } from 'assets/svg/Handshake.svg'
import { ReactComponent as WhatsApp } from 'assets/svg/WhatsApp.svg'
import { ReactComponent as DownArrow } from 'assets/svg/DownArrow.svg'
import Button from 'components/atoms/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 130px);
`

export const Header = styled.div`
  width: 100%;
  height: 45px;
  text-align: left;
  align-content: center;
  padding-left: 3.7vw;
  margin-top: 34px;
  margin-bottom: 10px;
`

export const Title = styled.h3`
  font-family: ${Fonts.MANROPE};
  font-weight: 500;
  font-size: ${Fonts.SIZES.MMBIG}px;
`

export const Operations = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 150px;
  margin-bottom: 45px;
`

export const Operation = styled(MenuButtonCK)<{ disabled: boolean }>`
  position: relative;
  width: 17vw;
  min-width: 200px;
  margin-left: 10px;
  margin-right: 10px;
  height: 150px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  background-color: white;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const OperationTitle = styled.p`
  font-family: ${Fonts.MANROPE};
  font-weight: 500;
  font-size: ${Fonts.SIZES.BIG}px;
`

export const DollarIcon = styled(CurrencyCircleDollar)`
  width: 50px !important;
  height: 50px !important;
`

export const HandshakeIcon = styled(Handshake)`
  width: 50px !important;
  height: 50px !important;
`

export const WhatsAppIcon = styled(WhatsApp)`
  width: 40px !important;
  height: 40px !important;
  margin: 5px 0;
`

export const OperationButton = styled(DownArrow)<{
  visible?: boolean
  color: string
}>`
  position: absolute;
  bottom: 15px;
  right: 20px;
  transition: transform 0.2s ease-in-out;
  transform: ${({ visible }) => (visible ? 'rotate(180deg)' : 'rotate(0deg)')};

  path {
    stroke: ${({ color }) => color};
  }
`

export const MenuList = styled(MenuListCK)<{ disabled: boolean }>`
  padding: 0 !important;
  display: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 25px;
  width: 45%;
`

export const ModalDescription = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalButton = styled(Button)`
  margin-bottom: 10px;
`
