import styled from 'styled-components/native'

export const MovementsContainer = styled.View`
  flex: 1;
  padding: 1%;
`

export const ViewCustomSearchContainer = styled.View`
  flex-direction: row;
  width: 60%;
  align-self: flex-end;
  justify-content: flex-end;
  margin-right: 35px;
  height: fit-content;
`

export const ViewFilter = styled.View`
  margin-left: 10px;
  margin-top: 8px;
  cursor: pointer;
`
