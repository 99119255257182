import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  align-items: baseline;

  @media ${DEVICES.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${DEVICES.mobile} {
  }
`
