import React from 'react'
import { BoxStatus } from './styles'
import { StatusType, getStatusColor } from './constants'
import CustomText from '../CustomText'
import { FontSize } from 'types/fontStyles'

interface Props {
  statusType: StatusType
  fontSize?: FontSize | undefined
  testID?: string
  customStyle?: any
}

const Status: React.FC<Props> = ({
  statusType,
  fontSize,
  testID,
  customStyle
}) => {
  const { text, font, background } = getStatusColor(statusType)

  return (
    <BoxStatus background={background} id={testID} style={customStyle}>
      <CustomText size={fontSize || 'small'} weight="semibold" color={font}>
        {text}
      </CustomText>
    </BoxStatus>
  )
}

export default Status

Status.defaultProps = {
  customStyle: {},
  fontSize: 'small',
  testID: ''
}
