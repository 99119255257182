import { create } from 'apisauce'
import store from '@qirapagos/web/src/store'
import { setAppState } from '../store/auth/actions'

export const api = create({
  baseURL: process.env.REACT_APP_TEST_URL,
  headers: {
    client: process.env.REACT_APP_CLIENT_DEV,
    secret: process.env.REACT_APP_SECRET_DEV
  }
})

api.axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 503) {
      store.dispatch(setAppState(false))
      throw error
    } else if (error.response.status === 401) {
      throw error
    } else if (error.response.status === 404) {
      throw error
    } else {
      return Promise.reject(error)
    }
  }
)

export const IDERR_SUCCESS = 0
