export enum screenTitle {
  INICIO = 'Inicio',
  GRANOS = 'Granos',
  TU_CUENTA = 'Tu cuenta',
  MIS_DATOS = 'Mis datos',
  DOCUMENTACION = 'Documentación',
  CANJEADORES = 'Canjeadores',
  ATENCION_AL_CLIENTE = 'Atención al cliente',
  MI_REPRESENTANTE = 'Mi representante',
  MIS_EMPRESAS = 'Mis empresas',
  BLOQUEAR_CUENTA = 'Bloquear cuenta',
}
