import { StyleSheet } from 'react-native'
import { purpleDisabled } from 'theme/colors'
import { white, grayText } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  background-color: ${white} ;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 3.65vh;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 5%;
`

export const Title = styled.h2`
  margin-top: 2%;
  font-size: 1.3em;
`

export const SubTitle = styled.p`
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1em;
  color: ${grayText};
`

export const FieldContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  margin-top: 4%;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 4%;
  max-width: 1000px;
  width: 100%;
`

export const Button = styled(ButtonComponent)`
  max-width: 300px !important;
  width: 45% !important;
  margin-bottom: 0 !important;
`

export const CheckboxWithText = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  max-width: 350px;
  margin-bottom: 6%;
`

export const styles = StyleSheet.create({
  button: {
    width: '50%',
    marginRight: 30,
    marginTop: 100
  },
  buttonGoBack: {
    width: '50%',
    marginRight: 30,
    marginTop: 100,
    backgroundColor: purpleDisabled
  }
})
