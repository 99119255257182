import styled from 'styled-components/native'
import { StyleSheet } from 'react-native'

export const ViewFilter = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  margin: 12px;
  padding: 6px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  background-color: #fff;
`

export const ViewDownArrow = styled.View`
  justify-self: flex-end;
  margin-right: 6px;
`

export const styles = StyleSheet.create({
  text: {},
  filterOption: {
    fontSize: 13
  }
})
