import styledNative from 'styled-components/native'
import styled from 'styled-components'
import { ReactComponent as Document } from 'assets/svg/Document.svg'

export const ViewContainer = styledNative.View`
  flex-direction: row;
  align-items: center;
`

export const DocumentIcon = styled(Document)`
  width: 30px;
  height: 30px;
`
