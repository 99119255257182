import React from 'react'
import useNavigation from 'hooks/useNavigation'
import Button from 'components/atoms/Button'
import { Container, Title, buttonStyles, InfoUpdated } from './styles'

const Success: React.FC = () => {
  const { onNavigate } = useNavigation()

  return (
    <Container>
      <Title>
        ¡Tu contraseña ha sido restablecida con éxito!
      </Title>
      <InfoUpdated />
      <Button customStyle={buttonStyles} onPress={() => onNavigate('/')}>
        Aceptar
      </Button>
    </Container>

  )
}

export default Success
