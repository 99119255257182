import React from 'react'
import { BoxButtonsContainer, BoxContentContainer, ViewStores } from './styles'
import CustomModal from '../CustomModal'
import CustomText from 'components/atoms/CustomText'
import { useDispatch } from 'react-redux'
import setShowModal from 'store/modals/actions'
import StoreCard from 'components/atoms/StoreCard'
import {
  APP_STORE_QIRA_LINK,
  GOOGLE_PLAY_STORE_QIRA_LINK
} from '@qirapagos/lib/constants/common'

interface Props {
  isOpen: boolean
}

const NotValidUserModal: React.FC<Props> = ({ isOpen }) => {
  const dispatch = useDispatch()
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => dispatch(setShowModal(''))}
      title="Validá tu identidad"
    >
      <BoxContentContainer>
        <CustomText size="mmbig">
          Para poder utilizar la web necesitás terminar con el proceso de
          validación en la app
        </CustomText>
        <BoxButtonsContainer id="modal-logout">
          <ViewStores id="app-store-buttons">
            <StoreCard cardType="appStore" link={APP_STORE_QIRA_LINK} />
            <StoreCard
              cardType="googlePlay"
              link={GOOGLE_PLAY_STORE_QIRA_LINK}
            />
          </ViewStores>
        </BoxButtonsContainer>
      </BoxContentContainer>
    </CustomModal>
  )
}

export default NotValidUserModal
