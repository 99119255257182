import React from 'react'
import CustomText from 'components/atoms/CustomText'
import { Box } from '@chakra-ui/react'
import { BoxContainer, styles } from './styles'
import { purple } from 'theme/colors'

interface Props {
  icon?: any
  subTitle: string
  title: string
  text: string
  description: string
  onPress: () => void
  customStyle?: any
}

const CustomerSupportCard: React.FC<Props> = ({
  icon,
  title,
  text,
  subTitle,
  description,
  onPress,
  customStyle = {}
}) => {
  const { styleText, styleTitle, styleSubtitle } = customStyle
  return (
    <BoxContainer>
      <CustomText
        size="mmbig"
        weight="mediumfont"
        customStyle={[styles.customTitle, styleTitle]}
      >
        {title}
      </CustomText>
      <Box>
        <CustomText
          size="mmbig"
          weight="mediumfont"
          customStyle={[styles.customSubtitle, styleSubtitle]}
        >
          {subTitle}
        </CustomText>
        <button type="button" onClick={onPress}>
          <Box display="flex" alignItems="left">
            {icon && (
              <Box alignSelf="center" position="relative" marginRight={5}>
                {icon}
              </Box>
            )}
            <CustomText
              size="mmbig"
              weight="mediumfont"
              customStyle={{ color: purple, textDecoration: 'underline' }}
            >
              {text}
            </CustomText>
          </Box>
        </button>
      </Box>
      <CustomText
        size="xmedium"
        weight="mediumfont"
        customStyle={[styles.customText, styleText]}
      >
        {description}
      </CustomText>
    </BoxContainer>
  )
}

export default CustomerSupportCard

CustomerSupportCard.defaultProps = {
  customStyle: {},
  icon: undefined
}
