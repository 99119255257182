import styled from 'styled-components/native'

export const ViewContainer = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 1.5vh;
  border-radius: 5px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  height: 190px;
  min-height: 160px;
  width: 45%;
  margin-left: 2%;
`
