import React from 'react'
import CustomText from 'components/atoms/CustomText'
import MinusSVG from 'assets/svg/MinusSVG'
import PlusSVG from 'assets/svg/PlusSVG'
import { formatDateWithMonthAndDayName } from 'utils/common'
import { BoxContainer, BoxHandlerContainer, BoxQuotaQuantity } from './styles'

interface Props {
  date: Date;
  quantity: number;
  index: number;
  handleChange: (index: number, value: number) => void
  disabled: boolean
}

const Quota: React.FC<Props> = ({
  date,
  quantity,
  index,
  handleChange,
  disabled
}) => {
  const formattedDate = formatDateWithMonthAndDayName(date)

  return (
    <BoxContainer>
      <CustomText size="xmedium" weight="regular">
        {formattedDate}
      </CustomText>
      <BoxHandlerContainer>
        <button
          type="button"
          onClick={() => handleChange(index, quantity - 1)}
          disabled={!quantity}
        >
          <MinusSVG quotasLimitReach={!quantity} />
        </button>
        <BoxQuotaQuantity>
          <CustomText
            size="xmedium"
            weight="mediumfont"
            align="left"
            color={quantity ? 'purple' : 'gray'}
          >
            {quantity}
          </CustomText>
        </BoxQuotaQuantity>
        <button
          type="button"
          onClick={() => handleChange(index, quantity + 1)}
          disabled={disabled}
        >
          <PlusSVG quotasLimitReach={disabled} />
        </button>
      </BoxHandlerContainer>
    </BoxContainer>
  )
}

export default Quota
