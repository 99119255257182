import styled from 'styled-components'
import { gray, purple, white, graySec, grayLight, purpleDisabled } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'
import DropArrow from '@qirapagos/lib/assets/svg/DropArrow'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  margin-left: 5px;
  margin-top: 3.65vh;
  padding: 5vh 0;
`

export const ContainerPersonalInformation = styled.div`
  display: flex;
  gap: 3vh;
  margin-top: 4vh;
  margin-bottom: 2.5vh;
  margin-left: 3vw;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: center;
  }
`

export const ContainerAddressInformation = styled.div`
  display: flex;
  gap: 3vw;
  margin-top: 4vh;
  margin-bottom: 2.5vh;
  margin-left: 3vw;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: center;
  }
`

export const TextFullName = styled.div`
  font-size: 3.5vw;
  font-weight: 500;
  color: ${gray};
  margin-top: 1.3vh;
  margin-left: 3vw;

  @media ${DEVICES.mobile} {
    font-size: 40px;
  }
`
export const TooltipContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  justify-self: center;
`

export const ViewPhoneInformation = styled.div`
  display: flex;
  gap: 1vw;
  width: 10vw;

  @media ${DEVICES.mobile} {
    width: 100%;
    margin: 10px 0;
  }
`

export const ViewInformation = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media ${DEVICES.mobile} {
    width: 100%;
    margin: 10px 0;
  }
`

export const ViewLineVerticalSeparator = styled.div`
  align-self: center;
  border: 1px ${graySec} solid;
  height: 4vh;
`

export const ViewTitleWithEdit = styled.div`
  display: flex;
  align-self: flex-start;
  gap: 1vw;
`

export const ViewTitleWithEditContainer = styled.div`
  display: flex;
  margin-top: 3vh;
`

export const TextInfoRed = styled.p`
  font-size: 1.2vw;
  color: ${purple};
  text-align: center;
`

export const OptionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
`

export const OptionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  height: 50px;
  width: 400px;
  border: 1px ${graySec} solid;
  border-radius: 3px;
  background: ${white};
  box-shadow: 3px 4px 15px -9px rgba(0, 0, 0, 0.99);
`

export const Arrow = styled(DropArrow)`
  transform: rotate(-90deg);
`

export const ViewLineSeparator = styled.div`
  border: 1px ${grayLight} solid;
  width: calc(100% - 6vw);
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const TextInfoLabel = styled.p`
  font-size: 1.2vw;
  color: ${gray};
  font-weight: 600;
  margin-left: 3vw;
  margin-right: 1vw;

  @media ${DEVICES.tablet} {
    font-size: 2vw;
  }

  @media ${DEVICES.mobile} {
    width: 100%;
    font-size: 20px;
    margin-bottom: 12px;
  }
`

export const Button = styled(ButtonComponent)`
  width: 125px !important;
  margin-bottom: 0 !important;
`

export const TextLabelEdit = styled.p`
  color: ${purple};
  font-size: 20px;
  font-weight: 500;
  text-decoration: underline;

  @media ${DEVICES.tablet} {
    font-size: 1.8vw;
  }

  @media ${DEVICES.mobile} {
    width: 100%;
    font-size: 22px;
  }
`

export const TextLabel = styled.p<{ disabled?: boolean }>`
  color: ${({ disabled }) => disabled ? purpleDisabled : purple};
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;

  @media ${DEVICES.tablet} {
    font-size: 1.8vw;
  }

  @media ${DEVICES.mobile} {
    width: 100%;
    font-size: 22px;
  }
`

export const TextInfo = styled.p<{ disabled?: boolean }>`
  font-size: 1.2vw;
  color: ${({ disabled }) => disabled ? graySec : gray};
  text-align: center;

  @media ${DEVICES.tablet} {
    font-size: 2vw;
  }

  @media ${DEVICES.mobile} {
    width: 100%;
    font-size: 20px;
    margin-bottom: 12px;
  }
`

export const PencilButton = styled.button<{ active: boolean }>`
  visibility: ${({ active }) => active ? 'visible' : 'hidden'}
`
