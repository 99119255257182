import React, { useEffect, useMemo, useState } from 'react'
import {
  AccordionItemComponent,
  StepperContract,
  AccordionComponent
} from 'components'
import StepOne from '../StepOneAdd'
import StepTwo from '../StepTwoAdd'
import { useForm } from 'react-hook-form'
import {
  subTitleOne,
  subTitleThree,
  subTitleTwo,
  titleOne,
  titleThree,
  titleTwo
} from './constants'
import { ScrollView } from 'react-native'
import SuccessTickWithBackground from 'assets/svg/SuccessTickWithBackground'
import { purple, white } from '@qirapagos/lib/theme/colors'
import { styles } from './styles'
import { IDataContact } from './types'
import StepFive from '../../NewTransfer/TransferSteps/Steps/StepFive'

const TransferSteps: React.FC = () => {
  const [isFirstComplete, setFirstIscomplete] = useState(false)
  const [isSecondComplete, setSecondIscomplete] = useState(false)
  const [isThirdComplete, setIsThirdComplete] = useState(false)
  const [data, setData] = useState<IDataContact>()
  const [currentStepBox, setCurrentStepBox] = useState(0)
  const [index, setIndex] = useState(0)
  const { control, clearErrors, errors, setError, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      cuit: ''
    }
  })

  const titlesInfo = useMemo(
    () => [
      {
        title: '1. Ingresá los datos',
        isComplete: isFirstComplete,
        setComplete: (value: boolean) => setFirstIscomplete(value),
        setDataSecondStep: (value: any) => setData(value)
      },
      {
        title: '2. Confirmá los datos',
        isComplete: isSecondComplete,
        setComplete: (value: boolean) => setSecondIscomplete(value),
        dataSecondStep: data
      },
      {
        title: '3. Ingresá el token',
        isComplete: isThirdComplete,
        setComplete: (value: boolean) => setIsThirdComplete(value)
      }
    ],
    [data, isFirstComplete, isSecondComplete, isThirdComplete]
  )

  useEffect(() => {
    if (isSecondComplete) return setCurrentStepBox(2)
    if (isFirstComplete) return setCurrentStepBox(1)
  }, [isFirstComplete, isSecondComplete, titlesInfo])

  const handleOnPress = (data: IDataContact) => {
    titlesInfo[1].dataSecondStep = {
      ...data,
      alias: titlesInfo[1].dataSecondStep?.alias as string,
      contactId: data.contactId
    }
  }

  return (
    <ScrollView stickyHeaderIndices={[0]} style={{ paddingBottom: 50 }}>
      <StepperContract
        titlesInfo={titlesInfo}
        currentStepBox={currentStepBox}
      />
      <AccordionComponent
        customStyle={styles.accordionContainer}
        allowToggle={false}
        index={index}
      >
        <AccordionItemComponent
          accordionTitle={titleOne}
          accordionSubTitle={subTitleOne}
          accordionBody={(
            <StepOne
              titlesInfo={titlesInfo[0]}
              setIndex={setIndex}
              control={control}
              errors={errors}
              setError={setError}
              getValues={getValues}
            />
          )}
          customStyle={{
            marginBottom: 5
          }}
          icon={
            titlesInfo[0].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
        />
        <AccordionItemComponent
          accordionTitle={titleTwo}
          accordionSubTitle={subTitleTwo}
          accordionBody={(
            <StepTwo
              handleAdd={handleOnPress}
              control={control}
              clearErrors={clearErrors}
              errors={errors}
              setIndex={setIndex}
              titlesInfo={titlesInfo[1]}
            />
          )}
          customStyle={{
            marginBottom: 5
          }}
          icon={
            titlesInfo[1].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
        />
        <AccordionItemComponent
          accordionTitle={titleThree}
          accordionSubTitle={subTitleThree}
          accordionBody={<StepFive index={index} titlesInfo={titlesInfo} />}
          customStyle={{ marginBottom: 5 }}
          icon={
            titlesInfo[2].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
        />
      </AccordionComponent>
    </ScrollView>
  )
}

export default TransferSteps
