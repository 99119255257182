import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { white } from '@qirapagos/lib/theme/colors'

const ActivityDetailsSkeleton = () => (
  <ContentLoader
    speed={3}
    interval={0}
    width="100%"
    height="100vh"
    viewBox="0 0 100% 100vh"
    backgroundColor="#e8e7f7"
    foregroundColor={white}
  >
    <Rect x="8%" y="15.5vh" rx="8" ry="6" width="17%" height="25.5%" />
    <Rect x="25.4%" y="15.5vh" rx="8" ry="6" width="17%" height="25.5%" />
    <Rect x="43%" y="15.5vh" rx="8" ry="6" width="17%" height="25.5%" />
    <Rect x="60.4%" y="15.5vh" rx="8" ry="6" width="17%" height="25.5%" />
    <Rect x="77.9%" y="15.5vh" rx="8" ry="6" width="17%" height="25.5%" />
    <Rect x="2%" y="40.5vh" rx="8" ry="6" width="100%" height="70%" />
  </ContentLoader>
)

export default ActivityDetailsSkeleton
