/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import AnimatedTrailView from 'animations/AnimatedTrailView'
import { ViewContainer } from './styles'
import { TouchableOpacity } from 'react-native'
import CustomText from 'components/atoms/CustomText'

interface Props {
  visible: boolean
  data: any
  isMenuItemNeeded?: boolean
  customStyle?: any
  height?: number
}

const DropdownList: React.FC<Props> = ({
  visible,
  data,
  isMenuItemNeeded,
  customStyle,
  height
}) => {
  return (
    <ViewContainer>
      <AnimatedTrailView
        visible={visible}
        data={data}
        isMenuItemNeeded={isMenuItemNeeded}
        customStyle={customStyle}
        height={height}
      />
    </ViewContainer>
  )
}

export default DropdownList

DropdownList.defaultProps = {
  customStyle: {},
  height: 0,
  isMenuItemNeeded: false
}
