import React from 'react'
import CustomerSupportCard from 'components/organisms/CustomerSupportCard'
import { withFade } from 'components'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'
import { ViewContainer } from './styles'
import { WHATSAPP_LINK } from '@qirapagos/lib/constants/common'

import Envelope from 'assets/svg/Envelope'
import WhatsappIconPurple from 'assets/svg/WhatsappIconPurple'

const EMAIL_LINK = 'mailto:info@qirapagos.com'

const CustomerSupport: React.FC = () => {
  return (
    <ViewContainer>
      <Helmet title={SCREEN_TITLES.CLIENT_SERVICE} />
      <CustomerSupportCard
        icon={<WhatsappIconPurple />}
        title="Consultas y/o reclamos"
        subTitle="Escribinos directamente a nuestro"
        text='WhatsApp +54 9 341 664-7880'
        description="Horario de atención: días hábiles de 9 a 18hs."
        onPress={() => window.open(WHATSAPP_LINK, '_blank')}
      />
      <CustomerSupportCard
        icon={<Envelope />}
        title="Información y/o reclamos"
        subTitle="Envianos un mail a"
        text='info@qirapagos.com'
        description="Tiempo estimado de respuesta: 4 horas."
        onPress={() => window.open(EMAIL_LINK)}
      />
    </ViewContainer>
  )
}

export default withFade(CustomerSupport)
