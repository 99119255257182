import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { white } from '@qirapagos/lib/theme/colors'

const DrawerSkeleton = () => (
  <ContentLoader
    speed={3}
    interval={0}
    width="100%"
    height="50vh"
    viewBox="0 0 100% 100vh"
    backgroundColor="#e8e7f7"
    foregroundColor={white}
  >
    {' '}
    <Rect x="10%" y="15%" rx="8" ry="6" width="80%" height="5%" />
    {' '}
    <Rect x="10%" y="25%" rx="8" ry="6" width="80%" height="5%" />
    {' '}
    <Rect x="10%" y="35%" rx="8" ry="6" width="80%" height="5%" />
    {' '}
    <Rect x="10%" y="45%" rx="8" ry="6" width="80%" height="5%" />
    {' '}
    <Rect x="10%" y="55%" rx="8" ry="6" width="80%" height="5%" />
    {' '}
    <Rect x="10%" y="65%" rx="8" ry="6" width="80%" height="5%" />
    {' '}
  </ContentLoader>
)

export default DrawerSkeleton
