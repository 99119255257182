import { SignatureState } from './types'

const initialState: SignatureState = {
  documents: [],
  documentLink: '',
  isLoading: false,
  error: false,
  isDownloadingFile: -1
}

export default initialState
