/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function PencilEditV2 () {
  return (
    <Svg
      width={16}
      height={16}
      viewBox="0 0 13 13"
      fill="none"
    >
      <Path
        d="M0 10.5V13h2.5l7.373-7.374-2.5-2.5L0 10.5zm11.807-6.807a.665.665 0 000-.94l-1.56-1.56a.665.665 0 00-.94 0l-1.22 1.22 2.5 2.5 1.22-1.22z"
        fill="#B3B3B3"
      />
    </Svg>
  )
}

export default PencilEditV2
