import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, grayTextFilter, violet } from '@qirapagos/lib/theme/colors'

function DocumentationItem ({
  active,
  canBeHovered
}: {
  active: boolean
  canBeHovered?: boolean
}) {
  return (
    <Svg width={22} height={22} viewBox="0 0 24 24" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M3.099 19.5V6c0-.199.078-.39.217-.53.139-.141.328-.22.524-.22h4.946c.16 0 .316.053.445.15l2.571 1.95c.129.097.285.15.445.15h6.43c.196 0 .385.079.524.22.139.14.217.331.217.53v2.25"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        // eslint-disable-next-line max-len
        d="M3.099 19.5l2.798-8.487A.75.75 0 016.6 10.5h14.756a.735.735 0 01.602.312.754.754 0 01.102.675L19.418 19.5H3.098z"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default DocumentationItem

DocumentationItem.defaultProps = {
  canBeHovered: true
}
