import { StatusType } from 'components/atoms/Status/constants'
// eslint-disable-next-line import/no-duplicates
import { format as formatDatFns } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { es } from 'date-fns/locale'

const MONTHS = [
  '',
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic'
]
const DAYS = [
  'lunes',
  'martes',
  'miércoles',
  'jueves',
  'viernes',
  'sábado',
  'domingo'
]

export enum OnboardingStatus {
  NOT_STARTED = 0,
  TELEPHONE_BIRTHDATE_AND_DNI_INPUT = 1,
  SELFIES_VALIDATED = 2,
  PHOTO_DNI_VALIDATED = 3,
  REGULATIONS_ADDED = 4,
}

export const OnboardingStatusLabel = {
  [OnboardingStatus.NOT_STARTED]: StatusType.PENDING,
  [OnboardingStatus.TELEPHONE_BIRTHDATE_AND_DNI_INPUT]: StatusType.PENDING,
  [OnboardingStatus.SELFIES_VALIDATED]: StatusType.PENDING,
  [OnboardingStatus.PHOTO_DNI_VALIDATED]: StatusType.PENDING,
  [OnboardingStatus.REGULATIONS_ADDED]: StatusType.APPROVED
}

export enum CompanyStatus {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  CANCELED = 4,
  SUSPENDED = 5,
}

export const CompanyStatusLabel = {
  [CompanyStatus.PENDING]: StatusType.PENDING,
  [CompanyStatus.APPROVED]: StatusType.APPROVED,
  [CompanyStatus.REJECTED]: StatusType.REJECTED,
  [CompanyStatus.CANCELED]: StatusType.CANCELED,
  [CompanyStatus.SUSPENDED]: StatusType.SUSPENDED
}

export function getOperationType (operationType: number) {
  return [6, 7, 8, 9, 10].includes(operationType)
}

export const getStatusTypeString = (status: number) => {
  switch (status) {
    case 1:
      return StatusType.PENDING
    case 2:
      return StatusType.APPROVED
    case 3:
      return StatusType.REJECTED
    default:
      return StatusType.PENDING
  }
}

export enum SaleOrderCurrency {
  ARG = 2,
  USD = 3
}

export const getCurrencySymbol = (currency: number) => {
  if (Number(currency) === SaleOrderCurrency.ARG) {
    return '$'
  }
  if (Number(currency) === SaleOrderCurrency.USD) {
    return 'US$'
  }
}

export function formatDateWithMonthAndDayName (date: Date) {
  return formatDatFns(date, 'dd MMM yyyy, EEEE', { locale: es })
}

export function getLocalTimeOfDate (date: Date) {
  const serverTime = new Date(date)
  const serverDay = serverTime.getDay()
  const serverHours = serverTime.getHours()
  const serverMinutes = serverTime.getMinutes()
  const serverSeconds = serverTime.getSeconds()
  const serverSecondsOneDigit =
    serverSeconds < 10 ? `0${serverSeconds}` : serverSeconds
  // Saved as string if needed
  const serverTimeFormated = `${serverHours}:${serverMinutes}:${serverSecondsOneDigit}`
  // Saved as object to get any property of the date
  const timeAsObject = {
    day: serverDay,
    hours: serverHours,
    minutes: serverMinutes,
    seconds: serverSecondsOneDigit
  }
  return { timeAsObject, serverTimeFormated }
}

export function getStatusType (status: StatusType) {
  switch (status) {
    case 'IN_PROGRESS':
      return StatusType.IN_PROGRESS
    case 'NEW':
      return StatusType.NEW
    case 'ON_APPROBATION':
      return StatusType.ON_APPROBATION
    case 'APPROVED':
      return StatusType.APPROVED
    case 'PENDING':
      return StatusType.PENDING
    case 'ENTERPRISE_PENDING':
      return StatusType.ENTERPRISE_PENDING
    case 'CANCELED':
      return StatusType.CANCELED
    case 'COUNTEROFFER_RECEIVED':
      return StatusType.COUNTEROFFER_RECEIVED
    case 'SHE_FINISHED':
      return StatusType.SHE_FINISHED
    case 'HE_FINISHED':
      return StatusType.HE_FINISHED
    case 'REJECTED':
      return StatusType.REJECTED
    case 'ACCOMPLISHED':
      return StatusType.ACCOMPLISHED
    default:
      return StatusType.NEW
  }
}

export const customSort = (array: any[], property: string) => {
  return array.sort((a, b) => {
    if (a[property] < b[property]) {
      return -1
    }
    if (a[property] > b[property]) {
      return 1
    }
    return 0
  })
}
export function textCutter (text: string, maxLenght: number) {
  if (text.length > maxLenght) {
    return `${text.substring(0, maxLenght)}...`
  }
  return text
}

export function formatDateToString (date: Date) {
  const today = new Date()
  if (date.getDate() === today.getDate()) return 'Hoy'
  if (date.getDate() === today.getDate() - 1) return 'Ayer'
  return null
}

export function formatDateWithMonth (date: string) {
  if (!date) return ''
  if (!date.includes('T')) {
    date = `${date}T`
    const formatedArray = date.split('T')[0].split('-').reverse()
    const month1 =
      formatedArray[0].split('')[0] === '0'
        ? MONTHS[Number(formatedArray[0].split('')[1]) - 1]
        : MONTHS[Number(formatedArray[0])]
    return `${formatedArray[1]} ${month1} ${formatedArray[2]}`
  }

  const formatedArray = date.split('T')[0].split('-').reverse()

  const month =
    formatedArray[1].split('')[0] === '0'
      ? MONTHS[Number(formatedArray[1].split('')[1]) - 1]
      : MONTHS[Number(formatedArray[1]) - 1]
  return `${formatedArray[0]} ${month} ${formatedArray[2]}`
}

export function formatDateWithMonthAsSecondPlace (date: string) {
  if (!date) return ''
  if (!date.includes('T')) {
    date = `${date}T`
    const formatedArray = date.split('T')[0].split('-').reverse()
    const month1 =
      formatedArray[0].split('')[0] === '0'
        ? MONTHS[Number(formatedArray[0].split('')[1])]
        : MONTHS[Number(formatedArray[1])]
    return `${formatedArray[0]} ${month1} ${formatedArray[2]}`
  }

  const formatedArray = date.split('T')[0].split('-').reverse()
  const month =
    formatedArray[1].split('')[0] === '0'
      ? MONTHS[Number(formatedArray[1].split('')[1])]
      : MONTHS[Number(formatedArray[1])]
  return `${formatedArray[0]} ${month} ${formatedArray[2]}`
}

export function addCommaZero (amount: string) {
  if (/,[1-9]$/.test(amount)) {
    return '0'
  }
  return ''
}

export const toArgCurrency = (amount: number): string => {
  return Number(amount).toLocaleString('es-AR', {
    style: 'decimal',
    currency: 'ARS',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export function parseDate (date?: string | Date, format = 'yyyy-MM-dd'): string | null {
  if (!date) {
    return null
  }
  return formatDatFns(new Date(date), format)
}

export const isInIntervalAndWeekday = () => {
  // Check if it is from Monday to Friday between 9 a.m. and 6 p.m.
  const now = new Date()
  return now.getHours() >= 9 && now.getHours() < 18 && now.getDay() >= 1 && now.getDay() <= 5
}
