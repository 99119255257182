import * as React from 'react'
import Svg, { Mask, Path, G } from 'react-native-svg'

interface Props {
  circleColor?: string
  tickColor?: string
}

const SuccessTickWithBackground: React.FC<Props> = ({
  circleColor,
  tickColor
}) => {
  return (
    <Svg width={20} height={20} viewBox="0 0 33 32" fill="none">
      <Mask id="a" x={0} y={0} width={33} height={32}>
        <Path
          d="M16.5 32c8.835 0 16-7.16 16-16s-7.165-16-16-16S.5 7.16.5 16s7.165 16 16 16z"
          fill="#FFF"
        />
      </Mask>
      <G>
        <Path
          d="M16.5 32.326c8.835 0 16-7.16 16-16s-7.165-16-16-16-16 7.16-16 16 7.165 16 16 16z"
          fill={circleColor}
        />
        <Path
          d="M9.459 18.488l4 4.024L24.715 9.6"
          stroke={tickColor}
          strokeWidth={3}
          strokeLinecap="round"
        />
      </G>
    </Svg>
  )
}

export default SuccessTickWithBackground

SuccessTickWithBackground.defaultProps = {
  circleColor: '#FFF',
  tickColor: '#6C57DD'
}
