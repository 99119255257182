import { white } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components/native'

export const ViewContainer = styled.View`
  background-color: ${white};
  margin-left: 5px;
  margin-top: 3.65vh;
  margin-bottom: 3vh;
`

export const ViewChangePassword = styled.View`
  align-self: flex-end;
  margin-top: 1.2vh;
  margin-right: 3vw;
`

export const ViewLastLogin = styled.View`
  align-self: flex-start;
  padding-top: 25px;
  padding-left: 25px;
`

export const ViewSvgContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65;
  position: absolute;
  top: 15;
  right: 25;
`
