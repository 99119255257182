import styled from 'styled-components'
import { Colors } from '@qirapagos/lib/theme'
import DrawerComponent from 'react-modern-drawer'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'
import { Text } from '@chakra-ui/react'

export const Drawer = styled(DrawerComponent)`
  width: 500px !important;
`

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.purple};
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
`

export const Title = styled.div`
  margin-bottom: 3%;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${Colors.white};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
  width: 100%;
`

export const BackButton = styled(ButtonComponent)`
  display: flex;
  justify-content: flex-start;
  margin: 10% 0 0 10px;
  width: 430px;
  height: 20px;
`

export const MainButton = styled(ButtonComponent)`
  width: 80%;
  height: 60px;
  background-color: ${Colors.white};
  margin-bottom: 20%;
`

export const MainButtonText = styled(Text)`
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.purple};
`
