import { white, purple, grayContentLight, error } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 3.65vh;
  margin-left: 50px;
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 5px;
  background-color: ${white};
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 450px;
`

export const Title = styled.h2`
  font-weight: 400;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 3%;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
  margin-bottom: 5%;
`

export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
`

export const Paragraph = styled.p`
  display: flex;
  justify-content: center;
  height: 100%;
  margin-right: 7px;
  margin-bottom: 3px;
  color: ${grayContentLight}
`

export const Error = styled(Paragraph)`
  height: 30px;
  margin-bottom: 10%;
  color: ${error};
`

export const PurpleParagraph = styled(Paragraph)`
  margin-left: 5px;
  color: ${purple}
`

export const ReSendButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const CountContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 80%;
  margin-right: 7px;
  color: ${grayContentLight}
`
