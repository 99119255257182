import styled from 'styled-components'
import { Colors } from 'theme'

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 80px;
  width: 100%;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
`

export const BackButton = styled.button`
  position: absolute;
  left: 20px;
`

export const CalendarIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

export const TextDescription = styled.p`
  align-self: center;
  margin-top: 30px;
  width: 75%;
  text-align: center;
  font-size: 1.2vw;
  color: ${Colors.gray};
`

export const drawerStyle = {
  width: '25%'
}
