import { Colors } from '../../../theme'

export enum StatusType {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  COUNTER_OFFER = 'COUNTER_OFFER',
  REJECTED = 'REJECTED',
  REMOVED = 'REMOVED',
  NEW = 'NEW',
  ON_APPROBATION = 'ON_APPROBATION',
  APPROVED = 'APPROVED',
  ENTERPRISE_PENDING = 'ENTERPRISE_PENDING',
  CANCELED = 'CANCELED',
  COUNTEROFFER_RECEIVED = 'COUNTEROFFER_RECEIVED',
  HE_FINISHED = 'HE_FINISHED',
  SHE_FINISHED = 'SHE_FINISHED',
  ACCOMPLISHED = 'ACCOMPLISHED',
  SUSPENDED = 'SUSPENDED'
}

export const STATUS_STYLES = {
  [StatusType.PENDING]: {
    text: 'PENDIENTE',
    font: 'darkGray',
    background: Colors.graySec
  },
  [StatusType.IN_PROGRESS]: {
    text: 'EN CURSO',
    font: 'black',
    background: Colors.newPurple
  },
  [StatusType.REMOVED]: {
    text: 'ELIMINADA',
    font: 'black',
    background: Colors.redSec
  },
  [StatusType.COUNTER_OFFER]: {
    text: 'CONTRAOFERTA RECIBIDA',
    font: 'black',
    background: Colors.blueSec
  },
  [StatusType.COMPLETE]: {
    text: 'FINALIZADA',
    font: 'black',
    background: Colors.greenSec
  },
  [StatusType.REJECTED]: {
    text: 'RECHAZADA',
    font: 'black',
    background: Colors.redSec
  },
  [StatusType.NEW]: {
    text: '¡NUEVO!',
    font: 'white',
    background: Colors.purple
  },
  [StatusType.ON_APPROBATION]: {
    text: 'EN APROBACIÓN',
    font: 'black',
    background: Colors.yellowSec
  },
  [StatusType.APPROVED]: {
    text: 'APROBADA',
    font: 'black',
    background: Colors.greenSec
  },
  [StatusType.ENTERPRISE_PENDING]: {
    text: 'PENDIENTE',
    font: 'black',
    background: Colors.graySoft
  },
  [StatusType.CANCELED]: {
    text: 'CANCELADO',
    font: 'black',
    background: Colors.redSec
  },
  [StatusType.COUNTEROFFER_RECEIVED]: {
    text: 'CONTRAOFERTA RECIBIDA',
    font: 'black',
    background: Colors.blueSec
  },
  [StatusType.SHE_FINISHED]: {
    text: 'FINALIZADA',
    font: 'black',
    background: Colors.greenSec
  },
  [StatusType.HE_FINISHED]: {
    text: 'FINALIZADO',
    font: 'black',
    background: Colors.greenSec
  },
  [StatusType.ACCOMPLISHED]: {
    text: 'CUMPLIDO',
    font: 'black',
    background: Colors.newPurple
  },
  [StatusType.SUSPENDED]: {
    text: 'SUSPENDIDA',
    font: 'black',
    background: Colors.yellowSec
  }
}

export const getStatusColor = (status: StatusType) => STATUS_STYLES[status]
