import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
// import useAppState from '@qirapagos/lib/hooks/useAppState'
import useInitApp from '@qirapagos/lib/hooks/useInitApp'
import Router from 'routes'
import { ENVIROMENT, Enviroments } from 'constants/enviroment'
import { GlobalDebug } from 'utils/common/consoles'
import { PauseModal } from 'components'
import { useAppSelector } from 'hooks/redux'
import { getFirebaseToken } from 'config/firebase'

const App: React.FC = () => {
  const { checkDevice, setBusinessInfo } = useInitApp()
  const appStatus = useAppSelector((state) => state.auth.appStatus)

  if (ENVIROMENT === Enviroments.PRODUCTION) {
    Sentry.init({
      dsn: process.env.REACT_APP_DSN_AGRO,
      environment: ENVIROMENT,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.2
    })
  }

  useEffect(() => {
    getFirebaseToken()
  }, [])

  useEffect(() => {
    checkDevice()
    setBusinessInfo()
  }, [checkDevice, setBusinessInfo])

  useEffect(() => {
    checkDevice()
    setBusinessInfo()
  }, [checkDevice, setBusinessInfo])

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) { GlobalDebug(true, true) }
  }, [])

  // useAppState()

  return (
    <>
      <PauseModal isOpen={!appStatus} />
      <Router />
    </>
  )
}

export default App
