import { useWindowSize } from 'react-use'

export const useScreenSizes = () => {
  const { width } = useWindowSize()

  const isTabletDisplayOrLower = width <= 1024
  const isMobileDisplay = width <= 600
  return {
    isTabletDisplayOrLower,
    isMobileDisplay
  }
}
