
export interface AuthState {
  deviceId: string
  isDeviceIdUpdating: boolean
  error: undefined
}

export enum AuthActionTypes {
  UPDATE_DEVICE_ID_PENDING = '@AUTH:UPDATE_DEVICE_ID_PENDING',
  UPDATE_DEVICE_ID_FULFILLED = '@AUTH:UPDATE_DEVICE_ID_FULFILLED',
  UPDATE_DEVICE_ID_REJECTED = '@AUTH:UPDATE_DEVICE_ID_REJECTED',
}
