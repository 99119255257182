import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

function CircleWithCheck () {
  return (
    <Svg width={33} height={32} viewBox="0 0 33 32" fill="none">
      <Circle cx={16.6497} cy={16} r={15} stroke="#0ACC94" strokeWidth={2} />
      <Path
        d="M21.341 12.985l-6.104 6.172-3.053-3.085"
        stroke="#0ACC94"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default CircleWithCheck
