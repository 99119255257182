import * as React from "react"
import Svg, { SvgProps, Ellipse, Path } from "react-native-svg"

function TransferSend(props: SvgProps) {
  return (
    <Svg
      width={43}
      height={42}
      viewBox="0 0 43 42"
      fill="none"
      {...props}
    >
      <Ellipse cx={21.232} cy={21} rx={17.188} ry={17} fill="#fff" />
      <Path
        d="M41.465 21c0 11.035-9.048 20-20.233 20C10.048 41 1 32.035 1 21S10.048 1 21.232 1c11.185 0 20.233 8.965 20.233 20z"
        fill="#fff"
        stroke="#FF50A4"
        strokeWidth={2}
      />
      <Path
        d="M16.573 25.6l8.962-9.014M18.192 16.597l7.343-.011.01 7.365"
        stroke="#FF50A4"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default TransferSend
  