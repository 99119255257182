import { instance } from '.'
import { insertKeyEndpoint } from './utils'
import { AssociateCompany, Enterprise } from 'store/enterprise/types'

export const getEnterprise = async (cuit: string) => {
  return instance.get<Enterprise>(insertKeyEndpoint(`user/getrazonsocial/${cuit}`))
}

export const getSendEmailDocuments = async () => {
  return instance.get(insertKeyEndpoint('user/sendmaildocuments'))
}

export const associateCompany = async (body: AssociateCompany) => {
  return instance.post(insertKeyEndpoint('mu-me/associate-company'), body)
}

export const uploadDocuments = async (formData: FormData, dni: string) => {
  return instance.post(insertKeyEndpoint('user/uploaddocuments'), formData, {
    headers: { dni }
  })
}
