/* eslint-disable max-len */
import React from 'react'

function WhatsappIconPurple () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#7D66FF"
        d="M13.287 2.683A7.362 7.362 0 008.03.5C3.935.5.597 3.838.597 7.933c0 1.312.345 2.587.99 3.712L.537 15.5l3.938-1.035a7.434 7.434 0 003.555.908c4.095 0 7.433-3.338 7.433-7.433a7.386 7.386 0 00-2.175-5.257zM8.03 14.113c-1.11 0-2.198-.3-3.15-.863l-.225-.135-2.34.615.622-2.28-.15-.232a6.198 6.198 0 01-.945-3.285 6.19 6.19 0 016.18-6.18c1.65 0 3.203.645 4.365 1.815a6.136 6.136 0 011.808 4.372c.015 3.405-2.76 6.173-6.165 6.173zm3.39-4.62c-.188-.09-1.102-.54-1.268-.608-.172-.06-.292-.09-.42.09-.127.188-.48.608-.585.728-.105.127-.217.142-.405.045-.187-.09-.787-.293-1.492-.923-.555-.495-.923-1.102-1.035-1.29-.105-.187-.015-.285.082-.382.083-.083.188-.218.278-.323.09-.105.127-.187.187-.307.06-.128.03-.233-.015-.323s-.42-1.005-.57-1.38c-.15-.36-.307-.315-.42-.322h-.36a.674.674 0 00-.495.232c-.165.188-.645.638-.645 1.553 0 .915.668 1.8.758 1.92.09.127 1.312 2.002 3.172 2.805.443.195.788.307 1.058.39.442.142.848.12 1.17.075.36-.053 1.103-.45 1.253-.886.157-.434.157-.802.105-.884-.053-.083-.165-.12-.353-.21z"
      />
    </svg>
  )
}

export default WhatsappIconPurple
