import React from 'react'
import StoreCard from '@qirapagos/web/src/components/atoms/StoreCard'
import QiraPagosLogoWeb from '@qirapagos/web/src/assets/svg/QirapagosLogo'
import { violet } from '@qirapagos/lib/theme/colors'
import {
  GOOGLE_PLAY_STORE_QIRA_LINK,
  APP_STORE_QIRA_LINK
} from '@qirapagos/lib/constants/common'
import useNavigation from 'hooks/useNavigation'
import {
  InformationContainer,
  TextTitleText,
  DescriptionContainer,
  ImageContainer,
  TextBodyText,
  StoresContainer,
  slideStyle,
  ScreenContainer,
  LoginButton,
  MainImage,
  Legend,
  mainContentContainerStyle
} from './styles'
import AnimatedSpringView from 'animations/AnimatedSpringView'
import { bodyAnimationConfig } from './constants'
import { Slide } from '@chakra-ui/react'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'

const Welcome: React.FC = () => {
  const { onNavigate } = useNavigation()
  const onPress = () => {
    // Open App Mobile if it is a mobile browser
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      window.location.href = 'qirapagos://Login'
    } else {
      onNavigate('auth/login')
    }
  }

  return (
    <ScreenContainer>
      <Helmet title={SCREEN_TITLES.WELCOME} />
      <ImageContainer>
        <MainImage
          src={require('@qirapagos/lib/assets/LoginWallet.png')}
          draggable={false}
        />
      </ImageContainer>
      <InformationContainer>
        <Slide in direction="left" style={slideStyle}>
          <QiraPagosLogoWeb color={violet} />
        </Slide>
        <AnimatedSpringView customAnimation={bodyAnimationConfig} customStyle={mainContentContainerStyle}>
          <TextTitleText id="welcome-title">
            Bienvenidos
            {'\n'}
            a la billetera del campo.
          </TextTitleText>
          <DescriptionContainer>
            <TextBodyText id="welcome-subtitle">
              Tu cuenta en dinero y en granos.
              {'\n'}
              Vendé, comprá, canjeá y transferí.
            </TextBodyText>
            <LoginButton
              id="buttonEnterWeb"
              onPress={onPress}
            >
              Ingresar
            </LoginButton>
            <TextBodyText>
              ¿No tenés cuenta?
              {'\n'}
              Abrí una desde nuestra App.
            </TextBodyText>
            <StoresContainer id="app-store-buttons">
              <StoreCard cardType="appStore" link={APP_STORE_QIRA_LINK} />
              <StoreCard
                cardType="googlePlay"
                link={GOOGLE_PLAY_STORE_QIRA_LINK}
              />
            </StoresContainer>
          </DescriptionContainer>
          <Legend>
            QIRA PAGOS se limita a ofrecer servicios de pago y no se encuentra autorizada a operar como entidad
            financiera por el BCRA. Los fondos depositados en cuentas de pago no constituyen depósitos en una
            entidad financiera, ni cuentan con ninguna de las garantías que tales depósitos puedan gozar de
            acuerdo con la legislación y reglamentación aplicables en materia de depósitos en entidades financieras
          </Legend>
        </AnimatedSpringView>
      </InformationContainer>
    </ScreenContainer>
  )
}

export default Welcome
