import React, { useRef, useEffect } from 'react'
import { View, Animated, TextInput } from 'react-native'
import SearchLogo from '@qirapagos/lib/assets/svg/Search'
import styles from './style'
interface Props {
  onChange: (value: string) => void
  value: string
  setIsSearchFocused: (value: boolean) => void
  isSearchFocused: boolean
  placeholder?: string
}
const CustomSearch: React.FC<Props> = ({
  onChange,
  value,
  setIsSearchFocused,
  isSearchFocused,
  placeholder
}: Props) => {
  const searcherRef = useRef<TextInput>(null)
  const animatedSearch = useRef(new Animated.Value(0)).current
  const width = animatedSearch.interpolate({
    inputRange: [0, 1],
    outputRange: ['100%', '80%']
  })
  const focusHandler = (focus: boolean) => {
    if (focus) searcherRef.current?.focus()
    else searcherRef.current?.blur()
    setIsSearchFocused(focus)
  }
  const onFocus = () => {
    focusHandler(true)
  }
  const onBlur = () => {
    focusHandler(false)
  }
  useEffect(() => {
    if (value && !isSearchFocused) {
      Animated.spring(animatedSearch, {
        toValue: 1,
        useNativeDriver: false
      }).start()
    }
  }, [value, focusHandler, isSearchFocused])
  return (
    <View style={[styles.container]}>
      <Animated.View style={[styles.searchInputContainer, { width }]}>
        <SearchLogo style={{ position: 'absolute', left: 12 }} />
        <TextInput
          ref={searcherRef}
          value={value}
          onChangeText={onChange}
          style={[styles.textInputContainer, styles.textStyle]}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      </Animated.View>
    </View>
  )
}
export default CustomSearch

CustomSearch.defaultProps = {
  placeholder: 'Buscar'
}
