import React, { useState } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Button from 'components/atoms/Button'
import { black, gray, purple, white } from '@qirapagos/lib/theme/colors'

import {
  ViewContainer,
  ViewContainerInformation,
  TextFullName,
  ViewInformation,
  TextInfo,
  ViewButtonModal
} from './styles'
import useNavigation from 'hooks/useNavigation'
import { deleteContact } from 'store/transfers/thunks'
import { CustomModal } from 'components'
import PersonSVG from 'assets/svg/PersonSVG'
import { useAppDispatch, useAppSelector } from 'hooks/redux'

export const DeleteContact: React.FC = () => {
  const { location } = useNavigation()
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const { name, cvu, account, id, observation } = location.state as any
  const { isLoading } = useAppSelector(state => state.transfers)

  const handleDelete = async () => {
    await dispatch(deleteContact(id))
    setIsOpen(false)
  }

  return (
    <>
      <Box mt={5} bg={white} display="flex" flexDirection="column">
        <ViewContainer>
          <TextFullName>{name}</TextFullName>
          <ViewContainerInformation>
            <ViewInformation>
              <Text
                color={purple}
                fontWeight={600}
                fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
                align="center"
              >
                Alias
              </Text>
              <TextInfo>Sin datos</TextInfo>
            </ViewInformation>
            <ViewInformation>
              <Text
                color={purple}
                fontWeight={600}
                fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
                align="center"
              >
                CVU
              </Text>
              <TextInfo>{cvu}</TextInfo>
            </ViewInformation>
            <ViewInformation>
              <Text
                color={purple}
                fontWeight={600}
                fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
                align="center"
              >
                CC
              </Text>
              <TextInfo>Sin datos</TextInfo>
            </ViewInformation>
            <ViewInformation>
              <Text
                color={purple}
                fontWeight={600}
                fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
                align="center"
              >
                CUIT / CUIL
              </Text>
              <TextInfo>{account}</TextInfo>
            </ViewInformation>
          </ViewContainerInformation>
          <ViewContainerInformation>
            <ViewInformation>
              <Text
                borderBottom={`2px solid ${purple}`}
                color={gray}
                fontWeight={400}
                fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
                align="left"
              >
                { observation || 'Observaciones'}
              </Text>
            </ViewInformation>
          </ViewContainerInformation>
          <Box display="flex" flex={1} flexDir="row" alignSelf="flex-end">
            <Button
              disabled={false}
              onPress={() => setIsOpen(true)}
              customStyle={{ paddingLeft: 10, paddingRight: 10 }}
            >
              Eliminar contacto
            </Button>
          </Box>
        </ViewContainer>
      </Box>
      {isOpen && (
        <CustomModal
          icon={<PersonSVG />}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title="Eliminar contacto"
        >
          <Text
            color={black}
            fontWeight={400}
            lineHeight={2}
            fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
            align="center"
          >
            ¿Estás seguro que querés
            {'\n'}
            eliminar a
            {' '}
            {name}
            ?
          </Text>
          <ViewButtonModal>
            <Button
              customStyle={{ marginTop: 15, width: '50%', marginBottom: 10 }}
              onPress={handleDelete}
              disabled={isLoading}
              loading={isLoading}
            >
              Eliminar
            </Button>
            <Button
              customStyle={{
                width: '50%',
                backgroundColor: '#F0EDFF'
              }}
              disabled={false}
              secondary
              onPress={() => setIsOpen(false)}
            >
              Cancelar
            </Button>
          </ViewButtonModal>
        </CustomModal>
      )}
    </>
  )
}

export default DeleteContact
