import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint } from './utils'

const REDEEMERS = 'redeemingCompanies'
const VENDOR = 'vendor/company/'
const TYPE_PAYMENT_METHODS = 'paymentMethods/'
const PAYMENT_METHODS = 'companyPreferences/'
const NEW_PAYMENT_METHOD = 'companyPreference/'
const ENABLE_TO_ADD_AGENT = 'company/representative/isEnabled'

export const getRedeemers = (
  limit: number,
  page: number,
  token: string,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${REDEEMERS}?limit=${limit}&page=${page}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const vendor = (token: string | null, companyId: string | null) =>
  api.get(
    insertKeyEndpoint(`${VENDOR}${companyId}`),
    {},
    { headers: { token } }
  )

export const typePaymentMethods = (token: string | null) =>
  api.get(insertKeyEndpoint(TYPE_PAYMENT_METHODS), {}, { headers: { token } })

export const paymentMethods = (
  companyId: number | null | string,
  token: string | null
) =>
  api.get(
    insertKeyEndpoint(`${PAYMENT_METHODS}${companyId}`),
    {},
    { headers: { token } }
  )

export const newPaymentMethod = (body: object, token: string | null) =>
  api.post(insertKeyEndpoint(`${NEW_PAYMENT_METHOD}`), body, {
    headers: { token }
  })

export const enableToAddAgent = (
  companyId: number | null | string,
  token: string | null
) =>
  api.get(
    insertKeyEndpoint(`${ENABLE_TO_ADD_AGENT}/${companyId}`),
    {},
    { headers: { token } }
  )
