import React, { useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import Button from '@qirapagos/web/src/components/atoms/Button'
import { debounce } from 'lodash'
import { SelectField } from 'components/shared/SelectField'
import { DatePickerField } from 'components/shared/DatePickerField'
import { getLocations, getStates, getDestinationStates, getDestinations } from 'store/location/thunks'
import { useDispatch } from 'react-redux'
import { AutocompleteField } from 'components/shared/AutocompleteField'
import {
  Container,
  Title,
  SubTitle,
  Inputs,
  InputsGroup,
  ButtonContainer
} from './styles'
import moment from 'moment'
import { useAppSelector } from 'hooks/redux'
import { RootState } from 'store'
import { cleanLocations } from 'store/location/actions'

export enum DeliveryPeriod {
  IMMEDIATE = 'INMEDIATA',
  CONTRACTUAL = 'CONTRACTUAL',
  FUTURE = 'FUTURA',
}

const deliveryPeriodOptions = [
  { value: DeliveryPeriod.IMMEDIATE, label: 'Entrega inmediata (7 días)' },
  { value: DeliveryPeriod.CONTRACTUAL, label: 'Entrega contractual (30 días)' },
  { value: DeliveryPeriod.FUTURE, label: 'Entrega a futuro' }
]

export const formDeliveryInitialValues = {
  stateOrigin: undefined,
  originId: undefined,
  stateDestination: undefined,
  destinationId: undefined,
  deliveryPeriod: undefined,
  deliveryFrom: undefined,
  deliveryTo: undefined
}

export const formDeliveryValidator = Yup.object().shape({
  stateOrigin: Yup.string()
    .required('Es requerido'),
  originId: Yup.string()
    .required('Es requerido'),
  stateDestination: Yup.string()
    .required('Es requerido'),
  destinationId: Yup.string()
    .required('Es requerido'),
  deliveryPeriod: Yup.string()
    .typeError('Período de entrega debe ser una opción')
    .required('Período de entrega es requerido'),
  deliveryFrom: Yup.date()
    .typeError('Desde debe ser una fecha válida')
    .required('Desde es requerido'),
  deliveryTo: Yup.date()
    .typeError('Hasta debe ser una fecha válida')
    .required('Hasta es requerido')
    .when('deliveryFrom', {
      is: (deliveryFrom: Date) => {
        if (deliveryFrom) return !isNaN(deliveryFrom.getTime())
      },
      then: Yup.date().min(
        Yup.ref('deliveryFrom'),
        "'Hasta' debe ser posterior a 'Desde'"
      )
    })
    .when(['deliveryPeriod', 'deliveryFrom'],
      (deliveryPeriod: DeliveryPeriod, deliveryFrom: Date, schema: any) => {
        if (deliveryFrom && !isNaN(deliveryFrom.getTime())) {
          if (deliveryPeriod === DeliveryPeriod.IMMEDIATE) {
            const deliveryFromDate = moment(deliveryFrom).add(7, 'days').toDate()
            return schema.max(
              deliveryFromDate,
              "'Hasta' no debe ser más de 7 días después de 'Desde'"
            )
          }
          if (deliveryPeriod === DeliveryPeriod.CONTRACTUAL) {
            const deliveryFromDate = moment(deliveryFrom).add(30, 'days').toDate()
            return schema.max(
              deliveryFromDate,
              "'Hasta' no debe ser más de 30 días después de 'Desde'"
            )
          }
        }
        return schema
      })
})

export interface FormDeliveryValues {
  stateOrigin: number | undefined;
  originId: number | undefined;
  stateDestination: number | undefined;
  destinationId: number | undefined;
  deliveryPeriod: DeliveryPeriod | undefined;
  deliveryFrom: Date | undefined;
  deliveryTo: Date | undefined;
}

interface Props {
  titlesInfo: string;
  formik: FormikProps<any>;
}

const FormDelivery: React.FC<Props> = ({ titlesInfo, formik }) => {
  const dispatch = useDispatch()

  const {
    isLoading: isLoadingLocations,
    isLoadingDestinations,
    states,
    destinationStates,
    locations,
    destinations
  } = useAppSelector((state: RootState) => state.location)

  useEffect(() => {
    dispatch(getStates())
    dispatch(getDestinationStates())
  }, [dispatch])

  const debouncedSearchLocations = debounce(async (_, query) => {
    if (query.length > 3) {
      dispatch(getLocations({ search: query, stateId: Number(formik.values.stateOrigin) }))
    }
  }, 300)

  const statesList = useMemo(() => {
    return states?.map((state) => ({
      value: state.id.toString(),
      label: state.province
    }))
  }, [states])

  const destinationStatesList = useMemo(() => {
    return destinationStates?.map((state) => ({
      value: state.id.toString(),
      label: state.province
    }))
  }, [destinationStates])

  const locationsList = useMemo(() => {
    return locations.map((location) => ({
      title: location.location,
      id: location.id
    }))
  }, [locations])

  const destinationsList = useMemo(() => {
    return destinations.map((destination) => ({
      title: destination.destination,
      id: destination.id
    }))
  }, [destinations])

  return (
    <Container>
      <Title>{titlesInfo}</Title>
      <Inputs>
        <SubTitle>Procedencia</SubTitle>
        <InputsGroup>
          <SelectField
            formik={formik}
            name="stateOrigin"
            label="Provincia"
            options={statesList}
            onChange={() => {
              dispatch(cleanLocations())
              formik.setFieldValue('originId', '')
            }}
          />
          <AutocompleteField
            formik={formik}
            name="originId"
            label="Localidad"
            options={locationsList}
            loading={isLoadingLocations}
            onInputChange={debouncedSearchLocations}
          />
        </InputsGroup>
        <SubTitle>Destino</SubTitle>
        <InputsGroup>
          <SelectField
            formik={formik}
            name="stateDestination"
            label="Provincia"
            options={destinationStatesList}
            onChange={(event) => {
              dispatch(getDestinations({ stateId: Number(event.target.value) }))
              formik.setFieldValue('destinationId', '')
            }}
          />
          <AutocompleteField
            formik={formik}
            name="destinationId"
            label="Localidad"
            options={destinationsList}
            loading={isLoadingDestinations}
          />
        </InputsGroup>
        <SubTitle>Periodo de entrega</SubTitle>
        <InputsGroup>
          <SelectField
            formik={formik}
            name="deliveryPeriod"
            label="Período de entrega"
            options={deliveryPeriodOptions}
          />
          <DatePickerField
            formik={formik}
            name="deliveryFrom"
            label="Desde"
          />
          <DatePickerField
            formik={formik}
            name="deliveryTo"
            label="Hasta"
          />
        </InputsGroup>
      </Inputs>
      <ButtonContainer>
        <Button customStyle={{ width: 300, margin: 10 }}>
          Continuar
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default FormDelivery
