import { Location } from '@qirapagos/web/src/store/location/types'

export interface IDocument {
  uri?: string;
  name?: string;
  type?: string;
}

export interface IRegisterUser {
  cuit?: string;
  name?: string;
  password?: string;
  email?: string;
  dni?: string;
  code?: string;
}

export interface IVerifyUser {
  clave: string;
  codigo: string;
}

export interface IVerifyPhone {
  phone?: {
    codearea: string;
    number: string;
  };
  codigo?: string;
}

export interface IRecoverUser {
  email: string;
  password: string;
  code: string;
  validCode: string;
}

export interface IUserSelfie {
  feliz: string;
  serio: string;
}

export interface IUserDNI {
  frente?: string;
  dorso?: string;
}

export interface IMultiEnterprise {
  businessName: string;
  companyId: number;
  companyStatus: number;
  cuit: string;
  dni: string;
  docs: boolean;
  idclient: number;
  loginId: number;
  onboardingStatus: number;
  sisa: boolean;
  token: string;
  userId: number;
  url?: string;
}

export interface IDocumentRejected {
  description: string;
  id: number;
  lastUpdated: string;
  reason: string;
  DocumentStatus: {
    status: string;
    id: number;
  };
}

export interface ILastLogin {
  platform: string
  clientId: number
  date: string
  deviceId: string | null
  ip: string | null
}

export interface IMyData {
  firstName: string
  lastName: string
  email: string
  telephoneNumber: string
  personalId: string
  address: string
  number: number
  locationId: string
  Location: Location,
  stateId: string
  postalCode: string
  taxId: string
}

export interface IBodyAddress {
  address: string;
  number: number;
  locationId: number;
  postalCode: string;
}

export interface INewPasswordError {
  message: string;
  code: number;
  error: boolean;
}

export interface AuthState {
  registerUser: IRegisterUser
  verifyUser: IVerifyUser
  verifyPhone: IVerifyPhone
  userSelfie: IUserSelfie
  userDNI?: IUserDNI
  keyEndPoint: string
  isLoading: boolean
  isRecoverPasswordLoading: boolean
  isError: boolean
  userStatus: string
  documents: IDocument[]
  documentType: string
  businessName: string
  isBusiness: boolean
  recoverUser: IRecoverUser
  documentsRejected?: IDocumentRejected[]
  localAuthFromSplash: boolean
  multiEnterprise: IMultiEnterprise[]
  twoFactorVerified: boolean
  appStatus: boolean
  newPasswordError: INewPasswordError
  recoverPasswordError: string
  lastLogin: ILastLogin
  isLoadingFullTermsAndConditions: boolean
  isLoadingShortTermsAndConditions: boolean
  isLoadingPrivacyPolicies: boolean
  fullTermsAndConditionsError: string | undefined
  shortTermsAndConditionsError: string | undefined
  privacyPoliciesError: string | undefined
  myData: IMyData;
  isMyDataEditing: boolean;
}

export enum AuthActionTypes {
  SET_REGISTER_USER = '@AUTH:SET_REGISTER_USER',
  SET_RECOVER_USER = '@AUTH:SET_RECOVER_USER',
  SET_KEYENDPOINTS = '@AUTH:SET_KEYENDPOINTS',
  SET_ERROR = '@AUTH:SET_LOGIN_ERROR',
  DISMISS_LOGIN_ERROR = '@AUTH:DISMISS_LOGIN_ERROR',
  SET_VERIFY_INFO = '@AUTH:SET_VERIFY_INFO',
  SET_VERIFY_PHONE_INFO = '@AUTH:SET_VERIFY_PHONE_INFO',
  SET_USER_SELFIE = '@AUTH:SET_USER_SELFIE',
  SET_USER_DNI_PHOTO = '@AUTH:SET_USER_DNI_PHOTO',
  CLEAN_SELFIE = '@AUTH:CLEAN_SELFIE',
  SET_LOADING = '@AUTH:SET_LOADING',
  DISMISS_LOADING = '@AUTH:DISMISS_LOADING',
  SET_USER_STATUS = '@AUTH:SET_USER_STATUS',
  SET_FILE = '@AUTH:SET_FILE',
  SET_DOCUMENT_TYPE = '@AUTH:SET_DOCUMENT_TYPE',
  SET_BUSINESS_NAME = '@AUTH:SET_BUSINESS_NAME',
  SET_IS_BUSINESS = '@AUTH:SET_IS_BUSINESS',
  REMOVE_FILE = '@AUTH:REMOVE_FILE',
  RESET_FILES = '@AUTH:RESET_FILES',
  LOGOUT = '@AUTH:LOGOUT',
  LOCAL_AUTH_SPLASH = '@AUTH:LOCAL_AUTH_SPLASH',
  SET_DOCUMENT_REJECTED = '@AUTH:SET_DOCUMENT_REJECTED',
  CLEAR_DOCUMENT_REJECTED = '@AUTH:CLEAR_DOCUMENT_REJECTED',
  SET_MULTI_ENTERPRISES = '@AUTH:SET_MULTI_ENTERPRISES',
  SET_TWO_FACTOR_VERIFIED = '@AUTH:SET_TWO_FACTOR_VERIFIED',
  SET_APP_STATUS = '@AUTH:SET_APP_STATUS',
  SET_NEW_PASSWORD_ERROR= '@AUTH:SET_NEW_PASSWORD_ERROR',
  REMOVE_NEW_PASSWORD_ERROR = '@AUTH:REMOVE_NEW_PASSWORD_ERROR',
  SET_RECOVER_PASSWORD_LOADING = '@AUTH:SET_RECOVER_PASSWORD_LOADING',
  SET_RECOVER_PASSWORD_ERROR = '@AUTH:SET_RECOVER_PASSWORD_ERROR',
  SET_LAST_LOGIN = '@AUTH:SET_LAST_LOGIN',
  GET_FULL_TERMS_AND_CONDITIONS_PENDING = '@AUTH:GET_FULL_TERMS_AND_CONDITIONS_PENDING',
  GET_FULL_TERMS_AND_CONDITIONS_FULFILLED = '@AUTH:GET_FULL_TERMS_AND_CONDITIONS_FULFILLED',
  GET_FULL_TERMS_AND_CONDITIONS_REJECTED = '@AUTH:GET_FULL_TERMS_AND_CONDITIONS_REJECTED',
  GET_SHORT_TERMS_AND_CONDITIONS_PENDING = '@AUTH:GET_SHORT_TERMS_AND_CONDITIONS_PENDING',
  GET_SHORT_TERMS_AND_CONDITIONS_FULFILLED = '@AUTH:GET_SHORT_TERMS_AND_CONDITIONS_FULFILLED',
  GET_SHORT_TERMS_AND_CONDITIONS_REJECTED = '@AUTH:GET_SHORT_TERMS_AND_CONDITIONS_REJECTED',
  GET_PRIVACY_POLICIES_PENDING = '@AUTH:GET_PRIVACY_POLICIES_PENDING',
  GET_PRIVACY_POLICIES_FULFILLED = '@AUTH:GET_PRIVACY_POLICIES_FULFILLED',
  GET_PRIVACY_POLICIES_REJECTED = '@AUTH:GET_PRIVACY_POLICIES_REJECTED',
  GET_MY_DATA_PENDING = '@AUTH:GET_MY_DATA_PENDING',
  GET_MY_DATA_FULFILLED = '@AUTH:GET_MY_DATA_FULFILLED',
  GET_MY_DATA_REJECTED = '@AUTH:GET_MY_DATA_REJECTED',
  SET_PHONE_PENDING = '@AUTH:SET_PHONE_PENDING',
  SET_PHONE_FULFILLED = '@AUTH:SET_PHONE_FULFILLED',
  SET_PHONE_REJECTED = '@AUTH:SET_PHONE_REJECTED',
  SET_EMAIL_PENDING = '@AUTH:SET_EMAIL_PENDING',
  SET_EMAIL_FULFILLED = '@AUTH:SET_EMAIL_FULFILLED',
  SET_EMAIL_REJECTED = '@AUTH:SET_EMAIL_REJECTED',
  CHANGE_ADDRESS_FULFILLED = '@AUTH:CHANGE_ADDRESS_FULFILLED',
  CHANGE_ADDRESS_REJECTED = '@AUTH:CHANGE_ADDRESS_REJECTED',
  CHANGE_ADDRESS_PENDING = '@AUTH:CHANGE_ADDRESS_PENDING',
}
