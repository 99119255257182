import React from 'react'

const DocumentsSent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="154"
      height="151"
      fill="none"
      viewBox="0 0 154 151"
    >
      <g clipPath="url(#clip0_1529_22670)">
        <path
          fill="#EAEEF9"
          d="M76.718 126.031c32.8 0 59.4-26.4 59.4-59s-26.6-59-59.4-59-59.4 26.4-59.4 59 26.6 59 59.4 59zM134.6 38.831a4.1 4.1 0 100-8.199 4.1 4.1 0 000 8.2zM130.501 25.531a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zM17.1 34.731a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zM10.8 96.331a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4z"
        />
        <g filter="url(#filter0_d_1529_22670)">
          <path
            fill="url(#paint0_linear_1529_22670)"
            d="M131.444 52.424v54.699c-.112 3.126-1.563 5.805-3.796 7.703-1.898 1.674-4.465 2.79-7.256 2.79H32.985c-1.897 0-3.683-.446-5.246-1.339a7.34 7.34 0 01-1.898-1.34c-1.228-1.004-2.344-2.456-2.902-4.019-.447-1.116-.782-2.344-.782-3.572V52.424s4.577-3.907 11.163-9.265c6.81-5.693 15.74-13.06 23.554-19.535 4.465-3.796 4.019-3.35 10.94-9.154a14.033 14.033 0 0118.196 0c1.34 1.116 2.79 2.456 4.13 3.572 2.902 2.344 6.028 5.023 9.377 7.814 7.033 5.805 14.736 12.28 20.764 17.303 6.139 5.023 10.604 8.819 11.163 9.265z"
          />
        </g>
        <path
          fill="#989FB0"
          d="M120.28 43.047v19.424l-19.088 11.498-5.693 3.46-12.056 7.256-5.693 3.46-2.456 1.452-2.344-1.451-5.359-3.46-11.497-7.257-5.359-3.46-17.637-11.052V43.16c6.81-5.693 15.74-13.061 23.554-19.535l42.754 2.12c7.144 5.917 14.846 12.28 20.874 17.303z"
        />
        <path
          fill="#fff"
          d="M116.485 27.643V61.8L99.071 72.183l-5.135 3.126-11.051 6.474-5.135 3.126-2.233 1.228-2.12-1.228-4.912-3.126L57.99 75.31l-4.911-3.126-16.075-10.047V27.643c0-3.573 2.902-6.475 6.586-6.475H109.9c3.572-.112 6.586 2.902 6.586 6.475z"
        />
        <path
          fill="url(#paint1_linear_1529_22670)"
          d="M22.605 52.201l52.466 3.46 55.591-3.46-2.121 9.935S76.299 89.374 76.634 89.374c.334 0-53.918-32.261-53.918-32.261l-.111-4.912z"
          opacity="0.5"
        />
        <path
          fill="#738DFF"
          d="M131.444 52.424v54.699c-.112 3.126-1.563 5.805-3.796 7.703-1.898 1.674-4.465 2.79-7.256 2.79H32.985c-1.897 0-3.683-.446-5.246-1.339a7.34 7.34 0 01-1.898-1.34c-1.228-1.005-2.344-2.456-2.902-4.019-.447-1.116-.782-2.344-.782-3.572V52.425L71.051 83.01l4.242 2.68 1.451-.894 1.34-.781 53.36-31.592z"
        />
        <path
          fill="url(#paint2_linear_1529_22670)"
          d="M127.76 114.826c-1.898 1.674-4.465 2.79-7.256 2.79H32.985c-1.897 0-3.683-.446-5.246-1.339a7.34 7.34 0 01-1.898-1.34c-1.228-1.005-2.344-2.456-2.902-4.019-.447-1.116-.782-2.344-.782-3.572V52.425l5.693 3.46 48.894 28.912 1.34.781.111.112 43.872 25.898 5.693 3.238z"
        />
        <path
          fill="url(#paint3_linear_1529_22670)"
          d="M78.085 83.904v3.795L27.74 116.277a7.343 7.343 0 01-1.898-1.34c-1.228-1.004-2.344-2.456-2.902-4.018l49.005-29.025 4.242 2.68.558.223 1.34-.893z"
        />
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          d="M22.157 52.424l54.587 32.373"
        />
        <path
          fill="url(#paint4_linear_1529_22670)"
          d="M131.443 52.424v54.699c-.111 3.126-1.562 5.805-3.795 7.703-1.898 1.674-4.465 2.79-7.256 2.79H32.985c-1.898 0-3.684-.446-5.247-1.339a7.342 7.342 0 01-1.897-1.34l5.581-3.349L75.293 85.69l1.451-.893 1.34-.781 47.555-28.243 5.804-3.349z"
        />
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          d="M131.443 52.424l-53.36 31.48-1.339.893-1.451.893-49.452 29.136"
        />
        <path
          fill="#CED7E2"
          d="M118.271 90.713H89.247c-.67 0-1.228-.558-1.228-1.228 0-.67.559-1.228 1.228-1.228h29.024c.67 0 1.228.558 1.228 1.228.112.67-.446 1.228-1.228 1.228zM118.271 99.086H89.247c-.67 0-1.228-.558-1.228-1.228 0-.67.559-1.228 1.228-1.228h29.024c.67 0 1.228.558 1.228 1.228.112.67-.446 1.228-1.228 1.228z"
        />
        <path
          fill="#526DFF"
          d="M124.299 116.5l3.572-1.786-.223.223h-.112l-3.237 1.563z"
        />
        <path
          fill="url(#paint5_linear_1529_22670)"
          d="M73.951 63.847c-.865 0-1.68-.318-2.341-1.007L60.362 51.128c-1.272-1.325-1.272-3.551 0-4.876a3.255 3.255 0 014.683 0l8.906 9.274 20.968-21.78a3.255 3.255 0 014.682 0c1.273 1.324 1.273 3.55 0 4.875L76.292 62.84c-.61.689-1.476 1.007-2.341 1.007z"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1529_22670"
          width="153.286"
          height="150.495"
          x="0.157"
          y="0.121"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1529_22670"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1529_22670"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1529_22670"
          x1="76.756"
          x2="76.756"
          y1="12.37"
          y2="91.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1529_22670"
          x1="76.585"
          x2="76.585"
          y1="101.159"
          y2="54.706"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8A90B5" />
          <stop offset="1" stopColor="#A7AED2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1529_22670"
          x1="74.924"
          x2="74.924"
          y1="50.916"
          y2="118.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.996" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1529_22670"
          x1="53.661"
          x2="49.381"
          y1="102.234"
          y2="95.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5D8E4" />
          <stop offset="1" stopColor="#E1E4F0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1529_22670"
          x1="78.608"
          x2="78.608"
          y1="50.916"
          y2="118.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.996" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1529_22670"
          x1="79.985"
          x2="79.985"
          y1="33.832"
          y2="51.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <clipPath id="clip0_1529_22670">
          <path fill="#fff" d="M0 0H154V151H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DocumentsSent
