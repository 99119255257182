import React from 'react'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { useAppSelector } from 'hooks/redux'
import { Drawers } from 'store/drawer/types'
import {
  Drawer,
  Title,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  ButtonsContainer,
  Button,
  ContentContainer
} from './styles'

import wrapper from 'components/organisms/Drawers/wrapper'
import QuotaDetailList from 'components/molecules/QuotaDetailList'
import DrawerSkeleton from 'assets/skeletons/DrawerSkeleton'

interface Props {
  showDrawer: boolean
  onClose: () => void
}

const QuotaDetails: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { quotaSelected, isQuotaLoading } = useAppSelector(
    state => state.contractsV2
  )

  return (
    <Drawer open={showDrawer} onClose={onClose} direction="right">
      <DrawerContainer>
        <HeaderContainer>
          <BackButton onPress={onClose}>
            <BackArrowIcon />
          </BackButton>
          <Title>Información del cupo</Title>
        </HeaderContainer>
        <ContentContainer>
          {!quotaSelected || isQuotaLoading
            ? (
              <DrawerSkeleton />
              )
            : (
              <QuotaDetailList quota={quotaSelected} />
              )}
        </ContentContainer>
        <ButtonsContainer>
          <Button onPress={onClose}>Entendido</Button>
        </ButtonsContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(QuotaDetails, Drawers.QUOTA_DETAILS)
