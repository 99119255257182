import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, grayTextFilter, violet } from '@qirapagos/lib/theme/colors'

function DollarSignSVG ({
  active,
  strokeWidth,
  width,
  height,
  canBeHovered
}: {
  active?: boolean
  strokeWidth?: number
  width?: number
  height?: number
  canBeHovered?: boolean
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
    >
      <Path
        d="M13 6v2M13 18v2M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12z"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10 18h4.5a2.5 2.5 0 000-5h-3a2.5 2.5 0 010-5H16"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default DollarSignSVG

DollarSignSVG.defaultProps = {
  strokeWidth: 2,
  width: 22,
  height: 22,
  active: false,
  canBeHovered: true

}
