import { cream, yellowThr } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import { violet } from 'theme/colors'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  padding: 15px;
  border-radius: 6px;
  border-width: 1px;
  border-color: ${yellowThr};
  background-color: ${cream};
`

export const Title = styled.div`
  font-weight: 600;
  font-size; 1rem;
  margin: 0 10px;
`

export const Action = styled.button`
  text-decoration-line: underline;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 1rem;
  color: ${violet};
`
