import React from "react";
import Svg, { Path } from "react-native-svg"
import { purple, white } from "@qirapagos/lib/theme/colors";

function Check({ isPurple, ...props }) {
  return (
    <Svg
      width="13"
      height="10"
      fill="none"
      viewBox="0 0 13 10"
      {...props}
    >
      <Path
        stroke={isPurple ? purple : white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.5 1.5l-7 7L1 5"
      ></Path>
    </Svg>
  );
}

export default Check;
