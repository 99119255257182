import React from 'react'
import {
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Box
} from '@chakra-ui/react'
import CustomText from 'components/atoms/CustomText'

interface Props {
    title?: string
    icon?: any
}

const CollapsableItem: React.FC<Props> = ({ title, icon }) => {
  return (
    <Accordion w="100%" py={3} defaultIndex={[1]} allowMultiple>
      <AccordionItem borderBottom="gray" borderTop="inherit" w="100%">
        <h2>
          <AccordionButton
            display="flex"
            w="100%"
            justifyContent="flex-start"
            alignItems="flex-start"
            _hover={{
              backgroundColor: 'inherit'
            }}
          >
            <Flex w="100%" justifyContent="flex-start">
              <Box w="20%" mr={2}>
                {icon}
              </Box>
              <Text
                style={{
                  fontSize: '1rem',
                  fontWeight: '500'
                }}
              >
                {title}
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel
          pb={4}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <button type="button">
            <CustomText color="gray" weight="mediumfont" size="xmedium">
              {'Contratos Activos\n'}
            </CustomText>
          </button>
          <button type="button">
            <CustomText weight="semibold" color="violet" size="xmedium">
              Contratos Finalizados
            </CustomText>
          </button>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default CollapsableItem

CollapsableItem.defaultProps = {
  title: '',
  icon: ''
}
