import { TransfersState } from './types'

const initialState: TransfersState = {
  movements: undefined,
  filteredMovements: undefined,
  contacts: undefined,
  currentPage: 1,
  countPages: 1,
  balance: '0',
  showModal: false,
  showBalance: true,
  isLoading: false,
  isInfoLoading: false,
  isLoadingStatements: false,
  accountId: undefined,
  transferAccount: {
    accountId: 0,
    destinationId: '',
    amount: 0,
    contact: false,
    conceptId: 1
  },
  account: {
    contactId: '',
    bank: '',
    name: '',
    alias: '',
    cvu: '',
    transactionLimit: 0
  },
  accountNumber: undefined
}

export default initialState
