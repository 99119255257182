import React, { forwardRef, useState } from 'react'
import { Controller, Control, FieldError } from 'react-hook-form'
import { Box, Input, InputGroup, InputRightElement, InputLeftElement, Text, Flex } from '@chakra-ui/react'
import EyeShowPassword from '@qirapagos/lib/assets/svg/EyeShowPassword'
import EyeSlashPassword from '@qirapagos/lib/assets/svg/EyeSlashPassword'
import ErrorIcon from '@qirapagos/lib/assets/svg/ErrorIcon'
import { TFieldType } from './types'
import { gray, graySoft, purple, error as errorColor } from '@qirapagos/lib/theme/colors'
import { ReactComponent as Currency } from 'assets/svg/Currency.svg'

interface Props {
  name: string
  control: Control
  placeholder: string
  type?: TFieldType
  error?: FieldError
  showErrorMessage?: boolean
  clearErrors?: () => void
  customStyle?: any
  typeOfField?: string
  autoFocus?: boolean
  maxLength?: number
  alignError?: string
  isDisabled?: boolean
  hasContent?: boolean
}

export const Field = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      control,
      placeholder,
      type = 'text',
      error,
      showErrorMessage = true,
      clearErrors = () => null,
      customStyle,
      typeOfField,
      autoFocus,
      maxLength,
      alignError,
      isDisabled,
      hasContent
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const handleShowPassword = () => {
      setShowPassword(!showPassword)
    }

    const replaceValue = (value: string) => {
      const isLastIndexComma = /.+,$/.test(String(value))
      const isLastIndexCommaZero = /.+,0$/.test(String(value))
      let replacedValue = Number(
        value.replace(/\./g, '').replace(',', '.').replace('$', '')
      ).toLocaleString('es-AR')
      if (isLastIndexComma) replacedValue += ','
      if (isLastIndexCommaZero) replacedValue += ',0'
      return replacedValue
    }

    const onChangeOnlyNumbers =
      (onChange: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const dotlessValue = e.currentTarget.value
          .replaceAll('.', '')
          .replace('$', '')
        if (!/^(\d?)+(,?[0-9]{0,2}$)?$/.test(dotlessValue)) return
        onChange(e.currentTarget.value || '')
      }

    return (
      <InputGroup display="flex" flexDirection="column">
        <Controller
          control={control}
          name={name}
          render={({ onChange, onBlur, value }) => {
            return (
              <>
                <Input
                  maxLength={maxLength}
                  ref={ref}
                  autoFocus={autoFocus}
                  style={customStyle}
                  value={
                    typeOfField === 'only-numbers'
                      ? `$${replaceValue(value)}`
                      : value
                  }
                  type={showPassword ? 'text' : type}
                  placeholder={placeholder}
                  onChange={
                    typeOfField === 'only-numbers'
                      ? onChangeOnlyNumbers(onChange)
                      : onChange
                  }
                  onFocus={() => {
                    setFocus(true)
                    clearErrors()
                  }}
                  onBlur={() => {
                    onBlur()
                    setFocus(false)
                  }}
                  position="relative"
                  mb={4}
                  pt={focus || value ? 5 : 0}
                  bg={graySoft}
                  borderColor={error ? errorColor : graySoft}
                  borderWidth={2}
                  height="65px"
                  _focus={{
                    borderColor: error ? errorColor : purple,
                    borderWidth: 2
                  }}
                  _hover={{
                    borderColor: error ? errorColor : graySoft
                  }}
                  _placeholder={
                    focus
                      ? { opacity: 0 }
                      : {
                          color: gray,
                          paddingTop: 0,
                          mb: 4,
                          fontSize: { sm: '16', md: '16px', lg: '16px' }
                        }
                  }
                  isDisabled={isDisabled}
                />

                {type === 'password' && (
                  <InputRightElement alignSelf="center" mt={3} mr={5}>
                    <button type="button" onClick={handleShowPassword}>
                      {showPassword
                        ? (
                          <EyeShowPassword color={focus ? null : '#BEC3D0'} />
                          )
                        : (
                          <EyeSlashPassword color={focus ? null : '#BEC3D0'} />
                          )}
                    </button>
                  </InputRightElement>
                )}
                {type === 'currency' && (focus || hasContent) && (
                  <InputLeftElement mt="22px" ml="-8px">
                    <Currency />
                  </InputLeftElement>
                )}

                {(focus || value) && (
                  <Box
                    position="absolute"
                    top={2}
                    left={5}
                    color={error ? errorColor : purple}
                  >
                    {placeholder}
                  </Box>
                )}
              </>
            )
          }}
        />

        {error && showErrorMessage && (
          <Flex alignItems="center" justifyContent={alignError}>
            <ErrorIcon />
            <Text color={errorColor} ml={2} alignSelf='center'>
              {error.message}
            </Text>
          </Flex>
        )}
      </InputGroup>
    )
  }
)

Field.defaultProps = {
  type: 'text',
  error: undefined,
  showErrorMessage: true,
  clearErrors: () => null,
  customStyle: undefined,
  typeOfField: '',
  autoFocus: false,
  maxLength: undefined,
  alignError: 'center',
  isDisabled: false,
  hasContent: false
}
