import React from 'react'
import AgentsItem from 'assets/svg/AgentsItem'
import AccountItem from 'assets/svg/DollarSignSVG'
import CustomerSupportItem from 'assets/svg/CustomerSupportItem'
import DocumentationItem from 'assets/svg/DocumentationItem'
import GrainItem from 'assets/svg/GrainItem'
import LogOutItem from 'assets/svg/LogOutItem'
import MyDataItem from 'assets/svg/MyDataItem'
import WalletItem from 'assets/svg/WalletItem'
import YourSellerItem from 'assets/svg/YourSellerItem'
import { ListItemType } from 'constants/common'
import MyEnterprisesItem from 'assets/svg/MyEnterprisesItem'

interface Props {
    active: boolean
    title?: string
    icon: string
}

const SidebarItem: React.FC<Props> = ({ active, title, icon }) => {
  const {
    WALLET,
    GRAIN,
    ACCOUNT,
    MY_DATA,
    DOCUMENTATION,
    AGENTS,
    CUSTOMER_SUPPORT,
    YOUR_SELLER,
    YOUR_ENTERPRISES,
    LOG_OUT
  } = ListItemType

  const selectIcon = {
    [WALLET]: <WalletItem />,
    [GRAIN]: <GrainItem active={active} />,
    [ACCOUNT]: <AccountItem active={active} />,
    [MY_DATA]: <MyDataItem active={active} />,
    [DOCUMENTATION]: <DocumentationItem active={active} />,
    [AGENTS]: <AgentsItem active={active} />,
    [CUSTOMER_SUPPORT]: <CustomerSupportItem active={active} />,
    [YOUR_SELLER]: <YourSellerItem active={active} />,
    [YOUR_ENTERPRISES]: <MyEnterprisesItem active={active} />,
    [LOG_OUT]: <LogOutItem active={active} />
  }

  return (
    <button type='button'>
      <p>{selectIcon[icon as keyof typeof selectIcon]}</p>
      <p>{title}</p>
    </button>
  )
}

export default SidebarItem

SidebarItem.defaultProps = {
  title: ''
}
