import React, { SetStateAction } from 'react'
import { BoxButtonsContainer, BoxContentContainer, styles } from './styles'
import CustomModal from '../CustomModal'
import LogoutSessionSVG from 'assets/svg/LogoutSessionSVG'
import CustomText from 'components/atoms/CustomText'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { logOut } from 'store/auth/thunks'
import useNavigation from 'hooks/useNavigation'

interface Props {
  setIsOpen: SetStateAction<any>
  isOpen: boolean
}

const LogoutModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { onNavigate } = useNavigation()
  const dispatch = useDispatch()
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Cerrar sesión"
      icon={<LogoutSessionSVG active={false} />}
    >
      <BoxContentContainer>
        <CustomText size="mmbig">
          {'¿Estás seguro que  querés\n cerrar sesión?'}
        </CustomText>
        <BoxButtonsContainer id="modal-logout">
          <Button
            disabled={false}
            customStyle={styles.button}
            onPress={() => dispatch(logOut(onNavigate))}
          >
            Cerrar sesión
          </Button>
          <Button
            disabled={false}
            secondary
            customStyle={styles.button}
            onPress={() => setIsOpen(false)}
          >
            Cancelar
          </Button>
        </BoxButtonsContainer>
      </BoxContentContainer>
    </CustomModal>
  )
}

export default LogoutModal
