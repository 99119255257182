import styled from 'styled-components'
import DrawerComponent from 'react-modern-drawer'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'
import { Colors, Fonts } from '@qirapagos/web/src/theme'

export const Drawer = styled(DrawerComponent)`
  width: 500px !important;
`

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.white};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
`

export const Title = styled.p`
  color: ${Colors.black};
  font-size: ${Fonts.SIZES.XBIG}px;
  font-weight: 500;
`

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: center;
  margin-bottom: 3%;
`

export const BackButton = styled(ButtonComponent)`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 15px;
  width: fit-content;
  height: 20px;
  background-color: ${Colors.white};
  margin-bottom: 0;
`
