import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  color?: string
  props?: any
}

const BackArrow: React.FC<Props> = ({ color, props }) => {
  return (
    <Svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Path
        d="M9.46 1.68L7.231 3.949l-5.943 6.05m0 0l8.172 8.32M1.288 10h19.316"
        stroke={color}
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default BackArrow

BackArrow.defaultProps = {
  color: '#000',
  props: {}
}
