import React, { useCallback } from 'react'
import useNavigation from 'hooks/useNavigation'
import {
  setShowDrawerError
} from 'store/modals/actions'
import { useDispatch } from 'react-redux'
import {
  Drawer,
  Container,
  BackArrowButton,
  Title,
  Body,
  ButtonStyled,
  ContentContainer,
  Header,
  ErrorCross,
  BackArrow
} from './style'
import { useAppSelector } from 'hooks/redux'
import { Box } from '@chakra-ui/react'

const DrawerError: React.FC = () => {
  const { showDrawerError } = useAppSelector(state => state.modal)
  const { title, body, btnText, callback } = showDrawerError
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  const onClose = useCallback(() => {
    if (callback) {
      callback()
    }
    dispatch(setShowDrawerError(false, '', ''))
    onNavigate(showDrawerError?.navigation?.path, {
      title: showDrawerError?.navigation?.title
    })
  }, [dispatch, onNavigate, showDrawerError])

  const onPressBackToHome = useCallback(() => {
    dispatch(setShowDrawerError(false, '', ''))
    onNavigate('/content/wallet')
  }, [dispatch, onNavigate])

  const renderContent = () => {
    if (showDrawerError.navigation?.fromScreen === 'transfer') {
      return (
        <Box display="flex" flexDir="column" width="100%">
          <ButtonStyled
            onPress={onPressBackToHome}
            disabled={false}
          >
            Volver al inicio
          </ButtonStyled>
        </Box>
      )
    }
    return (
      <ButtonStyled
        onPress={onClose}
        disabled={false}
      >
        {btnText}
      </ButtonStyled>
    )
  }

  return (
    <Drawer
      open={showDrawerError?.show}
      onClose={onClose}
      direction="right"
    >
      <Container>
        <Header>
          <BackArrowButton type="button" onClick={onClose}>
            <BackArrow />
          </BackArrowButton>
        </Header>
        <ContentContainer>
          <ErrorCross />
          <Title>{title}</Title>
          {body && <Body>{body}</Body>}
        </ContentContainer>
        {renderContent()}
      </Container>
    </Drawer>
  )
}

export default DrawerError
