import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function ErrorIcon (props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        // eslint-disable-next-line max-len
        d="M5.578 5.92a.917.917 0 00.339-.711v-2.5a.917.917 0 10-1.834 0v2.5a.917.917 0 00.332.706 1.115 1.115 0 00-.54.996l.5-.018-.5.02a1.137 1.137 0 001.133 1.092h.012v0h.01A1.115 1.115 0 006.123 6.85l-.546-.929zm0 0a1.136 1.136 0 01.546.93l-.546-.93zM1.8 1.877h0l.004-.004A4.585 4.585 0 015.001.501l-.008-.5.01.5A4.402 4.402 0 019.5 4.912l.5.001-.5.005A4.546 4.546 0 015.003 9.5h-.087A4.407 4.407 0 01.5 5.087h0V5.08a4.416 4.416 0 011.3-3.202z"
        fill="#FF5A3D"
        stroke="#FF5A3D"
      />
    </Svg>
  )
}

export default ErrorIcon
