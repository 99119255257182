import { setKeyEndpoints } from '../../store/auth/actions'
import DeviceService from '../../services/DeviceService'
import { ThunkDispatchType } from '../../types/reduxTypes'
import { Platform } from 'react-native'
import { ApiResponse } from 'apisauce'

export const getKeyEndPoints =
  (device: string, token: string) => async (dispatch: ThunkDispatchType) => {
    try {
      const response: ApiResponse<any> = await DeviceService.keyEndpoints(device, token)
      const { keyendpoints } = response.data
      await localStorage.setItem('keyendpoints', keyendpoints)
      dispatch(setKeyEndpoints(keyendpoints))
    } catch (error) {
      console.log('Error')
    }
  }

export const initDevice = () => async (dispatch: ThunkDispatchType) => {
  try {
    const os = Platform.OS
    const device = Math.random().toString(36).substring(7)
    const body = {
      device,
      os,
      useragent: `${device} ${os}`
    }
    const response: ApiResponse<any> = await DeviceService.registerDevice(body)
    const { token } = response.data
    await localStorage.setItem('device', device)
    await localStorage.setItem('deviceToken', token)
    dispatch(getKeyEndPoints(device, token))
  } catch (error) {
    console.log('Error')
  }
}
