import styled from 'styled-components'
import { Colors } from 'theme'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'
import { Text } from '@chakra-ui/react'

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
  height: 100%;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
`

export const BackButton = styled.button`
  position: absolute;
  left: 20px;
`

export const CalendarIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TextDescription = styled.p`
  align-self: center;
  width: 75%;
  text-align: center;
  font-size: 1vw;
  margin: 2%;
  color: ${Colors.gray};
`

export const BoldText = styled.span`
  font-weight: bold;
`

export const drawerStyle = {
  width: '25%'
}

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: flex-end;
  margin-bottom: 5%;
  width: 100%;
`

export const MainButton = styled(ButtonComponent)`
  width: 80%;
  height: 60px;
  margin-bottom: 10%;
`

export const MainButtonText = styled(Text)`
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
`
