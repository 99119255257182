import React from 'react'
import { Controller } from 'react-hook-form'
import { Box, Radio as RadioChakra, RadioGroup } from '@chakra-ui/react'

interface Props {
  options: any
  control: any
  customStyle?: any
  colorScheme?: string
  name: string
  register: any
  setValueID: any
}

const Radio: React.FC<Props> = ({
  options,
  control,
  customStyle,
  colorScheme,
  name,
  register,
  setValueID
}) => {
  return (
    <Box style={customStyle.box}>
      <Controller
        name={name}
        control={control}
        as={(
          <RadioGroup
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%'
            }}
            ref={register()}
          >
            {options.map((item: any) => (
              <RadioChakra
                colorScheme={colorScheme}
                value={item.name}
                flexDirection="row"
                onChange={() => {
                  setValueID(item.id)
                }}
              >
                {item.option || 'lacacacaca'}
              </RadioChakra>
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  )
}

export default Radio

Radio.defaultProps = {
  customStyle: {},
  colorScheme: ''
}
