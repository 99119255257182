import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { grayTextFilter, gray, violet, white } from '@qirapagos/lib/theme/colors'

function GrainItem ({
  active,
  canBeHovered,
  isWhite,
  width,
  height
}: {
  active: boolean
  canBeHovered?: boolean
  isWhite?: boolean
  width?: string
  height?: string
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M23.365 3.244L17.982.161a1.194 1.194 0 00-1.199 0l-4.795 2.746L7.193.161a1.195 1.195 0 00-1.199 0L.611 3.244A1.201 1.201 0 000 4.292v6.166a1.208 1.208 0 00.611 1.048l4.796 2.734v5.468a1.209 1.209 0 00.611 1.048l5.395 3.083a1.194 1.194 0 001.198 0l5.371-3.083a1.2 1.2 0 00.611-1.048V14.24l4.796-2.734A1.2 1.2 0 0024 10.458V4.292a1.208 1.208 0 00-.635-1.048zM2.398 9.76V4.943l4.195-2.41 4.196 2.41V9.76l-4.196 2.409-4.195-2.41zm13.786 9.25l-4.196 2.408-4.196-2.409v-4.817l4.196-2.409 4.196 2.409v4.817zm5.394-9.25l-4.195 2.409-4.196-2.41V4.944l4.196-2.41 4.195 2.41V9.76z"
        fill={isWhite ? white : !canBeHovered ? grayTextFilter : active ? violet : gray}
      />
    </Svg>
  )
}

export default GrainItem

GrainItem.defaultProps = {
  canBeHovered: null,
  isWhite: false,
  width: '22',
  height: '22'
}
