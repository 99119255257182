import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, grayTextFilter, violet } from '@qirapagos/lib/theme/colors'

function AgentsItem ({
  active,
  canBeHovered
}: {
  active: boolean
  canBeHovered?: boolean
}) {
  return (
    <Svg width={22} height={22} viewBox="0 0 21 23" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M19.684 9.218c.012-.544-.322-1.894-2.197-1.889-1.03.003-2.06-.014-3.088-.005-.619.006-.918-.259-.91-.896.012-.936.001-1.871-.014-2.807-.029-1.736-.646-2.372-2.39-2.388-2.527-.022-5.054-.02-7.58-.003-1.575.01-2.228.671-2.233 2.227-.008 2.59-.002 5.179-.002 7.768-.083 3.795-.058 6.744.014 8.33.016.34.053.971.497 1.428.575.592 1.536.587 2.318.582.856-.005 1.285-.008 1.526-.235.5-.47.217-1.35.274-4.023.006-.318.026-.81.365-1.144.237-.234.6-.338 1.12-.352a37.664 37.664 0 012.059.005c.526.014.881.114 1.106.342.333.338.328.841.325 1.075-.028 2.384-.042 3.577.149 3.862.336.504.82.307 5.123.39 1.536.028 2.417.072 3.004-.547.484-.51.531-1.196.534-1.701.018-3.244.014-6.232 0-9.476v-.543z"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <Path
        // eslint-disable-next-line max-len
        d="M5.665 8.653H4.53a.473.473 0 00-.473.473v1.135c0 .261.212.473.473.473h1.135a.473.473 0 00.473-.473V9.126a.473.473 0 00-.473-.473zM5.665 4.72H4.53a.473.473 0 00-.473.473v1.135c0 .261.212.473.473.473h1.135a.473.473 0 00.473-.473V5.193a.473.473 0 00-.473-.473zM9.895 8.653H8.76a.473.473 0 00-.473.473v1.135c0 .261.212.473.473.473h1.135a.473.473 0 00.473-.473V9.126a.473.473 0 00-.473-.473zM9.895 4.72H8.76a.473.473 0 00-.473.473v1.135c0 .261.212.473.473.473h1.135a.473.473 0 00.473-.473V5.193a.473.473 0 00-.473-.473z"
        fill="#7D8893"
      />
    </Svg>
  )
}

export default AgentsItem

AgentsItem.defaultProps = {
  canBeHovered: true
}
