import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import WalletDesktopSkeleton from 'assets/skeletons/WalletDesktopSkeleton'

const ProtectedRoute = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const location = useLocation()
  const isLogged = sessionStorage.getItem('isLogged') === 'true'

  const setUserLoggedIn = async () => {
    setIsLoading(true)
    const userLoggedIn = await localStorage.getItem('userLoggedIn')
    if (userLoggedIn === 'true' && isLogged) setIsAuthenticated(true)
    if (!isLogged) localStorage.clear()
    setIsLoading(false)
  }

  useEffect(() => {
    setUserLoggedIn()
  }, [])

  if (isLoading) return <WalletDesktopSkeleton />

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />
  }
  return children
}

export default ProtectedRoute
