import * as React from "react"
import Svg, { SvgProps, Path, G } from "react-native-svg"

function AvatarSquare(props: SvgProps) {
  return (
    <Svg
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <Path
        stroke="#B1B1B1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M26 5H6a1 1 0 00-1 1v20a1 1 0 001 1h20a1 1 0 001-1V6a1 1 0 00-1-1z"
      ></Path>
      <Path
        stroke="#B1B1B1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M27 20l-5.293-5.293a1 1 0 00-1.414 0l-5.586 5.586a1 1 0 01-1.414 0l-2.586-2.586a1 1 0 00-1.414 0L5 22"
      ></Path>
      <Path fill="#B1B1B1" d="M12.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></Path>
    </Svg>
  )
}

export default AvatarSquare
