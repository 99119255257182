/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react'
import { FontSize, FontWeight } from 'types/fontStyles'
import { getFontColor, StyledText } from './styles'

interface Props {
  /** Medidas en pixeles correspondientes a cada tamaño
   * small: 12, medium: 14, xmedium: 16
   */
  size?: FontSize
  weight?: FontWeight
  color?: string
  children?: ReactNode
  customStyle?: React.CSSProperties | React.CSSProperties[]
  align?: 'left' | 'center' | 'right'
  testID?: string
  numberOfLines?: number
  underlined?: boolean
}

const CustomText = ({
  size = 'small',
  weight = 'regular',
  color = 'gray',
  align = 'center',
  customStyle = {},
  children,
  testID = '',
  numberOfLines = 0,
  underlined
}: Props) => {
  const style = Array.isArray(customStyle) ? Object.assign({}, ...customStyle) : customStyle

  return (
    <StyledText
      data-testid={testID}
      style={style}
      noOfLines={numberOfLines}
      color={getFontColor(color)}
      size={size}
      weight={weight}
      align={align}
      underlined={underlined}
    >
      {children}
    </StyledText>
  )
}

export default CustomText
