
export const errorMessagesByCode = (code?: string | number) => {
  // eslint-disable-next-line no-prototype-builtins
  if (code && errorMessages.hasOwnProperty(Number(code))) {
    return errorMessages[Number(code)]
  }
  return 'Hubo un error'
}

interface ErrorMessages {
  [key: number]: string
}

export const errorMessagesCode = {
  NEW_PASSWORD_USED_BEFORE: 103,
  OLD_PASSWORD_INCORRECT: 109,
  DNI_ALREADY_IN_USE: 111
}

const errorMessages: ErrorMessages = {
  102: 'El alias no se puede editar más de una vez en 24 horas.',
  104: 'Su contraseña no puede incluir datos personales.',
  105: 'No pudimos encontrar tu cuenta de Qira Pagos.',
  108: 'El pin ingresado es incorrecto.',
  [errorMessagesCode.DNI_ALREADY_IN_USE]: 'Ya existe un usuario con el DNI ingresado.',
  [errorMessagesCode.NEW_PASSWORD_USED_BEFORE]: 'No puede utilizar una contraseña usada anteriormente.',
  [errorMessagesCode.OLD_PASSWORD_INCORRECT]: 'Contraseña actual incorrecta.'
}
