import styled from 'styled-components'
import { gray, white } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'

export const ViewButtonPencil = styled.div`
  display: flex;
  flex-direction: row;
`

export const ViewButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
`

export const ViewButtonModal = styled.div`
  display: flex;
  flex-direction: column;
`

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  margin-left: 87px;
  margin-right: 87px;
  margin-top: 3.65vh;
`
export const ViewContainerInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5vh;
  gap: 40px 0px;
  margin-bottom: 2.5vh;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: center;
  }
`

export const TextFullName = styled.div`
  font-size: 3.5vw;
  font-weight: 500;
  text-align: center;
  color: ${gray};
  margin-top: 1.3vh;

  @media ${DEVICES.mobile} {
    font-size: 40px;
  }
`

export const ViewInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;

  @media ${DEVICES.mobile} {
    width: 100%;
    margin: 10px 0;
  }
`
export const TextInfo = styled.p`
  font-size: 1.1vw;
  color: ${gray};
  text-align: center;

  @media ${DEVICES.tablet} {
    font-size: 2vw;
  }

  @media ${DEVICES.mobile} {
    width: 100%;
    font-size: 20px;
    margin-bottom: 12px;
  }
`
