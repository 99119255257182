import styled from 'styled-components'

export const FieldsContainer = styled.div`
  width: 100%;
  max-width: 350px;
`

export const ForgotPasswordButtonContainer = styled.div`
  margin-top: 45px;
`
