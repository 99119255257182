import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function Copy () {
  return (
    <Svg width={28} height={28} viewBox="0 0 29 28" fill="none">
      <Path
        d="M24.126 20.124V4.374H8.375"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M20.626 7.875H4.875v15.75h15.75V7.875z"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Copy
