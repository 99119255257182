import styled from 'styled-components'

export const AccordionContainer = styled.div`
  width: 85%;
  align-self: flex-start;
  margin-left: 40px;
`

export const ScrollView = styled.div`
  padding-bottom: 50px;
`
