import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, violet } from '@qirapagos/lib/theme/colors'
// import IisSelected from 'interfaces/isSelected'

// eslint-disable-next-line react/require-default-props
const WalletItem = ({ active }: {active?: boolean}) => {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 24" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M20.778 7.22L13.445.916A3.708 3.708 0 0011 0c-.902 0-1.772.326-2.444.916L1.222 7.22a3.6 3.6 0 00-.908 1.225C.103 8.912-.004 9.418 0 9.93v10.475a3.56 3.56 0 001.074 2.543A3.703 3.703 0 003.667 24h14.666c.973 0 1.905-.379 2.593-1.053A3.56 3.56 0 0022 20.404V9.917a3.536 3.536 0 00-.316-1.477 3.6 3.6 0 00-.906-1.22zm-7.333 14.383h-4.89V15.61c0-.318.13-.623.359-.848.229-.224.54-.35.864-.35h2.444c.324 0 .635.126.864.35.23.225.358.53.358.848v5.993zm6.11-1.199c0 .318-.128.623-.357.848-.23.225-.54.35-.865.35H15.89V15.61a3.56 3.56 0 00-1.074-2.542 3.704 3.704 0 00-2.593-1.054H9.778c-.973 0-1.905.38-2.593 1.054a3.56 3.56 0 00-1.074 2.542v5.993H3.667c-.324 0-.635-.126-.864-.351a1.187 1.187 0 01-.358-.848V9.917a1.179 1.179 0 01.415-.9l7.333-6.292a1.236 1.236 0 011.614 0l7.333 6.293a1.2 1.2 0 01.415.899v10.487z"
        fill={active ? violet : gray}
      />
    </Svg>
  )
}

export default WalletItem
