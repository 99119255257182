import styled from 'styled-components/native'

export const ViewContainer = styled.View`
  flex: 1;
  margin-top: 3.65vh;
`
export const ViewAcordionContainer = styled.View`
  flex: 1;
  margin-top: 20px;
  overflow-x: scroll;
`
