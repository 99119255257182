import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  border-radius: 6px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  height: 73px;
  width: 343px;
  min-height: 60px;
`

export const TextInfo = styled.p`
  font-size: 16px;
  font-weight: 500
`
