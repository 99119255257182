import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { Box } from '@chakra-ui/react'
import {
  getConditionType
} from '@qirapagos/web/src/store/contracts/thunks'
import { AppOperation } from '@qirapagos/web/src/store/contracts/types'
import { showDrawer } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'
import FormExchangers, {
  formExchangerInitialValues,
  formExchangersValidator,
  FormExchangersValues
} from 'components/organisms/OperationFormSteps/FormExchangers'
import FormProductTrade, {
  formProductTradeInitialValues,
  formProductTradeValidator,
  FormProductTradeValues
} from 'components/organisms/OperationFormSteps/FormProductTrade'
import FormGrain, {
  formGrainValidator,
  FormGrainValues
} from 'components/organisms/OperationFormSteps/FormGrain'
import FormDelivery, {
  formDeliveryInitialValues,
  formDeliveryValidator,
  FormDeliveryValues
} from 'components/organisms/OperationFormSteps/FormDelivery'
import FormCondition, {
  formConditionInitialValues,
  formConditionValidator,
  FormConditionValues
} from 'components/organisms/OperationFormSteps/FormCondition'
import StepperContract from 'components/molecules/StepperContract'
import { merge } from '@qirapagos/web/src/validations/operationsForms'
import { parseDate } from 'utils/common/index'
import { Container } from './styles'

type FormValues = FormDeliveryValues &
  FormGrainValues &
  FormExchangersValues &
  FormProductTradeValues &
  FormConditionValues & { appOperation: AppOperation }

const NewSaleOrderSwapToFix: React.FC = () => {
  const dispatch = useDispatch()

  const [currentStepBox, setCurrentStepBox] = useState(0)
  const [isFirstComplete, setFirstIsComplete] = useState(false)
  const [isSecondComplete, setSecondIsComplete] = useState(false)
  const [isThirdComplete, setThirdIsComplete] = useState(false)
  const [isFourthComplete, setFourthIsComplete] = useState(false)
  const [isFifthComplete, setFifthIsComplete] = useState(false)

  useEffect(() => {
    dispatch(getConditionType())
  }, [dispatch])

  const titlesInfo = [
    {
      title: '1. Contraparte de contrato',
      isComplete: isFirstComplete
    },
    {
      title: '2. Condición de canje',
      isComplete: isSecondComplete
    },
    {
      title: '3. Condición de granos',
      isComplete: isThirdComplete
    },
    {
      title: '4. Condición de fijación',
      isComplete: isFourthComplete
    },
    {
      title: '5. Procedencia',
      isComplete: isFifthComplete
    }
  ]

  const onSubmit = (values: FormValues) => {
    const saleOrder = {
      appOperation: values.appOperation,
      // FormExchangers
      exchanger: values.exchanger,
      exchangerTaxId: values.exchangerTaxId,
      // FormProductTrade
      exchangeProduct: values.exchangeProduct,
      exchangeCurrencyId: values.exchangeCurrencyId,
      exchangeAmount: values.exchangeAmount,
      // FormGrain
      sellerTaxId: values.sellerTaxId,
      currencyId: values.currencyId,
      grainId: values.grainId,
      harvestDateId: values.harvestDateId,
      price: values.price,
      tons: values.tons,
      // FormCondition
      fixationCondition: values.fixationCondition,
      conditionDateFrom: parseDate(values.conditionDateFrom, 'yyyy-MM-dd'),
      conditionDateTo: parseDate(values.conditionDateTo, 'yyyy-MM-dd'),
      // FormDelivery
      originId: values.originId,
      destinationId: values.destinationId,
      deliveryPeriod: values.deliveryPeriod,
      deliveryFrom: parseDate(values.deliveryFrom, 'yyyy-MM-dd'),
      deliveryTo: parseDate(values.deliveryTo, 'yyyy-MM-dd')
    }
    dispatch(showDrawer(Drawers.CONFIRM_NEW_SALE_ORDER, { saleOrder }))
  }

  const formik = useFormik({
    initialValues: {
      appOperation: AppOperation.SWAP_TO_FIX,
      price: 0,
      currencyId: 2,
      sellerTaxId: undefined,
      grainId: undefined,
      harvestDateId: undefined,
      tons: undefined,
      ...formExchangerInitialValues,
      ...formProductTradeInitialValues,
      ...formConditionInitialValues,
      ...formDeliveryInitialValues
    },
    validationSchema: merge(
      formExchangersValidator,
      formProductTradeValidator,
      formGrainValidator,
      formConditionValidator,
      formDeliveryValidator
    ),
    onSubmit
  })

  useEffect(() => {
    const {
      exchanger,
      exchangerTaxId,
      exchangeProduct,
      exchangeCurrencyId,
      exchangeAmount,
      sellerTaxId,
      currencyId,
      grainId,
      harvestDateId,
      price,
      tons,
      fixationCondition,
      conditionDateFrom,
      conditionDateTo,
      stateOrigin,
      originId,
      stateDestination,
      destinationId,
      deliveryPeriod,
      deliveryFrom,
      deliveryTo
    } = formik.values
    // FormExchangers
    if (exchanger && exchangerTaxId) {
      setFirstIsComplete(true)
      setCurrentStepBox(1)
    } else {
      setFirstIsComplete(false)
      setCurrentStepBox(0)
    }
    // FormProductTrade
    if (exchangeProduct && exchangeCurrencyId && exchangeAmount) {
      setSecondIsComplete(true)
    } else {
      setSecondIsComplete(false)
    }
    // FormGrain
    if (
      sellerTaxId &&
      currencyId &&
      grainId &&
      harvestDateId &&
      price &&
      tons
    ) {
      setThirdIsComplete(true)
    } else {
      setThirdIsComplete(false)
    }
    // FormCondition
    if (fixationCondition && conditionDateFrom && conditionDateTo) {
      setFourthIsComplete(true)
    } else {
      setFourthIsComplete(false)
    }
    // FormDelivery
    if (
      stateOrigin &&
      originId &&
      stateDestination &&
      destinationId &&
      deliveryPeriod &&
      deliveryFrom &&
      deliveryTo
    ) {
      setFifthIsComplete(true)
    } else {
      setFifthIsComplete(false)
    }
  }, [formik.values])

  return (
    <>
      <StepperContract
        titlesInfo={titlesInfo}
        currentStepBox={currentStepBox}
      />
      <Container>
        <Helmet title="Nueva orden de canje con precio hecho" />
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <FormExchangers
              titlesInfo="1. Contraparte del contrato"
              formik={formik}
            />
          </Box>
          <Box>
            <FormProductTrade
              titlesInfo="2. Condición de canje"
              formik={formik}
            />
          </Box>
          <Box>
            <FormGrain
              titlesInfo="3. Condición de granos"
              formik={formik}
              toFix
            />
          </Box>
          <Box>
            <FormCondition
              titlesInfo="4. Condición de fijación"
              formik={formik}
            />
          </Box>
          <Box>
            <FormDelivery titlesInfo="5. Entrega" formik={formik} />
          </Box>
        </form>
      </Container>
    </>
  )
}

export default NewSaleOrderSwapToFix
