/* eslint-disable react/require-default-props */
import React from 'react'
import { FormikProps } from 'formik'
import {
  TextField as TextFieldMUI,
  MenuItem as MenuItemMUI,
  FormControl
} from './styles'

interface Option {
  label: string,
  value: string | number
}

interface Props {
  name: string;
  label: string;
  options?: Option[]
  formik: FormikProps<any>
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const SelectField = (props: Props) => {
  const { options, label, name, formik, onChange } = props

  return (
    <FormControl variant="outlined">
      <TextFieldMUI
        select
        name={name}
        label={label}
        variant="filled"
        onChange={(event) => {
          if (onChange) {
            onChange(event)
          }
          formik.handleChange(event)
        }}
        value={formik.values[name]}
        error={formik.touched?.[name] && Boolean(formik.errors?.[name])}
        helperText={formik.touched?.[name] && formik.errors?.[name]}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            elevation: 1
          }
        }}
      >
        {options?.map((option) => (
          <MenuItemMUI value={option.value}>{option.label}</MenuItemMUI>
        ))}
      </TextFieldMUI>
    </FormControl>
  )
}
