import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  accordionContainer: {
    width: '85%',
    alignSelf: 'flex-start',
    marginLeft: 40
  },
  accordionItem: {
    marginBottom: 5
  },
  scrollView: {
    paddingBottom: 50
  }
})
