/* eslint-disable max-len */
export const titleOne = '1. Ingresá Alias, CBU, CVU o Email del nuevo contacto'
export const subTitleOne = 'Si ingresás un alias, recordá que debe contener entre 6 y 20 caracteres (letras, números, guión y punto)'
export const titleTwo = '2. Confirmá los datos del nuevo contacto ingresado'
export const titleTwoAlternative = '2. Confirmá los datos del contacto ingresado'
export const subTitleTwo = 'Una vez realizada la transferencia la cuenta se añadirá a tus cuentas frecuentes.'
export const titleThree = '3. Ingresá el monto a transferir'
export const titleFour = '4. Revisá los datos de tu transferencia, transferí y agendá'
export const subTitleFour = 'Una vez confirmada la transferencia se debitará de tu cuenta y en las próximas horas se vera reflejada en la cuenta destino.'
export const titleFive = '5. Ingresá el código que recibiste en tu celular'
export const subTitleFive = 'Ingresá el código de 6 dígitos envíado a tu celular.'
