import { purple, white } from '@qirapagos/lib/theme/colors'
import styled, { keyframes } from 'styled-components'

export const OverlayAnimation = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`
export const Overlay = styled.div<any>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background: ${props =>
    props.showOverlay ? 'rgba(255,255,255,.7)' : 'rgba(0,0,0,0)'};
  padding: 40px;
  display: flex;
  align-items: ${props => (props.position ? props.position : 'center')};
  justify-content: center;
  animation-duration: 0.3s;
  animation-name: ${OverlayAnimation};
`

export const ModalContainer = styled.div<any>`
  width: 430px;
  min-height: 100px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-bottom: ${props => (props.padding ? props.padding : '0px')};
`

export const BoxChildrenContainer = styled.div`
  height: 300px;
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  background-color: ${purple};
  h3 {
    font-weight: 600;
    font-size: 16px;
    color: ${white};
    align-self: flex-end;
  }
`

export const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: ${white};
`

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  left: 25px;
`
