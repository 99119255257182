import { StyleSheet } from 'react-native'
import { SIZES } from '@qirapagos/lib/theme/fonts'
import { moderateScale } from '@qirapagos/lib/utils/scaling'
const IMAGE_SIZE = moderateScale(15)
export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  textInputContainer: {
    flex: 1
  },
  textStyle: {
    fontSize: SIZES.BIG
  },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    marginRight: moderateScale(10)
  },
  searchInputContainer: {
    flexDirection: 'row',
    paddingVertical: 0,
    paddingLeft: 35,
    backgroundColor: '#7676801F',
    alignItems: 'center',
    width: '100%',
    borderRadius: 10
  },
  cancelText: {
    marginLeft: moderateScale(6)
  },
  storieWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20
  }
})
