import { NotificationsActionsTypes, NotificationState } from './types'

export const setNotifications = (pushNotifications: NotificationState['pushNotifications']) => ({
  type: NotificationsActionsTypes.SET_NOTIFICATIONS,
  payload: pushNotifications
})

export const setNotificationSingular = (id: number) => ({
  type: NotificationsActionsTypes.SET_NOTIFICATION,
  payload: id
})

export const removeNotification = (id: number) => ({
  type: NotificationsActionsTypes.REMOVE,
  payload: id
})

export const removeNotifications = () => ({
  type: NotificationsActionsTypes.REMOVE_ALL
})

export const markAsViewNotification = (id: number) => ({
  type: NotificationsActionsTypes.MARK_AS_VIEWED,
  payload: id
})

export const setError = (error: Error) => ({
  type: NotificationsActionsTypes.ERROR,
  payload: error
})
