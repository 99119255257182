import styled from 'styled-components'
import {
  PinInput as PinInputComponent,
  PinInputField as PinInputFieldComponent,
  chakra
} from '@chakra-ui/react'
import { purple, graySoft } from '@qirapagos/web/src/theme/colors'

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const PinInput = chakra(PinInputComponent, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row'
  }
})

export const PinInputField = chakra(PinInputFieldComponent, {
  baseStyle: {
    margin: 2,
    color: purple,
    background: graySoft,
    borderColor: 'transparent',
    _focus: {
      borderColor: purple,
      boxShadow: 'none'
    }
  }
})
