import React, { ReactNode } from 'react'
import { Text } from '@chakra-ui/react'
import { black } from '@qirapagos/lib/theme/colors'
import { ViewContainer } from './style'
import { View } from 'react-native'

interface Props {
  title: string
  icon: ReactNode
  onPress: () => void
}

const BoxNewAction: React.FC<Props> = ({ title, icon, onPress }) => {
  return (
    <ViewContainer onPress={onPress}>
      <Text
        color={black}
        fontSize={{ sm: '18px', md: '20px', lg: '24px' }}
        fontWeight={500}
      >
        {title}
      </Text>
      <View>{icon}</View>
    </ViewContainer>
  )
}

export default BoxNewAction
