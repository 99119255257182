import { Colors } from 'theme'
import { ReactComponent as CircleMarkIcon } from 'assets/svg/CircleMark.svg'
import styled from 'styled-components'

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
`

export const CircleMark = styled(CircleMarkIcon)<{ isActive: boolean }>`
  margin-right: 5px;
  circle {
    fill: ${({ isActive }) => isActive ? Colors.purple : Colors.grayLight};
  }
`
