import React, { useEffect, useState } from 'react'
import Helmet from 'components/utilities/Helmet'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AuthLayout } from 'components'
import { forgotPasswordValidate } from 'store/auth/thunks'
import { PageBody, Spinner, QiraPagos } from './styles'
import RenderIf from 'components/utilities/RenderIf'
import { TokenExpired, ChangePassword, Success } from 'screens'

const Steps = {
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  RECOVER_PASSWORD: 'RECOVER_PASSWORD',
  SUCCESS: 'SUCCESS'
}

const PasswordRecover: React.FC = () => {
  const [step, setStep] = useState(Steps.TOKEN_EXPIRED)
  const { isRecoverPasswordLoading, recoverPasswordError } = useSelector((state: any) => state.auth)
  const { token } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const verifyToken = async () => {
      dispatch(forgotPasswordValidate(token as string))
      setStep(Steps.RECOVER_PASSWORD)
    }
    // Since this endpoint needs a keyEndpoints value, it should be executed 5 seconds later to be sure
    // that the application already created a valid keyEndpoints. It is an async issue.
    setTimeout(verifyToken, 5000)
  }, [token, dispatch])

  useEffect(() => {
    if (recoverPasswordError) {
      setStep(Steps.TOKEN_EXPIRED)
    }
  }, [recoverPasswordError])

  const onPasswordRecover = () => {
    setStep(Steps.SUCCESS)
  }

  return (
    <AuthLayout>
      <Helmet title="Restablecer contraseña" />
      <QiraPagos />
      <PageBody>
        {isRecoverPasswordLoading
          ? <Spinner />
          : (
            <>
              <RenderIf isTrue={step === Steps.TOKEN_EXPIRED}>
                <TokenExpired />
              </RenderIf>
              <RenderIf isTrue={step === Steps.RECOVER_PASSWORD}>
                <ChangePassword next={onPasswordRecover} />
              </RenderIf>
              <RenderIf isTrue={step === Steps.SUCCESS}>
                <Success />
              </RenderIf>
            </>
            )}
      </PageBody>
    </AuthLayout>
  )
}

export default PasswordRecover
