import styled from 'styled-components'
import { grayTextFilter, gray, purple } from '@qirapagos/lib/theme/colors'

export const ViewIcon = styled.div`
  margin-left: 15px;
  width: 20%;
`

export const ViewContainer = styled.div<any>`
  display: flex;
  flex-direction: 'row';
  padding-top: 10px;
  padding-bottom: 10px;
  width: ${props => (props.canBeHovered ? '' : '90%')};
  color: ${props => (props.canBeHovered ? '' : grayTextFilter)};
  &:hover {
    color: ${props => (props.canBeHovered ? purple : grayTextFilter)};
  }
`

export const TextTitle = styled.div`
  width: 80%;
  font-size: 1rem;
  color: ${gray};
  font-weight: 500;
`
export const ViewItemSelectorContainer = styled.div`
  display: flex;
  width: 210px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const TextMulti = styled.p`
  padding-left: 5px;
  font-size: 13px;
  color: ${gray};
  font-weight: 500;
`
export const DropDownView = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 35;
`
