import { css } from '@emotion/core'
import styled from 'styled-components'
import { DEVICES } from './devicesSizes'

export const GlobalStyled = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`
/*
  This style is used to align Logo on auth screen flow
*/

export const ViewLogoContainer = styled.div`
  display: none;
  @media ${DEVICES.tablet} {
    display: block;
    position: absolute;
    top: 10%;
  }
  @media ${DEVICES.mobile} {
    top: 3%;
  }
`
