export interface SignatureDocument {
  id: string
  documentTitle: string
  signingProcessStatusId: number
  companyName: string
  cuit: string
  processStartDate: string
  url: string
  link: string
}

export interface SignatureResponse {
  data: SignatureDocument[]
  message: string
  error?: boolean
}

export interface SignatureState {
  documents: SignatureDocument[]
  isLoading: boolean
  error: boolean
  documentLink: string
  isDownloadingFile: number
}

export enum SignatureActionTypes {
  GET_DOCUMENTS = '@SIGNATURE:GET_DOCUMENTS',
  GET_DOCUMENTS_PENDING = '@SIGNATURE:GET_DOCUMENTS_PENDING',
  GET_DOCUMENTS_FULFILLED = '@SIGNATURE:GET_DOCUMENTS_FULFILLED',
  GET_DOCUMENTS_REJECTED = '@SIGNATURE:GET_DOCUMENTS_REJECTED',
  SET_DOCUMENT_LINK = '@SIGNATURE:SET_DOCUMENT_LINK',
  DOWNLOAD_DOCUMENT_PENDING = '@SIGNATURE:DOWNLOAD_DOCUMENT_PENDING',
  DOWNLOAD_DOCUMENT_FULFILLED = '@SIGNATURE:DOWNLOAD_DOCUMENT_FULFILLED',
  DOWNLOAD_DOCUMENT_REJECTED = '@SIGNATURE:DOWNLOAD_DOCUMENT_REJECTED',
}

export enum ISignatureStatustypes {
  SIGNED = 30,
  PENDING_SIGNATURE = 20
}
