export interface Enterprise {
  name: string
  isBusiness: boolean
  registered: boolean
}

export interface EnterpriseState {
  enterprise: Enterprise | undefined,
  isLoading: boolean,
  error: string | undefined,
}

export interface AssociateCompany {
  cuit: string;
  name: string;
  loginId: number;
  email: string;
}

export enum EnterpriseActionsTypes {
  GET_ENTERPRISE_PENDING = '@ENTERPRISE:GET_ENTERPRISE_PENDING',
  GET_ENTERPRISE_FULFILLED = '@ENTERPRISE:GET_ENTERPRISE_FULFILLED',
  GET_ENTERPRISE_REJECTED = '@ENTERPRISE:GET_ENTERPRISE_REJECTED',
  GET_SEND_EMAIL_DOCUMENTS_PENDING = '@ENTERPRISE:GET_SEND_EMAIL_DOCUMENTS_PENDING',
  GET_SEND_EMAIL_DOCUMENTS_FULFILLED = '@ENTERPRISE:GET_SEND_EMAIL_DOCUMENTS_FULFILLED',
  GET_SEND_EMAIL_DOCUMENTS_REJECTED = '@ENTERPRISE:GET_SEND_EMAIL_DOCUMENTS_REJECTED',
  ASSOCIATE_COMPANY_PENDING = '@ENTERPRISE:ASSOCIATE_COMPANY_PENDING',
  ASSOCIATE_COMPANY_FULFILLED = '@ENTERPRISE:ASSOCIATE_COMPANY_FULFILLED',
  ASSOCIATE_COMPANY_REJECTED = '@ENTERPRISE:ASSOCIATE_COMPANY_REJECTED',
  UPLOAD_DOCUMENTS_PENDING = '@ENTERPRISE:UPLOAD_DOCUMENTS_PENDING',
  UPLOAD_DOCUMENTS_FULFILLED = '@ENTERPRISE:UPLOAD_DOCUMENTS_FULFILLED',
  UPLOAD_DOCUMENTS_REJECTED = '@ENTERPRISE:UPLOAD_DOCUMENTS_REJECTED',
  CLEAR_ENTERPRISE_STATE = '@ENTERPRISE:CLEAR_ENTERPRISE_STATE'
}
