import { white } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components/native'

export const ViewContainer = styled.View`
  width: 85%;
  margin: 20px 40px;
  padding: 35px 20px;
  background-color: ${white};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
`

export const ViewTitle = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
`

export const TextTitle = styled.Text`
  font-size: 1.2rem;
`

export const ViewChildren = styled.View`
  margin-bottom: 30px;
`
