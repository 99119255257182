export const SHOW_MODAL = '@MODALS:SHOW_MODAL'
export const SHOW_DRAWER_STATEMENT = '@MODALS:SHOW_DRAWER_STATEMENT'
export const SHOW_DRAWER_SUCCESS = '@MODALS:SHOW_DRAWER_SUCCESS'
export const SHOW_DRAWER_ERROR = '@MODALS:SHOW_DRAWER_ERROR'
export const SHOW_DRAWER_MY_AGENT = '@MODALS:SHOW_DRAWER_MY_AGENT'
export const SHOW_DRAWER_NOTIFICATION = '@MODALS:SHOW_DRAWER_NOTIFICATION'
export const SHOW_HELP_DRAWER = '@MODALS:SHOW_HELP_DRAWER'
export const SHOW_DRAWER_GRAINS = '@MODALS:SHOW_DRAWER_GRAINS'
export const LOGOUT = '@MODALS:LOGOUT'
export const SHOW_DRAWER_NOTIFICATION_DETAIL =
  '@MODALS:SHOW_DRAWER_NOTIFICATION_DETAIL'
export interface ModalState {
  showModal:
    | 'account'
    | 'options'
    | 'help'
    | 'howWorks'
    | 'helpLastCanje'
    | 'selectorEnterprise'
    | 'filter'
    | 'createdBy'
    | 'grain'
    | 'harvest'
    | 'currency'
    | 'condition'
    | 'fromDate'
    | 'toDate'
    | 'zone'
    | 'origin'
    | 'deliveryPeriod'
    | 'helpContrato'
    | 'helpProduct'
    | 'filter'
    | 'howWorksApplication'
    | 'deleteAgent'
    | 'closeSession'
    | 'info'
    | 'deleteContact'
    | 'WEBinvalidUser'
    | null
}

export enum ModalActionTypes {
  SHOW_MODAL = '@MODALS:SHOW_MODAL'
}
interface IMovementType {
  id: number
  typeDescription: string
}
export interface IDrawerInfo {
  debitAccount: string
  creditAccount: string
  alias: string
  date: string
  amount: number | string
  cbu: string
  cuit: string
  id: number
  owner: string
  movementType: IMovementType
  description: string
  destination: string
  destino: string
  origenOper: string
  source: string
  status: string
  timestamp: string
  zoneId: number
  zoneName: string
  deliveryPeriod: string
  operationName: string
  createdBy: string
  grainName: string
  weight: string
  harvestDate: string
  currency: any
  price: string | number
  location: string
  province: string
  idLocation: number
  shippingFrom: any
  expiration: any
  title: string
  config: string
  grain: number
  hervesDate: string
  origin: string
  product: any
  condition: number
  currencyProduct: number
  customerId: number
  priceFixingStart: string
  priceFixingEnd: string
  saleOrder: any
}
