import React from 'react'
import QiraPagosLogo from '@qirapagos/web/src/assets/svg/QirapagosLogo'
import { Content, Container, LogoContainer, Text, Maintenance } from './styles'
import { violet } from '@qirapagos/lib/theme/colors'

interface Props {
  isOpen: boolean
}

const PauseModal: React.FC<Props> = ({ isOpen }) => {
  if (!isOpen) {
    return null
  }
  return (
    <Container>
      <Content>
        <LogoContainer>
          <QiraPagosLogo color={violet} />
        </LogoContainer>
        <Text>
          La aplicación se encuentra en mantenimiento,
        </Text>
        <Text>
          restableceremos el servicio a la brevedad.
        </Text>
        <Maintenance />
      </Content>
    </Container>
  )
}

export default PauseModal
