import React from 'react'
import { FormikProps } from 'formik'

interface Props {
  name: string
  formik: FormikProps<any>
  fileType: string
  style: React.CSSProperties
}

const FileField: React.FC<Props> = ({ formik, name, fileType, style }) => {
  return (
    <input
      id={name}
      multiple
      type="file"
      name={name}
      accept={fileType}
      onChange={(event) => {
        const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : []
        const currentFiles = formik.values[name] || []
        const updatedFiles = [...currentFiles, ...newFiles]
        formik.setFieldValue(name, updatedFiles)
        event.currentTarget.value = ''
      }}
      style={style}
    />
  )
}

export default FileField
