import { useEffect, useState } from 'react'
import { getToken } from 'services/utils'
import * as ServerServices from 'services/ServerServices'
import { ApiResponse } from 'apisauce'
import { getLocalTimeOfDate } from 'utils/common'

const useServerTime = () => {
  // TODO: We need to improve this component so we dont need to start the state after 9AM.
  const [serverTimeHours, setServerTimeHours] = useState<number>(11)
  const [isOutOfTimeRange, setIsOutOfTimeRange] = useState<boolean>()
  const getAsynchronousServerHour = async () => {
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await ServerServices.getServerTime(
      token,
      keyEndpoints as string
    )
    const serverDate = response.data.dateTime
    const serverTime = getLocalTimeOfDate(serverDate)
    const { timeAsObject } = getLocalTimeOfDate(serverDate)

    setServerTimeHours(serverTime.timeAsObject.hours)

    const isMakeContractsTimeEnable =
      serverTimeHours < 9 ||
      serverTimeHours >= 18 ||
      timeAsObject.day === 0 ||
      timeAsObject.day === 6
    setServerTimeHours(serverTime.timeAsObject.hours)
    if (isMakeContractsTimeEnable) {
      return setIsOutOfTimeRange(true)
    }
    setIsOutOfTimeRange(false)
  }

  useEffect(() => {
    getAsynchronousServerHour()
  }, [isOutOfTimeRange])

  return { isOutOfTimeRange }
}

export default useServerTime
