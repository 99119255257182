import { AuthActionTypes } from './types'

export const updateDeviceIdPending = () => ({
  type: AuthActionTypes.UPDATE_DEVICE_ID_PENDING
})

export const updateDeviceIdFulfilled = (deviceId: string) => ({
  type: AuthActionTypes.UPDATE_DEVICE_ID_FULFILLED,
  payload: deviceId
})

export const updateDeviceIdRejected = (error: Error) => ({
  type: AuthActionTypes.UPDATE_DEVICE_ID_REJECTED,
  payload: error
})
