import { ActivityActionTypes, ActivityMovement } from './types'

export const getActivityPending = () => ({
  type: ActivityActionTypes.GET_ACTIVITY_PENDING
})

export const getActivityFulfilled = (activity: ActivityMovement[]) => ({
  type: ActivityActionTypes.GET_ACTIVITY_FULFILLED,
  payload: activity
})

export const getActivityRejected = (error: Error) => ({
  type: ActivityActionTypes.GET_ACTIVITY_REJECTED,
  payload: error
})
