import CustomText from 'components/atoms/CustomText'
import Status from 'components/atoms/Status'
import { getStatusType } from 'utils/common'
import { AvatarOutline } from 'components'
import React from 'react'
import { BoxContainer, BoxName, styles } from './styles'
import { StatusType } from 'components/atoms/Status/constants'

interface Props {
  initials: string
  enterpriseName: string
  description: string
  status: StatusType
}

const EnterpriseCard: React.FC<Props> = ({
  initials,
  enterpriseName,
  description,
  status
}) => {
  const statusType = getStatusType(status)
  return (
    <BoxContainer>
      <BoxName>
        <AvatarOutline initials={initials} />
        <CustomText
          size="xmedium"
          weight="regular"
          align="left"
          customStyle={styles.customName}
        >
          {enterpriseName}
        </CustomText>
      </BoxName>
      <CustomText
        size="xmedium"
        weight="mediumfont"
        customStyle={styles.customText}
      >
        {description}
      </CustomText>
      <Status
        statusType={statusType}
        customStyle={{
          marginBottom: '15%',
          width: '100px',
          alignSelf: 'center'
        }}
      />
    </BoxContainer>
  )
}

export default EnterpriseCard
