import {
  Flex,
  Spinner as SpinnerComponent
} from '@chakra-ui/react'
import { purple } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import { ReactComponent as QirapagosLogo } from 'assets/svg/QiraPagosLogoRow.svg'
import { DEVICES } from 'theme/devicesSizes'

export const PageBody = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const QiraPagos = styled(QirapagosLogo)`
  display: none;
  @media ${DEVICES.tablet} {
    min-width: 144px !important;
    min-height: 51px !important;
    width: 144px !important;
    height: 51px !important;
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

export const Spinner = styled(SpinnerComponent)`
  width: 50px !important;
  height: 50px !important;
  color: ${purple};
`
