import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function Users () {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        d="M7.92 14.9a4.68 4.68 0 100-9.36 4.68 4.68 0 000 9.36z"
        stroke="#F5F6FA"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <Path
        // eslint-disable-next-line max-len
        d="M13.987 5.714a4.68 4.68 0 111.27 9.186M1.44 18.266a7.921 7.921 0 0112.96 0M15.257 14.9a7.91 7.91 0 016.48 3.366"
        stroke="#F5F6FA"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Users
