import { Action } from '../../interfaces/reduxInterfaces'
import initialState from './initialState'
import { ContractActionTypes, ContractState } from './types'

export default function contractReducer (
  state = initialState,
  action: Action
): ContractState {
  switch (action.type) {
    case ContractActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case ContractActionTypes.DISMISS_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case ContractActionTypes.SET_LOADING_DETAIL:
      return {
        ...state,
        isLoadingDetail: true
      }
    case ContractActionTypes.DISMISS_LOADING_DETAIL:
      return {
        ...state,
        isLoadingDetail: false
      }
    case ContractActionTypes.SET_LOADING_OPERATION_DETAIL:
      return {
        ...state,
        isLoadingOperationDetail: true
      }
    case ContractActionTypes.DISMISS_LOADING_OPERATION_DETAIL:
      return {
        ...state,
        isLoadingOperationDetail: false
      }
    case ContractActionTypes.GET_GRAIN_TYPE:
      return {
        ...state,
        graintype: action.payload
      }
    case ContractActionTypes.GET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload
      }
    case ContractActionTypes.GET_CONTRACTS:
      return {
        ...state,
        contracts: action.payload
      }
    case ContractActionTypes.GET_OPERATION_DETAIL:
      return {
        ...state,
        operationdetail: action.payload
      }
    case ContractActionTypes.GET_QUOTA_DETAIL:
      return {
        ...state,
        quotadetail: action.payload
      }
    case ContractActionTypes.GET_EXCHANGERS:
      return {
        ...state,
        exchangers: action.payload
      }
    case ContractActionTypes.CLEAR_SALE_INFO: {
      return {
        ...state,
        sale: initialState.sale
      }
    }
    case ContractActionTypes.GET_CONDITION_TYPE:
      return {
        ...state,
        conditiontype: action.payload
      }
    case ContractActionTypes.SET_FIXINGS:
      return {
        ...state,
        fixings: action.payload
      }
    case ContractActionTypes.CLEAR_TRADE_PRICE_TO_SET: {
      return {
        ...state,
        tradepricetoset: initialState.tradepricetoset
      }
    }
    case ContractActionTypes.GET_ZONES:
      return {
        ...state,
        zones: action.payload
      }
    case ContractActionTypes.SET_HARVEST_DATE: {
      return {
        ...state,
        harvest: action.payload
      }
    }
    default:
      return state
  }
}
