import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function SvgComponent (props: any) {
  return (
    <Svg
      viewBox="0 0 16 9"
      fill="none"
      width={20}
      height={20}
    >
      <Path
        d="M14.4 1.5L8.15 7.75 1.9 1.5"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SvgComponent
