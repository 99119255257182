export const defaultDataTransfers: any[] = [
  {
    id: '1',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '2',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  },
  {
    id: '3',
    'movementType.typeDescription': 'transferSend',
    date: '12 Ene 2021',
    amount: '$1.000.000',
    transferTo: 'Nombre Apellido',
    concept: 'Varios',
    progress: 50
  }
]

export const configTransfers = [
  { id: 'movementType', header: 'Actividad' },
  { id: 'date', header: 'Fecha' },
  { id: 'amount', header: 'Monto' },
  { id: 'concept', header: 'Concepto' },
  { id: 'actions', header: '' }
]
