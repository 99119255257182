import React, { ReactNode } from 'react'
import styles, { CursorPointer } from './styles'
import CustomText from '../CustomText'

interface Props {
  onPress?: () => void
  children: ReactNode
  customStyle?: any
  id?: string
  disabled?: boolean
  isBig?: boolean
  secondary?: boolean
  marginBottom?: number
}

const ButtonLine: React.FC<Props> = ({
  onPress,
  children,
  customStyle,
  id,
  disabled,
  isBig,
  secondary,
  marginBottom
}) => {
  return (
    <button
      onClick={onPress}
      type="button"
      id={id}
      disabled={disabled}
      style={{ ...customStyle, marginBottom, padding: 0 }}
    >
      <CursorPointer>
        <CustomText
          weight="mediumfont"
          customStyle={{ ...(disabled && styles.disabled), ...customStyle }}
          size={isBig ? 'xmedium' : 'medium'}
          color={secondary ? 'white' : 'purple'}
          underlined
        >
          {children}
        </CustomText>
      </CursorPointer>
    </button>
  )
}

export default ButtonLine

ButtonLine.defaultProps = {
  onPress: () => ({}),
  customStyle: {},
  id: '',
  disabled: false,
  isBig: true,
  secondary: false,
  marginBottom: 0
}
