import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  searchInput: {
    width: '80%',
    borderBottomColor: '#4657CE',
    borderBottomWidth: 1,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 21,
    alignSelf: 'center'
  },
  error: {
    paddingTop: 4,
    paddingLeft: 16
  }
})
