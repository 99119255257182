export const MANROPE = 'Poppins'

export const NORMAL = 'Normal'

export const SIZES = {
  XSMALL: 10,
  SMALL: 12,
  MEDIUM: 14,
  XMEDIUM: 16,
  BIG: 18,
  MMBIG: 20,
  MBIG: 22,
  XBIG: 24,
  LARGE: 26,
  XLARGE: 35,
  XXLARGE: 52
}

export const POPPINS_REG = 'Poppins-Regular'
export const POPPINS_MED = 'Poppins-Medium'
export const POPPINS_SEMI = 'Poppins-SemiBold'
