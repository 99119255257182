import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'

export const ViewStageContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  gap: 15px;
`

export default StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'center'
  }
})
