import { useCallback } from 'react'
import { initDevice } from '@qirapagos/lib/store/device/thunks'
import {
  setBusinessName,
  setIsBusiness
} from '@qirapagos/lib/store/auth/actions'
import { useDispatch } from 'react-redux'

const useInitApp = () => {
  const dispatch = useDispatch()
  const checkDevice = useCallback(async () => {
    try {
      await localStorage.removeItem('device')
      await localStorage.removeItem('deviceToken')
      dispatch(initDevice())
    } catch (error) {}
  }, [dispatch])

  const setBusinessInfo = useCallback(async () => {
    try {
      const isBusiness = await localStorage.getItem('isBusiness')
      const businessName = await localStorage.getItem('businessName')
      const isBusinessBoolean = isBusiness === 'true'
      dispatch(setBusinessName(businessName || ''))
      dispatch(setIsBusiness(isBusinessBoolean || false))
    } catch (error) {}
  }, [dispatch])

  return { checkDevice, setBusinessInfo }
}

export default useInitApp
