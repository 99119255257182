import StepContract from 'components/atoms/StepContract'
import React from 'react'
import { View } from 'react-native'
import { ViewContainer, ViewMapContainer } from './style'

interface Props {
  titlesInfo: any
  currentStepBox: number
}

const StepperContract: React.FC<Props> = ({ titlesInfo, currentStepBox }) => {
  const WIDTH_CONTAINER = 100 / titlesInfo.length - 1
  return (
    <ViewContainer>
      <ViewMapContainer>
        {titlesInfo.map((item: any, index: number) => {
          return (
            <View
              style={{
                width: `${WIDTH_CONTAINER}%`
              }}
            >
              <StepContract
                item={item}
                currentStepBox={currentStepBox}
                index={index}
              />
            </View>
          )
        })}
      </ViewMapContainer>
    </ViewContainer>
  )
}

export default StepperContract
