import { AccountActionTypes, AccountSummary } from './types'

export const getAccountSummariesPending = () => ({
  type: AccountActionTypes.GET_ACCOUNT_SUMMARIES_PENDING
})

export const getAccountSummariesFulfilled = (accountSummaries: AccountSummary[]) => ({
  type: AccountActionTypes.GET_ACCOUNT_SUMMARIES_FULFILLED,
  payload: accountSummaries
})

export const getAccountSummariesRejected = (error: Error) => ({
  type: AccountActionTypes.GET_ACCOUNT_SUMMARIES_REJECTED,
  payload: error
})
