import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Box,
  Text
} from '@chakra-ui/react'
import { graySoft, violet } from '@qirapagos/lib/theme/colors'
import { useTable } from 'hooks/useTable'
import CustomFilter from './CustomFilter'

interface Props {
    filterOptions?: string[],
    onFilter?: any,
    shouldOpenDrawer?: boolean,
}

// TODO: refactor CustomText to a web version

const FilterTable: React.FC<Props> = ({ filterOptions, onFilter, shouldOpenDrawer }) => {
  const { handleDrawer, selectedDateFilter, selectedFilter } = useTable()
  return (
    <Box>
      {shouldOpenDrawer
        ? (
          <button type="button" onClick={() => handleDrawer()}>
            <CustomFilter title={selectedDateFilter} />
          </button>
          )
        : (
          <Menu id='testId'>
            <MenuButton>
              <CustomFilter title={selectedFilter} />
            </MenuButton>
            <MenuList textAlign="end" p={0}>
              {filterOptions?.map(option => (
                <>
                  <MenuItem
                    key={option}
                    onClick={() => onFilter(option)}
                    h="40px"
                    alignSelf="end"
                    justifySelf="end"
                    _focus={{ color: violet, bg: graySoft }}
                    id={`id-${option}`}
                  >
                    <Box w="100%" textAlign="end">
                      <Text
                        w="100%"
                        fontFamily="Poppins"
                        fontSize="15px"
                        fontWeight={500}
                      >
                        {option}
                      </Text>
                    </Box>
                  </MenuItem>
                  {option !== filterOptions[filterOptions.length - 1] && (
                  <MenuDivider m={0} />
                  )}
                </>
              ))}
            </MenuList>
          </Menu>
          )}
    </Box>
  )
}

export default FilterTable

FilterTable.defaultProps = {
  filterOptions: [],
  onFilter: () => null,
  shouldOpenDrawer: false
}
