import React, { useState } from 'react'
import { FlatList, ListRenderItemInfo } from 'react-native'
import ActionCard from '../ActionCard'
import useNavigation from 'hooks/useNavigation'
import { gray } from '@qirapagos/lib/theme/colors'
import { Text } from '@chakra-ui/react'
import {
  Container,
  BoxDirectAccess,
  RenderView,
  ButtonContainer,
  ModalDescription,
  ModalButton
} from './styles'
import { useScreenSizes } from 'hooks/useScreenSizes'
import useHasSisa from '@qirapagos/lib/hooks/useHasSisa'
import { WHATSAPP_LINK } from '@qirapagos/lib/constants/common'
import { useAppSelector } from 'hooks/redux'
import { CustomModal } from 'components'
import useHasOnboardingComplete, { StatusOnboardingLabel } from '@qirapagos/lib/hooks/useHasOnboardingComplete'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface IDataActionCards {
  icon: 'grains' | 'newTransfer' | 'helpCenter' | 'extranetLink'
  title: string
  onPress: () => void
  isDisabled?: boolean
}

const renderItem = ({ item }: ListRenderItemInfo<IDataActionCards>) => {
  const { icon, title, onPress, isDisabled = false } = item
  return (
    <RenderView>
      <ActionCard
        icon={icon}
        title={title}
        onPress={onPress}
        width="11.78vw"
        height="6.33vh"
        flexDirection="row"
        marginRight="20px"
        isDisabled={isDisabled}
      />
    </RenderView>
  )
}

const DirectAccess: React.FC = () => {
  const { onNavigate } = useNavigation()
  const { multiEnterprise } = useAppSelector(state => state.auth)
  const [isOpen, setIsOpen] = useState(false)
  const hasOnboardingComplete = useHasOnboardingComplete()

  const { isTabletDisplayOrLower } = useScreenSizes()
  const hasSisa = useHasSisa()
  const { isRepresentative, represented } = useSelector(
    (state: RootState) => state.representation
  )
  const notSisaNotRepresented = !hasSisa && !represented?.length
  const representativeNotSisaNotRepresented = isRepresentative && notSisaNotRepresented
  const representedNotSisa = !isRepresentative && !hasSisa

  const dataActionCards: IDataActionCards[] = [
    {
      icon: 'grains',
      isDisabled: representativeNotSisaNotRepresented ||
      representedNotSisa,
      title: 'Nueva operación',
      onPress: () =>
        onNavigate('/content/grains/', {
          title: 'Granos',
          hasBackArrow: true
        })
    },
    {
      icon: 'newTransfer',
      isDisabled: hasOnboardingComplete === StatusOnboardingLabel.PENDING,
      title: 'Nueva transferencia',
      onPress: () =>
        onNavigate('/content/your-account/new-transfer', {
          title: 'Nueva transferencia',
          hasBackArrow: true
        })
    },
    {
      icon: 'helpCenter',
      title: 'Centro de ayuda',
      onPress: () => window.open(WHATSAPP_LINK, '_blank')
    },
    {
      icon: 'extranetLink',
      title: 'Clientes ALZ AGRO',
      onPress: () => {
        setIsOpen(true)
      },
      isDisabled: multiEnterprise[0]?.url === null
    }
  ]

  return (
    <Container>
      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Saliendo de Qira"
      >
        <ModalDescription>
          <Text>Serás redireccionado a la sección</Text>
          <Text>de Clientes de ALZ AGRO</Text>
        </ModalDescription>
        <ButtonContainer>
          <ModalButton
            onPress={() => {
              setIsOpen(false)
              window.open(multiEnterprise[0]?.url, '_blank')
            }}
          >
            Continuar
          </ModalButton>
          <ModalButton secondary onPress={() => setIsOpen(false)}>
            Cancelar
          </ModalButton>
        </ButtonContainer>
      </CustomModal>
      <BoxDirectAccess>
        <Text
          textAlign={isTabletDisplayOrLower ? 'center' : 'left'}
          color={gray}
          fontSize={{ sm: '12px', md: '18px', lg: '20px' }}
          mb={{ sm: 2, md: 2, lg: 3 }}
          mt={4}
          ml={isTabletDisplayOrLower ? 0 : 6}
          fontWeight={500}
        >
          Accesos directos
        </Text>
        <FlatList
          data={dataActionCards}
          renderItem={renderItem}
          contentContainerStyle={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        />
      </BoxDirectAccess>
    </Container>
  )
}

export default DirectAccess
