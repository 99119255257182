import React from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch } from 'react-redux'
import { setShowDrawerMyAgent } from 'store/modals/actions'
import BackArrow from '@qirapagos/web/src/assets/svg/BackArrow'
import { CustomList, AvatarOutline } from 'components'
import { getInitials } from '@qirapagos/lib/utils/common'

import {
  Title,
  ButtonContainer,
  customListStyles,
  getDrawerStyles,
  ConfirmButton,
  Header,
  DrawerContainer,
  BackButton,
  AgentInfoContainer,
  AvatarContainer
} from './styles'
import { useAppSelector } from 'hooks/redux'
import { setRepresentative } from 'store/representation/thunks'
import { clearRepresentativeCompanyDataRejected } from 'store/representation/actions'

const DrawerModal: React.FC = () => {
  const { dataRepresentativeProvisory } = useAppSelector((state) => state.representation)
  const { showDrawerMyAgent } = useAppSelector((state) => state.modal)

  const dispatch = useDispatch()
  const onClose = () => {
    dispatch(clearRepresentativeCompanyDataRejected())
    dispatch(setShowDrawerMyAgent(false))
  }

  const AGENT_INFO = [
    { NOMBRE: dataRepresentativeProvisory?.companyName },
    { ALIAS: dataRepresentativeProvisory?.alias },
    { CVU: dataRepresentativeProvisory?.cvu },
    { CUIT: dataRepresentativeProvisory?.cuit },
    { BANCO: dataRepresentativeProvisory?.bank }
  ]

  const onPressConfirm = () => {
    dispatch(setRepresentative(dataRepresentativeProvisory?.companyId || 0))
    onClose()
  }

  return (
    <Drawer
      open={showDrawerMyAgent}
      onClose={onClose}
      direction="right"
      style={getDrawerStyles()}
    >
      <DrawerContainer>
        <Header>
          <BackButton type='button' onClick={onClose}>
            <BackArrow />
          </BackButton>
          <Title>Datos Representante</Title>
        </Header>
        <AvatarContainer>
          <AvatarOutline isBig initials={getInitials(dataRepresentativeProvisory?.companyName || '')} />
        </AvatarContainer>
        <AgentInfoContainer>
          <CustomList
            data={AGENT_INFO}
            positioning="vertical"
            customStyle={customListStyles}
          />
        </AgentInfoContainer>
        <ButtonContainer>
          <ConfirmButton onPress={onPressConfirm}>
            Aceptar
          </ConfirmButton>
        </ButtonContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default DrawerModal
