import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { logOut } from 'store/auth/thunks'
import useNavigation from 'hooks/useNavigation'
import { useEffect } from 'react'

export const useTimeOutLogout = () => {
  const { onNavigate } = useNavigation()
  const dispatch = useDispatch()
  const onIdle = () => {
    dispatch(logOut(onNavigate))
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: Number(process.env.REACT_APP_TIMEOUT_AUTODISCONNECT),
    throttle: 500,
    events: [
      'keydown',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus',
      'scroll'
    ]
  })

  useEffect(() => {
    const interval = setInterval(() => {
      Math.ceil(getRemainingTime() / 1000)
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime])
}
