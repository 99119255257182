import styled from 'styled-components'
import { StyleSheet } from 'react-native'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height: 100%;
  background-color: #fff;
`

export const ViewInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  width: 50%;
`

export const TextTitleText = styled.p`
  font-size: 2.7rem;
  font-weight: 900;
  margin-top: 20px;
  line-height: 55;
`

export const ViewInfoDescription = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  width: 75%;
`

export const TextBodyText = styled.p`
  font-size: 1.1rem;
  margin-bottom: 20px;
`

export const ViewStores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ViewImageContainer = styled.div`
  width: 50%;
  @media ${DEVICES.tablet} {
    display: none;
  }
`

export const styles = StyleSheet.create({
  loginButton: {
    alignSelf: 'flex-start',
    marginBottom: 75
  },
  imageBackground: {
    width: '100%',
    height: '100vh'
  },
  image: {
    height: '100vh',
    zIndex: 100
  },
  imageLogin: {
    height: '100vh'
  }
})
