import { EnterpriseActionsTypes, Enterprise } from './types'

export const getEnterprisePending = () => ({
  type: EnterpriseActionsTypes.GET_ENTERPRISE_PENDING
})

export const getEnterpriseFulfilled = (enterprise: Enterprise) => ({
  type: EnterpriseActionsTypes.GET_ENTERPRISE_FULFILLED,
  payload: enterprise
})

export const getEnterpriseRejected = (error: Error) => ({
  type: EnterpriseActionsTypes.GET_ENTERPRISE_REJECTED,
  payload: error.message
})

export const getSendEmailDocumentsPending = () => ({
  type: EnterpriseActionsTypes.GET_SEND_EMAIL_DOCUMENTS_PENDING
})

export const getSendEmailDocumentsFulfilled = () => ({
  type: EnterpriseActionsTypes.GET_SEND_EMAIL_DOCUMENTS_FULFILLED
})

export const getSendEmailDocumentsRejected = (error: Error) => ({
  type: EnterpriseActionsTypes.GET_SEND_EMAIL_DOCUMENTS_REJECTED,
  payload: error.message
})

export const associateCompanyPending = () => ({
  type: EnterpriseActionsTypes.ASSOCIATE_COMPANY_PENDING
})

export const associateCompanyFulfilled = () => ({
  type: EnterpriseActionsTypes.ASSOCIATE_COMPANY_FULFILLED
})

export const associateCompanyRejected = (error: Error) => ({
  type: EnterpriseActionsTypes.ASSOCIATE_COMPANY_REJECTED,
  payload: error.message
})

export const uploadDocumentsPending = () => ({
  type: EnterpriseActionsTypes.UPLOAD_DOCUMENTS_PENDING
})

export const uploadDocumentsFulfilled = () => ({
  type: EnterpriseActionsTypes.UPLOAD_DOCUMENTS_FULFILLED
})

export const uploadDocumentsRejected = (error: Error) => ({
  type: EnterpriseActionsTypes.UPLOAD_DOCUMENTS_REJECTED,
  payload: error.message
})

export const clearEnterpriseState = () => ({
  type: EnterpriseActionsTypes.CLEAR_ENTERPRISE_STATE
})
