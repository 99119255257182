import { grayLast, purple, purpleDisabled } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'

type TColorBar = {
  isComplete: boolean
  currentStepBox: number
  index: number
}

export const ViewBar = styled.div<TColorBar>`
  height: 15px;
  border-radius: 4px;
  transition: background-color 0.5s ease-in-out;
  ${({ isComplete, currentStepBox, index }) =>
    `
    background-color: ${
      isComplete ? purple : currentStepBox === index ? purpleDisabled : grayLast
    };
    border:  ${
      isComplete ? purple : currentStepBox === index ? purpleDisabled : grayLast
    };
    `}
`
