import React from 'react'
import { Helmet as HelmetReact } from 'react-helmet'

interface Props {
    title: string
}

const Helmet: React.FC<Props> = ({ title }) => {
  return (
    <HelmetReact>
      <title>{title}</title>
    </HelmetReact>
  )
}

export default Helmet
