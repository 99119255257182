import React from 'react'
import { FormikProps } from 'formik'
import { KeyboardDatePicker as KeyboardDatePickerMUI } from './style'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

interface Props {
  name: string
  label: string
  formik: FormikProps<any>

}

export const DatePickerField: React.FC<Props> = (props) => {
  const { label, name, formik } = props

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePickerMUI
        name={name}
        value={formik.values[name] || null}
        onChange={(value) => formik.setFieldValue(name, value)}
        disableToolbar
        format="dd/MM/yyyy"
        margin="normal"
        label={label}
        inputVariant="filled"
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      />
    </MuiPickersUtilsProvider>
  )
}
