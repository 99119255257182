import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, grayTextFilter, violet } from '@qirapagos/lib/theme/colors'

function WarningIcon ({
  active,
  canBeHovered
}: {
  active: boolean
  canBeHovered?: boolean
}) {
  return (
    <Svg
      width={22}
      height={22}
      viewBox="0 0 21 23"
      fill="none"
    >
      <Path
        // eslint-disable-next-line max-len
        d="M13.3 3.148l8.633 14.954a1.5 1.5 0 01-1.299 2.25H3.366a1.5 1.5 0 01-1.299-2.25l8.634-14.954c.577-1 2.02-1 2.598 0zM12 4.898L4.232 18.352h15.536L12 4.898zM12 15a1 1 0 110 2 1 1 0 010-2zm0-7a1 1 0 011 1v4a1 1 0 01-2 0V9a1 1 0 011-1z"
        fill={!canBeHovered ? grayTextFilter : active ? violet : gray}
      />
    </Svg>
  )
}

WarningIcon.defaultProps = {
  canBeHovered: true
}
export default WarningIcon
