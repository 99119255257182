import React, { useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useAppSelector } from 'hooks/redux'
import { getCurrencies } from '@qirapagos/web/src/store/contracts/thunks'
import { useDispatch } from 'react-redux'
import { RadioField } from 'components/shared/RadioField'
import { TextField } from 'components/shared/TextField'
import { Container, Title, Inputs } from './styles'

export const formProductTradeInitialValues = {
  exchangeProduct: undefined,
  exchangeCurrencyId: undefined,
  exchangeAmount: undefined
}

export const formProductTradeValidator = Yup.object().shape({
  exchangeProduct: Yup.string()
    .required('Es requerido'),
  exchangeCurrencyId: Yup.string()
    .required('Es requerido'),
  exchangeAmount: Yup.string()
    .required('Es requerido')
})

export interface FormProductTradeValues {
  exchangeProduct: string | undefined;
  exchangeCurrencyId: number | undefined;
  exchangeAmount: number | undefined;
}

interface Props {
  titlesInfo: string
  formik: FormikProps<any>;
}

const FormProductTrade: React.FC<Props> = (props) => {
  const { titlesInfo, formik } = props

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCurrencies())
  }, [dispatch])

  const { currencies, isLoading } = useAppSelector((state) => state.contracts)

  const currenciesList = useMemo(() => {
    return currencies?.map((currency: any) => ({
      value: currency.id,
      label: currency.currency
    }))
  }, [currencies])

  if (isLoading) {
    return null
  }

  return (
    <Container>
      <Title>{titlesInfo}</Title>
      <Inputs>
        <TextField
          formik={formik}
          name="exchangeProduct"
          label="Producto o servicio a canjear"
        />
        <RadioField
          formik={formik}
          name="exchangeCurrencyId"
          options={currenciesList}
        />
        <TextField
          formik={formik}
          name="exchangeAmount"
          label="Monto a canjear"
          type="number"
        />
      </Inputs>
    </Container>
  )
}

export default FormProductTrade
