/* eslint-disable react/require-default-props */
import React from 'react'
import Warning from 'assets/svg/Warning'
import { Container, Title, Action } from './styles'

interface Props {
  title: string
  buttonText: string
  onclick: (param?: unknown) => void
  className?: string
}

const WarningBox: React.FC<Props> = ({ title, buttonText, onclick, className }: Props) => {
  return (
    <Container className={className}>
      <Warning />
      <Title>
        {title}
      </Title>
      <Action onClick={onclick}>
        {buttonText}
      </Action>
    </Container>
  )
}

export default WarningBox
