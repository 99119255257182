import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuDivider
} from '@chakra-ui/react'
import { Select, DownArrowIcon, Text, MenuItem } from './style'

interface Props {
  placeholder: string
  selected: string
  options: string[]
  onSelect: (value: string) => void
}

const SelectComponent: React.FC<Props> = ({
  placeholder,
  options,
  selected,
  onSelect
}) => {
  return (
    <Menu>
      <MenuButton>
        <Select>
          {selected || placeholder}
          <DownArrowIcon />
        </Select>
      </MenuButton>
      <MenuList zIndex={9999}>
        {options.map((option, index) => (
          <>
            <MenuItem
              autoFocus={(option === selected)}
              onClick={() => onSelect(option)}
            >
              <Text>
                {option}
              </Text>
            </MenuItem>
            {index < options.length - 1 && (<MenuDivider m={0} />)}
          </>
        ))}
      </MenuList>
    </Menu>
  )
}

export default SelectComponent
