/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

export function CloseButton (props: any) {
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="currentColor"
      className="bi bi-x"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Path
        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </Svg>
  )
}
