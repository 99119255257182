import React from 'react'
import CustomText from 'components/atoms/CustomText'
import { SlideContainer, TextContainer } from './styles'

interface Props {
  title: string
  subtitle: string
}

const Slide: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <SlideContainer>
      <TextContainer>
        <CustomText size="large" color="white" weight="bold">
          {title}
        </CustomText>
        <CustomText size="xmedium" color="white">
          {subtitle}
        </CustomText>
      </TextContainer>
    </SlideContainer>
  )
}

export default Slide
