import React, { useCallback, useEffect, useState } from 'react'
import { Field } from 'components/molecules/Field'
import Helmet from 'components/utilities/Helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { changeEmail, getMyData } from 'store/auth/thunks'
import {
  setShowDrawerError,
  setShowDrawerSuccess
} from 'store/modals/actions'
import { SCREEN_TITLES } from 'utils/navigation'
import { RenderIf } from 'components'
import { ErrorMessages } from '@qirapagos/lib/utils/common'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  Button,
  SubTitle,
  Container,
  FieldContainer,
  ButtonContainer
} from './styles'
import { setTwoFactorVerified } from '@qirapagos/lib/store/auth/actions'
import TwoFactorAuthentication from 'components/molecules/TwoFactorAuthentication'
import { emailRegex } from 'constants/validations'

const Steps = {
  TWO_FACTOR: 'TWO_FACTOR',
  EMAIL_CHANGE: 'EMAIL_CHANGE'
}

const ChangeEmailMyData = () => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(Steps.EMAIL_CHANGE)
  const [newEmail, setNewEmail] = useState('')
  const { isLoading } = useSelector((state: any) => state.auth)

  const { control, handleSubmit, errors, clearErrors, setError, watch } =
    useForm({
      defaultValues: {
        email: '',
        emailRepeat: ''
      }
    })
  const { onNavigate } = useNavigation()

  const areFieldsEmpty = !watch('email') || !watch('emailRepeat')

  useEffect(() => {
    dispatch(getMyData())
  }, [dispatch])

  const updateEmail = useCallback(async () => {
    try {
      await dispatch(changeEmail(newEmail))
      dispatch(
        setShowDrawerSuccess(
          true,
          true,
          '¡Listo!',
          'Editaste tu e-mail con éxito.',
          {
            path: '/content/my-data'
          }
        )
      )
    } catch (error: any) {
      dispatch(
        setShowDrawerError(
          true,
          error?.toString() || ErrorMessages.EMAIL_EDIT_ERROR,
          '',
          {
            path: '/content/my-data'
          }, 'Reintentar'
        )
      )
    }
  }, [newEmail, dispatch])

  const onCancel = useCallback(() => {
    onNavigate('/content/my-data', { title: 'Mis datos' })
    dispatch(setTwoFactorVerified(false))
  }, [onNavigate, dispatch])

  const onPressChange = useCallback((values) => {
    if (emailRegex.test(values.email)) {
      if (values.email !== values.emailRepeat) {
        clearErrors('email')
        setError('emailRepeat', { message: 'Los e-mails no coinciden' })
      } else {
        setNewEmail(values.email)
        setStep(Steps.TWO_FACTOR)
      }
    } else {
      setError('email', { message: 'El formato del e-mail es incorrecto' })
    }
  }, [setNewEmail, setStep, clearErrors, setError])

  return (
    <>
      <RenderIf isTrue={step === Steps.TWO_FACTOR}>
        <TwoFactorAuthentication
          onlyByEmail
          callback={updateEmail}
          newEmail={newEmail}
        />
      </RenderIf>
      <RenderIf isTrue={step === Steps.EMAIL_CHANGE}>
        <Container>
          <Helmet title={SCREEN_TITLES.CHANGE_EMAIL} />
          <Title>Editar e-mail</Title>
          <FieldContainer>
            <SubTitle>
              Ingresa un e-mail distinto
            </SubTitle>
            <Field
              control={control}
              name="email"
              placeholder="correo@gmail.com"
              error={errors.email}
              type="email"
              clearErrors={clearErrors}
            />
            <SubTitle>
              Ingresalo nuevamente
            </SubTitle>
            <Field
              control={control}
              name="emailRepeat"
              placeholder="correo@gmail.com"
              error={errors.emailRepeat}
              clearErrors={clearErrors}
              type="email"
            />
          </FieldContainer>
          <ButtonContainer>
            <Button secondary onPress={onCancel} disabled={isLoading}>
              Cancelar
            </Button>
            <Button
              onPress={handleSubmit(onPressChange)}
              disabled={isLoading || areFieldsEmpty}
              loading={isLoading}
            >
              Cambiar e-mail
            </Button>
          </ButtonContainer>
        </Container>
      </RenderIf>
    </>
  )
}

export default ChangeEmailMyData
