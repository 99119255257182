/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

interface Props {
  shownAtActivity?: boolean
}
function TicketSVG ({ shownAtActivity }: Props) {
  return (
    <Svg
      width={shownAtActivity ? 43 : 32}
      height={shownAtActivity ? 42 : 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <Circle cx={16} cy={16} r={15} stroke="#161C27" strokeWidth={2} />
      <Path
        d="M12.681 13.977h7.041M12.681 16.167h7.041M9.702 21.096V10.69a.55.55 0 01.159-.387.539.539 0 01.383-.16H22.16c.143 0 .28.057.383.16a.55.55 0 01.158.387v10.405L20.535 20l-2.167 1.095L16.202 20l-2.167 1.095L11.87 20l-2.167 1.095z"
        stroke="#161C27"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default TicketSVG
