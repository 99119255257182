import { StyleSheet } from 'react-native'
import styled from 'styled-components'
import { gray, white } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'
import { greenSec, yellowSec } from 'theme/colors'
import { ReactComponent as Spinner } from 'assets/svg/Spinner.svg'

type TCustomZindex = {
  isTextFilter: boolean
}

export const SpinnerIcon = styled(Spinner)`
  width: 20px;
  height: 20px;
  margin: unset !important;
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  width: 100%;
  height: 100%;
  background-color: ${white};
  overflow: auto;
  position: relative;
`

export const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-self: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 2%;
  position: sticky;
  top: 0;
  background-color: ${white};
  z-index: 10;
`

export const Title = styled.p`
  display: flex;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  margin-left: 15px;
`
export const FiltersContainer = styled.div`
  width: 100px;
  display: flex;
  flex-direction: row-reverse;
`

export const EmptyTextContainer = styled.div`
  max-width: 200px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: ${gray};
`

export const BoxSignedDocument = styled.div<{ signed: boolean }>`
  background-color: ${p => (p.signed ? greenSec : yellowSec)};
  width: 125px;
  height: 35px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconContainer = styled.div`
  margin-left: 10px;
`

export const HowWorksContainer = styled.div`
  position: absolute;
  right: 0vw;
`

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TableHeader = styled.thead<TCustomZindex>`
  position: sticky;
  z-index: ${({ isTextFilter }) => (isTextFilter ? '20' : '2')};
  top: 75px;
  background-color: white;
`

export const TableHeaderItem = styled.th<any>`
  text-align: start;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  &:first-child {
    text-align: left;
  }

  ${({ index }) =>
    index === 0 ? 'padding-left: 20px; width: 30%;' : 'padding-left: 0;'}

  ${({ index }) => (index === 1 ? 'width: 30%;' : '')}

  @media ${DEVICES.tablet} {
    font-size: 15px;

    ${({ index }) => (index === 4 ? 'width: 20%; padding-left: 10px;' : '')}
  }
`

export const TableRow = styled.tr<any>`
  ${({ index }) =>
    index % 2 === 0 ? 'background-color: #F5F6FA;' : 'background-color: white;'}
`

export const TableDataItem = styled.td<any>`
  text-align: left;
  ${({ index }) =>
    index === 0
      ? 'padding: 15px; padding-right: 20px; padding-left: 20px;'
      : 'padding: 0px; padding-left: 0px;'}
  ${({ index }) => (index === 1 ? 'width: 30%;' : '')}
`

export const styles = StyleSheet.create({
  status: {
    minWidth: 120,
    maxWidth: 200
  }
})
