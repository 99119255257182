import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { white } from '@qirapagos/lib/theme/colors'

const AddressEditSkeleton = () => (
  <div style={{ position: 'relative' }}>
    <ContentLoader
      speed={3}
      interval={0}
      width="100%"
      height="100vh"
      viewBox="0 0 100% 100vh"
      backgroundColor="#ffffff"
      foregroundColor="#f3f3f3"
    >
      <Rect x="4%" y="4vh" rx="5" ry="5" width="90%" height="78vh" />
    </ContentLoader>

    <ContentLoader
      style={{ position: 'absolute', top: 0, left: 0 }}
      speed={3}
      interval={0}
      width="100%"
      height="100vh"
      viewBox="0 0 100% 100vh"
      backgroundColor="#e8e7f7"
      foregroundColor={white}
    >
      <Rect x="40%" y="10vh" rx="5" ry="5" width="20%" height="4vh" />

      <Rect x="10%" y="20vh" rx="5" ry="5" width="13%" height="2vh" />
      <Rect x="10%" y="24vh" rx="5" ry="5" width="35%" height="8vh" />

      <Rect x="54%" y="20vh" rx="5" ry="5" width="13%" height="2vh" />
      <Rect x="54%" y="24vh" rx="5" ry="5" width="35%" height="8vh" />

      <Rect x="10%" y="37vh" rx="5" ry="5" width="13%" height="2vh" />
      <Rect x="10%" y="41vh" rx="5" ry="5" width="35%" height="8vh" />

      <Rect x="54%" y="37vh" rx="5" ry="5" width="13%" height="2vh" />
      <Rect x="54%" y="41vh" rx="5" ry="5" width="35%" height="8vh" />

      <Rect x="10%" y="56vh" rx="5" ry="5" width="13%" height="2vh" />
      <Rect x="10%" y="60vh" rx="5" ry="5" width="35%" height="8vh" />

      <Rect x="54%" y="60vh" rx="5" ry="5" width="35%" height="3vh" />
      <Rect x="54%" y="65vh" rx="5" ry="5" width="35%" height="3vh" />

      <Rect x="24%" y="72vh" rx="5" ry="5" width="21%" height="8vh" />
      <Rect x="54%" y="72vh" rx="5" ry="5" width="21%" height="8vh" />
    </ContentLoader>
  </div>
)

export default AddressEditSkeleton
