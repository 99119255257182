import React, { ReactNode } from 'react'
import FileField from 'components/shared/FileField'
import { FormikProps } from 'formik'
import { TextUploadFile, ViewIcon } from './style'
import BoxWithAction from 'components/shared/BoxWithAction'

interface Props {
  title: string;
  icon: ReactNode;
  formik: FormikProps<any>;
}

const BoxUploadFile: React.FC<Props> = ({ title, icon, formik }) => {
  return (
    <BoxWithAction>
      <TextUploadFile>
        {title}
      </TextUploadFile>
      <ViewIcon onClick={() => document.getElementById('files')?.click()}>{icon}</ViewIcon>
      <FileField
        name="files"
        formik={formik}
        fileType="application/pdf"
        style={{ cursor: 'pointer', display: 'none' }}
      />
    </BoxWithAction>
  )
}

export default BoxUploadFile
