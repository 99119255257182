
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, grayTextFilter, violet } from '@qirapagos/lib/theme/colors'

interface Props {
  active: boolean
  width?: number
  height?: number
  strokeColor?: any
  canBeHovered?: boolean
}

const SvgComponent: React.FC<Props> = ({
  active,
  width,
  height,
  strokeColor,
  canBeHovered
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 26 21" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M7.335 4.696l-4.008.87a2.005 2.005 0 00-1.57 2.132l.455 5.268a1.995 1.995 0 001.913 1.822l1.168.044M7.335 4.696v0c-.06 3.438.237 6.871.887 10.246v0M7.335 4.696l1.832-.408A39.464 39.464 0 0016.71 1.79v0c.961-.43 2.057.215 2.148 1.263l1.038 11.995a1.223 1.223 0 01-1.567 1.277l-.818-.243a25.36 25.36 0 00-7.03-1.054v0m-2.258-.085l2.259.085m-2.26-.085l-2.928-.11m5.188.195l-.764.88a4.01 4.01 0 00-.953 2.151l-.116.968-3.087.396-.075-.22a7.727 7.727 0 01-.193-4.37v0m16.7-11.398l2.488-1.84m-2.13 7.082h3.58m-3.22 5.38l2.566 1.238"
        stroke={!canBeHovered ? grayTextFilter : active ? strokeColor : gray}
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SvgComponent

SvgComponent.defaultProps = {
  width: 22,
  height: 22,
  strokeColor: violet,
  canBeHovered: true
}
