import { purple, white } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components/native'
import { ChildrenButtonViewProps, IconViewProps } from './types'

export const TouchableContainer = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100px;
  background-color: ${purple};
  border-radius: 8px;
`

export const IconView = styled.View<IconViewProps>`
  margin-right: ${props => props.marginRight || '0px'};
`

export const TitleText = styled.Text`
  width: 80%;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  color: ${white};
`

export const ChildrenButtonView = styled.View<ChildrenButtonViewProps>`
  flex-direction: ${props => props.flexDirection || 'column'};
  justify-content: center;
  align-items: center;
`
