import { ThunkDispatchType } from '@qirapagos/lib/types/reduxTypes'
import * as Sentry from '@sentry/react'
import { ApiResponse } from 'apisauce'
import * as ContractActions from '@qirapagos/lib/store/contracts/actions'
import * as ContractActionsWeb from './actions'
import { format } from 'date-fns'
import { getToken } from 'services/utils'
import * as ContractService from '../../services/ContractServices'
import { ErrorMessages } from '@qirapagos/lib/utils/common'
import { getOperationShipping } from '../../services/ContractServices'
import {
  setContractDates
} from './actions'
import { QuotaBody, SaleOrder } from './types'

export const getCurrencies = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActions.setContractLoading())
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await ContractService.getCurrencies(
      token as string,
      keyEndpoints as string
    )
    dispatch(ContractActions.setCurrencies(response.data))
    dispatch(ContractActions.dismissContractLoading())
  } catch (error) {
    dispatch(ContractActions.dismissContractLoading())
  }
}

export const getConditionType = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActions.setContractLoading())
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await ContractService.getConditionType(
      token as string,
      keyEndpoints as string
    )
    dispatch(ContractActions.setConditionType(response.data))
    dispatch(ContractActions.dismissContractLoading())
  } catch (error) {
    dispatch(ContractActions.dismissContractLoading())
  }
}

export const getExchangers = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActions.setContractLoading())
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await ContractService.getExchangers(
      token as string,
      keyEndpoints as string
    )
    dispatch(ContractActions.setExchangers(response.data))
    dispatch(ContractActions.dismissContractLoading())
  } catch (error) {
    dispatch(ContractActions.dismissContractLoading())
  }
}

export const getGrainType = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActions.setContractLoading())
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await ContractService.getGrainType(
      token as string,
      keyEndpoints as string
    )
    dispatch(ContractActions.setGrainType(response.data))
    dispatch(ContractActions.dismissContractLoading())
  } catch (error) {
    dispatch(ContractActions.dismissContractLoading())
  }
}

export const getHarvestDate = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActions.setContractLoading())
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await ContractService.getHarvestDate(
      token as string,
      keyEndpoints as string
    )
    dispatch(ContractActions.setHarvestDate(response.data))
    dispatch(ContractActions.dismissContractLoading())
  } catch (error) {
    dispatch(ContractActions.dismissContractLoading())
  }
}
export const getZones = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActions.setContractLoading())
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await ContractService.getZones(
      token as string,
      keyEndpoints as string
    )
    dispatch(ContractActions.setZones(response.data))
    dispatch(ContractActions.dismissContractLoading())
  } catch (error) {
    dispatch(ContractActions.dismissContractLoading())
  }
}

export const getOperationDetail =
  (operationId: string | number | null) =>
    async (dispatch: ThunkDispatchType) => {
      try {
        dispatch(ContractActions.setContractLoadingOperationDetail())
        const token = await getToken()
        const keyEndpoints = await localStorage.getItem('keyendpoints')
        const response: ApiResponse<any> =
        await ContractService.getOperationDetail(
          operationId,
          token,
          keyEndpoints as string
        )
        Sentry.setExtras({
          data: {
            response: response.data,
            token,
            keyEndpoints,
            operationId
          }
        })
        dispatch(ContractActions.setOperationDetail(response.data))
        localStorage.setItem(
          'cuposAvailableToRequest',
          response.data.cuposAvailableToRequest
        )
        localStorage.setItem('operationId', String(operationId))
        dispatch(ContractActions.dismissContractLoadingOperationDetail())
      } catch (error) {
        Sentry.captureMessage(
        `Error al conseguir detealle de operación:${error}`,
        'error'
        )
        dispatch(ContractActions.dismissContractLoadingOperationDetail())
      }
    }

export const newCupo =
  (datesArray: Date[]) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(ContractActions.setContractLoading())
      const token = await getToken()
      const operationId = await localStorage.getItem('operationId')
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const responseArray = []
      for (let index = 0; index < datesArray.length; index++) {
        const data = {
          operationId: Number(operationId),
          deliveryDate: format(datesArray[index], 'yyyy-MM-dd')
        }
        const response: ApiResponse<any> = await ContractService.newCupo(
          data,
          token,
          keyEndpoints as string
        )
        responseArray.push(response)
        if (responseArray[index].status !== 200) {
          throw new Error('')
        }
      }
      dispatch(getOperationDetail(operationId))
      dispatch(ContractActions.dismissContractLoading())
    } catch (error) {
      Sentry.captureMessage(`Error al crear cupo:${error}`, 'warning')
      dispatch(ContractActions.dismissContractLoading())
    }
  }

export const getShippingDate =
  (operationId: number) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(ContractActions.setContractLoading())
      const token: any = await getToken()
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const response: any = await getOperationShipping(
        operationId,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          token,
          keyEndpoints,
          operationId
        }
      })
      if (response.status !== 200) {
        throw new Error()
      }
      dispatch(setContractDates(response.data.shippingFrom, 'shippingfrom'))
      localStorage.setItem('shippingFrom', response.data.shippingFrom)
      dispatch(ContractActions.dismissContractLoading())
    } catch (error) {
      Sentry.captureMessage(
        `Error al conseguir shippingDate:${error}`,
        'warning'
      )
      dispatch(ContractActions.dismissContractLoading())
    }
  }

export const getPriceFixations =
  (operationId: number) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(ContractActions.setContractLoading())
      const token: any = await getToken()
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const response: any = await ContractService.getPriceFixations(
        operationId,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          token,
          keyEndpoints,
          operationId
        }
      })
      if (response.status !== 200) {
        throw new Error()
      }
      dispatch(ContractActions.setFixings(response.data))
      dispatch(ContractActions.dismissContractLoading())
    } catch (error) {
      Sentry.captureMessage(
        `Error al conseguir shippingDate:${error}`,
        'warning'
      )
      dispatch(ContractActions.dismissContractLoading())
    }
  }

export const newPriceFix =
  (body: object, operationId: number) =>
    async (dispatch: ThunkDispatchType) => {
      try {
        dispatch(ContractActions.setContractLoading())
        const token: any = await getToken()
        const keyEndpoints = await localStorage.getItem('keyendpoints')
        const response: ApiResponse<any> = await ContractService.newFix(
          body,
          token,
        keyEndpoints as string
        )
        if (response.status !== 200) {
          throw new Error(ErrorMessages.DEFAULT_BACKEND_ERROR)
        }
        await dispatch(getPriceFixations(operationId))
        dispatch(ContractActions.dismissContractLoading())
      } catch (error) {
        Sentry.captureMessage(
          `Error al conseguir shippingDate:${error}`,
          'warning'
        )
        dispatch(ContractActions.dismissContractLoading())
      }
    }

export const detailContract =
  (data: any) => async (dispatch: ThunkDispatchType) => {
    dispatch(ContractActions.setDetailContractLoading())
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await ContractService.getCounterOffer(
      data,
      token,
      keyEndpoints as string
    )
    dispatch(ContractActions.getDetailsContract(response.data))
    dispatch(ContractActions.dismissDetailContractLoading())
  }

export const getSaleOrders = (silentMode?: boolean) => async (dispatch: ThunkDispatchType) => {
  try {
    if (!silentMode) {
      dispatch(ContractActionsWeb.getSaleOrdersPending())
    }
    const response: ApiResponse<any> = await ContractService.getSaleOrders()
    if (response.status !== 200) {
      throw response.data
    }
    dispatch(ContractActionsWeb.getSaleOrdersFulfilled(response.data.data))
  } catch (error: any) {
    dispatch(ContractActionsWeb.getSaleOrdersRejected(error))
    throw error?.toString()
  }
}

export const createSaleOrder = (saleOrder: SaleOrder) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActionsWeb.createSaleOrderPending())
    const response: any = await ContractService.createSaleOrder(saleOrder)
    if (response.status !== 200) {
      throw response.data
    }
    dispatch(ContractActionsWeb.createSaleOrderFulfilled(response.data.data))
  } catch (error) {
    dispatch(ContractActionsWeb.createSaleOrderRejected(error))
    throw error
  }
}

export const replyCounterOffer = (saleOrderId: number, accept: boolean) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(ContractActionsWeb.replyCounterOfferPending())
      const response: ApiResponse<any> = await ContractService.replyCounterOffer(saleOrderId, accept)
      if (response.status !== 200) {
        throw response.data?.message
      }
      dispatch(ContractActionsWeb.replyCounterOfferFulfilled(response.data.data))
    } catch (error: any) {
      dispatch(
        ContractActionsWeb.replyCounterOfferRejected(
          error instanceof Error ? error.message : error?.toString?.()
        )
      )
      throw error
    }
  }

export const getSaleOrder = (id: number) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActionsWeb.getSaleOrderPending())
    const response: ApiResponse<any> = await ContractService.getSaleOrder(id)
    if (response.status !== 200) {
      throw response.data
    }
    dispatch(ContractActionsWeb.getSaleOrderFulfilled(response.data.data))
  } catch (error: any) {
    dispatch(ContractActionsWeb.getSaleOrderRejected(error))
    throw error?.toString()
  }
}

export const createQuotas = (saleOrderId: number, body: QuotaBody) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActionsWeb.createQuotaPending())
    const response: ApiResponse<any> = await ContractService.createQuotas(saleOrderId, body)
    if (response.status !== 200) {
      throw response.data
    }
    dispatch(ContractActionsWeb.createQuotaFulfilled(response.data.data))
  } catch (error: any) {
    dispatch(ContractActionsWeb.createQuotaRejected(error))
    throw error?.toString()
  }
}

export const getQuota = (quotaId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(ContractActionsWeb.getQuotaPending())
    const response: ApiResponse<any> = await ContractService.getQuota(quotaId)
    if (response.status !== 200) {
      throw response.data
    }
    dispatch(ContractActionsWeb.getQuotaFulfilled(response.data.data))
  } catch (error: any) {
    dispatch(ContractActionsWeb.getQuotaRejected(error))
    throw error?.toString()
  }
}
