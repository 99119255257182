import React from 'react'
import { MovementsList } from 'components'
import { ViewContainer } from './styleWeb'
import { Text } from '@chakra-ui/react'
import { gray } from '@qirapagos/lib/theme/colors'
import {
  MovementsContainer
} from './style'

const YourActivity: React.FC = () => {
  return (
    <ViewContainer id="your-activity-box">
      <Text
        textAlign="left"
        color={gray}
        fontSize={{ sm: '12px', md: '18px', lg: '20px' }}
        marginTop={4}
        marginLeft={{ sm: '25px' }}
        fontWeight={500}
      >
        Tu actividad
      </Text>
      <MovementsContainer>
        <MovementsList />
      </MovementsContainer>
    </ViewContainer>
  )
}
export default YourActivity
