import { AnyAction } from 'redux'
import initialState from './initialState'
import { AuthState, AuthActionTypes } from './types'

export default function signatureReducer (state = initialState, action: AnyAction): AuthState {
  switch (action.type) {
    case AuthActionTypes.UPDATE_DEVICE_ID_PENDING: {
      return {
        ...state,
        isDeviceIdUpdating: true
      }
    }
    case AuthActionTypes.UPDATE_DEVICE_ID_FULFILLED: {
      return {
        ...state,
        isDeviceIdUpdating: initialState.isDeviceIdUpdating,
        deviceId: action.payload.deviceId
      }
    }
    case AuthActionTypes.UPDATE_DEVICE_ID_REJECTED: {
      return {
        ...state,
        isDeviceIdUpdating: initialState.isDeviceIdUpdating,
        error: action.error
      }
    }
    default:
      return state
  }
}
