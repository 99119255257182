import React from 'react'
import { animated, useSpring } from '@react-spring/native'
import { StyleProp, ViewStyle } from 'react-native'

interface AnimatedProps {
  children: React.ReactNode
  customStyle?: any
}

const AnimatedSpringView: React.FC<AnimatedProps> = ({
  children,
  customStyle
}) => {
  const { from, to, config, delay } = customStyle
  const styles: StyleProp<ViewStyle> = useSpring({
    from,
    to,
    config,
    delay
  })
  return <animated.View style={{ ...styles }}>{children}</animated.View>
}

export default AnimatedSpringView

AnimatedSpringView.defaultProps = {
  customStyle: {}
}
