import React, { CSSProperties } from 'react'
import { animated, useSpring } from 'react-spring'

interface AnimatedProps {
  children: React.ReactNode
  customAnimation?: any
  customStyle?: CSSProperties
}

const AnimatedSpringView: React.FC<AnimatedProps> = ({
  children,
  customAnimation,
  customStyle
}) => {
  const styles: any = useSpring({ ...customAnimation })
  return (
    <animated.div style={{ ...styles, ...customStyle }}>
      {children}
    </animated.div>
  )
}

export default AnimatedSpringView

AnimatedSpringView.defaultProps = {
  customAnimation: {},
  customStyle: {}
}
