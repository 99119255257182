import styled from 'styled-components'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'
import { DEVICES } from 'theme/devicesSizes'
import { Colors, Fonts } from 'theme'

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 66px;
`

export const DrawerBody = styled.div`
  display: flex;
  width: 575px;
  padding: 1%;
  flex-direction: column;
  align-self: center;
  @media ${DEVICES.mobile} {
    margin-top: 25px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
`

export const BackButton = styled.button`
  position: absolute;
  left: 20px;
`

export const ButtonContainer = styled.div`
  width: 50%;
  align-self: center;
  @media ${DEVICES.mobile} {
    margin-top: 25px;
  }
`

export const ContinueButton = styled(ButtonComponent)`
  height: 60px;
  align-self: center;
`

export const ButtonText = styled.p`
  font-size: ${Fonts.SIZES.XMEDIUM};
  font-weight: 500;
  color: ${Colors.white}
`

export const getDrawerStyles = () => {
  const mobileDevice = window.innerWidth < 600
  if (mobileDevice) {
    return {
      width: '100%'
    }
  }
  return {
    width: '500px'
  }
}
