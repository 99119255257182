import initialState from './initialState'
import { DrawerActionTypes, DrawerState, Action } from './types'

export default function signatureReducer (state = initialState, action: Action): DrawerState {
  switch (action.type) {
    case DrawerActionTypes.SHOW_DRAWER:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.drawer]: action.payload.data
        },
        actives: [
          ...state.actives,
          action.payload.drawer
        ]
      }
    case DrawerActionTypes.HIDE_DRAWER:
      return {
        ...state,
        actives: state.actives.filter((drawer) => drawer !== action.payload)
      }
    default:
      return state
  }
}
