/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function ShareSVG () {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        d="M6 15a3 3 0 100-6 3 3 0 000 6zM16.5 21.75a3 3 0 100-6 3 3 0 000 6zM16.5 8.25a3 3 0 100-6 3 3 0 000 6zM13.977 6.872l-5.454 3.506M8.523 13.622l5.454 3.506"
        stroke="#7D66FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default ShareSVG
