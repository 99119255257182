import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  Login,
  ForgotPassword,
  RecoverPassword,
  ErrorScreen
} from 'screens'

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="error" element={<ErrorScreen />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="recover-password/:token" element={<RecoverPassword />} />
    </Routes>
  )
}

export default AuthRoutes
