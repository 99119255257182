import React from 'react'
import useNavigation from 'hooks/useNavigation'
import CustomText from 'components/atoms/CustomText'
import Button from 'components/atoms/Button'

type Props = {
  email: string
}

const EmailSent: React.FC<Props> = ({ email }) => {
  const { onNavigate } = useNavigation()

  return (
    <>
      <CustomText
        size="big"
        weight="mediumfont"
      >
        {'Enviamos un correo a '}
        <CustomText
          size="big"
          weight="mediumfont"
          color="violet"
        >
          {email}
        </CustomText>
        Ingresá a tu casilla y seguí las instrucciones para restablecer tu contraseña.
      </CustomText>
      <Button onPress={() => onNavigate('/auth/login')}>
        Volver al inicio
      </Button>
    </>
  )
}

export default EmailSent
