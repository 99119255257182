const sizes = {
  tablet: '1024px',
  mobile: '600px'
}

export const DEVICES = {
  tablet: `screen and (max-width: ${sizes.tablet})`,
  mobile: `screen and (max-width: ${sizes.mobile})`
}

export const isTabletDisplayOrLower = window.innerWidth <= 1024
export const isMobileDisplay = window.innerWidth <= 600
