import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const ViewBoxBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3.65vh;
  gap: 100px;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: center;
  }
`

export const ViewBoxContainer = styled.div`
  margin-left: 2%;
  width: 45%;

  @media ${DEVICES.mobile} {
    width: 70%;
  }
`
