import styled from 'styled-components/native'

export const ContainerView = styled.View`
  flex-direction: row;
  width: 350px;
`

export const RenderView = styled.View`
  margin: 15px;
`
