import { Button } from '@chakra-ui/react'
import WhatsApp from '@qirapagos/lib/assets/svg/WhatsApp'
import { purple, purpleDark, white } from '@qirapagos/lib/theme/colors'
import Transfer from 'assets/svg/Transfer'
import User from 'assets/svg/User'
import Users from 'assets/svg/Users'
import React from 'react'
import { View } from 'react-native'
import { TitleText, ChildrenButtonView, IconView } from './styles'
import GrainItem from 'assets/svg/GrainItem'
import { ReactComponent as AlzLogoSmall } from 'assets/svg/AlzLogoSmall.svg'

interface Props {
  icon:
    | 'accountData'
    | 'newTransfer'
    | 'myContacts'
    | 'helpCenter'
    | 'grains'
    | 'extranetLink'
  title: string
  onPress: () => void
  height: string
  width: string
  isDisabled?: boolean
  marginRight?: string
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
}

const ActionCard: React.FC<Props> = ({
  icon,
  title,
  onPress,
  height,
  width,
  isDisabled,
  marginRight,
  flexDirection
}) => {
  const getIcon = (icon: string) => {
    switch (icon) {
      case 'accountData':
        return <User />
      case 'newTransfer':
        return <Transfer />
      case 'myContacts':
        return <Users />
      case 'helpCenter':
        return <WhatsApp width={30} height={30} color={white} />
      case 'grains':
        return <GrainItem isWhite active width="30" height="30" />
      case 'extranetLink':
        return <AlzLogoSmall />
      default:
        return <View> default getIcon</View>
    }
  }

  return (
    <Button
      _hover={{ bg: purpleDark }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginBottom={0}
      width={width}
      height={height}
      bg={purple}
      borderRadius="8px"
      disabled={isDisabled}
      onClick={onPress}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      pointerEvents={isDisabled ? 'none' : 'auto'}
    >
      <ChildrenButtonView flexDirection={flexDirection}>
        <IconView marginRight={marginRight}>{getIcon(icon)}</IconView>
        <TitleText>{title}</TitleText>
      </ChildrenButtonView>
    </Button>
  )
}

export default ActionCard

ActionCard.defaultProps = {
  isDisabled: false,
  marginRight: '0px',
  flexDirection: 'column'
}
