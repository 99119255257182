import { api } from '../config/api'
import { Device } from '../interfaces/deviceinterface'

const REGISTER_DEVICE = '/appregister'
const KEY_ENDPOINTS = '/endpoints'

const registerDevice = (deviceInfo: Device) =>
  api.post(`${REGISTER_DEVICE}/${deviceInfo.device}`, deviceInfo)

const keyEndpoints = (device: string, token: string) =>
  api.post(`${KEY_ENDPOINTS}/${device}`, { token: token }, { headers: { token: token } })

export default {
  registerDevice,
  keyEndpoints
}
