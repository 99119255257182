import React, { useState } from 'react'
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PlacementWithLogical,
  Text
} from '@chakra-ui/react'
import { graySoft, purple } from '@qirapagos/lib/theme/colors'
import TripleDot from 'assets/svg/TripleDot'

interface Props {
  dotActionConfig?: any,
  placement?: PlacementWithLogical
}

const TableActionButton: React.FC<Props> = ({ dotActionConfig, placement }) => {
  const [isDotOpen, setIsDotOpen] = useState(false)

  return (
    <Menu placement={placement} onOpen={() => setIsDotOpen(true)} onClose={() => setIsDotOpen(false)}>
      <MenuButton onClick={() => setIsDotOpen(!isDotOpen)}>
        <TripleDot isDotOpen={isDotOpen} />
      </MenuButton>
      <MenuList zIndex={30} textAlign="end" alignSelf="end" justifySelf="end" p={0} style={{ minWidth: 'auto' }}>
        {dotActionConfig.map((item: any) => {
          return (
            <MenuItem
              _hover={{ color: purple, bg: graySoft }}
              onClick={item?.onPress}
            >
              <Box w="100%" textAlign="end">
                <Text
                  w="100%"
                  fontFamily="Poppins"
                  fontSize="15px"
                  fontWeight={500}
                >
                  {item?.title}
                </Text>
              </Box>
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default TableActionButton

TableActionButton.defaultProps = {
  dotActionConfig: undefined,
  placement: 'bottom'
}
