import { ThunkDispatchType } from 'store'
import * as SignatureActionTypes from 'store/signature/actions'
import { SignatureServices } from 'services'
import { ApiResponse } from 'apisauce'

export const getDocuments = () => async (dispatch: ThunkDispatchType) => {
  try {
    const cuit = localStorage.getItem('cuit') as string
    dispatch(SignatureActionTypes.getDocumentsPending())
    const response: ApiResponse<any> = await SignatureServices.getDocuments(cuit)
    /**
     * Since the Shared Table component is not made properly, we have to rename
     * the documents props to avoid conflict with other data used with the table on other pages.
     */
    const formattedData = response.data.data.map((document: any) => ({
      ...document,
      documentCompany: document.companyName,
      documentName: document.documentName,
      processStartDate: document.documentCreation,
      signingProcessStatusId: document.status
    }))
    dispatch(SignatureActionTypes.getDocumentsFulfilled(formattedData))
  } catch (error) {
    dispatch(SignatureActionTypes.getDocumentsRejected(error as Error))
  }
}

export const downloadDocument = (documentId: string, index: number) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(SignatureActionTypes.getDownloadDocumentPending(index))

    const response: ApiResponse<any> = await SignatureServices.downloadDocument(documentId)

    if (response.status !== 200) {
      throw response.data.message
    }

    const linkSource = `data:application/pdf;base64,${response.data.data}`
    const downloadLink = document.createElement('a')

    downloadLink.href = linkSource
    downloadLink.download = `${documentId}.pdf`
    downloadLink.click()
    downloadLink.remove()

    dispatch(SignatureActionTypes.getDownloadDocumentFulfilled())
  } catch (error) {
    dispatch(SignatureActionTypes.getDownloadDocumentRejected(error as Error))
  }
}
