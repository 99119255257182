import { styled } from '@material-ui/core/styles'
import TextFieldMUI from '@material-ui/core/TextField'
import { graySoft, purple, error } from 'theme/colors'

export const TextField = styled(TextFieldMUI)({
  height: 65,
  margin: 10,
  minWidth: 300,
  maxWidth: 500,
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins',
    fontWeight: 400

  },
  '& .MuiFilledInput-root': {
    backgroundColor: graySoft,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: 'white',
    '&:hover': {
      backgroundColor: graySoft
    },
    '&.Mui-focused': {
      backgroundColor: graySoft,
      borderColor: purple,
      borderWidth: 2
    },
    '&.MuiFilledInput-underline:before, &.MuiFilledInput-underline:after': {
      borderBottom: 'none !important'
    },
    '&.Mui-error': {
      borderColor: error,
      borderWidth: 1.5
    }
  }
})
