import React from 'react'
import Agents from './Agents'
import Represented from './Represented'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import RepresentationSkeleton from 'assets/skeletons/RepresentationSkeleton'

const Representation = () => {
  const { isRepresentative, isDeletingRepresentative } = useSelector((state: RootState) => state.representation)

  if (isDeletingRepresentative) {
    return (<RepresentationSkeleton />)
  }

  if (isRepresentative) {
    return <Represented />
  }
  return <Agents />
}

export default Representation
