import React from 'react'
import DownArrow from '@qirapagos/lib/assets/svg/DownArrow'
import CustomText from 'components/atoms/CustomText'
import { grayTextFilter } from '@qirapagos/lib/theme/colors'
import { styles, ViewDownArrow, ViewFilter } from './styles'

interface Props {
    title: string
}

const CustomFilter: React.FC<Props> = ({ title }) => {
  return (
    <ViewFilter>
      <CustomText
        color="grayTextFilter"
        weight="bold"
        size="medium"
        customStyle={styles.text}
      >
        {title}
      </CustomText>
      <ViewDownArrow>
        <DownArrow color={grayTextFilter} />
      </ViewDownArrow>
    </ViewFilter>
  )
}

export default CustomFilter
