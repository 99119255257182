import { styled } from '@material-ui/core/styles'
import CheckboxFieldMUI from '@material-ui/core/Checkbox'
import { purple } from 'theme/colors'

export const CheckboxField = styled(CheckboxFieldMUI)({
  color: purple,
  '&.Mui-checked': {
    color: purple
  }
})
