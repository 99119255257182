/* eslint-disable max-len */
import * as React from 'react'
import { Icon } from '@chakra-ui/react'
import { purple } from '@qirapagos/lib/theme/colors'

function MenuHamb () {
  return (
    <Icon
      w={7}
      h={7}
      color="#6A6A6A"
      _hover={{
        color: purple
      }}
    >
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 0.516129H1C0.447715 0.516129 0 0.963844 0 1.51613V1.99573C0 2.54802 0.447715 2.99573 1 2.99573H19C19.5523 2.99573 20 2.54802 20 1.99573V1.51613C20 0.963844 19.5523 0.516129 19 0.516129ZM19 5.99562H1C0.447715 5.99562 0 6.44334 0 6.99562V7.47523C0 8.02751 0.447715 8.47523 1 8.47523H19C19.5523 8.47523 20 8.02751 20 7.47523V6.99562C20 6.44334 19.5523 5.99562 19 5.99562ZM1 11.4751H19C19.5523 11.4751 20 11.9228 20 12.4751V12.9547C20 13.507 19.5523 13.9547 19 13.9547H1C0.447715 13.9547 0 13.507 0 12.9547V12.4751C0 11.9228 0.447715 11.4751 1 11.4751Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  )
}

export default MenuHamb
