import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function User () {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 24 25"
      fill="none"
    >
      <Path
        d="M12 15.5a6 6 0 100-12 6 6 0 000 12z"
        stroke="#F5F6FA"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <Path
        d="M2.905 20.75a10.504 10.504 0 0118.19 0"
        stroke="#F5F6FA"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default User
