import React from 'react'
import EmailSent from '@qirapagos/lib/assets/svg/EmailSent'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  ButtonsContainer,
  Drawer,
  ContentContainer,
  DrawerContainer,
  TextBody,
  MainButton,
  MainButtonText
} from './styles'
import { Drawers } from 'store/drawer/types'
import wrapper from 'components/organisms/Drawers/wrapper'
import { Header } from '../DrawerSuccess/style'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const EmailSentSuccess: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { onNavigate } = useNavigation()

  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
    >
      <DrawerContainer>
        <Header />
        <ContentContainer>
          <EmailSent />
          <Title>¡Listo!</Title>
          <TextBody>
            Te enviamos un correo electrónico con las
            indicaciones para que envíes la documentación.
          </TextBody>
        </ContentContainer>
        <ButtonsContainer>
          <MainButton
            onPress={() => {
              onClose()
              onNavigate('/content/wallet')
            }}
          >
            <MainButtonText testID="drawer-btn">
              Ir a Inicio
            </MainButtonText>
          </MainButton>
        </ButtonsContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(EmailSentSuccess, Drawers.EMAIL_SENT_SUCCESS)
