import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  width?: number
  height?: number
}

const InfoSVG: React.FC<Props> = ({ width, height }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path d="M10 17.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z" fill="#7D66FF" />
      <Path d="M9.375 9.375H10v4.375h.625" fill="#fff" />
      <Path
        d="M9.375 9.375H10v4.375h.625"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M10 7.5a.937.937 0 100-1.875.937.937 0 000 1.875z" fill="#fff" />
    </Svg>
  )
}

export default InfoSVG

InfoSVG.defaultProps = {
  width: 24,
  height: 24
}
