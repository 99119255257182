import styled from 'styled-components'
import ButtonComponent from 'components/atoms/Button'
import { Colors } from 'theme'
import { MANROPE, SIZES } from 'theme/fonts'

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${Colors.black};
  align-self: center;
`

export const BackButton = styled.button`
  position: absolute;
  left: 20px;
`

export const DrawerContentContainer = styled.div`
  display: flex;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  align-self: center;
`

export const TextContent = styled.pre`
  white-space: break-spaces;
  display: flex;
  align-self: center;
  width: 82%;
  font-size: ${SIZES.MEDIUM};
  color: ${Colors.gray};
  font-family: ${MANROPE};
  text-align: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10%;
`

export const DownloadButton = styled(ButtonComponent)`
  width: 60%;
  height: 60px;
`

export const getDrawerStyles = () => {
  const isMobile = window.innerWidth < 600

  if (isMobile) {
    return {
      width: '100%'
    }
  }
  return {
    width: '450px'
  }
}
