import { ContractState } from './types'

const initialState: ContractState = {
  isLoading: false,
  isLoadingDetail: false,
  isLoadingOperationDetail: false,
  graintype: undefined,
  currencies: undefined,
  contracts: undefined,
  customerid: undefined,
  operationdetail: undefined,
  quotadetail: undefined,
  counteroffer: undefined,
  companytypes: undefined,
  exchangers: undefined,
  operationtypeid: undefined,
  sale: {
    zoneId: undefined,
    zoneName: undefined,
    destination: undefined,
    deliveryPeriod: undefined,
    shippingFrom: undefined,
    expiration: undefined,
    grainName: undefined,
    dateTo: undefined,
    dateFrom: undefined,
    operationType: undefined,
    harvestDate: undefined,
    origin: undefined,
    originId: undefined,
    location: undefined,
    fromContra: false
  },
  trade: {
    customerId: undefined,
    companyId: undefined,
    product: undefined,
    amount: undefined,
    currencyProduct: undefined,
    currencyProductSymbol: undefined,
    contraparte: undefined,
    origin: undefined,
    location: undefined,
    harvestDate: undefined,
    deliveryPeriod: undefined,
    fromContra: false
  },
  salepricetoset: {
    companyId: undefined,
    userId: undefined,
    operationType: undefined,
    grain: undefined,
    weight: undefined,
    price: undefined,
    product: undefined,
    amount: undefined,
    customerId: undefined,
    shippingFrom: '',
    expiration: '',
    destination: undefined,
    currency: undefined,
    currencyProduct: undefined,
    fechainiciofijacion: undefined,
    fechafinfijacion: undefined,
    priceFixingStart: undefined,
    priceFixingEnd: undefined,
    condition: undefined,
    deliveryPeriod: undefined,
    conditionName: undefined,
    grainName: undefined,
    createdBy: undefined,
    zoneId: undefined,
    zoneName: undefined,
    fromContra: undefined,
    origin: undefined,
    location: undefined,
    productAmount: undefined
  },
  tradepricetoset: {
    operationId: undefined,
    companyId: undefined,
    userId: undefined,
    operationType: undefined,
    grain: undefined,
    weight: undefined,
    price: undefined,
    product: undefined,
    amount: undefined,
    customerId: undefined,
    shippingFrom: '',
    expiration: '',
    destination: undefined,
    currency: undefined,
    currencyProduct: undefined,
    currencyProductSymbol: undefined,
    fechainiciofijacion: undefined,
    fechafinfijacion: undefined,
    priceFixingStart: undefined,
    priceFixingEnd: undefined,
    condition: undefined,
    deliveryPeriod: undefined,
    conditionName: undefined,
    grainName: undefined,
    createdBy: undefined,
    zoneId: undefined,
    zoneName: undefined,
    fromContra: undefined,
    origin: undefined,
    location: undefined,
    productAmount: undefined
  },
  fixings: undefined,
  conditiontype: undefined,
  mydata: undefined,
  zones: undefined,
  harvest: undefined,
  origin: undefined,
  location: undefined,
  detailsContract: undefined,
  contractsID: {
    grain: '',
    condition: '',
    location: '',
    zone: '',
    exchanger: '',
    currency: '',
    currencyProduct: ''
  },
  contractsDates: {
    priceFixingEnd: '',
    priceFixingStart: '',
    dateFrom: '',
    dateTo: '',
    shippingFrom: '',
    expiration: ''
  }
}

export default initialState
