import React, { useState } from 'react'
import Swiper from 'react-native-web-swiper'
import { Dimensions, ImageBackground, View } from 'react-native'
import { Slide } from 'components'
import { ViewImageContainer, styles } from './styles'

const Carousel: React.FC = () => {
  const [hasIndexChanged, setHasIndexChanged] = useState<boolean>(true)
  const dimentionWidth = Dimensions.get('window').width
  const dimentionHeight = Dimensions.get('window').height

  return (
    <ViewImageContainer>
      <View>
        <ImageBackground
          source={require('@qirapagos/lib/assets/LoginWallet.png')}
          resizeMode="stretch"
          imageStyle={styles.imageLogin}
        />
        <ImageBackground
          source={require('@qirapagos/lib/assets/EllipseLoginWallet.png')}
          resizeMode="stretch"
          imageStyle={styles.image}
        />
      </View>
      <Swiper
        loop
        timeout={3}
        containerStyle={{ height: '100%' }}
        controlsProps={{
          dotsTouchable: true,
          prevTitle: '',
          nextTitle: '',
          dotsPos: 'center',
          dotsWrapperStyle: {
            right: dimentionWidth * 0.035,
            bottom: dimentionHeight * 0.01
          }
        }}
        onIndexChanged={() => {
          setHasIndexChanged(!hasIndexChanged)
        }}
        innerContainerStyle={{ justifyContent: 'flex-end' }}
      >
        <Slide
          title="No pierdas más tiempo liquidando"
          subtitle={
            'Tu cuenta en dinero y en granos.\n Vendé, comprá y canjeá.'
          }
        />

        <Slide
          title="Gestioná tus cupos"
          subtitle="Gestioná tus cupos en pocos clicks de forma rápida y fácil."
        />

        <Slide
          title="Obtené tu cuenta en pesos"
          subtitle={
            'Aparte de transaccionar en granos, obtené tu \n cuenta en pesos.'
          }
        />
      </Swiper>
    </ViewImageContainer>
  )
}
export default Carousel
