import React from 'react'
import OperationSuccess from '@qirapagos/lib/assets/svg/OperationSuccess'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  ButtonsContainer,
  Drawer,
  ContentContainer,
  DrawerContainer,
  BackButton,
  MainButton,
  MainButtonText
} from './styles'
import BackArrow from 'assets/svg/BackArrow'
import { Drawers } from 'store/drawer/types'
import wrapper from 'components/organisms/Drawers/wrapper'
import { Colors } from '@qirapagos/lib/theme'
import { useAppSelector } from 'hooks/redux'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const QuotaCreateSuccess: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { onNavigate } = useNavigation()
  const { selected } = useAppSelector(
    state => state.contractsV2
  )

  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
    >
      <DrawerContainer>
        <BackButton onPress={onClose}>
          <BackArrow color={Colors.white} />
        </BackButton>
        <ContentContainer>
          <OperationSuccess />
          <Title>Solicitud de cupo enviada</Title>
        </ContentContainer>
        <ButtonsContainer>
          <MainButton
            onPress={() => {
              onClose()
              onNavigate(`/content/grains/activity/${selected?.id}`)
            }}
          >
            <MainButtonText testID="drawer-btn">
              Entendido
            </MainButtonText>
          </MainButton>
        </ButtonsContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(QuotaCreateSuccess, Drawers.QUOTA_CREATE_SUCCESS)
