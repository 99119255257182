import numeral from 'numeral'

numeral.register('locale', 'es_ar', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: '',
    million: '',
    billion: '',
    trillion: ''
  },
  ordinal () {
    return ''
  },
  currency: {
    symbol: '$'
  }
})

numeral.locale('es_ar')

export const formatAmount = (number?: string | number) => {
  const float = `${number || 0}`.replace(',', '.')
  const values = float.split('.')

  return [
    numeral(parseFloat(values[0])).format('0', Math.floor),
    values.length > 1 ? values[1] : '00'
  ]
}

export const checkAmountEndsWith = (value: string) => ({
  separator: value.endsWith('.'),
  pointNumber: /\.\d$/.test(value)
})
