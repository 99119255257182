import { TransfersAction } from '@qirapagos/web/src/store/transfers/types'

import { Action } from '../../interfaces/reduxInterfaces'
import initialState from './initialState'
import {
  TransfersState,
  TransfersActionTypes,
  ContactsActionTypes
} from './types'

export default function transferReducer (
  state = initialState,
  action: Action
): TransfersState {
  switch (action.type) {
    case TransfersActionTypes.GET_MOVEMENTS: {
      const { movements, currentPage, countPages } = action.payload
      return {
        ...state,
        movements,
        currentPage,
        countPages
      }
    }
    case TransfersActionTypes.GET_BALANCE:
      return {
        ...state,
        balance: action.payload
      }
    case TransfersActionTypes.GET_ACCOUNT_INFO:
      return {
        ...state,
        account: action.payload
      }
    case TransfersActionTypes.SET_SHOW_BALANCE:
      return {
        ...state,
        showBalance: !action.payload
      }
    case TransfersActionTypes.GET_SHOW_BALANCE:
      return {
        ...state,
        showBalance: action.payload
      }
    case TransfersActionTypes.GET_TRANSFER_ACCOUNT:
      return {
        ...state,
        transferAccount: {
          ...state.transferAccount,
          ...action.payload
        }
      }
    case TransfersActionTypes.GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload
      }
    case TransfersActionTypes.GET_ACCOUNT_ID:
      return {
        ...state,
        accountId: action.payload
      }
    case TransfersActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case TransfersActionTypes.SET_LOADING_STATEMENTS:
      return {
        ...state,
        isLoadingStatements: action.payload
      }
    case TransfersActionTypes.CLEAR_ACCOUNT_INFO: {
      return {
        ...state,
        transferAccount: initialState.transferAccount
      }
    }
    case TransfersActionTypes.SET_FILTERED_MOVEMENTS:
      return {
        ...state,
        filteredMovements: action.payload
      }
    case TransfersActionTypes.DISMISS_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case ContactsActionTypes.NEW_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, action.payload]
      }
    case ContactsActionTypes.DELETE_CONTACT:
      return {
        ...state
      }
    case ContactsActionTypes.EDIT_OBSERVATION:
      return {
        ...state
      }
    case TransfersActionTypes.SET_TRANSFER_LIMIT: {
      return {
        ...state,
        account: {
          ...state.account,
          transactionLimit: action.payload
        }
      }
    }
    case TransfersActionTypes.LOGOUT:
      return {
        ...initialState
      }
    case TransfersActionTypes.SET_ACCOUNT_LOADING:
      return {
        ...state,
        isInfoLoading: true
      }
    case TransfersActionTypes.DISMISS_ACCOUNT_LOADING:
      return {
        ...state,
        isInfoLoading: false
      }

    case TransfersAction.UPDATE_ALIAS:
      return {
        ...state,
        account: {
          ...state.account,
          alias: action.payload
        }
      }
    default:
      return state
  }
}
