import { gray } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components/native'

export const ViewMargin = styled.View`
  margin: 30px;
`

export const TextStyled = styled.Text`
  color: ${gray};
`
