import * as Sentry from '@sentry/react'
import { ThunkDispatchType } from '@qirapagos/lib/interfaces/reduxInterfaces'
import * as MenuActions from 'store/menu/actions'
import { MultiEnterpriseServices, MenuServices } from 'services'
import { getToken, getCompanyId } from 'services/utils'
import { setMultiEnterpriseAssociations } from 'store/menu/actions'
import { ErrorMessages } from '@qirapagos/lib/utils/common'
import { ApiResponse } from 'apisauce'

export const getMultiEnterpriseAssociations =
  () => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(MenuActions.setMenuLoading())
      const token = await getToken()
      const loginId = await localStorage.getItem('loginId')
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const response: any = await MultiEnterpriseServices.getAssociations(
        loginId as string,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          token,
          loginId
        }
      })
      if (response.status === 400) {
        dispatch(setMultiEnterpriseAssociations([]))
        dispatch(MenuActions.dismissMenuLoading())
        return
      }
      dispatch(setMultiEnterpriseAssociations(response.data))
      dispatch(MenuActions.dismissMenuLoading())
    } catch (error) {
      Sentry.captureMessage(
        `Error al conseguir mis asociaciones:${error}`,
        'warning'
      )
      dispatch(setMultiEnterpriseAssociations([]))
      dispatch(MenuActions.dismissMenuLoading())
    }
  }

export const getRedeemers =
    (page = 1, limit = 5) =>
      async (dispatch: ThunkDispatchType) => {
        try {
          dispatch(MenuActions.setMenuLoading())
          const token = await getToken()
          const keyEndpoints = await localStorage.getItem('keyendpoints')
          const response: any = await MenuServices.getRedeemers(
            limit,
            page,
            token as string,
            keyEndpoints as string
          )
          Sentry.setExtras({
            data: {
              response: response.data,
              token
            }
          })
          if (response.status !== 200 && response?.data?.error_message) {
            throw ErrorMessages.DEFAULT_REQUEST_ERROR
          }
          dispatch(MenuActions.setCountPageRedeemers(response.data.countPages))
          dispatch(MenuActions.setRedeemers(response.data.redeemingCompany))
          dispatch(MenuActions.dismissMenuLoading())
        } catch (error) {
          Sentry.captureMessage(
          `Error al conseguir representantes:${error}`,
          'warning'
          )
          dispatch(MenuActions.dismissMenuLoading())
          dispatch(MenuActions.setClearRedeemers())
        }
      }

export const getVendor = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(MenuActions.setMenuLoading())
    const token = await getToken()
    const companyId = await localStorage.getItem('companyId')
    const response: ApiResponse<any> = await MenuServices.vendor(
      token,
      companyId
    )
    if (response.status !== 200) {
      throw new Error(ErrorMessages.DEFAULT_BACKEND_ERROR)
    }
    dispatch(MenuActions.setVendor(response.data))
    dispatch(MenuActions.dismissMenuLoading())
  } catch (error) {
    dispatch(MenuActions.dismissMenuLoading())
  }
}

export const getTypePaymentMethod =
  () => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(MenuActions.setMenuLoading())
      const token = await getToken()
      const response: ApiResponse<any> = await MenuServices.typePaymentMethods(
        token
      )
      if (response.status !== 200 && response?.data?.error_message) {
        throw ErrorMessages.DEFAULT_REQUEST_ERROR
      }
      dispatch(MenuActions.setTypePaymentMethod(response.data))
      dispatch(MenuActions.dismissMenuLoading())
    } catch (error) {
      dispatch(MenuActions.dismissMenuLoading())
    }
  }

export const getPaymentMethod = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(MenuActions.setMenuLoading())
    const token = await getToken()
    const companyId = await getCompanyId()
    const response: ApiResponse<any> = await MenuServices.paymentMethods(
      companyId,
      token
    )
    if (response.status !== 200 && response?.data?.error_message) {
      throw ErrorMessages.DEFAULT_REQUEST_ERROR
    }
    dispatch(MenuActions.setPaymentMethod(response.data))
    dispatch(MenuActions.dismissMenuLoading())
  } catch (error) {
    dispatch(MenuActions.dismissMenuLoading())
  }
}

export const newPaymentMethod =
  (
    preferenceId: number,
    cbu: number,
    setError: (arg0: string, arg1: { message: any }) => void,
    navigation: any
  ) =>
    async (dispatch: ThunkDispatchType) => {
      try {
        dispatch(MenuActions.setMenuLoading())
        const token = await getToken()
        const companyId = await getCompanyId()
        const body = {
          companyId,
          preferenceId,
          cbu
        }
        const response: ApiResponse<any> = await MenuServices.newPaymentMethod(
          body,
          token
        )
        if (response.status !== 200 && response?.data?.error_message) {
          throw ErrorMessages.DEFAULT_REQUEST_ERROR
        }
        dispatch(MenuActions.setPaymentMethod(response.data))
        dispatch(MenuActions.dismissMenuLoading())
        navigation.navigate('ConfirmedPaymentMethod')
      } catch (error) {
        dispatch(MenuActions.dismissMenuLoading())
        setError('cbu', { message: error })
      }
    }

export const checkIfEnable =
  (setError: (arg0: string, arg1: { type: string; message: any }) => void) =>
    async (dispatch: ThunkDispatchType) => {
      try {
        dispatch(MenuActions.setMenuLoading())
        const token = await getToken()
        const companyId = await localStorage.getItem('companyId')
        const responseIsEnabled: ApiResponse<any> =
          await MenuServices.enableToAddAgent(companyId, token)
        if (
          responseIsEnabled.status !== 200 ||
          responseIsEnabled.data?.status === false
        ) {
          throw responseIsEnabled?.data
            ? 'No habilitado como representante'
            : responseIsEnabled.data?.errormsg
        }
        dispatch(MenuActions.dismissMenuLoading())
      } catch (error) {
        dispatch(MenuActions.dismissMenuLoading())
        const errorMessage = error || 'No habilitado como representante'
        setError('cuit', { type: 'registered', message: errorMessage })
      }
    }
