import styled from 'styled-components/native'
import { StyleSheet } from 'react-native'

type TypeViewRenderItemContainer = {
  horizontal?: boolean
}

export const ViewRenderItemContainer = styled.View<TypeViewRenderItemContainer>`
  align-items: center;
  margin: 4% 0;
  ${({ horizontal }) =>
    horizontal &&
    `
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1.5% 0;
    `}
`

export const TextRenderItem = styled.Text`
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(22, 28, 39);
`

export const styles = StyleSheet.create({
  title: {
    flex: 1,
    textAlign: 'right',
    marginRight: 10
  },
  flatList: {
    marginTop: 20
  },
  contentStyle: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50
  }
})
