import React from 'react'
import { AuthProvider } from 'hooks/useAuth'
import { Routes, Route } from 'react-router-dom'
import { Welcome } from 'screens'
import AuthRoutes from './auth'
import ContentRoutes from './content'
import ProtectedRoute from './ProtectedRoute'

const Router = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="auth/*" element={<AuthRoutes />} />
        <Route
          path="content/*"
          element={(
            <ProtectedRoute>
              <ContentRoutes />
            </ProtectedRoute>
          )}
        />
        <Route path="*" element={<Welcome />} />
      </Routes>
    </AuthProvider>
  )
}

export default Router
