import React, { useState, useEffect, useRef, ComponentType } from 'react'
import 'react-modern-drawer/dist/index.css'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'hooks/redux'
import { hideDrawer } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'

interface DrawerProps {
  onClose: () => void;
  showDrawer: boolean;
}

/**
 * This wrapper was made to show and hide Drawers keeping the animations
 * without the need to keep the Drawers mounted all the time.
 */

const wrapper = (Drawer: ComponentType<DrawerProps>, drawerName: Drawers) =>
  (props: Omit<DrawerProps, 'onClose' | 'showDrawer'>) => {
    const dispatch = useDispatch()
    const { actives } = useAppSelector((state) => state.drawer)
    const [showContractLocal, setShowContractLocal] = useState(false)
    const isClosing = useRef(false)

    useEffect(() => {
      if (actives.includes(drawerName) && !showContractLocal && !isClosing.current) {
        setTimeout(() => {
          setShowContractLocal(true)
        }, 150)
      }
    }, [actives, showContractLocal])

    const onClose = () => {
      setShowContractLocal(false)
      isClosing.current = true
      setTimeout(() => {
        dispatch(hideDrawer(drawerName))
        isClosing.current = false
      }, 400)
    }

    if (!actives.includes(drawerName)) {
      return null
    }

    return (
    // eslint-disable-next-line react/jsx-props-no-spreading
      <Drawer {...props} onClose={onClose} showDrawer={showContractLocal} />
    )
  }

export default wrapper
