import { useEffect, useState } from 'react'
import { getSimpleDataAsyncStorage } from '../utils/asyncStorage'

export default function useHasSisa () {
  const [hasSisa, setHasSisa] = useState<boolean>()
  let isSisaApproved: string | undefined
  useEffect(() => {
    getSimpleDataAsyncStorage('hasSisa').then(value => {
      isSisaApproved = value
      setHasSisa(isSisaApproved === 'true')
    })
  }, [])

  /* Here we need to change the return true for the actual state hasSisa
  to proper functionality of the hook, now it's flagged to still use
  the contracts functionality for testing */
  return hasSisa
}
