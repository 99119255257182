import * as React from 'react'

function Envelope () {
  return (
    <svg width={16} height={12} viewBox="0 0 16 12" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M15.5 1.5C15.5.675 14.825 0 14 0H2C1.175 0 .5.675.5 1.5v9c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-9zm-1.5 0L8 5.25 2 1.5h12zm0 9H2V3l6 3.75L14 3v7.5z"
        fill="#7D66FF"
      />
    </svg>
  )
}

export default Envelope
