import * as yup from 'yup'
import {
  ErrorMessages,
  VIRTUAL_ZERO_NUMBER,
  VIRTUAL_ZERO_STRING
} from '../constants/validations'
import { ObjectSchema } from 'yup'

export const merge = (...schemas: ObjectSchema[]) => {
  const [first, ...rest] = schemas
  return rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  )
}

export const TonsSchemeValidator = yup.object().shape({
  tons: yup
    .number()
    .typeError(ErrorMessages.MULTIPLE_OF_THIRTY)
    .transform(value => value || '')
    .positive(ErrorMessages.MULTIPLE_OF_THIRTY)
    .integer(ErrorMessages.MULTIPLE_OF_THIRTY)
    .test(
      'isMultipleOf30',
      ErrorMessages.MULTIPLE_OF_THIRTY,
      value => value! % 30 === 0
    )
    .transform((value, originalValue) =>
      /\s/.test(originalValue) ? NaN : value
    )
})

export const priceSchemeValidator = yup.object().shape({
  price: yup
    .string()
    .matches(/^\d+(\.\d{1,2})?$/, ErrorMessages.INVALID_FORMAT)
    .transform(value => value || VIRTUAL_ZERO_STRING)
    .test('startWithZero', ErrorMessages.INVALID_FORMAT, value => {
      if (
        value!.length > 1 &&
        !value?.includes('.') &&
        value?.startsWith('0')
      ) {
        return false
      }
      if (value!.length === 1 && value?.startsWith('0')) return false
      return true
    })
    .test(
      'isGreaterThanZero',
      ErrorMessages.REQUIRED,
      value => +value! > VIRTUAL_ZERO_NUMBER
    )
    .required(ErrorMessages.REQUIRED)
})

export const amountToExchangeSchemeValidator = yup.object().shape({
  amount: yup
    .string()
    .matches(/^\d+(\.\d{1,2})?$/, ErrorMessages.INVALID_FORMAT)
    .transform(value => value || VIRTUAL_ZERO_STRING)
    .test('startWithZero', ErrorMessages.INVALID_FORMAT, value => {
      if (
        value!.length > 1 &&
        !value?.includes('.') &&
        value?.startsWith('0')
      ) {
        return false
      }
      if (value!.length === 1 && value?.startsWith('0')) return false
      return true
    })
    .test(
      'isGreaterThanZero',
      ErrorMessages.REQUIRED,
      value => +value! > VIRTUAL_ZERO_NUMBER
    )
    .required(ErrorMessages.REQUIRED)
})

export const onlyNumberSchemeValidation = yup.object().shape({
  quotas: yup
    .number()
    .test('exponential', ErrorMessages.INTERGER, value => {
      if (value && value.toString().includes('e')) return true
      return false
    })
    .typeError('Por favor ingresá números enteros')
    .required(ErrorMessages.REQUIRED)
})

export const onlyNumberQuotas = yup.object().shape({
  quotas: yup
    .string()
    .matches(/^\d+(\d{1,2})?$/, ErrorMessages.INVALID_FORMAT_QUOTAS)
    .test('startWithZero', 'Por favor ingrese un numero distinto de 0', value => {
      if (
        value!.length > 1 &&
        value?.startsWith('0')
      ) {
        return false
      }
      if (value!.length === 1 && value?.startsWith('0')) return false
      return true
    })
    .test(
      'isGreaterThanZero',
      ErrorMessages.REQUIRED,
      value => +value! > VIRTUAL_ZERO_NUMBER
    )
    .required(ErrorMessages.REQUIRED)
})

export const agentSchemeValidation = yup.object().shape({
  cuit: yup
    .string()
    .matches(/^[\d-]+$/, ErrorMessages.INVALID_CUIT)
    .matches(/^[^-]*$/, ErrorMessages.INVALID_CUIT_HAS_HYPHEN)
    .required(ErrorMessages.REQUIRED)
})

// TODO: Ver como manejarlo con un campo de tipo number lo mismo para amountToExchange
/* export const priceSchemeValidator = yup.object().shape({
  price: yup
    .number()
    .typeError('Debe ser un número')
    .positive('Debe ser positivo')
    .test(
      'startsWithZero',
      ErrorMessages.INVALID_FORMAT,
      value => !value?.startsWith(0)
    )
}) */
