export interface Notification {
  id: number
  title: string
  body: string
  createdAt: string
  viewed: boolean
}

export interface NotificationState {
  pushNotifications: Notification[]
  selected: number
  error?: Error
}

export enum NotificationsActionsTypes {
  SET_NOTIFICATIONS = '@NOTIFICATIONS:SET_NOTIFICATIONS',
  SET_NOTIFICATION = '@NOTIFICATIONS:SET_NOTIFICATION',
  REMOVE = '@NOTIFICATIONS:REMOVE',
  REMOVE_ALL = '@NOTIFICATIONS:REMOVE_ALL',
  LOGOUT = '@NOTIFICATIONS:LOGOUT',
  ERROR= '@NOTIFICATIONS:ERROR',
  MARK_AS_VIEWED= '@NOTIFICATIONS:MARK_AS_VIEWED',
}
