import React, { useEffect, useCallback } from 'react'
import { Field } from 'components/molecules/Field'
import Helmet from 'components/utilities/Helmet'
import { useForm } from 'react-hook-form'
import useCheckAlias from 'hooks/useCheckAlias'
import { useDispatch, useSelector } from 'react-redux'
import { setShowDrawerError, setShowDrawerSuccess } from 'store/modals/actions'
import { useAppSelector } from 'hooks/redux'
import { SCREEN_TITLES } from 'utils/navigation'
import {
  Button,
  ButtonContainer,
  ViewContainer,
  TextTitle,
  FieldContainer,
  textInfo
} from './styles'
import CustomText from 'components/atoms/CustomText'
import useNavigation from 'hooks/useNavigation'
import { getAccountInfo } from 'store/transfers/thunks'
import AliasCheckers from 'components/molecules/MyAccountDetails/AliasCheckers'
import TransferLimitSkeleton from 'assets/skeletons/TransferLimitSkeleton'
import { changeAlias } from 'store/auth/thunks'

type FormData = {
  alias: string
}

const ChangeAlias = () => {
  const { account } = useAppSelector(state => state.transfers)
  const { isLoading } = useSelector((state: any) => state.auth)
  const { isInfoLoading } = useSelector((state: any) => state.transfers)
  const dispatch: any = useDispatch()
  const { onNavigate } = useNavigation()

  const { control, watch, handleSubmit, clearErrors, reset } = useForm({
    defaultValues: {
      alias: account.alias.trim()
    }
  })

  useEffect(() => {
    dispatch(getAccountInfo())
  }, [dispatch])

  useEffect(() => {
    reset({ alias: account.alias.trim() })
  }, [account, reset])

  const watchAlias = watch('alias')

  const {
    checkAliasLength,
    checkCharacters
  } = useCheckAlias(watchAlias)

  const isAliasValid =
  checkAliasLength && checkCharacters

  const isDisabled =
    !watchAlias ||
    !isAliasValid

  const onPressSubmit = async (values: FormData) => {
    try {
      await dispatch(changeAlias(values.alias))
      dispatch(
        setShowDrawerSuccess(
          true,
          true,
          '¡El alias se ha editado con éxito!',
          '',
          {
            path: '/content/your-account'
          }
        )
      )
    } catch (error) {
      dispatch(
        setShowDrawerError(
          true,
          error as string,
          '',
          {
            path: '/content/your-account'
          }
        )
      )
    }
  }

  const onCancel = useCallback(() => {
    onNavigate('/content/your-account/details', { title: 'Datos de mi cuenta' })
  }, [onNavigate])

  if (isInfoLoading) return <TransferLimitSkeleton investmentsFlag={false} />

  return (
    <ViewContainer>
      <Helmet title={SCREEN_TITLES.CHANGE_ALIAS} />
      <TextTitle>Editar alias</TextTitle>
      <CustomText size="xmedium" color='black' customStyle={textInfo}>
        Creá un alias que te sea fácil
        de recordar y que no hayas usado en el último año
      </CustomText>
      <FieldContainer>
        <Field
          control={control}
          name="alias"
          placeholder="Alias"
          type="text"
          clearErrors={clearErrors}
        />
        <AliasCheckers
          checkAliasLength={checkAliasLength}
          checkCharacters={checkCharacters}
        />
      </FieldContainer>
      <ButtonContainer>
        <Button secondary onPress={onCancel} disabled={isLoading}>
          Volver atrás
        </Button>
        <Button
          onPress={handleSubmit(onPressSubmit)}
          disabled={isLoading || isDisabled}
          loading={isLoading}
        >
          Cambiar alias
        </Button>
      </ButtonContainer>
    </ViewContainer>
  )
}

export default ChangeAlias
