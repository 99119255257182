import React from 'react'
import { FlatList } from 'react-native'
import CustomText from 'components/atoms/CustomText'
import { TextRenderItem, ViewRenderItemContainer } from './styles'

interface Props<TData> {
  data: TData[]
  positioning?: 'horizontal' | 'vertical'
  customStyle?: {
    title: any
    flatList: any
    containerStyle?: any
  }
}

interface IRenderItemProps<TItem> {
  item: TItem
}
const ItemSeparator = () => {
  return <div style={{ height: 10 }} />
}

const CustomList = <TData, >({
  data,
  customStyle,
  positioning
}: Props<TData>) => {
  const renderItem = <TItem, >({ item }: IRenderItemProps<TItem>) => {
    return (
      item && (
        <ViewRenderItemContainer horizontal={positioning === 'horizontal'}>
          <CustomText
            color="gray"
            size="xmedium"
            customStyle={customStyle?.title}
            weight="mediumfont"
          >
            {Object.keys(item)}
          </CustomText>
          <TextRenderItem>{Object.values(item)}</TextRenderItem>
        </ViewRenderItemContainer>
      )
    )
  }

  return (
    <FlatList
      data={data}
      renderItem={({ item }) => renderItem({ item })}
      style={customStyle?.flatList}
      contentContainerStyle={customStyle?.containerStyle}
      ItemSeparatorComponent={ItemSeparator}
    />
  )
}

export default CustomList

CustomList.defaultProps = {
  customStyle: {
    title: {},
    flatList: {},
    containerStyle: {}
  },
  positioning: 'horizontal'
}
