import React from 'react'
import Svg, { Path } from 'react-native-svg'

function Icon () {
  return (
    <Svg width="23" height="23" fill="none" viewBox="0 0 23 23">
      <Path
        fill="#FFC83A"
        stroke="#FFC83A"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M11.5 20.125a8.625 8.625 0 100-17.25 8.625 8.625 0 000 17.25z"
      />
      <Path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.5 7.188v5.03"
      />
      <Path
        fill="#fff"
        d="M11.5 16.531a1.078 1.078 0 100-2.156 1.078 1.078 0 000 2.156z"
      />
    </Svg>
  )
}

export default Icon
