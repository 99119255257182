import React, { ReactNode } from 'react'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text
} from '@chakra-ui/react'
import {
  black,
  grayLight,
  grayTextLight,
  violet,
  white
} from '@qirapagos/lib/theme/colors'

interface Props {
  accordionTitle: string
  accordionSubTitle?: string
  accordionBody: any
  customStyle?: any
  isDisabled?: boolean
  icon?: ReactNode
}

const AccordionItemComponent: React.FC<Props> = ({
  accordionTitle,
  accordionSubTitle,
  accordionBody,
  customStyle,
  isDisabled,
  icon
}) => {
  return (
    <AccordionItem
      boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1)"
      bg={grayLight}
      style={customStyle}
      isDisabled={isDisabled}
    >
      {({ isExpanded }) => (
        <>
          <Heading as="h2" fontFamily="Poppins">
            <AccordionButton
              bg={white}
              h={20}
              fontSize={25}
              color={violet}
              justifyContent="space-between"
              _hover={{ color: 'none' }}
            >
              <Box>
                <Text
                  flex="1"
                  textAlign="left"
                  fontWeight="medium"
                  fontSize={{ sm: '14px', md: '16px', lg: '18px' }}
                  ml={50}
                  color={black}
                  mt={isExpanded ? 5 : 0}
                >
                  {accordionTitle}
                </Text>
                {isExpanded && accordionSubTitle && (
                  <Text
                    color={grayTextLight}
                    textAlign="left"
                    fontWeight={100}
                    fontSize={{ sm: '10px', md: '12px', lg: '14px' }}
                    ml={70}
                    mt={2}
                  >
                    {accordionSubTitle}
                  </Text>
                )}
              </Box>
              <Box mr={50}>{icon}</Box>
            </AccordionButton>
          </Heading>
          <AccordionPanel m={0} p={0} bg="white">
            {accordionBody}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

export default AccordionItemComponent

AccordionItemComponent.defaultProps = {
  accordionSubTitle: '',
  customStyle: {},
  isDisabled: false,
  icon: <AccordionIcon />
}
