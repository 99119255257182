/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { FlatList, TouchableOpacity, View } from 'react-native'
import RenderIf from 'components/utilities/RenderIf'
import useShow from '@qirapagos/lib/hooks/useShow'
import Field from '@qirapagos/lib/components/molecules/Field/index.web'
import DownArrow from '@qirapagos/web/src/assets/svg/DownArrow'
import UpArrow from '@qirapagos/web/src/assets/svg/UpArrow'
import Check from '@qirapagos/lib/assets/svg/Check'
import { purple, white } from '@qirapagos/lib/theme/colors'
import OutsideAlerter from 'components/utilities/OutsideClickListener'
import { ViewContainer, styles } from './style'
import { ButtonContainer, ContentContainer, TextList } from './styleWeb'
interface Props {
  data: any
  control: any
  name: string
  label: string
  editable?: boolean
  accessibilityLabelValue: any
  setValue: any
  customStyle?: any
  setSelected: (value: string) => void
  selected: string
  filterBy?: string
  setSelectedID?: any
  isDisabled?: boolean
}

const FieldSelect: React.FC<Props> = ({
  data,
  control,
  name,
  label,
  editable,
  accessibilityLabelValue,
  setValue,
  customStyle,
  setSelected,
  selected,
  filterBy,
  setSelectedID,
  isDisabled
}) => {
  const { show, setShow } = useShow()
  const renderItem = ({ item }: any) => {
    const itemValue = filterBy ? item[filterBy].toUpperCase() : item
    const onPress = () => {
      setValue(name, itemValue)
      setSelected(itemValue)
      setSelectedID(item.id)
      setShow(!show)
    }
    return (
      <ButtonContainer onClick={onPress}>
        <ContentContainer>
          {filterBy
            ? (
              <TextList>
                {name === 'grain'
                  ? item[filterBy].toUpperCase()
                  : item[filterBy]}
              </TextList>
              )
            : (
              <TextList>{item}</TextList>
              )}
          <View
            style={[
              styles.check,
              filterBy
                ? {
                    backgroundColor: selected === item.id ? purple : white
                  }
                : { backgroundColor: selected === item ? purple : white }
            ]}
          >
            <Check style={styles.checkmark} isPurple={undefined} />
          </View>
        </ContentContainer>
      </ButtonContainer>
    )
  }

  return (
    <ViewContainer style={[customStyle, { opacity: isDisabled ? 0.4 : 1 }]}>
      <Field
        customStyleContainer={show && styles.customStyleContainer}
        control={control}
        name={name}
        label={label}
        disabled={isDisabled}
        rules={{ required: true }}
        onPress={() => setShow(!show)}
        accessibilityLabelValue={accessibilityLabelValue}
      />
      <TouchableOpacity
        style={styles.downArrow}
        onPress={() => setShow(!show)}
        disabled={!editable}
      >
        {show ? <UpArrow width={16} height={16} /> : <DownArrow width={16} height={16} />}
      </TouchableOpacity>
      <RenderIf isTrue={show}>
        <OutsideAlerter setState={setShow} state={show}>
          <FlatList
            data={data}
            renderItem={renderItem}
            style={styles.flatList}
            keyExtractor={(_, index) => index.toString()}
          />
        </OutsideAlerter>
      </RenderIf>
    </ViewContainer>
  )
}

export default FieldSelect

FieldSelect.defaultProps = {
  editable: true,
  isDisabled: false,
  customStyle: {},
  filterBy: '',
  setSelectedID: () => null
}
