/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { ReactComponent as BellWithNotifications } from 'assets/svg/BellWithNotifications.svg'
import { ReactComponent as BellWithNoNotifications } from 'assets/svg/BellWithNoNotifications.svg'
import { violet } from 'theme/colors'
import { EnterpriseSelector } from 'components'
import BackArrow from 'assets/svg/BackArrow'
import useNavigation from 'hooks/useNavigation'
import { TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { setShowDrawerNotification } from 'store/modals/actions'
import MenuHamburger from 'assets/svg/MenuHamburger'
import { screenTitle } from './constants'
import { ViewNotifyAndMe } from './style'
import {
  ViewTopBar,
  TouchableOpacityHamburger,
  ViewTitleContainer,
  TextTitle,
  ViewEnterpriseSelector
} from './styleWeb'
import { getNotifications } from 'store/notifications/thunks'

interface Props {
  title?: string
  hasBackArrow?: boolean
  onClickMenuHamburger: () => void
}

const TopBar: React.FC<Props> = ({
  title = '',
  hasBackArrow,
  onClickMenuHamburger
}) => {
  const [newTitleScreen, setNewScreenTitle] = useState('')
  const { onNavigate } = useNavigation()
  const pushNotifications = useSelector((state: any) =>
    state.notifications.pushNotifications.filter(
      (notification: any) => !notification.viewed
    )
  )
  const dispatch = useDispatch()
  const { location } = useNavigation()
  const newTitle = location.pathname

  const handleBackArrowPress = () => {
    onNavigate(-1)
  }

  const onPressBell = () => {
    dispatch(getNotifications())
    dispatch(setShowDrawerNotification(true))
  }

  useEffect(() => {
    if (title === '') {
      const findTerm = (term: string) => {
        if (newTitle.includes(term)) {
          return newTitle
        }
      }
      switch (newTitle) {
        case findTerm('wallet'):
          setNewScreenTitle(screenTitle.INICIO)
          break
        case findTerm('grain'):
          setNewScreenTitle(screenTitle.GRANOS)
          break
        case findTerm('your-account'):
          setNewScreenTitle(screenTitle.TU_CUENTA)
          break
        case findTerm('my-data'):
          setNewScreenTitle(screenTitle.MIS_DATOS)
          break
        case findTerm('documentation'):
          setNewScreenTitle(screenTitle.DOCUMENTACION)
          break
        case findTerm('redeemers'):
          setNewScreenTitle(screenTitle.CANJEADORES)
          break
        case findTerm('customer-support'):
          setNewScreenTitle(screenTitle.ATENCION_AL_CLIENTE)
          break
        case findTerm('agents'):
          setNewScreenTitle(screenTitle.MI_REPRESENTANTE)
          break
        case findTerm('your-enterprises'):
          setNewScreenTitle(screenTitle.MIS_EMPRESAS)
          break
        case findTerm('block-account'):
          setNewScreenTitle(screenTitle.BLOQUEAR_CUENTA)
          break
        default:
          break
      }
    }
  }, [])

  return (
    <ViewTopBar>
      <ViewTitleContainer>
        <TouchableOpacityHamburger onClick={onClickMenuHamburger}>
          <MenuHamburger />
        </TouchableOpacityHamburger>
        {hasBackArrow && (
          <TouchableOpacity
            style={{
              marginLeft: 10,
              marginRight: 10
            }}
            onPress={handleBackArrowPress}
          >
            <BackArrow color={violet} />
          </TouchableOpacity>
        )}
        <TextTitle hasBackArrow={hasBackArrow}>
          {title || newTitleScreen}
        </TextTitle>
      </ViewTitleContainer>
      <ViewNotifyAndMe>
        <div>
          <TouchableOpacity onPress={onPressBell}>
            {pushNotifications.length
              ? (
                <BellWithNotifications />
                )
              : (
                <BellWithNoNotifications />
                )}
          </TouchableOpacity>
        </div>
        <ViewEnterpriseSelector>
          <EnterpriseSelector />
        </ViewEnterpriseSelector>
      </ViewNotifyAndMe>
    </ViewTopBar>
  )
}

export default TopBar
