import React from 'react'
import { Container, Row, Label, Value } from './styles'
import { formatStringDateType } from 'utils/date'
import { ILiquidation } from '@qirapagos/web/src/store/contracts/types'
import { LiquidationStatus } from 'screens/Home/Grains/ActivityGrains'

interface Props {
  liquidation: ILiquidation
}

const ApplicationDetailList: React.FC<Props> = ({ liquidation }) => {
  return (
    <Container>
      <Row>
        <Label>Nro. de contrato</Label>
        <Value>{liquidation.agreement}</Value>
      </Row>
      <Row>
        <Label>Comprobante</Label>
        <Value>{liquidation.receipt}</Value>
      </Row>
      <Row>
        <Label>Descripción</Label>
        <Value>{liquidation.description}</Value>
      </Row>
      <Row>
        <Label>Cliente comprador</Label>
        <Value>{liquidation.buyerClient}</Value>
      </Row>
      <Row>
        <Label>Cliente vendedor</Label>
        <Value>{liquidation.sellerClient}</Value>
      </Row>
      <Row>
        <Label>Kgs liquidados</Label>
        <Value>{liquidation.liquidatedKg}</Value>
      </Row>
      <Row>
        <Label>Kgs liquidados parcial</Label>
        <Value>{liquidation.partiallyLiquidatedKg}</Value>
      </Row>
      <Row>
        <Label>Precio</Label>
        <Value>{liquidation.price}</Value>
      </Row>
      <Row>
        <Label>Moneda</Label>
        <Value>{liquidation.currency}</Value>
      </Row>
      <Row>
        <Label>Importe gravado</Label>
        <Value>{liquidation.taxedAmount}</Value>
      </Row>
      <Row>
        <Label>Importe no gravado</Label>
        <Value>{liquidation.untaxedAmount}</Value>
      </Row>
      <Row>
        <Label>Importe IVA</Label>
        <Value>{liquidation.VATAmount}</Value>
      </Row>
      <Row>
        <Label>Importe percepción</Label>
        <Value>{liquidation.perceptionAmount}</Value>
      </Row>
      <Row>
        <Label>Importe retención</Label>
        <Value>{liquidation.retentionAmount}</Value>
      </Row>
      <Row>
        <Label>Importe retención IBB</Label>
        <Value>{liquidation.grossIncomeRetentionAmount}</Value>
      </Row>
      <Row>
        <Label>Import neto</Label>
        <Value>{liquidation.netAmount}</Value>
      </Row>
      <Row>
        <Label>Importe gravado parcial</Label>
        <Value>{liquidation.partiallyTaxedAmount}</Value>
      </Row>
      <Row>
        <Label>Importe no gravado parcial</Label>
        <Value>{liquidation.partiallyUntaxedAmount}</Value>
      </Row>
      <Row>
        <Label>Importe IVA parcial</Label>
        <Value>{liquidation.partiallyVATAmount}</Value>
      </Row>
      <Row>
        <Label>Importe percepción parcial</Label>
        <Value>{liquidation.partiallyPerceptionAmount}</Value>
      </Row>
      <Row>
        <Label>Importe retención parcial</Label>
        <Value>{liquidation.partiallyRetentionAmount}</Value>
      </Row>
      <Row>
        <Label>Importe retención IBB parcial</Label>
        <Value>{liquidation.partiallyGrossIncomeRetentionAmount}</Value>
      </Row>
      <Row>
        <Label>Import neto parcial</Label>
        <Value>{liquidation.partiallyNetAmount}</Value>
      </Row>
      <Row>
        <Label>Estado</Label>
        <Value>{LiquidationStatus[liquidation.status ?? '']}</Value>
      </Row>
      <Row>
        <Label>Fecha</Label>
        <Value>
          {formatStringDateType(liquidation.date?.toString() ?? '', 'yy-MM-dd')}
        </Value>
      </Row>
    </Container>
  )
}

export default ApplicationDetailList
