import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint } from './utils'

const GET_BALANCE = 'user/transactions/balance'
const TRANSACTIONS = 'user/transactions'
const TRANSACTION_LIMIT = 'user/transaction-limit'
const VALIDATE_ACCOUNT = 'account/validate'
const CONTACTS = 'contacts'
const NEW_TRANSFER = 'transfer'
const FILTER_TRANSACTIONS = 'user/transactions/filter'
const TAX_TRX_CALC = 'taxTrxCalc'
const MONTHLY_STATEMENT = 'account/accountSummaries/' // :idClient?year=2021&month=10
const CONTACT_BOOK = 'contactBook'
const DELETE_CONTACT = 'contactBook/low'

export const getBalance = (token: string | null, keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(GET_BALANCE, keyEndpoints),
    {},
    { headers: { token } }
  )

export const transactions = (
  body: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.post(insertKeyEndpoint(TRANSACTIONS, keyEndpoints), body, {
    headers: { token }
  })

export const filterTransactions = (
  body: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.post(insertKeyEndpoint(FILTER_TRANSACTIONS, keyEndpoints), body, {
    headers: { token }
  })

export const contacts = (
  body: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.post(insertKeyEndpoint(CONTACTS, keyEndpoints), body, {
    headers: { token }
  })

export const validateAccount = (
  body: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.post(insertKeyEndpoint(VALIDATE_ACCOUNT, keyEndpoints), body, {
    headers: { token }
  })

export const transfer = (
  body: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.post(insertKeyEndpoint(NEW_TRANSFER, keyEndpoints), body, {
    headers: { token }
  })

export const getTax = (
  accountId: number,
  destinationId: string,
  amount: number,
  token: string,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(
      `${TAX_TRX_CALC}?accountId=${accountId}&destinationId=${destinationId}&amount=${amount}`,
      keyEndpoints
    ),
    {},
    { headers: { token } }
  )

export const getStatement = (
  idClient: string,
  year: number,
  month: number,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(
      `${MONTHLY_STATEMENT}${idClient}?year=${year}&month=${month}`,
      keyEndpoints
    ),
    {},
    { headers: { token } }
  )

export const newContact = (
  data: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.post(insertKeyEndpoint(CONTACT_BOOK, keyEndpoints), data, {
    headers: { token }
  })

export const editObservation = (
  data: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.put(insertKeyEndpoint(CONTACT_BOOK, keyEndpoints), data, {
    headers: { token }
  })

export const deleteContact = (
  data: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.put(insertKeyEndpoint(DELETE_CONTACT, keyEndpoints), data, {
    headers: { token }
  })

export const transactionLimit = async (data: object) => {
  const keyEndpoints = localStorage.getItem('keyendpoints') as string
  const token = localStorage.getItem('token') as string

  return api.post(
    insertKeyEndpoint(TRANSACTION_LIMIT, keyEndpoints),
    data,
    { headers: { token } }
  )
}
