import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint, getToken } from './utils'

export const getDocuments = async (taxId: string) => {
  const token = await getToken()
  return api.get(
    insertKeyEndpoint(`digital-signature/by-filter?taxId=${taxId}`),
    {},
    { headers: { token } }
  )
}

export const downloadDocument = async (documentId: string) => {
  const token = await getToken()
  return api.get(
    insertKeyEndpoint(`digital-signature/file/${documentId}`),
    {},
    { headers: { token } }
  )
}
