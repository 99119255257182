/* eslint-disable react/jsx-props-no-spreading */
import { SlideFade } from '@chakra-ui/react'
import React from 'react'

const withFade = <TProps, >(Component: React.FC) => (props: TProps) => {
  return (
    <SlideFade in offsetY={30}>
      <Component {...props} />
    </SlideFade>
  )
}

export default withFade
