import React from 'react'
import Drawer from 'react-modern-drawer'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import {
  Title,
  drawerStyle,
  Header,
  DrawerContainer,
  BackButton,
  TextDescription,
  BodyContainer,
  CalendarIconContainer,
  ButtonsContainer,
  MainButton,
  MainButtonText,
  TextContainer,
  BoldText
} from './styles'
import { Drawers } from 'store/drawer/types'
import wrapper from 'components/organisms/Drawers/wrapper'
import { useAppSelector } from 'hooks/redux'
import useNavigation from 'hooks/useNavigation'
import InfoHowWorks from 'assets/svg/InfoHowWorks'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const HowWorksQuotas: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { onNavigate } = useNavigation()
  const { selected } = useAppSelector(
    state => state.contractsV2
  )

  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
      style={drawerStyle}
    >
      <DrawerContainer>
        <BodyContainer>
          <Header>
            <BackButton onClick={onClose}>
              <BackArrowIcon />
            </BackButton>
            <Title>Entrega de granos</Title>
          </Header>
          <CalendarIconContainer>
            <InfoHowWorks />
          </CalendarIconContainer>
          <TextContainer>
            <TextDescription>
              ¡Hola, tu contrato ya está confirmado! Ahora comenzá con el cumplimiento.
            </TextDescription>
            <TextDescription>
              Desde acá vas a
              {' '}
              <BoldText>solicitar todos los cupos necesarios</BoldText>
              {' '}
              para el envío de tus granos.
            </TextDescription>
            <TextDescription>
              Una vez confirmados, podés ver el detalle para así completar tus
              {' '}
              <BoldText>cartas de porte.</BoldText>
            </TextDescription>
          </TextContainer>
        </BodyContainer>
        <ButtonsContainer>
          <MainButton
            onPress={() => {
              onClose()
              onNavigate(`/content/grains/activity/${selected?.id}`)
            }}
          >
            <MainButtonText testID="drawer-btn">
              Entendido
            </MainButtonText>
          </MainButton>
        </ButtonsContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(HowWorksQuotas, Drawers.HOW_WORKS_QUOTA)
