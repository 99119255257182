import React, { useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import BoxUploadFile from 'components/molecules/BoxUploadFile'
import PlusWithBackground from 'assets/svg/PlusWithBackground'
import { BoxInputsContainer, ContinueButton as Button, FilesUploadedContainer, StepTwoContainer } from './styles'
import BoxWithCheckbox from 'components/molecules/BoxWithCheckbox'
import BoxRemoveUploadedFile from 'components/molecules/BoxRemoveUploadedFile'
import { useDispatch } from 'react-redux'
import { showDrawer as showDrawerAction } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'
import { associateCompany, getSendEmailDocuments, uploadDocuments } from 'store/enterprise/thunks'
import { useAppSelector } from 'hooks/redux'
import { truncateFileName } from '@qirapagos/lib/utils/common'

interface FormValues {
  files: File[];
  sendEmail: boolean;
}

export const StepTwo: React.FC = () => {
  const dispatch = useDispatch()
  const { enterprise, isLoading } = useAppSelector(state => state.enterprise)

  const dataToAssociate = {
    cuit: localStorage.getItem('cuit') as string,
    name: enterprise?.name as string,
    loginId: Number(localStorage.getItem('loginId')),
    email: localStorage.getItem('email') as string
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      files: [],
      sendEmail: false
    },
    onSubmit: async () => {
      if (formik.values.sendEmail) {
        try {
          await dispatch(getSendEmailDocuments())
          await dispatch(associateCompany(dataToAssociate))
          dispatch(showDrawerAction(Drawers.EMAIL_SENT_SUCCESS))
        } catch (error) {
          dispatch(showDrawerAction(Drawers.EMAIL_SENT_ERROR))
        }
      }
      if (formik.values.files?.length) {
        try {
          await dispatch(uploadDocuments(formik.values.files))
          await dispatch(associateCompany(dataToAssociate))
          dispatch(showDrawerAction(Drawers.DOCUMENTS_SENT_SUCCESS))
        } catch (error) {
          dispatch(showDrawerAction(Drawers.DOCUMENTS_SENT_ERROR))
        }
      }
    }
  })

  const formikRef = useRef(formik)

  const sendEmailChecked = formik.values.sendEmail

  const isAnyFileUploaded = !!formik.values.files?.length

  useEffect(() => {
    if (isAnyFileUploaded && sendEmailChecked) {
      formikRef.current.setFieldValue('sendEmail', false)
    }
  }, [isAnyFileUploaded, sendEmailChecked])

  const handleRemoveFile = (index: number) => {
    const updatedFiles = formik.values.files?.filter((_, i: number) => i !== index)
    formik.setFieldValue('files', updatedFiles)
  }

  return (
    <StepTwoContainer>
      <BoxInputsContainer isAnyFileUploaded={isAnyFileUploaded}>
        <BoxUploadFile
          title="Agregar archivo"
          icon={<PlusWithBackground />}
          formik={formik}
        />
        {!isAnyFileUploaded
          ? (
            <BoxWithCheckbox
              title="Enviar luego por email"
              formik={formik}
            />
            )
          : null}
      </BoxInputsContainer>
      <FilesUploadedContainer>
        {formik.values?.files?.map((file: File, index: number) => (
          <BoxRemoveUploadedFile
            title={truncateFileName(file.name, 30)}
            onClick={() => handleRemoveFile(index)}
          />
        ))}
      </FilesUploadedContainer>
      <Button
        onPress={formik.handleSubmit}
        disabled={!sendEmailChecked && !isAnyFileUploaded}
        loading={isLoading}
      >
        Continuar
      </Button>
    </StepTwoContainer>
  )
}

export default StepTwo
