import styled from 'styled-components/native'

export const ViewContainer = styled.View`
  position: absolute;
  top: 50px;
  border-radius: 6px;
  background-color: white;
  width: 190px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`
