import styled from 'styled-components'

export const styles = {
  button: {
    margin: 0,
    padding: 0,
    width: 200
  },
  customText: {
    marginBottom: 5,
    marginTop: 10
  }
}

export const BoxContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
`

export const BoxButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 10px;
  width: 70%;
`

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`
