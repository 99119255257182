import { AnyAction } from 'redux'
import initialState from './initialState'
import { ActivityState, ActivityActionTypes } from './types'

export default function menuReducer (state = initialState, action: AnyAction): ActivityState {
  switch (action.type) {
    case ActivityActionTypes.GET_ACTIVITY_PENDING:
      return {
        ...state,
        isLoading: true
      }
    case ActivityActionTypes.GET_ACTIVITY_FULFILLED:
      return {
        ...state,
        isLoading: initialState.isLoading,
        activity: action.payload
      }
    case ActivityActionTypes.GET_ACTIVITY_REJECTED:
      return {
        ...state,
        isLoading: initialState.isLoading
      }
    case ActivityActionTypes.LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
