import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { white } from '@qirapagos/lib/theme/colors'

interface Props {
  investmentsFlag?: boolean
}

const YourAccountSkeleton = ({ investmentsFlag }: Props) => (
  <ContentLoader
    speed={3}
    interval={0}
    width="100%"
    height="100vh"
    viewBox="0 0 100% 100vh"
    backgroundColor="#e8e7f7"
    foregroundColor={white}
  >
    <Rect x="2.5%" y="3.5vh" rx="8" ry="6" width="45%" height="28%" />
    {investmentsFlag && (
      <Rect x="52.5%" y="3.5vh" rx="8" ry="6" width="45%" height="28%" />
    )}
    <Rect x="0.5%" y="33vh" rx="0" ry="0" width="100%" height="70%" />
  </ContentLoader>
)

export default YourAccountSkeleton

YourAccountSkeleton.defaultProps = {
  investmentsFlag: false
}
