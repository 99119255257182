import React from 'react'
import { Container, Row, Label, Value } from './styles'
import { formatStringDateType } from 'utils/date'
import { IApplication } from '@qirapagos/web/src/store/contracts/types'

interface Props {
  application: IApplication
}

const ApplicationDetailList: React.FC<Props> = ({ application }) => {
  return (
    <Container>
      <Row>
        <Label>ID Tabla Temp</Label>
        <Value>{application.tableTempId}</Value>
      </Row>
      <Row>
        <Label>Nro. de contrato</Label>
        <Value>{application.agreement}</Value>
      </Row>
      <Row>
        <Label>Cliente vendedor</Label>
        <Value>{application.sellerClient}</Value>
      </Row>
      <Row>
        <Label>Cliente comprador</Label>
        <Value>{application.buyerClient}</Value>
      </Row>
      <Row>
        <Label>Transportista</Label>
        <Value>{application.transporter}</Value>
      </Row>
      <Row>
        <Label>Destinatario</Label>
        <Value>{application.recipient}</Value>
      </Row>
      <Row>
        <Label>Fecha de descarga</Label>
        <Value>
          {formatStringDateType(application.tempDownloadDate ?? '', 'yy-MM-dd')}
        </Value>
      </Row>
      <Row>
        <Label>Nro. CTG</Label>
        <Value>{application.ctgNumber}</Value>
      </Row>
      <Row>
        <Label>Romaneo Temp</Label>
        <Value>{application.tempRomaneo}</Value>
      </Row>
      <Row>
        <Label>Carta de Porte Temp</Label>
        <Value>{application.tempCarPorte}</Value>
      </Row>
      <Row>
        <Label>Fecha Carte de Porte Temp</Label>
        <Value>
          {formatStringDateType(application.tempCarPorteDate ?? '', 'yy-MM-dd')}
        </Value>
      </Row>
      <Row>
        <Label>Lugar de procedencia</Label>
        <Value>{application.originPlaceDesc}</Value>
      </Row>
      <Row>
        <Label>Lugar de destino</Label>
        <Value>{application.destinationPlaceDesc}</Value>
      </Row>
      <Row>
        <Label>Kgs aplicados</Label>
        <Value>{application.tempAppliedKgs}</Value>
      </Row>
      <Row>
        <Label>Kgs descarga brutos</Label>
        <Value>{application.tempGrossDownloadKgs}</Value>
      </Row>
      <Row>
        <Label>Kgs descarga tara</Label>
        <Value>{application.tempTareDownloadKgs}</Value>
      </Row>
      <Row>
        <Label>Kgs descarga netos</Label>
        <Value>{application.tempNetDownloadKgs}</Value>
      </Row>
      <Row>
        <Label>Kgs descarga merma</Label>
        <Value>{application.tempMermaDownloadKgs}</Value>
      </Row>
      <Row>
        <Label>Kgs pendientes de aplicar</Label>
        <Value>{application.tempPendingApplyKgs}</Value>
      </Row>
      <Row>
        <Label>Calidad de grano</Label>
        <Value>{application.grainQuality}</Value>
      </Row>
      <Row>
        <Label>Estado de descarga</Label>
        <Value>{application.downloadState}</Value>
      </Row>
      <Row>
        <Label>Estado de aplicación</Label>
        <Value>{application.applicationState}</Value>
      </Row>
      <Row>
        <Label>Confirmación CTG definitiva</Label>
        <Value>{application.definitiveCtgConfirmation}</Value>
      </Row>
    </Container>
  )
}

export default ApplicationDetailList
