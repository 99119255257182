import styled from 'styled-components/native'

export const SlideContainer = styled.View`
  width: 100%;
  height: 100%;
`

export const TextContainer = styled.View`
  top: 75%;
  right: 6%;
`
