import { Colors } from '@qirapagos/lib/theme'
import React from 'react'
import styled from 'styled-components'

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 15%;
`

export const ViewHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TouchableOpacityBackArrow = styled.button`
  position: absolute;
  left: 20px;
`
export const ViewTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`

export const ViewDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 370px;
  margin-top: 25%;
  margin-right: 5%;
  margin-left: 5%;
  overflow-y: scroll;
  scrollbar-color: ${Colors.violetPressed};
  border: 1px solid ${Colors.violetPressed};
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  scroll-behavior: smooth;
`
export const ViewMonthsDrawer = styled.div`
  display: flex;
  width: 70%;
  flex-direction: row;
  align-self: center;
  align-items: baseline;
  justify-content: space-between;
`

export const TooltipContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  justify-self: center;
`

export const stylesDrawer = {
  drawerContainer: {
    width: '30%'
  },
  drawerContainerTablet: {
    width: '50%'
  },
  drawerContainerMobile: {
    width: '100%'
  }
}

export const styleDrawerText = {
  marginRight: '5px',
  marginTop: '24px',
  textTransform: 'capitalize'
} as React.CSSProperties
