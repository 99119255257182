import { white } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: ${white};
  margin-left: 50px;
  margin-top: 3.65vh;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  height: 35%;

  @media ${DEVICES.tablet} {
    align-self: center;
    height: 40%;
    margin-left: 0;
  }
`

export const ViewContainerPassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  background-color: ${white};
  margin-left: 50px;
  margin-top: 3.65vh;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);

  @media ${DEVICES.tablet} {
    align-self: center;
    margin-left: 0;
  }
`

export const ViewContainer2FA = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center
`

export const TextChangePassword = styled.p`
  margin-top: 2vh;
  margin-left: 2vh;
  font-size: 20px;
`

export const ViewContainerFields = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;

  @media ${DEVICES.tablet} {
    flex-direction: column;
  }
`

export const ViewNewPasswords = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 280px;
  margin-left: 40px;

  @media ${DEVICES.tablet} {
    align-self: center;
    width: 45%;
    min-width: 280px;
    margin-left: 0px;
  }
`

export const ViewOldPassword = styled.div`
  width: 30%;
  margin-right: 40px;
  min-width: 280px;

  @media ${DEVICES.tablet} {
    align-self: center;
    width: 45%;
    min-width: 280px;
    margin-left: 40px;
  }
`

export const ViewInputContainer = styled.div`
  margin-bottom: 20px;

  @media ${DEVICES.tablet} {
    margin-bottom: 0px;
  }
`
