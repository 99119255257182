import { gray } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const TextRedeemers = styled.p`
  font-size: 24px;
  margin-left: 50px;
  margin-bottom: 30px;
  margin-top: 35px;
  font-weight: 500;

  @media ${DEVICES.mobile} {
    font-size: 20px;
  }
`

export const ViewContainerPopularRendeerems = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  margin-bottom: 30px;

  @media ${DEVICES.mobile} {
    flex-direction: column;
  }
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 2.5%;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  @media ${DEVICES.mobile} {
    align-self: center;
    width: 70%;
    margin: 5px 0;
  }
`

export const ViewTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  @media ${DEVICES.mobile} {
    margin: 0;
  }
`

export const TextTitle = styled.p`
  margin-left: 20px;
  font-weight: 500;

  @media ${DEVICES.mobile} {
    font-size: 20px;
  }
`

export const TextRol = styled.p`
  margin-bottom: 20px;
  color: ${gray};

  @media ${DEVICES.mobile} {
    margin: 0;
  }
`
