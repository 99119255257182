import { Text } from '@chakra-ui/react'
import Status from 'components/atoms/Status'
import { disabledGray } from '@qirapagos/lib/theme/colors'
import { getStatusType } from 'utils/common'
import InfoSVG from 'assets/svg/infoSVG'
import Tooltip from 'components/atoms/Tooltip'
import React from 'react'
import { BoxContainer, styles, TooltipContainer } from './styles'
import { StatusType } from 'components/atoms/Status/constants'

interface Props {
  title: string
  hasTooltip?: boolean
  labelTooltip?: string
  description: string
  status: StatusType
  margin?: number
}

const DocumentationCard: React.FC<Props> = ({
  title,
  description,
  status,
  hasTooltip,
  labelTooltip,
  margin
}) => {
  const statusType = getStatusType(status)
  return (
    <BoxContainer>
      <Text
        color={disabledGray}
        alignSelf="center"
        mt={6}
        fontSize={{ sm: '15px', md: '18px', lg: '20px' }}
        fontWeight="500"
      >
        {title}
      </Text>
      <Text
        color={disabledGray}
        alignSelf="center"
        mt={6}
        fontSize={{ sm: '14px', md: '15px', lg: '17px' }}
        fontWeight="500"
      >
        {description}
      </Text>
      <Status
        statusType={statusType}
        customStyle={{ margin: '5%', width: '100px', alignSelf: 'center' }}
      />
      {hasTooltip && (
        <TooltipContainer>
          <Tooltip label={labelTooltip} hasArrow={false} margin={margin}>
            <InfoSVG />
          </Tooltip>
        </TooltipContainer>
      )}
    </BoxContainer>
  )
}

export default DocumentationCard

DocumentationCard.defaultProps = {
  hasTooltip: false,
  labelTooltip: '',
  margin: 0
}
