import { ThunkDispatchType } from '@qirapagos/lib/types/reduxTypes'
import { ActivityService } from 'services'
import * as ActivityActionTypes from 'store/activity/actions'

export const getActivity = (silentMode?: boolean) => async (dispatch: ThunkDispatchType) => {
  try {
    if (!silentMode) {
      dispatch(ActivityActionTypes.getActivityPending())
    }
    const response = await ActivityService.getActivity()
    dispatch(ActivityActionTypes.getActivityFulfilled(response.data.data))
  } catch (error) {
    dispatch(ActivityActionTypes.getActivityRejected(error as Error))
  }
}
