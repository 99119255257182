/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'
interface Props {
  shownAtActivity?: boolean,
  color: string
}
function YellowTruck ({ shownAtActivity, color }: Props) {
  return (
    <Svg
      width={shownAtActivity ? 43 : 32}
      height={shownAtActivity ? 43 : 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <Circle cx={16} cy={16} r={15} stroke={color} strokeWidth={2} />
      <Path
        d="M23.737 15.428h-4.521v-2.857h3.008a.561.561 0 01.525.36l.988 2.497zM7.912 17.144h11.304"
        stroke={color}
        strokeWidth={1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M20.063 22.286c.937 0 1.696-.768 1.696-1.714 0-.947-.76-1.715-1.696-1.715-.936 0-1.695.768-1.695 1.715 0 .947.759 1.714 1.695 1.714zM11.586 22.286c.936 0 1.695-.768 1.695-1.714 0-.947-.759-1.715-1.695-1.715-.937 0-1.696.768-1.696 1.715 0 .947.76 1.714 1.696 1.714z"
        stroke={color}
        strokeWidth={1.7}
        strokeMiterlimit={10}
      />
      <Path
        d="M18.368 20.571h-5.086M9.89 20.572H8.478a.562.562 0 01-.4-.168.575.575 0 01-.166-.404v-8c0-.151.06-.297.166-.404a.562.562 0 01.4-.167h10.738v7.658M19.216 19.087v-3.658h4.521V20c0 .152-.06.297-.165.404a.562.562 0 01-.4.168h-1.413"
        stroke={color}
        strokeWidth={1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default YellowTruck
