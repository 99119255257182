import React from 'react'
import { FormikProps } from 'formik'
import { CheckboxField as CheckboxFieldMUI } from './styles'

interface Props {
  name: string
  formik: FormikProps<any>
}

const CheckboxField: React.FC<Props> = ({ formik, name }) => {
  return (
    <CheckboxFieldMUI
      color='default'
      name={name}
      checked={formik.values[name]}
      onChange={() => formik.setFieldValue(name, !formik.values[name])}
    />
  )
}

export default CheckboxField
