import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const styles = {
  button: {
    margin: 5,
    height: 50,
    borderRadius: '6px'
  }
}

export const BoxContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`

export const ViewStores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${DEVICES.mobile} {
    flex-wrap: wrap;
  }
`

export const BoxButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 50px;
  width: 40%;
`
