import styled from 'styled-components'
import { gray, white } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'

export const TableContainer = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${white};
  overflow: scroll;
  padding-left: 5px;
  position: relative;
`

export const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-self: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 20px 10px 0px 30px;
  padding-bottom: 27px;
  position: sticky;
  top: 0;
  background-color: ${white};
  z-index: 10;
`

export const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
`

export const EmptyTextContainer = styled.div`
  max-width: 200px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: ${gray};
`

export const IconContainer = styled.div`
  margin-left: 10px;
`

export const TableStyled = styled.table`
  width: 92%;
  border-collapse: collapse;
  background-color: blue;
`

export const TableHeader = styled.thead`
  position: sticky;
  z-index: '2';
  top: 75px;
  background-color: white;
`

export const TableHeaderItem = styled.th<any>`
  padding-bottom: 20px;

  @media ${DEVICES.tablet} {
    font-size: 15px;
    ${({ index }) => (index === 4 ? 'width: 20%; padding-left: 10px;' : '')}
  }
`

export const TableRow = styled.tr<any>`
  height: 50px;
  ${({ index }) => index % 2 === 0
    ? 'background-color: #F5F6FA;'
    : 'background-color: white;'}
`

export const TableDataItem = styled.td<any>`
  text-align: start;
`
