import { Fonts } from '@qirapagos/lib/theme'
import { gray, white } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import { Text } from '@chakra-ui/react'

export const Container = styled.div`
  border-radius: 5px;
  padding: 24px;
  background-color: ${white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`

export const Title = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  color: ${gray};
  margin-bottom: 0.75rem;
`

export const Subtitle = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${gray};
`

export const AmountContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: center;
  align-items: center;
  width: 100%;
`

export const EyeButton = styled.button`
  display: flex;
  height: 40px;
  margin: 0 10px;
`

export const BalanceWithNoDecimals = styled.div`
  font-size: 2.7vw;
  font-family: ${Fonts.POPPINS_MED};
  font-weight: 600;
`

export const BalanceDecimals = styled.div`
  align-self: flex-start;
  align-items: flex-end;
  margin-top: .7vw;
  font-size: 1.2vw;
  font-family: ${Fonts.POPPINS_MED};
  font-weight: 600;
`
