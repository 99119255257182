import React from 'react'
import { AvatarCircle } from 'components'
import { AnimatedSpringViewMobile } from 'animations'
import {
  firstCircleAnimationOut,
  secondCircleAnimationOut,
  firstCircleAnimationIn,
  secondCircleAnimationIn
} from './constants'
import { ViewAvatarContainer, ViewAvatarShadow } from './styles'

interface Props {
  initials?: string
  visible: boolean
}

const EnterpriseAvatar: React.FC<Props> = ({ initials, visible }) => {
  return (
    <ViewAvatarContainer>
      <AvatarCircle initials={initials} isBig />
      {visible !== null && (
        <>
          <AnimatedSpringViewMobile
            customStyle={
              visible ? firstCircleAnimationOut : firstCircleAnimationIn
            }
          >
            <ViewAvatarShadow opacity={0.5} left={5} />
          </AnimatedSpringViewMobile>

          <AnimatedSpringViewMobile
            customStyle={
              visible ? secondCircleAnimationOut : secondCircleAnimationIn
            }
          >
            <ViewAvatarShadow opacity={0.5} left={-38} />
          </AnimatedSpringViewMobile>
        </>
      )}
    </ViewAvatarContainer>
  )
}

export default EnterpriseAvatar

EnterpriseAvatar.defaultProps = {
  initials: 'AA'
}
