import styled from 'styled-components'
import { white } from '@qirapagos/lib/theme/colors'
import Button from 'components/atoms/Button'

export const StepOneContainer = styled.div`
  display: flex;
  background-color: ${white};
  flex-direction: column;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-self: center;
`

export const ContinueButton = styled(Button)`
  width: 15%;
  align-self: flex-end;
  margin-right: 30px;
`
