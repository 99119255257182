import { redirect } from 'react-router-dom'

export const insertKeyEndpoint = (url: string, keyEndpoints?: string) => {
  const keyEndpointsValue = localStorage.getItem('keyendpoints')
  return `${keyEndpoints || keyEndpointsValue}/${url}`
}

export const setToken = async (token: string) =>
  await localStorage.setItem('token', token)

export const getToken = async () => await localStorage.getItem('token')

export const resetAll = async () => {
  redirect('/auth/login')
  await Promise.all([
    localStorage.setItem('userLoggedIn', 'false'),
    localStorage.removeItem('clientId'),
    localStorage.removeItem('companyId'),
    localStorage.removeItem('isBusiness'),
    localStorage.removeItem('businessName'),
    localStorage.removeItem('dni'),
    localStorage.removeItem('cuit'),
    localStorage.removeItem('userLoggedIn'),
    localStorage.removeItem('docs'),
    localStorage.removeItem('status'),
    localStorage.removeItem('companyStatus'),
    localStorage.removeItem('onboardingStatus'),
    localStorage.removeItem('qiraGlobalNotification'),
    localStorage.removeItem('allEnterprises'),
    localStorage.removeItem('hasSisa'),
    localStorage.removeItem('email'),
    localStorage.removeItem('loginId'),
    localStorage.removeItem('twoFactorAuthCode'),
    localStorage.removeItem('validationTwofactor'),
    localStorage.removeItem('isPending'),
    localStorage.removeItem('isMainAccount'),
    localStorage.removeItem('token'),
    localStorage.removeItem('userId')
  ])
}

export const getCompanyId = async () => await localStorage.getItem('companyId')
