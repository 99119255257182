import { ThunkDispatchType } from '@qirapagos/lib/interfaces/reduxInterfaces'
import { AccountServices } from 'services'
import { ApiResponse } from 'apisauce'
import * as AccountActionTypes from 'store/account/actions'

export const getAccountSummaries = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(AccountActionTypes.getAccountSummariesPending())
    const response: ApiResponse<any> = await AccountServices.getAccountSummaries()
    dispatch(AccountActionTypes.getAccountSummariesFulfilled(response.data.data))
  } catch (error) {
    dispatch(AccountActionTypes.getAccountSummariesRejected(error as Error))
  }
}
