import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'theme/breakpoints'
import { NativeBaseProvider } from 'native-base'
import { Global } from '@emotion/core'
import { GlobalStyled } from './theme/globalStyles'
import './index.css'
import 'focus-visible/dist/focus-visible'

// Remove firebase-messaging-sw service worker to stop receiving push notification after page is close
window.addEventListener('beforeunload', () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        if (registration.active && registration.active.scriptURL.includes('firebase-messaging-sw.js')) {
          registration.unregister()
        }
      }
    })
  }
})

// https://stackoverflow.com/a/67568418
document.addEventListener('click', () => {
  Notification.requestPermission()
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <NativeBaseProvider>
        <ChakraProvider theme={theme}>
          <Global styles={GlobalStyled} />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ChakraProvider>
      </NativeBaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
