import React from 'react'
import { Linking, TouchableOpacity, View } from 'react-native'
import AppStore from '@qirapagos/lib/assets/svg/AppStore'
import GooglePlay from '@qirapagos/lib/assets/svg/GooglePlay'

interface Props {
  cardType: string
  link: string
}

const actualSVG = {
  googlePlay: <GooglePlay />,
  appStore: <AppStore />
}

const StoreCard: React.FC<Props> = ({ cardType, link }) => {
  const onPress = () => {
    Linking.openURL(link)
  }

  return (
    <View style={{ padding: 5 }}>
      <TouchableOpacity onPress={onPress}>
        {actualSVG[cardType as keyof typeof actualSVG]}
      </TouchableOpacity>
    </View>
  )
}

export default StoreCard
