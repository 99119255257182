import { styled } from '@material-ui/core/styles'
import FormControlMUI from '@material-ui/core/FormControl'
import RadioGroupMUI from '@material-ui/core/RadioGroup'
import { gray, purple } from 'theme/colors'

export const FormControl = styled(FormControlMUI)({
  display: 'flex',
  alignContent: 'center',
  height: 56,
  margin: 10,
  minWidth: 300,
  maxWidth: 500
})

export const RadioGroup = styled(RadioGroupMUI)(
  ({ disabled }: { disabled: boolean }) => ({
    display: 'flex',
    flexDirection: 'row',
    '& .MuiTypography-root': {
      fontFamily: 'Poppins',
      fontSize: '1rem'
    },
    '& .MuiRadio-root': {
      color: disabled ? gray : purple
    },
    '& .Mui-checked': {
      color: disabled ? gray : purple
    }
  })
)
