import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, LinearGradient, Stop, ClipPath } from 'react-native-svg'

function OperationSuccess(props: SvgProps) {
  return (
    <Svg width={192} height={139} viewBox="0 0 192 139" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)">
        <Path
          d="M86.977 115.977c28 0 50.7-22.7 50.7-50.8 0-28.1-22.7-50.8-50.7-50.8-28 0-50.7 22.7-50.7 50.8 0 28.1 22.7 50.8 50.7 50.8z"
          fill="#EAEEF9"
        />
        <G filter="url(#prefix__filter0_d)">
          <Path
            d="M97.264 45.566c0 7.439-6.016 13.455-13.52 13.455H38.59c-8.991.194-16.172-6.986-16.172-15.655 0-8.733 7.245-15.978 16.367-15.525C46.612 3.388 82.45 6.816 85.49 32.11c6.728.84 11.773 6.533 11.773 13.455z"
            fill="url(#prefix__paint0_linear)"
          />
        </G>
        <Path
          d="M83.747 59.021c7.44 0 13.52-6.016 13.52-13.455 0-7.44-6.08-13.456-13.52-13.456-7.44 0-13.52 6.016-13.52 13.456 0 7.439 6.08 13.455 13.52 13.455z"
          fill="url(#prefix__paint1_linear)"
        />
        <Path
          d="M61.686 59.15c13.261 0 24.065-10.738 24.065-24 0-13.261-10.804-24-24.065-24-13.262 0-24.065 10.739-24.065 24 0 13.262 10.739 24 24.065 24z"
          fill="url(#prefix__paint2_linear)"
        />
        <G filter="url(#prefix__filter1_d)">
          <Path
            d="M168.823 80.701c0 8.17-6.607 14.778-14.849 14.778h-49.591c-9.876.213-17.762-7.673-17.762-17.193 0-9.592 7.957-17.55 17.975-17.052 8.597-26.856 47.957-23.09 51.296 4.69 7.389.923 12.931 7.175 12.931 14.777z"
            fill="url(#prefix__paint3_linear)"
          />
        </G>
        <Path
          d="M153.974 95.48c8.171 0 14.849-6.608 14.849-14.778 0-8.17-6.678-14.778-14.849-14.778-8.17 0-14.849 6.607-14.849 14.778 0 8.17 6.679 14.778 14.849 14.778z"
          fill="url(#prefix__paint4_linear)"
        />
        <Path
          d="M129.75 95.622c14.565 0 26.43-11.794 26.43-26.36 0-14.564-11.865-26.358-26.43-26.358s-26.43 11.794-26.43 26.359c0 14.565 11.794 26.359 26.43 26.359z"
          fill="url(#prefix__paint5_linear)"
        />
        <G filter="url(#prefix__filter2_d)">
          <Path
            d="M85.752 97.355c0 4.649-3.76 8.41-8.45 8.41H49.08c-5.62.121-10.108-4.367-10.108-9.785 0-5.458 4.528-9.986 10.229-9.703 4.892-15.283 27.29-13.14 29.191 2.668 4.205.526 7.359 4.084 7.359 8.41z"
            fill="url(#prefix__paint6_linear)"
          />
        </G>
        <Path
          d="M77.302 105.765c4.65 0 8.45-3.761 8.45-8.41 0-4.65-3.8-8.41-8.45-8.41-4.65 0-8.45 3.76-8.45 8.41 0 4.649 3.8 8.41 8.45 8.41z"
          fill="url(#prefix__paint7_linear)"
        />
        <Path
          d="M63.513 105.845c8.289 0 15.04-6.711 15.04-15 0-8.288-6.751-15-15.04-15-8.288 0-15.04 6.712-15.04 15 0 8.289 6.711 15 15.04 15z"
          fill="url(#prefix__paint8_linear)"
        />
        <Path
          d="M83.336 46.117l-6.595 5.315c-1.23.917-2.835.264-3.211-1.307l-2.202-15.252"
          fill="#A5B0BE"
        />
        <Path
          d="M146.362 7.57l-4.238 3.92-44.347 41.342c-2.02 1.937-5.146 1.854-7.23.07L74.617 38.237l-3.196-2.972-8.812-8.271c-1.888-1.832-1.027-5.152 1.566-5.565l72.89-12.05 9.298-1.81z"
          fill="#CBD4E0"
        />
        <Path
          d="M146.364 7.57l-4.237 3.92-67.36 26.505-3.392-2.925 65.84-25.933 9.149-1.568zM23.572 90.83l-2.824 2.404c-.528.416-1.238.149-1.422-.535l-1.142-6.65"
          fill="#A5B0BE"
        />
        <Path
          d="M50.707 73.216l-1.809 1.766L29.973 93.6c-.862.872-2.23.873-3.163.116l-7.147-6.231-1.433-1.264-3.955-3.516c-.848-.78-.51-2.243.62-2.455l31.764-6.131 4.048-.902z"
          fill="#CBD4E0"
        />
        <Path
          d="M50.708 73.216l-1.81 1.766-29.172 12.394-1.519-1.24L46.721 74.01l3.987-.794zM78.592 101.333l-5.17 4.402c-.967.762-2.268.272-2.603-.979L68.727 92.58"
          fill="#A5B0BE"
        />
        <Path
          d="M128.279 69.082l-3.311 3.234-34.653 34.088c-1.578 1.596-4.084 1.597-5.793.212l-13.084-11.41-2.626-2.313-7.24-6.44c-1.553-1.427-.934-4.106 1.135-4.493l58.16-11.227 7.412-1.65z"
          fill="#CBD4E0"
        />
        <Path
          d="M128.279 69.082l-3.312 3.234-53.416 22.692-2.782-2.271 52.211-22.202 7.299-1.453z"
          fill="#A5B0BE"
        />
      </G>
      <Defs>
        <LinearGradient
          id="prefix__paint0_linear"
          x1={59.841}
          y1={30.968}
          x2={59.817}
          y2={59.541}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#fff" />
          <Stop offset={0.996} stopColor="#ECF0F5" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint1_linear"
          x1={68.788}
          y1={29.213}
          x2={80.773}
          y2={42.322}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#BCCBE1" />
          <Stop offset={0.994} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint2_linear"
          x1={35.368}
          y1={24.137}
          x2={54.258}
          y2={31.53}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#E2E8F0" />
          <Stop offset={0.994} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint3_linear"
          x1={127.722}
          y1={64.669}
          x2={127.695}
          y2={96.049}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#fff" />
          <Stop offset={0.996} stopColor="#ECF0F5" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint4_linear"
          x1={137.545}
          y1={62.742}
          x2={150.709}
          y2={77.139}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#BCCBE1" />
          <Stop offset={0.994} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint5_linear"
          x1={100.845}
          y1={57.168}
          x2={121.592}
          y2={65.287}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#E2E8F0" />
          <Stop offset={0.994} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint6_linear"
          x1={62.362}
          y1={88.232}
          x2={62.347}
          y2={106.089}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#fff" />
          <Stop offset={0.996} stopColor="#ECF0F5" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint7_linear"
          x1={67.952}
          y1={87.135}
          x2={75.443}
          y2={95.328}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#BCCBE1" />
          <Stop offset={0.994} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint8_linear"
          x1={47.064}
          y1={83.962}
          x2={58.871}
          y2={88.583}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#E2E8F0" />
          <Stop offset={0.994} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" transform="translate(.219)" d="M0 0h191v139H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default OperationSuccess
