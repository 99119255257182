import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import useServerTime from 'hooks/useServerTime'
import useHasSisa from '@qirapagos/lib/hooks/useHasSisa'
import WalletDesktopSkeleton from 'assets/skeletons/WalletDesktopSkeleton'
import { useDispatch, useSelector } from 'react-redux'
import { getRepresented } from 'store/representation/thunks'
import { RootState } from 'store'

const SisaAndTimeCheck = ({ children }: any) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { represented } = useSelector(
    (state: RootState) => state.representation
  )

  const companyId = localStorage.getItem('companyId')
  const { isOutOfTimeRange } = useServerTime()
  const hasSisa = useHasSisa()
  useEffect(() => {
    dispatch(getRepresented(Number(companyId)))
  }, [dispatch])

  if (hasSisa === undefined) return <WalletDesktopSkeleton />

  if (isOutOfTimeRange) {
    return <Navigate to="/content/grains" state={{ from: location }} replace />
  }
  if (!hasSisa && !represented?.length) {
    return (
      <Navigate
        to="/content/wallet"
        state={{ from: location, title: 'inicio' }}
        replace
      />
    )
  }

  return children
}

export default SisaAndTimeCheck
