import styled from 'styled-components'
import { Colors, Fonts } from '@qirapagos/web/src/theme'

export const Container = styled.div`
`

export const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`

export const Label = styled.div`
  width: 50%;
  text-align: right;
  margin-right: 10px;
  color: ${Colors.gray};
  font-size: ${Fonts.SIZES.MEDIUM}px;
`

export const Value = styled.div`
  width: 50%;
  text-align: left;
  color: ${Colors.black};
  font-size: ${Fonts.SIZES.MEDIUM}px;
  font-weight: 600;
`
