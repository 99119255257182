import React from 'react'
import CustomText from 'components/atoms/CustomText'
import { ItemContainer, CircleMark } from './styles'

interface Props {
  checkAliasLength: boolean
  checkCharacters: boolean
}

interface ItemProps {
  children: React.ReactChild;
  isActive: boolean;
}

const Item = ({ children, isActive }: ItemProps) => (
  <ItemContainer>
    <CircleMark isActive={isActive} />
    <CustomText
      size="medium"
      color="gray"
      align="left"
    >
      {children}
    </CustomText>
  </ItemContainer>
)

const AliasCheckers: React.FC<Props> = ({
  checkAliasLength,
  checkCharacters
}) => {
  return (
    <div>
      <Item isActive={checkAliasLength}>
        Debe contener entre 6 y 20 caracteres
      </Item>
      <Item isActive={checkCharacters}>
        Puede incluir letras, números, puntos o guiones.
      </Item>
    </div>
  )
}

export default AliasCheckers
