import React from 'react'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { useAppSelector } from 'hooks/redux'
import { Drawers } from 'store/drawer/types'
import {
  Drawer,
  Title,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  ContentContainer
} from './styles'
import wrapper from 'components/organisms/Drawers/wrapper'
import LiquidationDetailList from 'components/molecules/LiquidationDetailList'
import { ILiquidation } from 'store/contracts/types'

interface Props {
  showDrawer: boolean
  onClose: () => void
}

const LiquidationsDetails: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { selectedLiquidation } = useAppSelector(state => state.contractsV2)

  return (
    <Drawer open={showDrawer} onClose={onClose} direction="right">
      <DrawerContainer>
        <HeaderContainer>
          <BackButton onPress={onClose}>
            <BackArrowIcon />
          </BackButton>
          <Title>Detalles de la Liquidación</Title>
        </HeaderContainer>
        <ContentContainer>
          <LiquidationDetailList
            liquidation={selectedLiquidation as ILiquidation}
          />
        </ContentContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(LiquidationsDetails, Drawers.LIQUIDATIONS_DETAILS)
