import styled from 'styled-components'
import ButtonComponent from 'components/atoms/Button'
import { Fonts } from 'theme'

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 10px;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10%;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
`

export const BackButton = styled.button`
  position: absolute;
  left: 20px;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 10%;
`

export const AgentInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AvatarContainer = styled.div`
  display: flex;
  align-self: center;
`

export const ConfirmButton = styled(ButtonComponent)`
  width: 80%;
  height: 60px
`

export const getDrawerStyles = () => {
  if (window.innerWidth < 600) {
    return {
      width: '100%'
    }
  }
  return {
    width: '450px'
  }
}

export const customListStyles = {
  title: {
    flex: 1,
    textAlign: 'right',
    marginRight: 10,
    fontWeight: 400,
    fontSize: '1rem'
  },
  flatList: {
    flexGrow: 0
  }
}
