/* eslint-disable max-len */
import * as React from 'react'
import Svg, { G, Path } from 'react-native-svg'

function ConnectivityErrorSVG () {
  return (
    <Svg width={514} height={514} viewBox="0 0 514 514" fill="none">
      <G opacity={0.5}>
        <Path
          d="M155.221 135.633s55.115 41.825 138.904-36.38c74.413-69.452 135.173 40.128 135.598 92.604.551 67.976-74.413 122.368-38.033 167.016 36.379 44.648-72.147 118.377-130.637 54.57-72.76-79.375-92.471-14.883-133.944-14.883-29.765 0-90.876-73.959-49.608-128.983 34.726-46.302 15.787-61.668 6.615-77.72-13.23-23.151 18.189-85.989 71.105-56.224z"
          fill="#7D8893"
          fillOpacity={0.3}
        />
        <Path
          d="M376.49 228.06c2.963 1.101 6.2 1.174 9.281.467 12.905-2.962 57.279-10.805 49.568 23.581-1.679 6.822-27.668 45.029 9.343 58.976a25.518 25.518 0 008.483 1.67c11.395.235 33.977-2.455 43.428-12.076 11.417-11.622-1.056-6.11-1.056-6.11s-43.404 18.941-58.735-1.155c-1.707-2.235-2.793-5.483-3.104-8.279-.54-4.847 1.613-11.252 4.44-16.828 3.826-7.546 22.198-41.494-17.689-51.383-9.188-2.188-22.533-4.154-39.741 3.091-10.037 4.226-7.814 6.71-4.218 8.046z"
          fill="#7D8893"
        />
        <Path
          d="M385.372 215.386l-11.553 4.324a7.71 7.71 0 00-4.518 9.923l.573 1.531a7.71 7.71 0 009.923 4.518l11.553-4.324a7.709 7.709 0 004.518-9.923l-.573-1.53a7.71 7.71 0 00-9.923-4.519zM314.459 227.898l-38.1 14.259a3.855 3.855 0 00-2.259 4.962l.15.401a3.855 3.855 0 004.962 2.259l38.1-14.259a3.856 3.856 0 002.259-4.962l-.15-.401a3.855 3.855 0 00-4.962-2.259zM325.093 256.309l-38.1 14.259a3.855 3.855 0 00-2.259 4.962l.15.401a3.854 3.854 0 004.961 2.259l38.1-14.259a3.856 3.856 0 002.26-4.962l-.15-.401a3.855 3.855 0 00-4.962-2.259z"
          fill="#7D8893"
        />
        <Path
          d="M360.269 208.509l-30.88 11.557a7.709 7.709 0 00-4.518 9.923l10.543 28.169a7.709 7.709 0 009.923 4.518l30.879-11.556a7.712 7.712 0 004.519-9.924l-10.543-28.169a7.71 7.71 0 00-9.923-4.518z"
          fill="#F0EDFF"
        />
        <Path
          d="M332.579 215.899L301.7 227.456a7.71 7.71 0 00-4.519 9.923l12.578 33.607a7.71 7.71 0 009.924 4.518l30.879-11.557a7.709 7.709 0 004.518-9.923l-12.578-33.607a7.709 7.709 0 00-9.923-4.518z"
          fill="#F5F6FA"
        />
        <Path
          d="M337.413 209.078l-.242.091a5.14 5.14 0 00-3.012 6.616l17.62 47.079a5.14 5.14 0 006.616 3.013l.242-.091a5.14 5.14 0 003.012-6.615l-17.62-47.08a5.14 5.14 0 00-6.616-3.013z"
          fill="#F5F6FA"
        />
        <Path
          d="M22.546 239.179s62.016-17.428 53.012 22.725c-1.564 6.353-24.071 39.066 2.739 54.773 5.757 3.373 12.4 4.867 19.072 4.832 11.961-.063 30.924-2.365 39.443-11.036 11.417-11.622-1.056-6.11-1.056-6.11s-35.617 14.65-53.94 2.972c-6.257-3.988-9.093-11.709-7.394-18.931a43.408 43.408 0 013.935-10.303c3.985-7.463 22.198-41.494-17.689-51.383-9.188-2.188-22.533-4.154-39.74 3.091-17.208 7.245 1.618 9.37 1.618 9.37z"
          fill="#7D8893"
        />
        <Path
          d="M145.151 295.378l-11.553 4.324a7.709 7.709 0 00-4.518 9.923l.573 1.53a7.71 7.71 0 009.923 4.519l11.553-4.324a7.71 7.71 0 004.518-9.923l-.573-1.531a7.71 7.71 0 00-9.923-4.518z"
          fill="#7D8893"
        />
        <Path
          d="M164.67 276.37l-17.122 6.408a7.71 7.71 0 00-4.518 9.924l8.256 22.059a7.709 7.709 0 009.923 4.518l17.121-6.408a7.71 7.71 0 004.519-9.923l-8.256-22.059a7.71 7.71 0 00-9.923-4.519z"
          fill="#F0EDFF"
        />
        <Path
          d="M193.173 259.387l-25.533 9.556a7.71 7.71 0 00-4.519 9.923l12.578 33.607a7.712 7.712 0 009.924 4.519l25.533-9.556a7.71 7.71 0 004.518-9.924l-12.578-33.607a7.709 7.709 0 00-9.923-4.518z"
          fill="#F5F6FA"
        />
        <Path
          d="M198.581 252.427l-.242.091a5.14 5.14 0 00-3.012 6.615l17.62 47.08a5.14 5.14 0 006.616 3.012l.242-.09a5.14 5.14 0 003.012-6.616l-17.62-47.08a5.14 5.14 0 00-6.616-3.012z"
          fill="#F5F6FA"
        />
      </G>
      <Path
        d="M254.881 216.072a3.572 3.572 0 01-3.168-3.816l1.905-34.798c.114-2.079 1.869-3.658 3.861-3.474l2.776.257c1.992.184 3.418 2.056 3.139 4.12l-4.681 34.541a3.595 3.595 0 01-3.832 3.17zM238.431 219.073a2.274 2.274 0 01-2.817-1.539l-6.804-21.646a2.445 2.445 0 011.573-3.069l1.761-.532a2.275 2.275 0 012.498.872c.182.257.309.549.372.858l5.042 22.177a2.434 2.434 0 01-1.625 2.879zM229.251 231.555c-1.219 1.511-3.671 1.493-5.558-.041l-33.505-27.222c-2.001-1.626-2.592-4.317-1.299-5.918l1.803-2.233c1.293-1.602 3.949-1.471 5.843.289l31.703 29.455c1.785 1.659 2.232 4.16 1.013 5.67zM244.738 301.867a3.572 3.572 0 012.088 4.499l-10.729 33.157c-.64 1.98-2.741 3.058-4.62 2.372l-2.619-.956c-1.879-.687-2.781-2.862-1.983-4.785l13.348-32.2a3.593 3.593 0 014.515-2.087zM261.408 303.167a2.28 2.28 0 011.623.621 2.284 2.284 0 01.709 1.586l1.049 22.666a2.443 2.443 0 01-2.304 2.567l-1.839.064a2.276 2.276 0 01-1.694-.692 2.28 2.28 0 01-.639-1.714l.79-22.73a2.435 2.435 0 012.305-2.368zM273.472 293.444c1.565-1.15 3.93-.506 5.363 1.458l25.442 34.875c1.52 2.084 1.403 4.836-.257 6.054l-2.313 1.699c-1.659 1.218-4.194.413-5.575-1.772l-23.131-36.575c-1.3-2.058-1.093-4.591.471-5.739z"
        fill="#7D66FF"
      />
    </Svg>
  )
}

export default ConnectivityErrorSVG
