import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { white } from '@qirapagos/lib/theme/colors'

const SetQuotaSkeleton = () => (
  <ContentLoader
    speed={3}
    interval={0}
    width="100%"
    height="100vh"
    viewBox="0 0 100% 100vh"
    backgroundColor="#e8e7f7"
    foregroundColor={white}
  >

    <Rect x="3%" y="8%" rx="0" ry="0" width="35%" height="2%" />
    <Rect x="40%" y="8%" rx="0" ry="0" width="35%" height="2%" />
    <Rect x="3%" y="15%" rx="8" ry="50" width="85%" height="75%" />
  </ContentLoader>
)

export default SetQuotaSkeleton
