/* eslint-disable react/require-default-props */
import React, { forwardRef, useState } from 'react'
import { Controller, Control, FieldError } from 'react-hook-form'
import { InputGroup, Text, Flex, Input } from '@chakra-ui/react'
import ErrorIcon from '@qirapagos/lib/assets/svg/ErrorIcon'
import { error as errorColor } from '@qirapagos/lib/theme/colors'
import { NumericFormat, Placeholder } from './styles'

interface Props {
  name: string
  control: Control
  placeholder: string
  error?: FieldError
  showErrorMessage?: boolean
  clearErrors?: () => void
  alignError?: string
  isDisabled?: boolean
  rules?: object
}

export const FieldCurrency = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      control,
      placeholder,
      error,
      showErrorMessage = true,
      clearErrors = () => null,
      alignError,
      isDisabled,
      rules = {}
    }
  ) => {
    const [focus, setFocus] = useState(false)

    return (
      <InputGroup display="flex" flexDirection="column">
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ onChange, value, onBlur }) => {
            return (
              <>
                <NumericFormat
                  value={value}
                  prefix="$"
                  allowNegative={false}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  onValueChange={({ value }) => {
                    onChange(value)
                  }}
                  customInput={Input}
                  focus={focus}
                  error={error}
                  disabled={isDisabled}
                  placeholder={placeholder}
                  onFocus={() => {
                    setFocus(true)
                    clearErrors()
                  }}
                  onBlur={() => {
                    onBlur()
                    setFocus(false)
                  }}
                />
                {(focus || value) && (
                  <Placeholder error={error}>
                    {placeholder}
                  </Placeholder>
                )}
              </>
            )
          }}
        />
        {error && showErrorMessage && (
        <Flex alignItems="center" justifyContent={alignError}>
          <ErrorIcon />
          <Text color={errorColor} ml={2} alignSelf='center'>
            {error.message}
          </Text>
        </Flex>
        )}
      </InputGroup>
    )
  }
)
