import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'
import { Colors, Fonts } from 'theme'
import { Text as ChakraText } from '@chakra-ui/react'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const OperationsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 80px;
  margin-top: 50px;
  padding-left: 45px;

  @media ${DEVICES.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 25px;
  width: 45%;
`

export const Enterprise = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 300px;
  border-radius: 3px;
`

export const TableContainer = styled.div`
  background-color: white;
  width: 100%;
  margin-left: 1%;
  height: 58vh;
`

export const SelectTitle = styled(ChakraText)`
  color: ${Colors.black};
  font-weight: 500;
  font-size: ${Fonts.SIZES.XMEDIUM}px;
  text-align: center;
  margin-bottom: 10px;
`

export const Text = styled(ChakraText)`
  color: ${Colors.black};
  font-weight: 500;
  font-size: ${Fonts.SIZES.XMEDIUM}px;
  text-align: center;
`

export const customStyleField = {
  width: '250px'
}
