import { AnyAction } from 'redux'
import initialState from './initialState'
import { EnterpriseState, EnterpriseActionsTypes } from './types'

export default function enterpriseReducer (state = initialState, action: AnyAction): EnterpriseState {
  switch (action.type) {
    case EnterpriseActionsTypes.GET_ENTERPRISE_PENDING:
    case EnterpriseActionsTypes.GET_SEND_EMAIL_DOCUMENTS_PENDING:
    case EnterpriseActionsTypes.ASSOCIATE_COMPANY_PENDING:
    case EnterpriseActionsTypes.UPLOAD_DOCUMENTS_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoading: true
      }
    case EnterpriseActionsTypes.GET_ENTERPRISE_FULFILLED:
      return {
        ...state,
        enterprise: action.payload,
        isLoading: initialState.isLoading
      }
    case EnterpriseActionsTypes.GET_SEND_EMAIL_DOCUMENTS_FULFILLED:
    case EnterpriseActionsTypes.ASSOCIATE_COMPANY_FULFILLED:
    case EnterpriseActionsTypes.UPLOAD_DOCUMENTS_FULFILLED:
      return {
        ...state,
        isLoading: initialState.isLoading
      }
    case EnterpriseActionsTypes.GET_SEND_EMAIL_DOCUMENTS_REJECTED:
    case EnterpriseActionsTypes.ASSOCIATE_COMPANY_REJECTED:
    case EnterpriseActionsTypes.UPLOAD_DOCUMENTS_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: initialState.isLoading
      }
    case EnterpriseActionsTypes.GET_ENTERPRISE_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: initialState.isLoading
      }
    case EnterpriseActionsTypes.CLEAR_ENTERPRISE_STATE:
      return {
        ...state,
        enterprise: initialState.enterprise,
        error: initialState.error,
        isLoading: false
      }
    default:
      return state
  }
}
