import { graySoft, purple } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import { white } from 'theme/colors'

type CheckContainerType = {
  selected: boolean
}

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;

   &:hover {
    background-color: ${graySoft};
    color:  ${purple};
   }
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 10px 0px;
    padding-left: 10px;
`

export const TextList = styled.p`
  flex: 1;
  align-self: start;
  font-size: 1rem;
  margin-left: 15px;
`
export const ImageFlag = styled.img`
  width: 15%;
`
export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 100;
  position: relative;
  margin-bottom: 20px;
`
export const ListContainer = styled.div`
  border: 2px ${purple} solid;
  z-index: 100;
  top: 100;
  border-bottom-left-radius: 5;
  border-width: 2;
  border-bottom-right-radius: 5;
  background-color: white;
  position: absolute;
  max-height: 280px;
  overflow-y: scroll;
`
export const fieldContent = {
  borderStyle: 'solid',
  borderColor: purple,
  borderTopLeftRadius: 9,
  borderTopRightRadius: 9,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  marginBottom: -2,
  zIndex: -1
}

export const checkStyles = {
  width: 22,
  height: 22,
  borderRadius: 11,
  borderColor: purple,
  borderWidth: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 20
}

export const checkmarkStyles = {
  position: 'absolute'
}

export const downArrowStyles = {
  position: 'absolute',
  right: 25,
  top: 25
}

export const DownArrowContainer = styled.button`
  position: absolute;
  right: 25px;
  top: 25px;
`
export const CheckContainer = styled.div<CheckContainerType>`
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid ${purple};
  align-items: center;
  justify-content: center;
  margin-right: 20px;
    background-color:${white};
    ${({ selected }) =>
    selected &&
    `
    background-color: #7D66FF`}
`
