import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint } from './utils'
const GET_ASSOCIATIONS = 'mu-me/associations-me/loginId'

export const getAssociations = (loginId: string, token: string | null, keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(`${GET_ASSOCIATIONS}/${loginId}`, keyEndpoints),
    {},
    { headers: { token } }
  )
