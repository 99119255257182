import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { gray, grayTextFilter, violet } from '@qirapagos/lib/theme/colors'

function AgentsItem ({
  active,
  canBeHovered
}: {
  active: boolean
  canBeHovered?: boolean
}) {
  return (
    <Svg width={22} height={22} viewBox="0 0 24 25" fill="none">
      <Path
        // eslint-disable-next-line max-len
        d="M4.451 13.086V19.5c0 .199.078.39.217.53.14.141.328.22.525.22h13.352a.738.738 0 00.525-.22.755.755 0 00.217-.53v-6.413M5.01 3.75h13.717c.16 0 .318.053.446.151a.75.75 0 01.267.393L20.77 9H2.967l1.33-4.706a.75.75 0 01.266-.393.736.736 0 01.447-.151z"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        // eslint-disable-next-line max-len
        d="M8.901 9v1.5c0 .796-.312 1.559-.869 2.121a2.95 2.95 0 01-2.098.879 2.95 2.95 0 01-2.098-.879 3.017 3.017 0 01-.87-2.121V9"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        // eslint-disable-next-line max-len
        d="M14.836 9v1.5c0 .796-.313 1.559-.87 2.121a2.95 2.95 0 01-2.098.879 2.95 2.95 0 01-2.098-.879 3.017 3.017 0 01-.869-2.121V9"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        // eslint-disable-next-line max-len
        d="M20.77 9v1.5c0 .796-.312 1.559-.869 2.121a2.951 2.951 0 01-2.098.879 2.95 2.95 0 01-2.098-.879 3.017 3.017 0 01-.87-2.121V9"
        stroke={!canBeHovered ? grayTextFilter : active ? violet : gray}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default AgentsItem

AgentsItem.defaultProps = {
  canBeHovered: true
}
