export const firstCircleAnimationOut = {
  from: { left: -18, opacity: 0 },
  to: { left: 0, opacity: 1 },
  config: { duration: 150, mass: 1, tension: 180, friction: 12 },
  delay: 100
}
export const secondCircleAnimationOut = {
  from: { left: -36, opacity: 0 },
  to: { left: 0, opacity: 1 },
  config: { duration: 150, mass: 1, tension: 180, friction: 12 },
  delay: 150
}

export const firstCircleAnimationIn = {
  from: { left: 0, opacity: 0 },
  to: { left: -18, opacity: 1 },
  config: { duration: 150, mass: 1, tension: 180, friction: 12 },
  delay: 100
}

export const secondCircleAnimationIn = {
  from: { left: 0, opacity: 0 },
  to: { left: -36, opacity: 1 },
  config: { duration: 150, mass: 1, tension: 180, friction: 12 },
  delay: 150
}
