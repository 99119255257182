export const getSimpleDataAsyncStorage = async (item: string) => {
  try {
    const value = await localStorage.getItem(item)
    if (value !== null) {
      return value
    }
  } catch {
    return undefined
  }
}

export const setSimpleDataAsyncStorage = async (item: string, value: any) => {
  try {
    await localStorage.setItem(item, value)
  } catch {
    return undefined
  }
}
