/* eslint-disable react/require-default-props */
import React, { useRef, useState, useEffect } from 'react'
import {
  View,
  TextInput,
  Animated,
  LayoutAnimation,
  LayoutAnimationConfig,
  KeyboardTypeOptions
} from 'react-native'
import { Controller, UseFormMethods } from 'react-hook-form'
import {
  TouchableOpacity,
  TouchableWithoutFeedback
} from 'react-native-gesture-handler'
import { isIos, isAndroid } from '@qirapagos/lib/constants/platform'
import { animateLayout } from '@qirapagos/lib/utils/animation'
import { Colors } from '@qirapagos/lib/theme/index'
import EyeShowPassword from '@qirapagos/lib/assets/svg/EyeShowPassword'
import EyeSlashPassword from '@qirapagos/lib/assets/svg/EyeSlashPassword'
import CustomText from '@qirapagos/web/src/components/atoms/CustomText'
import { myTextContentType } from '@qirapagos/lib/constants/inputTypes'
import ErrorIcon from '@qirapagos/lib/assets/svg/ErrorIcon'
import styles, { getFieldStyle } from './styles'

interface Props {
  disabled?: boolean
  setValue?: any
  clearErrors?: UseFormMethods['clearErrors']
  name: string
  error?: any
  control: any
  defaultValue?: string
  label: string
  rules?: object
  errorMessage?: object
  editable?: boolean
  onPress?: () => void
  secure?: boolean
  customStyle?: any
  autoFocus?: boolean
  type?: KeyboardTypeOptions
  testID?: string
  errorTestID?: string
  textContentType?: myTextContentType
  accessibilityLabelValue: string
  autoCorrect?: boolean
  customStyleContainer?: any
  customInputContainerStyle?: any
}

const customFieldAnimation: LayoutAnimationConfig = {
  duration: 300,
  create: {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
    springDamping: 0.7
  },
  update: {
    type: LayoutAnimation.Types.easeInEaseOut,
    springDamping: 0.7
  }
}

const Field = ({
  disabled,
  setValue,
  clearErrors,
  name,
  error,
  control,
  defaultValue = '',
  label,
  rules,
  editable = true,
  errorMessage,
  onPress,
  secure,
  customStyle,
  autoFocus,
  type,
  testID = '',
  errorTestID = '',
  textContentType = 'none',
  accessibilityLabelValue = '',
  autoCorrect = false,
  customStyleContainer,
  customInputContainerStyle
}: Props) => {
  const [focus, setFocus] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const animatedFocus = useRef(new Animated.Value(0)).current
  const inputRef = useRef<TextInput | null>(null)

  useEffect(() => {
    if (isAndroid) {
      autoFocus &&
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus()
          }
        }, 40)
    }
  }, [])

  const focusHandler = () => {
    if (clearErrors) {
      clearErrors()
    }
    Animated.timing(animatedFocus, {
      toValue: 1,
      duration: 100,
      useNativeDriver: true
    }).start()
  }

  const blurHandler = () => {
    if (clearErrors) {
      clearErrors()
    }
    Animated.timing(animatedFocus, {
      toValue: 0,
      duration: 100,
      useNativeDriver: true
    }).start()
  }
  const setFocusHandler = (val: boolean) => {
    animateLayout(customFieldAnimation)
    setFocus(val)
    if (val) focusHandler()
    else blurHandler()
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <View style={[styles.container, customStyleContainer]}>
      <TouchableWithoutFeedback onPress={onPress} disabled={disabled}>
        <View
          style={[
            styles.inputContainer,
            customInputContainerStyle,
            !focus && styles.blured,
            error && styles.errorContainer
          ]}
          pointerEvents={onPress || !editable ? 'none' : 'auto'}
        >
          <CustomText
            size="xmedium"
            color={error ? 'error' : 'purple'}
            align="left"
            customStyle={styles.label}
          >
            {label}
          </CustomText>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextInput
                accessibilityLabel={accessibilityLabelValue}
                onBlur={() => {
                  setFocusHandler(false)
                  onBlur()
                }}
                onChange={
                  clearErrors ? () => onChange(clearErrors()) : () => onChange()
                }
                onChangeText={onChange}
                value={value || setValue}
                style={[
                  styles.input,
                  customStyle,
                  getFieldStyle(!focus && !value)
                ]}
                onFocus={() => {
                  setFocusHandler(true)
                }}
                editable={editable}
                secureTextEntry={showPassword ? false : secure}
                autoFocus={autoFocus && isIos}
                keyboardType={type || 'default'}
                textContentType={textContentType || 'none'}
                autoCapitalize="none"
                testID={testID}
                ref={inputRef}
                autoCorrect={autoCorrect}
                selectionColor={Colors.purple}
                autoComplete="off"
              />
            )}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
          />
          <View style={styles.eyeIcon}>
            {accessibilityLabelValue === 'Password'
              ? (
                <TouchableOpacity onPress={() => handleShowPassword()}>
                  {showPassword
                    ? (
                      <EyeShowPassword color={focus ? null : '#BEC3D0'} />
                      )
                    : (
                      <EyeSlashPassword color={focus ? null : '#BEC3D0'} />
                      )}
                </TouchableOpacity>
                )
              : null}
          </View>
        </View>
      </TouchableWithoutFeedback>
      {error && errorMessage && (
        <View style={styles.errorContainerText}>
          <ErrorIcon />
          <CustomText
            size="xmedium"
            color="error"
            align="left"
            testID={errorTestID}
            customStyle={styles.error}
          >
            {errorMessage[error.type] || error.message}
          </CustomText>
        </View>
      )}
    </View>
  )
}

export default Field
