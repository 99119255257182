import React from 'react'
import { RemoveButton, TextUploadedFile } from './style'
import BoxWithAction from 'components/shared/BoxWithAction'

interface Props {
  title: string;
  onClick: () => void;
}

const BoxRemoveUploadedFile: React.FC<Props> = ({ title, onClick }) => {
  return (
    <BoxWithAction>
      <TextUploadedFile>
        {title}
      </TextUploadedFile>
      <RemoveButton onClick={onClick}>Borrar</RemoveButton>
    </BoxWithAction>
  )
}

export default BoxRemoveUploadedFile
