import { logEvent, getAnalytics } from 'firebase/analytics'
import { app } from 'config/firebase'

/**
 * @param event: event name to send to Analytics
 * @param data: data of the event to send to Analytics
 * @description: send event to Analytics. If the environment is not production, do nothing.
 */

const handleAnalyticsEvent = (event: string, data?: object) => {
  return logEvent(getAnalytics(app), event, data)
}

export default handleAnalyticsEvent
