import { StyleSheet } from 'react-native'
import styled from 'styled-components'

export default StyleSheet.create({
  disabled: {
    opacity: 0.4
  }
})

export const CursorPointer = styled.div`
  cursor: pointer;
  align-self: center;
  padding: 5px 5px;
`
