import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1000px;
  margin: 20px 40px;
  padding: 35px 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  background-color: white;
`

export const Title = styled.p`
  font-size: 1.2rem;
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
