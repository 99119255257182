import React from 'react'
import { Text, View } from 'react-native'
import { ViewBar } from './style'

interface Props {
  item: {
    isComplete: boolean
    title: string
  }
  currentStepBox: number
  index: number
}

const StepContract: React.FC<Props> = ({
  item,
  currentStepBox,
  index
}) => {
  return (
    <View style={{ flex: 1 }}>
      <Text style={{ flex: 1 }}>{item.title}</Text>
      <ViewBar
        isComplete={item.isComplete}
        currentStepBox={currentStepBox}
        index={index}
      />
    </View>
  )
}

export default StepContract
