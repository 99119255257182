import { white } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: ${white};
  margin-left: 50px;
  margin-top: 3.65vh;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  height: 70%;

  @media ${DEVICES.tablet} {
    align-self: center;
    height: 40%;
    margin-left: 0;
  }
`

export const TextTitle = styled.p`
  margin-top: 4vh;
  margin-bottom: 4vh;
  font-size: 20px;
  align-self: center;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  max-width: 1000px;
  width: 100%;
  max-width: 350px;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-evenly;
  margin-top: 50px;
  max-width: 1000px;
  width: 55%;
`

export const Button = styled(ButtonComponent)`
  max-width: 300px !important;
  width: 45% !important;
  margin-bottom: 0 !important;
`

export const textInfo = {
  marginBottom: '5vh'
}
