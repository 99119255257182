import styled from 'styled-components/native'
import { SIZES } from '@qirapagos/lib/theme/fonts'
import { StageBoxType } from './types'
import { graySoft, white, black } from '@qirapagos/lib/theme/colors'

export const ViewOperationsContainer = styled.View`
  flex-direction: row;
  justify-content: center;
`

export const ViewStageBox = styled.View<StageBoxType>`
color: red;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 12.5vw;
  height: 22.556vh;
  min-height: 150px;
  min-width: 110px;
  padding: 5px;
  border: ${props =>
    props.isDone
      ? '0'
      : props.isPending
      ? '1.5px solid rgba(0, 0, 0, 0.1)'
      : '1.5px solid rgba(0, 0, 0, 0.2)'};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  margin: 1.5px;
  background-color: ${props =>
    props.isPending
      ? 'rgba(255, 255, 255, 0.5)'
      : props.isSelected
      ? graySoft
      : white};
`

export const ViewButton = styled.View`
  position: absolute;
  top: 80%;
`

export const ViewStatus = styled.View`
  position: absolute;
  top: 65%;
`

export const styles = {
  title: {
    marginTop: '10px',
    fontSize: SIZES.BIG,
    color: black,
    fontWeight: '500'
  },
  titleDisabled: {
    marginTop: '10px',
    fontSize: SIZES.BIG,
    fontWeight: '500'
  },
  text: {
    marginTop: '10px',
    fontSize: SIZES.MEDIUM,
    color: black
  },
  textDisabled: {
    marginTop: '10px',
    fontSize: SIZES.MEDIUM
  }
}
