import { StatusType } from 'components/atoms/Status/constants'

import ARFLAG from '../assets/images/ar-flag.png'
import BLFLAG from '../assets/images/bl-flag.png'
import BRFLAG from '../assets/images/br-flag.png'
import CIFLAG from '../assets/images/ci-flag.png'
import COFLAG from '../assets/images/co-flag.png'
import MXFLAG from '../assets/images/mx-flag.png'
import PAFLAG from '../assets/images/pa-flag.png'
import PEFLAG from '../assets/images/pe-flag.png'
import UYFLAG from '../assets/images/uy-flag.png'
import VEFLAG from '../assets/images/ve-flag.png'

export enum ListItemType {
  WALLET = 'wallet',
  GRAIN = 'grain',
  ACCOUNT = 'account',
  MY_DATA = 'myData',
  DOCUMENTATION = 'documentation',
  AGENTS = 'agents',
  CUSTOMER_SUPPORT = 'customerSupport',
  YOUR_SELLER = 'yourSeller',
  YOUR_ENTERPRISES = 'yourEnterprises',
  LOG_OUT = 'logOut',
  AVATAR = 'avatar',
  WARNING = 'warning'
}

export const ACTIVE_OPERATION = [
  StatusType.IN_PROGRESS,
  StatusType.NEW,
  StatusType.ON_APPROBATION,
  StatusType.APPROVED,
  StatusType.PENDING,
  StatusType.ENTERPRISE_PENDING,
  StatusType.COUNTEROFFER_RECEIVED
]

export const FINISHED_OPERATION = [
  StatusType.CANCELED,
  StatusType.HE_FINISHED,
  StatusType.SHE_FINISHED,
  StatusType.REJECTED,
  StatusType.ACCOMPLISHED
]

export const opacityAnimationConfig = {
  from: { opacity: 0 },
  to: { opacity: 1 },
  config: { duration: 300 },
  delay: 100
}

export const COUNTRYS = [
  { id: 1, country: 'Argentina', code: 549, flag: ARFLAG },
  { id: 2, country: 'Bolivia', code: 591, flag: BLFLAG },
  { id: 3, country: 'Brasil', code: 55, flag: BRFLAG },
  { id: 4, country: 'Chile', code: 56, flag: CIFLAG },
  { id: 5, country: 'Colombia', code: 57, flag: COFLAG },
  { id: 6, country: 'Mexico', code: 52, flag: MXFLAG },
  { id: 7, country: 'Paraguay', code: 595, flag: PAFLAG },
  { id: 8, country: 'Perú', code: 51, flag: PEFLAG },
  { id: 9, country: 'Uruguay', code: 598, flag: UYFLAG },
  { id: 10, country: 'Venezuela', code: 46, flag: VEFLAG }
]

export const STATES = [
  { id: 1, state: 'Buenos Aires' },
  { id: 2, state: 'Catamarca' },
  { id: 3, state: 'Chaco' },
  { id: 4, state: 'Chubut' },
  { id: 5, state: 'Córdoba' },
  { id: 6, state: 'Corrientes' },
  { id: 7, state: 'Entre Ríos' },
  { id: 8, state: 'Formosa' },
  { id: 9, state: 'Jujuy' },
  { id: 10, state: 'La Pampa' },
  { id: 11, state: 'La Rioja' },
  { id: 12, state: 'Mendoza' },
  { id: 13, state: 'Misiones' },
  { id: 14, state: 'Neuquén' },
  { id: 15, state: 'Río Negro' },
  { id: 16, state: 'Salta' },
  { id: 17, state: 'San Juan' },
  { id: 18, state: 'San Luis' },
  { id: 19, state: 'Santa Cruz' },
  { id: 20, state: 'Santa Fe' },
  { id: 21, state: 'Santiago del Estero' },
  { id: 22, state: 'Tierra del Fuego' },
  { id: 23, state: 'Tucumán' }
]
