import React from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch, useSelector } from 'react-redux'
import { setShowDrawerStatement } from 'store/modals/actions'
import CustomText from 'components/atoms/CustomText'
import DownloadSVG from 'assets/svg/DownloadSVG'
import BackArrow from 'assets/svg/BackArrow'
import useWindowDimensions from 'hooks/useWindowDimensions'
import {
  styleDrawerText,
  stylesDrawer,
  TooltipContainer,
  TouchableOpacityBackArrow,
  ViewContainer,
  ViewDrawerContainer,
  ViewHeader,
  ViewMonthsDrawer,
  ViewTitle
} from './styles'
import InfoSVG from 'assets/svg/infoSVG'
import { MONTHS_NAMES } from './constants'
import Tooltip from 'components/atoms/Tooltip'
import { RootState } from 'store'

const DrawerStatement: React.FC = () => {
  const { showStatementDrawer } = useSelector((state: RootState) => state.modal)
  const { summaries } = useSelector((state: RootState) => state.account)

  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const onClose = () => {
    dispatch(setShowDrawerStatement(false))
  }

  return (
    <Drawer
      open={showStatementDrawer}
      onClose={onClose}
      direction="right"
      style={
        width > 1024
          ? stylesDrawer.drawerContainer
          : width < 600
            ? stylesDrawer.drawerContainerMobile
            : stylesDrawer.drawerContainerTablet
      }
    >
      <ViewContainer>
        <ViewHeader>
          <TouchableOpacityBackArrow type="button" onClick={onClose}>
            <BackArrow />
          </TouchableOpacityBackArrow>
          <ViewTitle>Movimientos de mi cuenta</ViewTitle>
        </ViewHeader>
        <ViewDrawerContainer>
          {summaries?.sort((a: any, b: any) => a.period - b.period)?.map((item: any) => (
            <ViewMonthsDrawer>
              <CustomText
                weight="regular"
                size="xbig"
                color="grayText"
                customStyle={styleDrawerText}
              >
                {`${MONTHS_NAMES[item.period.slice(4) as keyof typeof MONTHS_NAMES].substring(0, 3)}
                ${item.period.slice(0, 4)}`}
              </CustomText>
              {item.url !== null
                ? (
                  <button
                    type="button"
                    onClick={() => window.open(item?.url, '_blank')}
                  >
                    <DownloadSVG />
                  </button>
                  )
                : (
                  <TooltipContainer>
                    <Tooltip
                      label="Resúmen no disponible. Debe comunicarse con atención al público."
                      hasArrow={false}
                      margin={1}
                    >
                      <InfoSVG />
                    </Tooltip>
                  </TooltipContainer>
                  )}
            </ViewMonthsDrawer>
          ))}
        </ViewDrawerContainer>
      </ViewContainer>
    </Drawer>
  )
}

export default DrawerStatement
