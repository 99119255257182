import styled from 'styled-components'

export const ViewIcon = styled.button`
  display: flex;
  align-items: center;
  width: 37px;
  height: 37px;
`

export const TextUploadFile = styled.p`
  font-size: 16px;
  font-weight: 500
`
