import React from 'react'
const EmailSent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="184"
      height="164"
      fill="none"
      viewBox="0 0 184 164"
    >
      <path
        fill="#6654CD"
        d="M167.451 90.053c0 27.114-15.047 51.248-36.201 63.315 0 0-.15.15-.298.15-2.683 1.639-6.108 3.129-7.599 3.873h-.149c-1.191.447-8.342 3.427-9.534 3.427-.149 0-.298 0-.298.148-.149 0-.298 0-.447.15-3.129.894-7.598 1.639-8.492 1.787 0 .448-13.706 1.788-25.326-.744-1.49 0-11.024-2.533-19.367-6.704-23.539-12.216-39.777-36.947-39.777-65.402 0-40.67 33.073-73.594 73.744-73.594 40.82 0 73.744 32.924 73.744 73.594z"
      />
      <path
        fill="#4988FD"
        d="M116.054 120.147v40.075c-.149 0-2.086.745-2.235.745-.149 0-.298 0-.298.149-.149 0-.298 0-.447.149-3.128.894-7.598 1.64-8.491 1.788v-42.906h11.471z"
      />
      <path
        fill="#fff"
        d="M116.054 120.147v40.075c-.149 0-2.086.745-2.235.745-.149 0-.298 0-.298.149-.149 0-.298 0-.447.149-3.128.894-7.598 1.64-8.491 1.788v-42.906h11.471z"
      />
      <path
        fill="#2767F4"
        d="M126.483 120.147v35.904c-.746.447-2.086.894-3.129 1.49h-.149c-1.937.893-4.171 1.787-8.045 2.979v-40.224h11.323v-.149z"
      />
      <path
        fill="#D5DAE8"
        d="M126.483 120.147v35.904c-.746.447-2.086.894-3.129 1.49h-.149c-1.937.893-4.171 1.787-8.045 2.979v-40.224h11.323v-.149z"
      />
      <path
        fill="#062C78"
        d="M126.483 135.642v17.877l-2.682 1.34h-.149c-2.533 1.341-6.406 2.533-8.045 2.979-.149 0-.298.15-.447.15v-3.873c1.788-5.364 4.767-10.875 8.79-15.494a24.158 24.158 0 012.533-2.979zM126.483 120.147h-21.9v12.664h21.9v-12.664z"
        opacity="0.1"
      />
      <path
        fill="url(#paint0_linear_1529_22828)"
        d="M173.113 75.156v45.587h-35.607v.149h-71.36V38.955h67.785c.894-.15 1.788-.15 2.831-.15 1.192 0 2.234 0 3.427.15 1.49.149 2.83.298 4.171.596 7.002 1.49 13.259 5.065 18.026 9.832 6.704 6.704 10.727 15.792 10.727 25.773z"
      />
      <path
        fill="#737C90"
        d="M159.406 87.67c4.469 0 8.194-3.575 8.194-8.194 0-4.618-3.725-8.193-8.194-8.193-4.47 0-8.193 3.575-8.193 8.194 0 4.618 3.575 8.193 8.193 8.193z"
      />
      <path
        fill="#D5DAE8"
        d="M160.747 86.628c4.618 0 8.491-3.576 8.491-8.194s-3.724-8.194-8.491-8.194c-4.619 0-8.492 3.576-8.492 8.194s3.725 8.194 8.492 8.194z"
      />
      <path
        fill="#F5F7FB"
        d="M161.194 86.628c4.469 0 8.194-3.576 8.194-8.194s-3.725-8.194-8.194-8.194c-4.47 0-8.194 3.576-8.194 8.194s3.575 8.194 8.194 8.194z"
      />
      <path
        fill="#CED7E2"
        d="M161.194 81.563c1.788 0 3.128-1.341 3.128-3.129s-1.34-3.128-3.128-3.128c-1.789 0-3.129 1.34-3.129 3.128s1.34 3.129 3.129 3.129z"
      />
      <path
        fill="#737C91"
        d="M101.603 71.58v49.312H28.902V71.581c0-15.494 11.024-28.604 25.475-31.882 2.384-.595 4.767-.744 7.3-.744h7.449c16.387 0 29.795 11.918 32.328 27.56 0 1.49.149 3.278.149 5.066z"
      />
      <path
        fill="url(#paint1_linear_1529_22828)"
        d="M101.603 78.881l-.447-11.918c-2.533-15.643-16.09-27.561-32.328-27.561h-7.45c-2.532 0-4.916.298-7.3.745l-.893.745 18.324 75.531 29.796 1.639.298-39.181z"
        opacity="0.7"
      />
      <path
        fill="#C3CAD6"
        d="M101.454 66.516c-2.533-15.643-16.09-27.561-32.328-27.561h-7.449c-2.533 0-4.916.149-7.3.745-14.6 3.277-25.475 16.238-25.475 31.88v49.312h72.701V71.581c0-1.788-.149-3.576-.149-5.066zm-2.83 51.397H31.88V71.581c0-13.706 9.684-25.922 23.241-28.902 1.937-.447 3.873-.596 6.555-.596h7.449c14.6 0 26.965 10.429 29.348 24.88v50.95h.15z"
      />
      <path
        fill="#C1C8D7"
        d="M84.62 148.751c-2.087 3.129-4.62 8.194-6.109 12.366-.149.298-.298.744-.298 1.042-1.043-.148-2.086-.446-3.128-.744-.894-2.087-1.49-4.321-1.49-4.321.596-.745 1.043-1.341 1.639-1.937 2.83-3.426 5.81-5.809 9.385-6.406z"
      />
      <path
        fill="#D5DAE8"
        d="M79.107 162.308c-.298 0-.596-.149-.894-.149-1.042-.149-2.085-.447-3.128-.745-.745-.149-1.341-.447-2.086-.596-.298-.149-.596-.149-.894-.298-.298-.148-.596-.148-1.043-.298-3.873-1.192-7.597-2.831-11.173-4.617-3.724-6.258-5.81-13.856-9.386-24.433 0-.15 0-.15-.148-.298 0 0 .298 0 1.042.298.447.298 1.192.595 2.086 1.191 1.788 1.192 4.618 3.278 8.79 6.704 5.214 4.321 9.683 10.726 12.812 15.941 1.34 2.383 2.532 4.47 3.426 6.108.298.298.447.745.596 1.192z"
      />
      <path
        fill="#EAEEF9"
        d="M72.999 160.669c-.298-.148-.596-.148-.894-.296-.298-.15-.596-.15-1.043-.299-6.853-13.707-14.6-22.495-19.069-27.561-.447-.596-.894-1.042-1.34-1.49 0-.148 0-.148-.15-.298 0 0 .298 0 1.043.298.447.298 1.192.596 2.086 1.192 4.618 5.364 12.514 14.45 19.367 28.454z"
      />
      <path
        fill="#D5DAE8"
        d="M142.571 128.64l-.446.893c-1.789 3.278-7.747 14.153-10.577 23.688-.15 0-.15.148-.298.148 0 0-.15.15-.298.15-2.683 1.639-6.108 3.129-7.599 3.873h-.149c-4.469 2.086-9.236 3.427-9.534 3.427 2.235-7.3 5.512-13.855 10.13-19.367.894-1.043 1.788-2.086 2.682-2.979l.447-.448c3.873-3.873 7.896-6.406 11.769-8.044.448-.15.894-.448 1.34-.596.448-.298 1.193-.447 1.938-.745.297.149.447 0 .595 0z"
      />
      <path
        fill="#EAEEF9"
        d="M142.423 128.342s-.15.148-.298.596c0 .148-.15.148-.15.298-7.746 6.852-13.854 18.323-17.727 27.858l-.895.448h-.149c-.298.148-.744.298-1.042.446.149-.298.298-.446.298-.446 1.936-4.768 7.151-18.325 16.238-27.413l.894-.893c1.043-.298 1.937-.596 2.831-.894z"
      />
      <path
        fill="#919AAC"
        d="M102.05 115.678v5.214H2.682c-1.49 0-2.682-1.192-2.682-2.533a2.67 2.67 0 012.682-2.681h99.368z"
      />
      <path
        fill="#CED7E2"
        d="M163.726 38.657v39.181c0 1.192-.894 2.086-2.085 2.086-1.192 0-2.086-.894-2.086-2.086v-39.18h4.171z"
      />
      <path
        fill="#EAEEF9"
        d="M183.69 37.316c.596.447.298 1.49-.596 1.49H159.406v-16.09h23.688a.86.86 0 01.596 1.49l-6.258 5.214a1.829 1.829 0 000 2.83l6.258 5.066zM156.456 145.578a5.244 5.244 0 100-10.49 5.244 5.244 0 000 10.49zM11.715 143.912a6.651 6.651 0 100-13.303 6.651 6.651 0 000 13.303z"
      />
      <g filter="url(#filter0_d_1529_22828)">
        <path
          fill="url(#paint2_linear_1529_22828)"
          d="M86.75 69.455H29.854a5.116 5.116 0 00-5.117 5.116v35.991a5.117 5.117 0 005.117 5.116H86.75a5.117 5.117 0 005.117-5.116v-35.99a5.117 5.117 0 00-5.117-5.117z"
        />
      </g>
      <path
        fill="#E0E4F0"
        d="M90.926 71.193L63.642 91.397a8.974 8.974 0 01-10.68 0L25.676 71.193a4.48 4.48 0 013.547-1.738H87.38a4.48 4.48 0 013.546 1.738z"
      />
      <path
        stroke="#A6B0C1"
        strokeWidth="2.5"
        d="M157.786 15.6L152.255 1M154.242 21.057l-11.172-3.871M164.322 13.389l1.217-11.393"
      />
      <defs>
        <filter
          id="filter0_d_1529_22828"
          width="111.129"
          height="90.224"
          x="2.737"
          y="58.455"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1529_22828"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1529_22828"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1529_22828"
          x1="119.587"
          x2="119.587"
          y1="39.768"
          y2="101.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1529_22828"
          x1="99.761"
          x2="69.063"
          y1="77.798"
          y2="79.229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#42465A" />
          <stop offset="1" stopColor="#575E84" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1529_22828"
          x1="58.28"
          x2="58.28"
          y1="68.385"
          y2="116.176"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.996" stopColor="#ECF0F5" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default EmailSent
