/* eslint-disable max-len */
import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

const InfoHowWorks = () => {
  return (
    <Svg
      width="174"
      height="159"
      fill="none"
      viewBox="0 0 174 159"
    >
      <Path
        fill="#EAEEF9"
        d="M83.71 123.734c32.699 0 59.207-26.54 59.207-59.392 0-32.852-26.625-59.39-59.206-59.39-32.698 0-59.207 26.538-59.207 59.39 0 32.853 26.509 59.392 59.207 59.392z"
      />
      <Path
        fill="#E7EBF6"
        d="M139.063 25.41a4.79 4.79 0 004.788-4.793 4.79 4.79 0 00-4.788-4.793 4.79 4.79 0 00-4.788 4.793 4.79 4.79 0 004.788 4.793zM146.07 6.705a3.271 3.271 0 003.27-3.274 3.271 3.271 0 10-6.54 0 3.272 3.272 0 003.27 3.274zM27.657 25.294a3.272 3.272 0 003.27-3.274 3.272 3.272 0 00-3.27-3.273 3.272 3.272 0 00-3.27 3.273 3.272 3.272 0 003.27 3.274zM7.688 88.426a6.076 6.076 0 006.072-6.08 6.076 6.076 0 00-6.072-6.079 6.076 6.076 0 00-6.073 6.08 6.076 6.076 0 006.073 6.08z"
      />
      <G>
        <Path
          fill="url(#paint0_linear_4_32710)"
          d="M100.877 32.776l17.867 64.301c.584 2.222-.701 4.443-2.919 5.028l-62.944 16.718a4.073 4.073 0 01-5.021-2.923L25.905 32.893c-.584-2.221.701-4.443 2.92-5.027l45.543-12.042 26.509 16.952z"
        />
      </G>
      <Path
        fill="#D5DDEA"
        d="M99.943 73.461L65.96 82.463l-3.736.936-11.328 3.04c-.467.116-.817.7-.584 1.285.117.585.7.936 1.168.819l11.327-3.04 3.737-.935 33.983-9.003c.467-.117.817-.701.584-1.286-.117-.584-.701-.935-1.168-.818zM97.724 65.511l-12.612 3.274-4.32 1.169-31.881 8.534c-.467.117-.818.702-.584 1.286.117.585.7.936 1.168.819l31.997-8.535 4.32-1.169 12.613-3.274c.467-.116.817-.701.584-1.286-.234-.467-.818-.935-1.285-.818zM95.856 57.444l-3.27.819-3.27.818-42.624 11.34c-.467.117-.818.702-.584 1.286.117.585.7.936 1.168.819L89.9 61.186l3.62-.936 2.92-.818c.467-.117.817-.702.584-1.286-.117-.468-.584-.819-1.168-.702z"
      />
      <Path
        fill="#CED7E2"
        d="M76.12 98.831l-2.336.585c-.467.117-1.05-.234-1.167-.819-.117-.584.117-1.169.584-1.286l2.335-.584c.467-.117 1.051.233 1.168.818.117.701-.117 1.17-.584 1.286z"
      />
      <Path
        fill="#D5DDEA"
        d="M68.997 100.701l-13.43 3.508c-.467.117-1.05-.234-1.168-.819-.116-.584.117-1.169.584-1.286l13.43-3.507c.467-.117 1.05.234 1.168.819.233.584 0 1.169-.584 1.285zM94.22 49.377l-10.626 2.806-2.57.702-36.317 9.587c-.468.117-.818.701-.584 1.286.117.584.7.935 1.168.818l36.2-9.47 2.57-.701 10.51-2.806c.584-.117.817-.702.7-1.403 0-.468-.583-.935-1.05-.819zM69.114 31.724l-30.13 7.95c-.7.233-1.4-.234-1.634-.936-.234-.701.233-1.403.934-1.636l30.129-7.95c.7-.234 1.401.233 1.635.935.233.701-.234 1.403-.934 1.637zM52.531 43.883l-11.56 3.04c-.702.233-1.402-.235-1.636-.936-.233-.702.234-1.403.934-1.637l11.445-3.04c.7-.233 1.401.234 1.635.936.233.701-.234 1.403-.818 1.636zM74.369 15.824l4.67 17.77c.702 2.455 3.388 3.975 5.84 3.274l15.998-4.092"
      />
      <G>
        <Path
          fill="url(#paint1_linear_4_32710)"
          d="M148.055 50.897l-10.743 65.939c-.351 2.221-2.569 3.741-4.788 3.273L68.53 108.886c-2.22-.351-3.737-2.573-3.387-4.794l14.83-84.527c.351-2.221 2.57-3.741 4.789-3.274l46.477 8.184 16.816 26.422z"
        />
      </G>
      <Path
        fill="#CED7E2"
        d="M130.188 87.49l-34.566-6.079-3.737-.701-11.56-1.988c-.468-.117-1.052.351-1.052.936-.117.584.234 1.169.7 1.286l11.562 1.987 3.737.702 34.566 6.079c.467.117 1.051-.35 1.051-.935.117-.585-.117-1.17-.701-1.286zM131.59 79.424l-12.846-2.221-4.321-.819-32.58-5.728c-.468-.117-1.052.35-1.052.935-.117.584.234 1.169.7 1.286l32.582 5.729 4.321.818 12.845 2.221c.467.117 1.051-.35 1.051-.935.117-.585-.233-1.17-.7-1.286z"
      />
      <Path
        fill="#D5DDEA"
        d="M133.224 71.24l-3.386-.584-3.387-.585-43.441-7.6c-.467-.116-1.051.351-1.051.936-.117.585.233 1.17.7 1.286l43.442 7.6 3.737.7 2.919.468c.467.117 1.051-.35 1.051-.935.234-.584 0-1.169-.584-1.286z"
      />
      <Path
        fill="#CED7E2"
        d="M97.958 100.585l-2.336-.351c-.467-.117-.817-.701-.7-1.286.116-.584.583-1.052 1.05-.935l2.336.35c.467.117.818.702.7 1.287-.116.701-.583.935-1.05.935z"
      />
      <Path
        fill="#E3EAF2"
        d="M90.834 99.299L77.171 96.96c-.467-.116-.817-.701-.7-1.286.116-.584.583-1.052 1.05-.935l13.663 2.338c.468.117.818.702.701 1.286-.117.702-.584 1.053-1.05.936z"
      />
      <Path
        fill="#D5DDEA"
        d="M135.093 63.29l-10.86-1.87-2.686-.468-37.019-6.547c-.467-.117-1.05.35-1.05.935-.118.584.233 1.169.7 1.286l37.018 6.547 2.686.468 10.744 1.87c.584.117 1.051-.35 1.168-.935.117-.585-.234-1.17-.701-1.286zM119.795 36.75l-30.712-5.377c-.701-.117-1.168-.819-1.051-1.52.116-.701.817-1.169 1.518-1.052l30.712 5.378c.701.117 1.168.818 1.051 1.52-.117.584-.817 1.169-1.518 1.052zM99.593 40.726l-11.795-2.104c-.7-.117-1.168-.819-1.051-1.52.117-.702.817-1.17 1.518-1.052l11.678 2.104c.701.117 1.168.818 1.051 1.52-.117.701-.818 1.169-1.401 1.052zM131.122 24.475l-3.153 18.122c-.467 2.572 1.402 5.027 3.971 5.494l16.115 2.806"
      />
      <Path
        fill="#fff"
        d="M104.03 110.639c14.382 0 26.042-11.672 26.042-26.071 0-14.4-11.66-26.072-26.042-26.072-14.382 0-26.041 11.673-26.041 26.072 0 14.398 11.659 26.071 26.041 26.071z"
      />
      <Path
        fill="#989FB0"
        d="M101.578 88.777c-.117-.468-.117-.936-.117-1.52 0-1.988.818-3.741 2.92-5.261l1.751-1.286c1.051-.819 1.518-1.754 1.518-2.923 0-1.637-1.167-3.274-3.62-3.274-2.569 0-3.737 2.105-3.737 4.092 0 .468 0 .819.117 1.17l-4.671-.117c-.117-.468-.234-1.053-.234-1.637 0-3.975 2.92-7.716 8.408-7.716 5.606 0 8.642 3.624 8.642 7.248 0 2.923-1.518 4.91-3.62 6.43l-1.518 1.052c-1.285.936-1.985 2.105-1.985 3.508v.234h-3.854zm1.985 2.338a2.893 2.893 0 012.92 2.923 2.893 2.893 0 01-2.92 2.922 2.892 2.892 0 01-2.919-2.922c.116-1.52 1.284-2.923 2.919-2.923z"
      />
      <Path
        fill="#989FB0"
        d="M143.011 125.046c-1.012 0-2.022-.404-2.831-1.315l-16.88-16.9-.608.405c-5.458 4.048-11.826 6.173-18.295 6.173-7.783 0-15.567-3.137-21.329-8.602-6.064-5.768-9.4-13.56-9.4-22.06 0-16.9 13.747-30.664 30.628-30.664 11.523 0 21.631 6.072 27.089 16.394 5.358 10.221 4.651 22.163-1.919 31.776l-.404.608 16.981 17.001c1.718 1.72 1.314 3.441 1.011 4.352-.809 1.619-2.426 2.832-4.043 2.832zm-38.715-64.563c-12.332 0-22.238 10.018-22.238 22.263 0 13.966 11.422 22.365 22.541 22.365 6.773 0 12.939-3.036 17.285-8.5 5.358-6.68 6.268-15.686 2.527-23.479-3.84-7.792-11.523-12.65-20.115-12.65z"
      />
      <defs>
        <filter
          id="filter0_d_4_32710"
          width="137.12"
          height="147.14"
          x="3.765"
          y="4.824"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackGroundImaGeFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend
            in2="BackGroundImaGeFix"
            result="effect1_dropShadow_4_32710"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4_32710"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4_32710"
          width="126.963"
          height="147.987"
          x="43.093"
          y="5.207"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackGroundImaGeFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend
            in2="BackGroundImaGeFix"
            result="effect1_dropShadow_4_32710"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4_32710"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4_32710"
          x1="72.294"
          x2="72.294"
          y1="13.438"
          y2="120.075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.996" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4_32710"
          x1="106.547"
          x2="106.547"
          y1="13.801"
          y2="121.315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.996" stopColor="#ECF0F5" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default InfoHowWorks
