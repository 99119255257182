import initialState from './initialState'
import { AccountState, AccountActionTypes } from './types'

export default function signatureReducer (state = initialState, action: any): AccountState {
  switch (action.type) {
    case AccountActionTypes.GET_ACCOUNT_SUMMARIES_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    case AccountActionTypes.GET_ACCOUNT_SUMMARIES_FULFILLED:
      return {
        ...state,
        summaries: action.payload,
        isLoading: false
      }
    case AccountActionTypes.GET_ACCOUNT_SUMMARIES_PENDING: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    default:
      return state
  }
}
