import styled from 'styled-components'
import DrawerComponent from 'react-modern-drawer'
import { ReactComponent as TrashIcon } from 'assets/svg/Trash.svg'
import { ReactComponent as OfflineHandlingIcon } from 'assets/svg/OfflineHandling.svg'
import { WarningBox as WarningBoxComponent } from 'components'

import { gray, violet } from 'theme/colors'

export const Container = styled.div`
  height: 100%;
  justify-content: space-evenly;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0;
  padding: 0 2.5rem;
`

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
`

export const EmptyLabel = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${gray}
`

export const List = styled.div`
  height: 100%;
  overflow: scroll;
`

interface ITrash {
  selected: boolean
}

export const Trash = styled(TrashIcon)<ITrash>`
  stroke: ${props => props.selected ? gray : violet} !important;
`

export const OfflineHandling = styled(OfflineHandlingIcon)`
  margin-bottom: 1.5rem;
`

export const WarningBox = styled(WarningBoxComponent)`
  margin: 2.5rem !important;
  width: calc(100% - 5rem);
`

export const Drawer = styled(DrawerComponent)`
  width: 50% !important;
  min-width: 300px !important;
  @media (max-width: 600px) {
    width: 100% !important;
  }
  @media (min-width: 1024px) {
    width: 30% !important;
  }
`
