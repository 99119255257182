import { AnyAction } from 'redux'
import initialState from './initialState'
import { LocationState, LocationActionTypes } from './types'

export default function signatureReducer (state = initialState, action: AnyAction): LocationState {
  switch (action.type) {
    case LocationActionTypes.GET_LOCATIONS_PENDING:
    case LocationActionTypes.GET_LOCATION_BY_ID_PENDING:
    case LocationActionTypes.GET_STATES_PENDING:
    case LocationActionTypes.GET_DESTINATION_STATES_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoading: true
      }
    case LocationActionTypes.GET_DESTINATIONS_PENDING:
    case LocationActionTypes.GET_DESTINATION_BY_ID_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoadingDestinations: true
      }
    case LocationActionTypes.GET_LOCATIONS_FULFILLED:
      return {
        ...state,
        locations: action.payload,
        isLoading: initialState.isLoading
      }
    case LocationActionTypes.GET_LOCATION_BY_ID_FULFILLED:
      return {
        ...state,
        locations: [action.payload],
        isLoading: initialState.isLoading
      }
    case LocationActionTypes.GET_STATES_FULFILLED:
      return {
        ...state,
        states: action.payload,
        locations: initialState.locations,
        isLoading: initialState.isLoading
      }
    case LocationActionTypes.GET_DESTINATION_STATES_FULFILLED:
      return {
        ...state,
        isLoading: initialState.isLoading,
        destinationStates: action.payload
      }
    case LocationActionTypes.GET_DESTINATIONS_FULFILLED:
      return {
        ...state,
        destinations: action.payload.result,
        isLoadingDestinations: initialState.isLoadingDestinations
      }
    case LocationActionTypes.GET_DESTINATION_BY_ID_FULFILLED:
      return {
        ...state,
        destinations: [action.payload],
        isLoadingDestinations: initialState.isLoadingDestinations
      }
    case LocationActionTypes.GET_LOCATIONS_REJECTED:
    case LocationActionTypes.GET_LOCATION_BY_ID_REJECTED:
    case LocationActionTypes.GET_STATES_REJECTED:
    case LocationActionTypes.GET_DESTINATION_STATES_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: initialState.isLoading
      }
    case LocationActionTypes.GET_DESTINATIONS_REJECTED:
    case LocationActionTypes.GET_DESTINATION_BY_ID_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoadingDestinations: initialState.isLoadingDestinations
      }
    case LocationActionTypes.CLEAN_LOCATIONS:
      return {
        ...state,
        locations: initialState.locations
      }
    default:
      return state
  }
}
