import React, { ReactNode } from 'react'
import {
  PlacementWithLogical,
  Tooltip as TooltipChakra
} from '@chakra-ui/react'
import { DivContainer } from './styles'
import { purple, white } from '@qirapagos/lib/theme/colors'

interface Props {
  children: ReactNode
  color?: string
  label?: string
  colorBackground?: string
  placement?: PlacementWithLogical
  hasArrow?: boolean
  margin?: number
}

const Tooltip: React.FC<Props> = ({
  children,
  color,
  label,
  colorBackground,
  placement,
  hasArrow,
  margin
}) => {
  return (
    <DivContainer>
      <TooltipChakra
        hasArrow={hasArrow}
        placement={placement}
        bg={colorBackground}
        label={label}
        fontSize="12px"
        fontWeight={400}
        color={color}
        borderRadius="4px"
        shouldWrapChildren
        marginRight={margin}
      >
        {children}
      </TooltipChakra>
    </DivContainer>
  )
}

export default Tooltip

Tooltip.defaultProps = {
  label: '',
  color: white,
  colorBackground: purple,
  placement: 'top',
  hasArrow: true,
  margin: 0
}
