import { ThunkDispatchType } from 'store'
import * as LocationsAction from 'store/location/actions'
import { LocationsService } from 'services'

export const getLocations = (params: { search?: string, stateId: number }) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LocationsAction.getLocationsPending())
      const response = await LocationsService.getLocations(params)
      dispatch(LocationsAction.getLocationsFulfilled(response.data.data))
    } catch (error) {
      dispatch(LocationsAction.getLocationsRejected(error as Error))
    }
  }

export const getLocationById = (id: number) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LocationsAction.getLocationByIdPending())
      const response = await LocationsService.getLocationById(id)
      dispatch(LocationsAction.getLocationByIdFulfilled(response.data.data))
    } catch (error) {
      dispatch(LocationsAction.getLocationByIdRejected(error as Error))
    }
  }

export const getStates = () =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LocationsAction.getStatesPending())
      const response = await LocationsService.getStates()
      dispatch(LocationsAction.getStatesFulfilled(response.data.data))
    } catch (error) {
      dispatch(LocationsAction.getStatesRejected(error as Error))
    }
  }

export const getDestinations = (params: { search?: string, stateId: number }) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LocationsAction.getDestinationsPending())
      const response = await LocationsService.getDestinations(params)
      dispatch(LocationsAction.getDestinationsFulfilled(response.data.data))
    } catch (error) {
      dispatch(LocationsAction.getDestinationsRejected(error as Error))
    }
  }

export const getDestinationById = (id: number) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LocationsAction.getDestinationByIdPending())
      const response = await LocationsService.getDestinationById(id)
      dispatch(LocationsAction.getDestinationByIdFulfilled(response.data.data))
    } catch (error) {
      dispatch(LocationsAction.getDestinationByIdRejected(error as Error))
    }
  }

export const getDestinationStates = () =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LocationsAction.getDestinationStatesPending())
      const response = await LocationsService.getDestinationStates()
      dispatch(LocationsAction.getDestinationStatesFulfilled(response.data.data))
    } catch (error) {
      dispatch(LocationsAction.getDestinationStatesRejected(error as Error))
    }
  }
