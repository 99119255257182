import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { Control } from 'react-hook-form'
import Button from 'components/atoms/Button'
import { purple, white } from '@qirapagos/lib/theme/colors'
import { BorderlessField } from 'components'
import { newContact } from 'store/transfers/thunks'
import { useDispatch } from 'react-redux'
import { TSetState } from 'types/generics'
import {
  ViewContainer,
  ViewContainerInformation,
  TextFullName,
  ViewInformation,
  TextInfo
} from './styles'

interface Props {
  setIndex: TSetState<number>
  control: Control
  clearErrors: (value: any) => void
  errors: any
  titlesInfo: any
}

export const StepTwo: React.FC<Props> = ({
  control,
  clearErrors,
  errors,
  setIndex,
  titlesInfo
}) => {
  const dispatch = useDispatch()

  const onPress = () => {
    if (titlesInfo?.dataSecondStep?.contactId) {
      dispatch(
        newContact(
          titlesInfo?.dataSecondStep,
          titlesInfo?.dataSecondStep?.contactId
        )
      )
    }
    setIndex(2)
    titlesInfo.setComplete(true)
  }

  return (
    <Box bg={white} display="flex" flexDirection="column">
      <ViewContainer>
        <TextFullName>{titlesInfo?.dataSecondStep?.name}</TextFullName>
        <ViewContainerInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              Alias
            </Text>
            <TextInfo>{titlesInfo?.dataSecondStep?.alias}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              CVU
            </Text>
            <TextInfo>{titlesInfo?.dataSecondStep?.cvu}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              CC
            </Text>
            <TextInfo>Sin datos</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              CUIT / CUIL
            </Text>
            <TextInfo>{titlesInfo?.dataSecondStep?.contactId}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <BorderlessField
              control={control}
              placeholder="Observaciones"
              placeholderTextColor="gray"
              testID="observations-field"
              clearErrors={clearErrors}
              error={errors.observation}
              errorMessage={{
                maxLenght: 'Debe contener menos de 20 caracteres'
              }}
              name="observation"
              rules={{
                maxLenght: 20
              }}
            />
          </ViewInformation>
        </ViewContainerInformation>
      </ViewContainer>
      <Button
        onPress={onPress}
        customStyle={{ width: '15%', alignSelf: 'flex-end', marginRight: 30 }}
        id="continueConfirmContact"
      >
        Continuar
      </Button>
    </Box>
  )
}

export default StepTwo
