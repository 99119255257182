/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { Text } from '@chakra-ui/react'
import { getInitials } from '@qirapagos/lib/utils/common'
import WalletItem from 'assets/svg/WalletItem'
import GrainItem from 'assets/svg/GrainItem'
import AccountItem from 'assets/svg/DollarSignSVG'
import MyDataItem from 'assets/svg/MyDataItem'
import DocumentationItem from 'assets/svg/DocumentationItem'
import AgentsItem from 'assets/svg/AgentsItem'
import CustomerSupportItem from 'assets/svg/CustomerSupportItem'
import YourSellerItem from 'assets/svg/YourSellerItem'
import LogOutItem from 'assets/svg/LogOutItem'
import { AvatarCircle } from 'components'
import { ListItemType } from 'constants/common'
import {
  ViewIcon,
  TextMulti,
  ViewItemSelectorContainer,
  ViewContainer
} from './styles'
import MyEnterprisesItem from 'assets/svg/MyEnterprisesItem'
import WarningIcon from 'assets/svg/WarningIcon'

interface Props {
  title?: string
  icon?: string
  active?: boolean
  canBeHovered?: boolean
}

const MenuItem: React.FC<Props> = ({
  title = '',
  icon = '',
  active = false,
  canBeHovered = true
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const initials = getInitials(title).toUpperCase()
  const {
    WALLET,
    GRAIN,
    ACCOUNT,
    MY_DATA,
    DOCUMENTATION,
    AGENTS,
    CUSTOMER_SUPPORT,
    YOUR_SELLER,
    YOUR_ENTERPRISES,
    LOG_OUT,
    AVATAR,
    WARNING
  } = ListItemType

  const selectIcon = {
    [WALLET]: <WalletItem active={active || isHovering} />,
    [GRAIN]: (
      <GrainItem
        canBeHovered={canBeHovered}
        active={active || (isHovering && canBeHovered)}
      />
    ),
    [ACCOUNT]: <AccountItem
      canBeHovered={canBeHovered}
      active={active || (isHovering && canBeHovered)}
    />,
    [MY_DATA]: <MyDataItem
      canBeHovered={canBeHovered}
      active={active || (isHovering && canBeHovered)}
    />,
    [DOCUMENTATION]: <DocumentationItem
      canBeHovered={canBeHovered}
      active={active || (isHovering && canBeHovered)}
    />,
    [AGENTS]: <AgentsItem
      canBeHovered={canBeHovered}
      active={active || (isHovering && canBeHovered)}
    />,
    [CUSTOMER_SUPPORT]: <CustomerSupportItem
      canBeHovered={canBeHovered}
      active={active || (isHovering && canBeHovered)}
    />,
    [YOUR_SELLER]: <YourSellerItem
      canBeHovered={canBeHovered}
      active={active || (isHovering && canBeHovered)}
    />,
    [YOUR_ENTERPRISES]: <MyEnterprisesItem
      canBeHovered={canBeHovered}
      active={active || (isHovering && canBeHovered)}
    />,
    [WARNING]: <WarningIcon
      canBeHovered={canBeHovered}
      active={active || (isHovering && canBeHovered)}
    />,
    [LOG_OUT]: <LogOutItem
      active={active || (isHovering && canBeHovered)}
    />,
    [AVATAR]: (
      <AvatarCircle
        width="40px"
        height="40px"
        minWidth="30px"
        initials={initials}
        isBig={false}
        left='0'
      />
    )
  }

  return (
    <>
      {ListItemType.AVATAR.toString() !== icon
        ? (
          <ViewContainer
            canBeHovered={canBeHovered}
            onMouseLeave={() => setIsHovering(false)}
            onMouseEnter={() => setIsHovering(true)}
          >
            <ViewIcon>{selectIcon[icon as keyof typeof selectIcon]}</ViewIcon>
            <Text
              style={{
                fontSize: '1rem',
                fontWeight: '500'
              }}
            >
              {title}
            </Text>
          </ViewContainer>
          )
        : (
          <ViewItemSelectorContainer>
            <ViewIcon>{selectIcon[icon as keyof typeof selectIcon]}</ViewIcon>
            <TextMulti>{title}</TextMulti>
          </ViewItemSelectorContainer>
          )}
    </>
  )
}

export default MenuItem

MenuItem.defaultProps = {
  title: '',
  icon: '',
  active: false,
  canBeHovered: true
}
