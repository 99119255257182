import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'
import { ReactComponent as QirapagosLogo } from 'assets/svg/QiraPagosLogoRow.svg'
import { DEVICES } from 'theme/devicesSizes'

export const PageBody = styled(Flex)`
  height: 500px;
  flex-direction: column;
  align-self: center;
  justify-content: space-between;
  width: 180%;
  max-width: 360px;
  min-width: 360px;
  margin-top: 50px
`

export const QiraPagos = styled(QirapagosLogo)`
  display: none;
  @media ${DEVICES.tablet} {
    min-width: 144px !important;
    min-height: 51px !important;
    width: 144px !important;
    height: 51px !important;
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`
