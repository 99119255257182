/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

 interface Props {
  w?: number;
  h?: number;
 }
function GreenDollarSign ({ w, h }: Props) {
  return (
    <Svg width={w} height={h} viewBox="0 0 32 32" fill="none">
      <Circle cx={16} cy={16} r={15} stroke="#0ACC94" strokeWidth={2} />
      <Path
        d="M15.825 10.667v1.524M15.825 19.81v1.523M13.564 19.81h3.391c.5 0 .979-.201 1.332-.558a1.916 1.916 0 000-2.694A1.873 1.873 0 0016.955 16h-2.26c-.5 0-.98-.2-1.333-.558a1.916 1.916 0 010-2.694 1.874 1.874 0 011.332-.558h3.391"
        stroke="#0ACC94"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default GreenDollarSign

GreenDollarSign.defaultProps = {
  w: 32,
  h: 32
}
