/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/require-default-props */
import React from 'react'
import RenderIf from 'components/utilities/RenderIf'
import useShow from '@qirapagos/lib/hooks/useShow'
import Field from '@qirapagos/lib/components/molecules/Field/index.web'
import DownArrow from '@qirapagos/web/src/assets/svg/DownArrow'
import UpArrow from '@qirapagos/web/src/assets/svg/UpArrow'
import Check from '@qirapagos/lib/assets/svg/Check'
import OutsideAlerter from 'components/utilities/OutsideClickListener'
import {
  fieldContent,
  ButtonContainer,
  Container,
  ContentContainer,
  ImageFlag,
  TextList,
  ListContainer,
  DownArrowContainer,
  CheckContainer
} from './style'
interface Props {
  data: any
  control: any
  name: string
  label: string
  placeholder: string
  editable: boolean
  error?: any
  errorMessage?: any
  accessibilityLabelValue: any
  setValue: any
  selected: number
  setSelectedID?: any
  clearErrors?: () => void
}

const FieldSelectCountry: React.FC<Props> = ({
  data,
  control,
  name,
  label,
  placeholder,
  editable,
  error,
  clearErrors,
  errorMessage,
  accessibilityLabelValue,
  setValue,
  selected,
  setSelectedID
}) => {
  const { show, setShow } = useShow()
  const RenderItem = (data: any) => {
    const { data: item } = data
    const onPress = () => {
      setValue(name, item.country)
      setSelectedID(item.id)
      setShow(!show)
    }

    return (
      <ButtonContainer onClick={onPress}>
        <ContentContainer>
          <ImageFlag
            src={item.flag}
            alt='flag'
          />
          <TextList>{item.country}</TextList>
          <CheckContainer selected={selected === item.id}>
            <Check isPurple={undefined} />
          </CheckContainer>
        </ContentContainer>
      </ButtonContainer>
    )
  }

  return (
    <Container>
      <Field
        customStyleContainer={show ? fieldContent : { marginBottom: -2 }}
        control={control}
        name={name}
        label={label}
        editable={editable}
        rules={{
          required: true
        }}
        onPress={() => setShow(!show)}
        accessibilityLabelValue={accessibilityLabelValue}
        error={error}
        clearErrors={clearErrors}
        errorMessage={errorMessage}
      />

      <DownArrowContainer type="button" onClick={() => setShow(!show)}>
        {show
          ? (
            <UpArrow width={16} height={16} />
            )
          : (
            <DownArrow width={16} height={16} />
            )}
      </DownArrowContainer>

      <RenderIf isTrue={show}>
        <OutsideAlerter setState={setShow} state={show}>
          <ListContainer>
            {
            data.map((country: any) => {
              return (

                <RenderItem data={country} />
              )
            })
              }
          </ListContainer>
        </OutsideAlerter>
      </RenderIf>
    </Container>
  )
}

export default FieldSelectCountry
