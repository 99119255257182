import React, { useMemo } from 'react'
import { Container, Row, Label, Value } from './styles'
import { getCurrencySymbol, toArgCurrency } from 'utils/common'
import { formatDate } from 'utils/date'
import Status from 'components/atoms/Status'
import { getSaleOrderStatus } from 'utils/operations'
import { AppOperation, FixationCondition, appOperationText } from '@qirapagos/web/src/store/contracts/types'

export interface Attributes {
  [key: string]: string | boolean | number | undefined | null
  showOnly?: boolean
}

export const fixationConditionText = {
  [FixationCondition.SLATE_PRICE]: 'Precio pizarra',
  [FixationCondition.MARKET_PRICE]: 'Precio mercado'
}

interface Props {
  saleOrder: any
}

const ContractDetailList: React.FC<Props> = ({ saleOrder }) => {
  const isToSet =
    saleOrder.appOperation === AppOperation.SWAP_TO_FIX ||
    saleOrder.appOperation === AppOperation.SALE_TO_FIX
  const isSwap =
    saleOrder.appOperation === AppOperation.SWAP_TO_FIX ||
    saleOrder.appOperation === AppOperation.SWAP_FIXED

  const status = useMemo(
    () => getSaleOrderStatus(saleOrder.status),
    [saleOrder?.status]
  )

  // TODO RRQP-2293: Complete details
  return (
    <Container>
      <Row>
        <Label>Estado de contrato: </Label>
        <Status statusType={status} />
      </Row>
      <Row>
        <Label>Tipo operación: </Label>
        <Value>
          {appOperationText[saleOrder.appOperation as AppOperation]}
        </Value>
      </Row>
      {isSwap && (
        <>
          <Row>
            <Label>Contraparte</Label>
            <Value>{saleOrder.Exchanger?.companyName}</Value>
          </Row>
          <Row>
            <Label>Bien o servicio a canejar</Label>
            <Value>{saleOrder.exchangeProduct}</Value>
          </Row>
          <Row>
            <Label>Precio del bien o servicio</Label>
            <Value>
              {`${getCurrencySymbol(saleOrder.exchangeCurrencyId)} ${
                saleOrder.exchangeAmount
              }`}
            </Value>
          </Row>
        </>
      )}
      <Row>
        <Label>A nombre de: </Label>
        <Value>{saleOrder.Seller.companyName}</Value>
      </Row>
      <Row>
        <Label>Fecha de solicitud: </Label>
        <Value>{formatDate(saleOrder.createdAt)}</Value>
      </Row>
      <Row>
        <Label>Grano: </Label>
        <Value>{saleOrder.Grain.grain}</Value>
      </Row>
      <Row>
        <Label>Toneladas: </Label>
        <Value>{saleOrder.tons}</Value>
      </Row>
      <Row>
        <Label>Cosecha: </Label>
        <Value>
          {`${saleOrder.HarvestDate?.endDate
            .toString()
            .substring(2, 4)}/${saleOrder.HarvestDate?.startDate
            .toString()
            .substring(2, 4)}`}
        </Value>
      </Row>
      <Row>
        <Label>Precio por TT: </Label>
        <Value>
          {isToSet
            ? 'a fijar'
            : `${getCurrencySymbol(saleOrder.currencyId)} ${toArgCurrency(
                saleOrder?.price
              )}`}
        </Value>
      </Row>
      {isToSet && (
        <>
          <Row>
            <Label>Condición de la fijación: </Label>
            <Value>
              {`${
                fixationConditionText[
                  saleOrder.fixationCondition as FixationCondition
                ]
              }`}
            </Value>
          </Row>
          <Row>
            <Label>Fecha inicio de fijación: </Label>
            <Value>{formatDate(saleOrder.conditionDateFrom)}</Value>
          </Row>
          <Row>
            <Label>Fecha final de fijación: </Label>
            <Value>{formatDate(saleOrder.conditionDateTo)}</Value>
          </Row>
        </>
      )}
      <Row>
        <Label>Procedencia: </Label>
        <Value>{saleOrder.Origin.location}</Value>
      </Row>
      <Row>
        <Label>Destino: </Label>
        <Value>{saleOrder.Destination?.destination}</Value>
      </Row>
      <Row>
        <Label>Periodo de entrega: </Label>
        <Value>{saleOrder.deliveryPeriod}</Value>
      </Row>
      <Row>
        <Label>Fecha inicio de entrega: </Label>
        <Value>{formatDate(saleOrder.deliveryFrom)}</Value>
      </Row>
      <Row>
        <Label>Fecha final de entrega: </Label>
        <Value>{formatDate(saleOrder.deliveryTo)}</Value>
      </Row>
    </Container>
  )
}

export default ContractDetailList
