import React from 'react'
import CustomText from 'components/atoms/CustomText'
import { ViewAvatar } from './styles'
import { Colors } from '@qirapagos/web/src/theme'

interface Props {
  initials?: string
  isBig?: boolean
  width?: string | 'min-content' | 'max-content' | 'fit-content' | 'auto'
  minWidth?: string | 'auto'
  height?: string | 'auto'
  padding?: string | undefined
  left?: string
}

const AvatarCircle: React.FC<Props> = ({
  initials,
  isBig,
  width,
  height,
  minWidth,
  padding,
  left
}) => {
  return (
    <ViewAvatar
      width={width}
      height={height}
      minWidth={minWidth}
      padding={padding}
      left={left}
    >
      <CustomText
        customStyle={{ color: Colors.white, overflow: 'visible' }}
        size={!isBig ? 'xmedium' : 'mbig'}
        weight="mediumfont"
      >
        {initials}
      </CustomText>
    </ViewAvatar>
  )
}

AvatarCircle.defaultProps = {
  initials: 'AA',
  isBig: false,
  width: 'min-content',
  height: '62.5px',
  minWidth: '62.5px',
  padding: undefined,
  left: '50'
}

export default AvatarCircle
