import React from 'react'
import { Accordion } from '@chakra-ui/react'
import { ViewAcordionContainer } from './styles'

interface Props {
  children: React.ReactNode
  customStyle?: any
  defaultIndex?: number
  allowToggle?: boolean
  index?: number
  allowMultiple?: boolean
}

const AccordionComponent: React.FC<Props> = ({
  children,
  customStyle,
  defaultIndex,
  allowToggle,
  index,
  allowMultiple
}) => {
  return (
    <ViewAcordionContainer style={customStyle}>
      <Accordion
        defaultIndex={defaultIndex}
        allowMultiple={allowMultiple}
        allowToggle={allowToggle}
        index={index}
      >
        {children}
      </Accordion>
    </ViewAcordionContainer>
  )
}

export default AccordionComponent

AccordionComponent.defaultProps = {
  customStyle: {},
  defaultIndex: 0,
  allowToggle: false,
  index: undefined,
  allowMultiple: false
}
