import { Spacing } from '@qirapagos/lib/theme'
import { StyleSheet } from 'react-native'
import styled from 'styled-components'
import Button from 'components/atoms/Button'

export const styles = StyleSheet.create({
  spacing: {
    marginTop: Spacing.MEDIUM
  },
  text: {
    textAlign: 'center',
    marginBottom: Spacing.MEDIUM,
    textDecorationLine: 'underline'
  }
})

export const Container2FA = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-top: 30px;
`

export const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
`

export const ButtonStyled = styled(Button)`
  width: 15% !important;
  min-width: 100px !important;
  align-self: flex-end !important;
  margin-right: 30px !important;
`
