import {
  ContractActionTypes,
  IConditionType,
  IContract,
  ICurrency,
  IExchanger,
  IFixing,
  IGrain,
  IOperationDetail,
  IQuotaDetail,
  IZone
} from './types'

export const setContractLoading = () => ({
  type: ContractActionTypes.SET_LOADING
})

export const dismissContractLoading = () => ({
  type: ContractActionTypes.DISMISS_LOADING
})

export const setDetailContractLoading = () => ({
  type: ContractActionTypes.SET_LOADING_DETAIL
})

export const dismissDetailContractLoading = () => ({
  type: ContractActionTypes.DISMISS_LOADING_DETAIL
})

export const setContractLoadingOperationDetail = () => ({
  type: ContractActionTypes.SET_LOADING_OPERATION_DETAIL
})

export const dismissContractLoadingOperationDetail = () => ({
  type: ContractActionTypes.DISMISS_LOADING_OPERATION_DETAIL
})

export const setGrainType = (data: IGrain[]) => ({
  type: ContractActionTypes.GET_GRAIN_TYPE,
  payload: data
})

export const setConditionType = (data: IConditionType[]) => ({
  type: ContractActionTypes.GET_CONDITION_TYPE,
  payload: data
})

export const setCurrencies = (data: ICurrency[]) => ({
  type: ContractActionTypes.GET_CURRENCIES,
  payload: data
})

export const setContracts = (data: IContract[]) => ({
  type: ContractActionTypes.GET_CONTRACTS,
  payload: data
})

export const setOperationDetail = (data: IOperationDetail) => ({
  type: ContractActionTypes.GET_OPERATION_DETAIL,
  payload: data
})

export const setNewCupo = (data: any) => ({
  type: ContractActionTypes.SET_NEW_CUPO,
  payload: data
})

export const setQuotaDetail = (data: IQuotaDetail) => ({
  type: ContractActionTypes.GET_QUOTA_DETAIL,
  payload: data
})

export const setExchangers = (data: IExchanger[]) => ({
  type: ContractActionTypes.GET_EXCHANGERS,
  payload: data
})

export const clearSaleInfo = () => ({
  type: ContractActionTypes.CLEAR_SALE_INFO
})

export const clearTradePriceToSet = () => ({
  type: ContractActionTypes.CLEAR_TRADE_PRICE_TO_SET
})

export const setFixings = (data: IFixing) => ({
  type: ContractActionTypes.SET_FIXINGS,
  payload: data
})

export const setZones = (data: IZone[]) => ({
  type: ContractActionTypes.GET_ZONES,
  payload: data
})

export const setHarvestDate = (data) => ({
  type: ContractActionTypes.SET_HARVEST_DATE,
  payload: data
})

export const getDetailsContract = (data: any) => ({
  type: ContractActionTypes.GET_DETAILS_CONTRACT,
  payload: data
})
