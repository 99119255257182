import React, { useEffect, useMemo, useState } from 'react'
import { AccordionItemComponent, StepperContract, AccordionComponent } from 'components'
import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepTwo'
import SuccessTickWithBackground from 'assets/svg/SuccessTickWithBackground'
import { purple, white } from '@qirapagos/lib/theme/colors'
import { AccordionContainer, ScrollView } from './styles'
import { useAppSelector } from 'hooks/redux'

interface TitleInfo {
  title: string;
  isComplete: boolean;
  setComplete?: (value: boolean) => void;
}

const NewEnterpriseSteps: React.FC = () => {
  const [isFirstComplete, setFirstIsComplete] = useState(false)
  const [currentStepBox, setCurrentStepBox] = useState(0)
  const [index, setIndex] = useState(0)
  const { enterprise } = useAppSelector(state => state.enterprise)

  const titlesInfo: TitleInfo[] = useMemo(
    () => [
      {
        title: '1. Ingresá CUIT/CUIL',
        isComplete: isFirstComplete,
        setComplete: (value: boolean) => setFirstIsComplete(value)
      },
      {
        title: '2. Cargá documentación',
        isComplete: false
      }
    ],
    [isFirstComplete]
  )

  useEffect(() => {
    if (isFirstComplete) return setCurrentStepBox(1)
  }, [isFirstComplete])

  return (
    <ScrollView>
      <StepperContract
        titlesInfo={titlesInfo}
        currentStepBox={currentStepBox}
      />
      <AccordionContainer>
        <AccordionComponent
          allowToggle={false}
          index={index}
        >
          <AccordionItemComponent
            accordionTitle="1. Escribí tu CUIT/CUIL sin guiones ni espacios"
            accordionSubTitle="Recordá que debes poseer la documentación para actuar en nombre de esta empresa"
            accordionBody={(<StepOne setIndex={setIndex} titlesInfo={titlesInfo[0]} />)}
            customStyle={{ marginBottom: 5 }}
            icon={
            titlesInfo[0].isComplete && (
              <SuccessTickWithBackground
                circleColor={purple}
                tickColor={white}
              />
            )
          }
          />
          <AccordionItemComponent
            accordionTitle="2. Documentación"
            accordionSubTitle={`Subí la documentación que te autoriza a actuar en nombre de ${enterprise?.name}.
            Podés usar un poder certificado por un escribano público, estatuto, acta de designación
            o contrato entre socios. Subí el documento completo con todas sus páginas.`}
            accordionBody={(<StepTwo />)}
            customStyle={{ marginBottom: 5 }}
            icon={null}
          />
        </AccordionComponent>
      </AccordionContainer>
    </ScrollView>
  )
}

export default NewEnterpriseSteps
