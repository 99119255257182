import Button from 'components/atoms/Button'
import { white } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'

interface BoxInputsContainerProps {
  isAnyFileUploaded: boolean;
}

export const StepTwoContainer = styled.div`
  display: flex;
  background-color: ${white};
  flex-direction: column;
`

export const BoxInputsContainer = styled.div<BoxInputsContainerProps>`
  display: flex;
  width: 100%;
  justify-content: ${({ isAnyFileUploaded }) => (isAnyFileUploaded ? 'flex-start' : 'center')};
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 42px;
  margin-bottom: 42px;
  margin-left: 40px;
`

export const FilesUploadedContainer = styled.div`
  display: flex;
  margin-left: 40px;
  margin-bottom: 20px;
  justify-content: start;
  overflow: scroll;
  gap: 32px;
`

export const ContinueButton = styled(Button)`
  width: 15%;
  align-self: flex-end;
  margin-right: 30px;
`
