import styled from 'styled-components/native'
import { ViewAvatarShadowType, ViewAvatarContainerType } from './types'

export const ViewAvatarShadow = styled.View<ViewAvatarShadowType>`
  border-radius: 50%;
  padding: 15px;
  opacity: ${props => props.opacity};
  background-color: #7d66ff;
  border: 1px solid #7d66ff;
  min-width: 62.5px;
  min-height: 62.5px;
  left: ${props => `${props.left}px`};
  right: ${props => `${props.right}px`};
  top: ${props => `${props.top}px`};
  bottom: ${props => `${props.bottom}px`};
  width: ${props => props.width};
  height: ${props => props.height};
  z-index: ${props => props.zIndex || -1};
`

export const ViewAvatarContainer = styled.View<ViewAvatarContainerType>`
  flex-direction: ${props => props.flexDirection || 'row'};
`

export const TouchableOpacityAvatarButton = styled.TouchableOpacity`
  z-index: 99;
`
