import { ContractState } from './types'

const initialState: ContractState = {
  isLoading: false,
  saleOrders: [],
  error: undefined,
  selected: undefined,
  quotaSelected: undefined,
  isQuotaLoading: undefined,
  selectedApplication: undefined,
  selectedLiquidation: undefined
}

export default initialState
