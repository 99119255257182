import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint, getToken } from './utils'

const CUENTAS = '/cuentas'
const ACCOUNTINFO = 'account/info?'

export const accounts = (
  idclient: string,
  device: string,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(CUENTAS, keyEndpoints),
    {},
    { headers: { device, idclient } }
  )

export const accountInfo = (
  idClient: string | null,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${ACCOUNTINFO}${'idClient='}${idClient}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getAccountSummaries = async () => {
  const token = await getToken()
  return api.get(
    insertKeyEndpoint('account/account-summaries'),
    {},
    { headers: { token } }
  )
}

export default accounts
