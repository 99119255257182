import React, { useEffect, useRef } from 'react'

const useOutsideAlerter = (
  ref: React.RefObject<HTMLDivElement>,
  setState: (state: boolean) => void,
  state: boolean
) => {
  useEffect(() => {
    function handleClickOutside (event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setState(!state)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    })

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setState, state])
}

interface Props {
  children: React.ReactNode
  state: boolean
  setState: (state: boolean) => void
}
const OutsideAlerter: React.FC<Props> = ({ children, state, setState }) => {
  const wrapperRef = useRef<null | HTMLDivElement>(null)
  useOutsideAlerter(wrapperRef, setState, state)

  return <div ref={wrapperRef}>{children}</div>
}

export default OutsideAlerter
