import {
  SHOW_DRAWER_SUCCESS,
  SHOW_DRAWER_ERROR,
  SHOW_DRAWER_STATEMENT,
  SHOW_DRAWER_MY_AGENT,
  SHOW_DRAWER_NOTIFICATION,
  ModalActionTypes,
  SHOW_HELP_DRAWER,
  SHOW_DRAWER_GRAINS,
  SHOW_DRAWER_NOTIFICATION_DETAIL
} from './types'

const setShowModal = (modal: string) => ({
  type: ModalActionTypes.SHOW_MODAL,
  payload: modal
})

export const setShowDrawerSuccess = (
  show: boolean,
  success?: boolean,
  title?: string,
  body?: string,
  navigation?: any
) => ({
  type: SHOW_DRAWER_SUCCESS,
  payload: {
    show,
    success,
    title,
    body,
    navigation
  }
})

export const setShowDrawerError = (
  show: boolean,
  title?: string,
  body?: string,
  navigation?: any,
  btnText?: string,
  callback?: () => void
) => ({
  type: SHOW_DRAWER_ERROR,
  payload: {
    show,
    title,
    body,
    navigation,
    btnText,
    callback
  }
})

export const setShowDrawerStatement = (showStatementDrawer: boolean) => ({
  type: SHOW_DRAWER_STATEMENT,
  payload: {
    showStatementDrawer
  }
})

export const setShowDrawerMyAgent = (showDrawerMyAgent: boolean) => ({
  type: SHOW_DRAWER_MY_AGENT,
  payload: showDrawerMyAgent
})

export const setShowDrawerNotification = (show: boolean) => ({
  type: SHOW_DRAWER_NOTIFICATION,
  payload: show
})

export const setShowDrawerNotificationsDetails = (show: boolean) => ({
  type: SHOW_DRAWER_NOTIFICATION_DETAIL,
  payload: show
})

export const setShowDrawerHelp = (data: any) => ({
  type: SHOW_HELP_DRAWER,
  payload: data
})

export const setShowDrawerGrains = (
  showDrawerGrains: boolean,
  drawerConfig?: any,
  drawerInfo?: any
) => ({
  type: SHOW_DRAWER_GRAINS,
  payload: {
    showDrawerGrains,
    drawerConfig: {
      title: drawerConfig.title,
      config: drawerConfig.config
    },
    drawerInfo
  }
})

export default setShowModal
