import styled from 'styled-components'
import { white, gray } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainer = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${white};
  margin-top: 3.65vh;
  width: 100%;

  @media ${DEVICES.mobile} {
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
  }
`

export const TextDescription = styled.p`
  width: 60%;
  font-size: 1.4vw;
  text-align: center;
  margin-top: 2vh;
  color: ${gray};

  @media ${DEVICES.mobile} {
    font-size: 18px;
  }
`

export const TextTitle = styled.p`
  font-size: 1.5vw;
  font-weight: 500;
  margin-top: 4vh;
  color: ${gray};

  @media ${DEVICES.mobile} {
    font-size: 20px;
  }
`
export const ViewFieldContainer = styled.div`
  width: 30%;

  @media ${DEVICES.mobile} {
    width: 60%;
  }
`

export const ViewInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10vh;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    height: 90%;
  }
`

export const TextInfo = styled.p`
  font-size: 1.2vw;
  color: ${gray};
  margin-top: 15px;
  text-align: center;
  font-weight: 500;

  @media ${DEVICES.tablet} {
    font-size: 15px;
  }

  @media ${DEVICES.mobile} {
    font-size: 19px;
    margin-top: 0px;
    margin-bottom: 18px;
  }
`

export const TextCompanyName = styled.p`
  font-size: 3.5vw;
  font-weight: 500;
  text-align: center;
  color: ${gray};
  margin-top: 1.3vh;
  margin-bottom: 5.3vh;

  @media ${DEVICES.mobile} {
    font-size: 30px;
  }
`

export const ViewButtonLine = styled.div`
  align-self: flex-end;
  margin-right: 60px;

  @media ${DEVICES.mobile} {
    margin-bottom: 30px;
  }
`
