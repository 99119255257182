import { Colors } from '@qirapagos/lib/theme'
import { StyleSheet } from 'react-native'

export const ICON_DIAMETER = 60
export default StyleSheet.create({
  icon: {
    height: ICON_DIAMETER,
    width: ICON_DIAMETER,
    borderRadius: ICON_DIAMETER / 3
  },
  background: {
    backgroundColor: Colors.violetPressed
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  textColor: {
    color: Colors.violet
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 16
  }
})
