import { graySoft, purple } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;

   &:hover {
    background-color: ${graySoft};
    color:  ${purple};
   }
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px 0px;
`

export const TextList = styled.p`
  flex: 1;
  align-self: start;
  font-size: 1rem;
  margin-left: 15px;
`
