import { StyleSheet } from 'react-native'
import styled from 'styled-components'
import { Colors } from '@qirapagos/lib/theme'

export default StyleSheet.create({
  textColor: {
    color: Colors.violet
  }
})

type isBig = {
  isBig?: boolean
}

export const BoxContainer = styled.div<isBig>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  width: ${props => (props?.isBig ? '80px' : '32px')};
  height: ${props => (props?.isBig ? '80px' : '32px')};
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid ${Colors.violet};
`
export const BoxContainerIsBig = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid ${Colors.violet};
  padding: 15px;
`
