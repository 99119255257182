import styled from 'styled-components'
import { ReactComponent as InfoUpdatedIcon } from 'assets/svg/InfoUpdated.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
`

export const buttonStyles = {
  maxWidth: '350px'
}

export const InfoUpdated = styled(InfoUpdatedIcon)`
  width: 300px;
  margin-bottom: 2rem;
`
