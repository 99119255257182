import React from 'react'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { useAppSelector } from 'hooks/redux'
import { Drawers } from 'store/drawer/types'
import {
  Drawer,
  Title,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  ContentContainer
} from './styles'
import wrapper from 'components/organisms/Drawers/wrapper'
import ApplicationDetailList from 'components/molecules/ApplicationDetailList'
import { IApplication } from 'store/contracts/types'

interface Props {
  showDrawer: boolean
  onClose: () => void
}

const ApplicationsDetails: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { selectedApplication } = useAppSelector(state => state.contractsV2)

  return (
    <Drawer open={showDrawer} onClose={onClose} direction="right">
      <DrawerContainer>
        <HeaderContainer>
          <BackButton onPress={onClose}>
            <BackArrowIcon />
          </BackButton>
          <Title>Detalles de la Aplicación</Title>
        </HeaderContainer>
        <ContentContainer>
          <ApplicationDetailList
            application={selectedApplication as IApplication}
          />
        </ContentContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(ApplicationsDetails, Drawers.APPLICATIONS_DETAILS)
