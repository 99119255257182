import React from 'react'
import { animated, useTrail } from 'react-spring'
import { MenuItem } from 'components'
import { ListItemType } from 'constants/common'
import CustomText from 'components/atoms/CustomText'
import { TouchableOpacity } from 'react-native'

const AnimatedTrailView: any = ({
  visible,
  data,
  isMenuItemNeeded,
  customStyle,
  height
}: any) => {
  const trail = useTrail(data?.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: visible ? 1 : 0,
    x: visible ? 0 : 0,
    height: visible ? height : 0,
    from: { opacity: 0, x: 20, height: 0 }
  })
  return isMenuItemNeeded
    ? trail.map((styles, index) => (
      <animated.div style={{ ...styles }}>
        <MenuItem
          title={data[index].businessName}
          icon={ListItemType.AVATAR}
        />
      </animated.div>
    ))
    : trail.map((styles, index) => (
      <animated.div style={{ ...styles }}>
        <TouchableOpacity onPress={data[index].callback}>
          <CustomText customStyle={customStyle}>
            {data[index].businessName}
          </CustomText>
        </TouchableOpacity>
      </animated.div>
    ))
}

export default AnimatedTrailView
