import styled from 'styled-components'
import { Colors } from '@qirapagos/lib/theme'
import DrawerComponent from 'react-modern-drawer'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'
import { Text } from '@chakra-ui/react'

export const Drawer = styled(DrawerComponent)`
  width: 500px !important;
`

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.purple};
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
`

export const Title = styled.div`
  margin-bottom: 3%;
  margin-top: 10%;
  font-size: 24px;
  font-weight: 600;
  color: ${Colors.white};
`

export const TextBody = styled.p`
  width: 80%;
  margin-bottom: 40%;
  font-size: 14px;
  text-align: center;
  color: ${Colors.white};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
  width: 100%;
`

export const MainButton = styled(ButtonComponent)`
  width: 80%;
  height: 60px;
  background-color: ${Colors.white};
  margin-bottom: 20%;
`

export const MainButtonText = styled(Text)`
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.purple};
`
