import { Text as ChakraText } from '@chakra-ui/react'

import { WINDOW_WIDTH } from '@qirapagos/lib/constants/platform'
import { Colors, Spacing, Fonts } from '@qirapagos/lib/theme'
import styled from 'styled-components'

const HALF = WINDOW_WIDTH / 2
const BUTTON_WIDTH = HALF - Spacing.LATERAL - 5.5

interface TextProps {
  tertiary?: boolean;
  secondary?: boolean
}
interface ButtonProps extends TextProps{
  fromHome?: boolean;
  customStyle?: string;
  fixed?: boolean;
}

export const ButtonWrapper = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: ${(props) => (props.fixed ? 'absolute' : 'relative')};
  bottom: ${props => props.fixed ? '0' : 'auto'};
  width: ${props => props.fromHome ? BUTTON_WIDTH + 'px' : '100%'};
  height: 59px;
  font-family: 'Poppins';
  padding: ${props => props.fromHome ? '11px' : Spacing.SMALL + 'px'}, 0;
  margin-bottom: ${Spacing.BIG}px;
  ${props => {
    if (props.secondary) {
      return `
        background-color: ${Colors.purpleSec};
      `
    }
    if (props.tertiary) {
      return `
        background-color: ${Colors.white};
      `
    }
    return `
        background-color: ${Colors.purple};
      `
  }}
  border-radius: 8px;

  &:disabled {
    background-color: ${Colors.purpleDisabled};
    opacity: ${(props) => (props.secondary ? '0.5' : '1')};
  }
`

export const ButtonText = styled(ChakraText)<TextProps>`
  color: ${(props) => (props.secondary ? Colors.purple : Colors.white)};
  align-self: center;
  font-weight: 500;
  padding: 0px 5px;
  font-size: ${Fonts.SIZES.XMEDIUM}px;
`
