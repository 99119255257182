import React from 'react'
import CustomText from 'components/atoms/CustomText'
import { BoxContainer } from './styles'
import { Colors } from 'theme'

export interface Props {
  initials?: string
  isBig?: boolean
}

const AvatarOutline: React.FC<Props> = ({ initials, isBig }) => {
  return (
    <BoxContainer isBig={isBig}>
      <CustomText
        customStyle={{ color: Colors.violet }}
        size={!isBig ? 'small' : 'xbig'}
        weight="semibold"
      >
        {initials}
      </CustomText>
    </BoxContainer>
  )
}

export default AvatarOutline

AvatarOutline.defaultProps = {
  initials: 'AA',
  isBig: false
}
