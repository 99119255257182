import initialState from './initialState'
import { NotificationState, NotificationsActionsTypes, Notification } from './types'

export default function menuReducer (state = initialState, action: any): NotificationState {
  switch (action.type) {
    case NotificationsActionsTypes.ERROR:
      return {
        ...state,
        error: action.payload
      }
    case NotificationsActionsTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        pushNotifications: action.payload
      }
    case NotificationsActionsTypes.REMOVE: {
      return {
        ...state,
        pushNotifications: state.pushNotifications.filter((notification: Notification) => {
          return (action.payload !== notification.id)
        })
      }
    }
    case NotificationsActionsTypes.MARK_AS_VIEWED: {
      return {
        ...state,
        pushNotifications: state.pushNotifications.map((notification: Notification) => {
          if (action.payload === notification.id) {
            return {
              ...notification,
              viewed: true
            }
          }
          return notification
        })
      }
    }
    case NotificationsActionsTypes.SET_NOTIFICATION:
      return {
        ...state,
        selected: action.payload
      }
    case NotificationsActionsTypes.REMOVE_ALL:
    case NotificationsActionsTypes.LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
